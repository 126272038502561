import { types } from '../types'

/**
 * Redux Reducers
 * @module trainingReducer
 * @category Redux
 * @subcategory Pages / trainingSchedule
 */

/**
 * @typedef Action
 * @property {ActionsTrainingTypes} type
 * @property {*} [payload]
 * @example
 *  {
 *    type: 'FETCH_FETCH_CATEGORIES',
 *    payload: { ... }
 *  }
 */

/**
 * @const
 * @inner
 * @type {Array<*>}
 */
const data = []

/**
 * @const
 * @inner
 * @static
 * @property {Object} [selectedCourse]
 * @property {Array<*>} [courses]
 * @property {Array<*>} [schedules]
 * @property {Array<*>} [categories]
 * @property {*} [error]
 */
export const trainingReducerInitialState = {
    courses: [],
    categories: [],
    schedules: data,
    error: null,
    selectedCourse: null
}

/**
 * Training scheduler reducer.
 * Receives the new organization ID and save it in the store.
 * @function
 * @inner
 * @param {Object} state - The current state
 * @param {Action} action - Any necessary data (including the action type)
 * @returns {Object} The new state
 */
function trainingReducer (state = trainingReducerInitialState, action) {
    switch (action.type) {
    case types.FETCH_FETCH_CATEGORIES:
        return { ...state, categories: action.payload, error: null }
    case types.SET_PUBLIC_SCHEDULE_TIMES:
        return { ...state, schedules: action.payload, error: null }
    case types.DELETE_PUBLIC_SCHEDULE:
        return { ...state, schedules: action.payload, error: null }
    case types.FETCH_MY_COURSES:
        return { ...state, courses: action.payload, error: null }
    case types.SET_SELECTED_COURSE:
        return { ...state, selectedCourse: action.payload }
    case types.CLEAR_SCHEDULE:
        return {
            ...state,
            schedules: data,
            error: null
        }
    case types.ERROR:
        return { ...state, courses: null, error: action.payload }
    default:
        return state
    }
}

export default trainingReducer
