import { v4 as uuidv4 } from 'uuid'
import { cmsLanguage } from '../../../commons/cms_language/cmsLanguage'

const textArea = () => {
    const fields = [
        {
            name: 'text',
            type: 'TEXT_EDITOR',
            id: uuidv4(),
            isMandatory: true
        }
    ]

    const values = fields.map(f => ({
        fieldId: f.id,
        id: uuidv4(),
        value: '',
        field: f
    }))

    return {
        id: uuidv4(),
        name: cmsLanguage.templatesNames.textArea,
        type: 'TEXT_AREA',
        elements: [
            {
                id: uuidv4(),
                values: values,
                type: 'default'
            }
        ],
        fields: fields
    }
}

export const TEXT_AREA_TEMPLATE_ORDER = { TEXT_EDITOR: 0 }

export default textArea
