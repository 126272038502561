import React, { useEffect, useState } from 'react'
import './menuItem.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Container, Nav, NavDropdown, Row } from 'react-bootstrap'
import { faStream } from '@fortawesome/free-solid-svg-icons'
import SearchBar from '../../../../../theme/commons/search_bar/SearchBar'
import { Link, useHistory } from 'react-router-dom'
import store from '../../../../../redux/store'
import { LOGGING_URL } from '../../../../../backend/mesh/Constants'
import { popPathElement } from '../../../../../utils/CommonReformatter'
import { translateValue } from '../../../../../utils/elementsUtils'
import EditableMenuItem from './EditableMenuItem'

/**
 * @typedef MenuItemProps
 * @memberOf MenuItem
 * @property {Menu} menu
 * @property {boolean} [fullDesktopMenu]
 * @property {function} setHeaderOpened
 * @property {boolean} [isSubPageMenu]
 * @property {string} [data-testid]
 */

/**
 * Menu item component
 * @class MenuItem
 * @category Components
 * @subcategory Header
 * @param {MenuItemProps} props
 * @returns {React.ReactNode}
 * @example
 * <MenuItem
 *     menu={{...}}
 *     className="sub-page-menu"
 *     isSubPageMenu={true}
 *     setHeaderOpened={setHeaderOpened}
 * />
 */
const MenuItem = (props) => {
    const history = useHistory()

    const {
        menu,
        fullDesktopMenu,
        setHeaderOpened,
        isSubPageMenu,
        'data-testid': testId = 'menu-item-testId'
    } = props

    const [listFiltered, setListFiltered] = useState(menu.children)

    useEffect(() => {
        setListFiltered(menu.children)
    }, [menu])

    /**
     * If `fullDesktopMenu` is true, calls the setHeaderOpened with param true if `.dropdown.show` is on the page.
     * @function
     * @memberOf MenuItem
     */
    const handleMakeHeaderDark = () => {
        fullDesktopMenu &&
        setTimeout(() => {
            setHeaderOpened(document.querySelector('.dropdown.show') != null)
        }, 10)
    }

    /**
     * Handles click menu item
     * @function
     * @memberOf MenuItem
     * @param {string} path
     * @param {string} contentId
     */
    const handleClick = (path, contentId) => {
        const user = store.getState().user
        let resultantPath
        if (!isSubPageMenu) {
            if (path.split('/').slice(1).join('') === 'members') {
                if (!user.loggedIn) {
                    window.location.href = LOGGING_URL
                } else {
                    resultantPath = '/' + popPathElement(path)
                }
            } else {
                resultantPath = '/' + popPathElement(path)
            }
        } else {
            resultantPath = '/' + path
        }
        history.push(resultantPath)
        setHeaderOpened && setHeaderOpened(false)
    }

    /**
     * @function
     * @memberOf MenuItem
     * @returns {JSX.Element}
     */
    const onRenderLink = () => (
        <Nav.Link
            onClick={() => handleClick(menu.path, menu.contentId, menu)}
            className={isSubPageMenu && 'sub-menu-item'}
        >
            {translateValue(menu.name)}
            <EditableMenuItem menuItem={menu}/>
        </Nav.Link>
    )

    /**
     * @function
     * @memberOf MenuItem
     * @returns {JSX.Element}
     */
    const onRenderDropdown = () => (
        <NavDropdown
            title={translateValue(menu.name)}
            id={isSubPageMenu ? 'sub-menu-item' : `dropdown-${menu.id}`}
            className={
                isSubPageMenu ? 'sub-page-item' : 'dropdown-without-subcategories'
            }
            onClick={handleMakeHeaderDark}
        >
            {menu.children.map(subMenu => {
                return (
                    <NavDropdown.Item
                        key={subMenu.id}
                        className={isSubPageMenu && 'sub-page-sub-item'}
                        onClick={() =>
                            handleClick(subMenu.path, subMenu.contentId, subMenu)
                        }
                    >
                        <Row key={subMenu.id}>
                            <Col xs={8}>
                                {subMenu.name}
                            </Col>
                            <EditableMenuItem menuItem={subMenu}/>
                        </Row>
                    </NavDropdown.Item>
                )
            })}
        </NavDropdown>
    )

    /**
     * @function
     * @memberOf MenuItem
     * @returns {JSX.Element}
     */
    const onRenderFullDropdown = () => (
        <NavDropdown
            title={translateValue(menu.name)}
            id={`dropdown-${menu.id}`}
            className="dropdown-with-subcategories"
            onClick={handleMakeHeaderDark}
            data-testid={testId}
        >
            <Container fluid className="container-submenu">
                <Row>
                    <Col xl={12} className="search">
                        <SearchBar
                            listDefault={menu.children}
                            setListFiltered={setListFiltered}
                            filterKey={'name'}
                            secondLevelKey={'children'}
                        />
                    </Col>
                </Row>
                <Row>
                    {listFiltered.map((subMenu) => {
                        return (
                            subMenu.children.length
                                ? (
                                    <Col xl={2} key={subMenu.id} className="dropdown-subcategory">
                                        <div className="dropdown-subcategory-icon">
                                            <FontAwesomeIcon
                                                icon={
                                                    subMenu.icon
                                                        ? Array.from(subMenu.icon.name.split('_'))
                                                        : faStream
                                                }
                                            />
                                        </div>
                                        <div className="dropdown-subcategory-list">
                                            <Row key={subMenu.id}>
                                                <Col xs={8}>
                                                    <div className="subcategory-title">{translateValue(subMenu.name)}</div>
                                                </Col>
                                                <EditableMenuItem menuItem={subMenu}/>
                                            </Row>
                                            {subMenu.children.map((subMenuList) => {
                                                return (
                                                    <Row key={subMenuList.id}>
                                                        <Col xs={8}>
                                                            <NavDropdown.Item
                                                                as={Link}
                                                                to={
                                                                    '/' + popPathElement(subMenuList.path)
                                                                }
                                                                onClick={() => setHeaderOpened(false)}
                                                                key={subMenuList.id}
                                                            >
                                                                {translateValue(subMenuList.name)}
                                                            </NavDropdown.Item>
                                                        </Col>
                                                        <EditableMenuItem menuItem={subMenuList}/>
                                                    </Row>
                                                )
                                            })}
                                        </div>
                                    </Col>
                                )
                                : ''
                        )
                    })}
                </Row>
            </Container>
        </NavDropdown>
    )

    return menu && menu.contentId !== null
        ? onRenderLink()
        : menu.children[0] && menu.children[0].contentId !== null
            ? onRenderDropdown()
            : onRenderFullDropdown()
}

export default MenuItem
