import React from 'react'
import './HomeAboutUs.scss'
import { Container, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NavigationWrapper from '../../../theme/commons/navigation_wrapper/NavigationWrapperContainer'
import { NAVIGATION_TYPE } from '../../../theme/constants/navigationType'

/**
 * @typedef HomeAboutUsProps
 * @memberOf HomeAboutUs
 * @property {Object} parallaxContent
 */

/**
 * Home about us information with a parallax content.
 * @class HomeAboutUs
 * @category Components
 * @subcategory Home
 * @param {HomeAboutUsProps} props
 * @returns {React.ReactNode}
 * @example
 * <HomeAboutUs parallaxContent={parallaxContent} />
 */
const HomeAboutUs = ({ parallaxContent }) => {
    return (
        <Container fluid className="about-us-container">
            <Row className="about-image-row">
                <Col
                    data-testid="parallax"
                    className="parallax"
                    style={{
                        position: 'relative',
                        backgroundImage: `url(${parallaxContent.PHOTO})`
                    }}
                >
                    <div className="gradient-overlay" />
                </Col>
            </Row>
            <Row xs={1} md={2} className="about-container">
                <Col md={4} className="column one">
                    <span data-testid="title" className="title">
                        {parallaxContent.H1}.
                    </span>
                    {parallaxContent.H2 && (
                        <span data-testid="subtitle" className="subtitle">
                            {parallaxContent.H2}
                        </span>
                    )}
                </Col>
                <Col md={8} className="column two">
                    <span data-testid="parallax-text" className="text">
                        {parallaxContent.LONG_TEXT}
                    </span>
                    {parallaxContent.LINK && parallaxContent.SHORT_TEXT && (
                        <>
                            <FontAwesomeIcon
                                data-testid="right-arrows-icon"
                                className="right-arrows-icon"
                                icon={['fas', 'angle-double-right']}
                            />
                            <NavigationWrapper
                                type={NAVIGATION_TYPE.link}
                                href={parallaxContent.LINK}
                                className="link-text"
                                data-testid="link-text"
                            >
                                {parallaxContent.SHORT_TEXT}
                            </NavigationWrapper>
                        </>
                    )}
                </Col>
            </Row>
        </Container>
    )
}

export default HomeAboutUs
