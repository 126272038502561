import CourseArea from './CourseArea'
import React from 'react'
import useCoursesData from './useCoursesData'

/**
 * Returns the {@link CourseArea} component with the data `coursesData`.
 * @class CoursesAreaContainer
 * @category Containers
 * @subcategory Templates / MembersArea
 * @returns {React.ReactNode}
 */
const CoursesAreaContainer = () => {
    const coursesData = useCoursesData()

    return <CourseArea data={coursesData}/>
}

export default CoursesAreaContainer
