/**
 *
 * @module storage
 * @category Utils
 * @subcategory General
 */

/**
 * Stores the object in localStorage, parsing it into a string
 * @function
 * @param {string} key
 * @param {Object} data
 */
export const save = (key, data) => {
    localStorage?.setItem(key, JSON.stringify(data))
}

/**
 * Gets a data stored in localStorage, parsing it into an object
 * @function
 * @param {string} key
 * @returns {Object|undefined}
 */
export const get = (key) => {
    let value
    try {
        const item = localStorage?.getItem(key)
        value = JSON.parse(item)
    } catch (e) {
        console.error('Error while getting item for key ' + key)
        value = undefined
    }
    return value
}

/**
 * Remove an object from localStorage
 * @function
 * @param {string} key
 */
export const remove = (key) => {
    localStorage?.removeItem(key)
}
