/**
 * @module getDisplayName
 * @category Utils
 * @subcategory General
 */

/**
 * Takes a React component and return a specific property (displayName || name || 'Component'
 * @function
 * @param {Object} BaseComponent - Functional React Component
 * @returns {string}
 */
export const getDisplayName = (BaseComponent) => BaseComponent.displayName || BaseComponent.name || 'Component'

/**
 * Get the displayName of a React component and add it to another React component (BaseComponent -> WithComponent)
 * @function
 * @param {Object} BaseComponent - Functional React Component
 * @param {function} WithComponent - Functional React Component that will be enhanced
 * @param {string} name
 * @example
 * setDisplayName(BaseComponent, WithErrorMessage, 'withErrorMessage')
 */
export const setDisplayName = (BaseComponent, WithComponent, name) => {
    WithComponent.displayName = `${name}(${getDisplayName((BaseComponent))})`
}
