import React from 'react'
import { Col, Row } from 'react-bootstrap'
import './familyMember.scss'

const FamilyMember = ({ firstNameElement, birthDayElement, addRemoveElement, counterElement }) => {
    return (
        <Row className={'family-member-inputs'}>
            <Col>
                {firstNameElement}
            </Col>
            <Col className={'family-member-inputs-date'}>
                {birthDayElement}
                {counterElement}
            </Col>
            {addRemoveElement}
        </Row>
    )
}

export default FamilyMember
