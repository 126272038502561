// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".main-menu-container{background-color:rgba(0,0,0,0.75);padding:0 3.75em 0 0}.main-menu-container.dark-background{transition:all 1000ms ease;background-color:#000}.navbar-brand{padding-top:0;padding-bottom:0;margin-right:0;font-size:inherit}.navbar-toggler{border:none}.navbar-toggler .navbar-toggler-icon{background-image:none;display:flex;align-items:center}.navbar-toggler .navbar-toggler-icon:before{font-family:'Font Awesome 5 Free';font-weight:900;content:\"\";color:#fff;font-size:1.875em}.navbar-toggler.collapsed .navbar-toggler-icon:before{font-family:'Font Awesome 5 Free';font-weight:900;content:\"\"}.navbar-toggler:focus{outline:none}.navbar-collapse.show,.navbar-collapse.collapsing{height:90vh;overflow-y:auto;padding:0 0 3.75em 3.75em}.navbar-collapse.show .header-social-media,.navbar-collapse.collapsing .header-social-media{margin-left:0;margin-top:3.75em}.mobile-avatar-menu-group{display:flex;flex-direction:row;gap:.9375em}.header-main-navigation{width:100%;justify-content:center !important;padding-right:2.8125em}.header-social-media{margin-left:3.75em}.sub-page-menu{border-top:solid 1px #fff;margin-top:1.875em;padding-top:2.8125em}@media screen and (min-width: 576px){.sub-page-menu{display:none}}@media screen and (max-width: 320px){.main-menu-container{padding-right:2.8125em}}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
