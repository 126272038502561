import React, { useState } from 'react'
import AdminButton from '../../../commons/admin_button/AdminButton'
import './addClassTemplateBar.scss'
import { cmsLanguage } from '../../../commons/cms_language/cmsLanguage'
import Modal from '../../../../theme/commons/Modal/Modal'
import AddEditClassModal from '../../../../theme/commons/AddEditClass/AddEditClassContainer'

const AddClassTemplateBar = (props) => {
    const { selectedCourse } = props
    const [isOpen, setIsOpen] = useState(false)

    const handleCloseEditClassModal = () => {
        setIsOpen(false)
    }

    return (
        <>
            {isOpen &&
                <Modal className="modal-common-config">
                    <AddEditClassModal onClose={handleCloseEditClassModal} selectedCourse={selectedCourse}/>
                </Modal>}
            <div className={'edit-template-bar add-class-bar horizontal-website-padding'}>
                <AdminButton
                    onClick={() => setIsOpen(true)}
                    text={cmsLanguage.cms.addClass}
                    type={'solid'}
                    icon={['fa', 'plus']}
                    color={'green'}
                    data-testid="add-class"
                />
            </div>
        </>
    )
}

export default AddClassTemplateBar
