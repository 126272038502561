// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".info-box{display:flex;align-items:center;margin-top:1.875em;padding:.9375em;background-color:#f2f2f2}.info-box .info-icon{font-size:1.375em;color:#595959}.info-box p{font-family:rucksack, sans-serif;font-weight:400;font-style:normal;font-size:1em;color:#191919;font-weight:lighter;margin:0;padding-left:.9375em}.info-box span{font-weight:600;text-transform:capitalize}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
