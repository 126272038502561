import { cmsLanguage } from '../cms/commons/cms_language/cmsLanguage'

/**
 * Enum for weekdays number
 * @readonly
 * @category Enums
 * @enum {number}
 * @example
 * MONDAY: 1,
 * TUESDAY: 2,
 * WEDNESDAY: 3,
 * THURSDAY: 4,
 * FRIDAY: 5,
 * SATURDAY: 6,
 * SUNDAY: 7
 */
export const DaysEnum = Object.freeze({
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
    SUNDAY: 7
})

/**
 * Weekdays name (i18n)
 * @category Enums
 * @type {Array<string>}
 * @example
 * 0: Monday,
 * 1: Tuesday,
 * 2: Wednesday,
 * 3: Thursday,
 * 4: Friday,
 * 5: Saturday,
 * 6: Sunday
 */
export const weekday = [
    cmsLanguage.weekday.monday,
    cmsLanguage.weekday.tuesday,
    cmsLanguage.weekday.wednesday,
    cmsLanguage.weekday.thursday,
    cmsLanguage.weekday.friday,
    cmsLanguage.weekday.saturday,
    cmsLanguage.weekday.sunday
]
