import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'

import TrainingPage from './TrainingPage'
import * as actions from './actions/trainingActions'
import { toggleTrainingPage, setIsHomePage } from '../../cms/actions/CMSActions'
import { setSelectedCourse } from './actions/trainingActions'

/**
 * {@link TrainingPage} container that binds the Training with the {@link module:trainingReducer}
 * @class TrainingContainer
 * @category Containers
 * @subcategory trainingSchedule
 *
 * @mermaid
 *   sequenceDiagram
 *   Parent->>TrainingContainer: Request the Training via TrainingContainer.
 *   Note right of TrainingContainer: TrainingContainer gets information from redux store.
 *   TrainingContainer->>Training: TrainingContainer sends redux store information to Training as props.
 *   Training-->>Parent: Training is rendered in the parent component.
 */

/**
 * Gets the `state.user` from redux state and makes available to the {@link TrainingPage} as a prop called `user`.
 * @function
 * @memberOf TrainingContainer
 * @alias mapStateToProps
 * @param {Object} state - redux state
 * @returns {Object}
 */
const mapStateToProps = (state) => {
    const { training, cms } = state
    return {
        courses: training.courses,
        schedules: training.schedules,
        isEditModeOn: cms.isEditModeOn,
        categories: training.categories,
        selectedCourse: training.selectedCourse
    }
}

/**
 * Passes certain Redux actions ({@link module:TrainingActions},
 * [toggleTrainingPage]{@link module:CmsActions.toggleTrainingPage} and
 * [setIsHomePage]{@link module:CmsActions.setIsHomePage})
 * to the {@link TrainingPage} component as props.
 * This function is used only by the react-redux connect function.
 * @function
 * @memberOf TrainingContainer
 * @alias mapDispatchToProps
 * @param {function} dispatch - The react-redux dispatch function
 * @returns {Object} - Redux actions used in the {@link TrainingPage} component
 */
const mapDispatchToProps = (dispatch) => bindActionCreators({ toggleTrainingPage, setIsHomePage, setSelectedCourse, ...actions }, dispatch)

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    TrainingPage
)
