import React, { useState } from 'react'
import './TemplateEditorModal.scss'
import CmsModal from '../../../commons/cms_modal/CmsModal'
import CmsModalMultipleElements from '../cms_modal_multiple_elements/CmsModalMultipleElementsContainer'
import Dropdown from '../../../../theme/commons/dropdown/Dropdown'
import { cmsLanguage } from '../../../commons/cms_language/cmsLanguage'
import generateTemplate from '../../../util/importTemplateInputs'
import { initializerTrainingScheduleDataName } from '../../../../initializerData'
import CmsModalTitle from '../../../commons/cms_modal/CmsModalTitle'
import InfoBoxSection, { BOLD_TAG } from '../../../cms_content/components/add_cms_content_modal_components/info_box_container/InfoBoxSection'
import { isEqual } from 'lodash'

const TemplateEditorModal = (props) => {
    const {
        template,
        onSave,
        onClose,
        onDelete,
        isNewPage,
        availableTemplates,
        contentDB
    } = props

    const [localTemplate, setLocalTemplate] = useState(template)

    // showError can have 3 states. This is to check if the input have ever been changed
    const [showError, setShowError] = useState(undefined)
    const [isTemplateChange, setIsTemplateChange] = useState(undefined)
    const foundTemplate = template && availableTemplates.find((item) => item.id === template.type)
    const [templatePicture, setTemplatePicture] = useState(foundTemplate ? foundTemplate.picture : '')

    const assignTemplatePreviewPicture = templateType => {
        const tempTemplatePicture = availableTemplates.find((item) => item.id === templateType).picture
        tempTemplatePicture
            ? setTemplatePicture(tempTemplatePicture)
            : setTemplatePicture('')
    }
    const handleTemplateSelection = (name, templateType) => {
        assignTemplatePreviewPicture(templateType)
        const templateDB = template && contentDB.templates.find(templateDB => templateDB.id === template.id && templateDB.type === templateType)
        if (templateDB) {
            // restoring the template from contentDB when user is returning from new template (eg. text) to existing template (eg. course info)
            setLocalTemplate(templateDB)
        } else {
            // when the user is changing to a new template - a new template is created and
            // the error and button need to be restored to default values
            setIsTemplateChange(false)
            setShowError(false)
            const newTemplate = generateTemplate(templateType)
            if (!!localTemplate && !!localTemplate.id) {
                newTemplate.id = localTemplate.id
            }
            setLocalTemplate(newTemplate)
        }
    }
    const handleOnChange = (updatedTemplate, hasError) => {
        const templateClone = JSON.parse(JSON.stringify(updatedTemplate))
        templateClone.elements.forEach(element => delete element.hasError)
        const isTemplateEqual = isEqual(templateClone, template)
        setShowError(hasError)
        setIsTemplateChange(!isTemplateEqual)
        setLocalTemplate({ ...updatedTemplate })
    }
    const handleOnSave = () => {
        onSave(localTemplate)
    }
    const title = () => {
        const customTitle = cmsLanguage.cms.createNewContent
        return (isNewPage && customTitle) || localTemplate?.name || cmsLanguage.cms.addNewTemplate
    }
    const secondTitle = () => {
        const customSubtitle = cmsLanguage.cms.manageContent
        return (isNewPage && customSubtitle) || ''
    }
    const continueText = () => {
        const continueText = cmsLanguage.cms.createPage
        return (isNewPage && continueText) || ''
    }
    const cancelText = () => {
        const cancelText = cmsLanguage.cms.cancel
        return (isNewPage && cancelText) || ''
    }
    const shouldShowInfo = () => {
        return isNewPage && !!localTemplate && showError === false
    }

    const infoBoxTitle = () => localTemplate.elements[0].values[0].value

    const dropdownDisabled = () => isChangeTemplateAllowed && template && template.name === initializerTrainingScheduleDataName

    const infoBoxFullText = `${cmsLanguage.infoBox.titleText1}${cmsLanguage.infoBox.titleText2}${BOLD_TAG}${cmsLanguage.infoBox.titleText3}`

    const isChangeTemplateAllowed = !template || (template && availableTemplates && availableTemplates.length > 0)

    return (
        <CmsModal
            title={<CmsModalTitle title={title()} detail={secondTitle()}/>}
            onSave={handleOnSave}
            onClose={onClose}
            onCancel={onClose}
            onDelete={onDelete ? () => onDelete(localTemplate) : null}
            showError={showError}
            saveAvailable={showError === false && isTemplateChange}
            continueText={continueText()}
            cancelText={cancelText()}
        >
            {isChangeTemplateAllowed &&
            <div className="add-cms-modal-top">
                <Dropdown
                    title={cmsLanguage.cms.chooseTemplate}
                    data={availableTemplates}
                    headerOnly="true"
                    onSelect={(name, type) => handleTemplateSelection(name, type)}
                    printHeader={true}
                    providedValue={template ? template.name : ''}
                    disabled={dropdownDisabled()}
                />
                {templatePicture && (
                    <img src={templatePicture} alt={cmsLanguage.cms.templatePreview}/>
                )}
            </div>
            }

            {localTemplate && (
                <CmsModalMultipleElements
                    template={localTemplate}
                    onChange={handleOnChange}
                />
            )}

            {shouldShowInfo() &&
            <InfoBoxSection
                fullText={infoBoxFullText}
                boldText={infoBoxTitle()}
            />}
        </CmsModal>
    )
}

export default TemplateEditorModal
