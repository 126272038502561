import { popPathElement } from '../../utils/CommonReformatter'
import React from 'react'
import './FooterMenuItem.scss'
import { translateValue } from '../../utils/elementsUtils'
import HTMLReactParser from 'html-react-parser'

/**
 * @typedef FooterMenuItemProps
 * @memberOf FooterMenuItem
 * @property {Array<Object>} menuItems
 */

/**
 * Footer menu item component for the {@link FooterColumn}
 * @class FooterMenuItem
 * @category Components
 * @subcategory Footer
 * @param {FooterMenuItemProps} props
 * @returns {React.ReactNode}
 * @example
 * <FooterMenuItem menuItems={[{...}]} />
 */
const FooterMenuItem = ({ menuItems }) => {
    return <ul className='footer-menu-item-container'>
        {menuItems?.map((menu) => {
            const name = translateValue(menu.name)
            return (
                menu.path
                    ? <li className='footer-menu-item' key={menu.id}>
                        <a href={'/' + popPathElement(menu.path)} key={menu.id} data-testid='footer-menu'>
                            {name}
                        </a>
                    </li>
                    : menu.name &&
                    <li className='footer-menu-item' key={menu.id}>
                        <div>
                            {typeof name === 'string' ? HTMLReactParser(name) : name}
                        </div>
                    </li>
            )
        })}
    </ul>
}

export default FooterMenuItem
