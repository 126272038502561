import { useEffect, useState } from 'react'
import Mesh from '../../backend/mesh/Mesh'

export const initialUserData = {
    firstName: '',
    lastName: '',
    displayName: '',
    birthDate: '',
    gender: '',
    email: '',
    contactNumber: '',
    streetNumber: '',
    postCode: '',
    city: '',
    sports: '',
    id: '',
    profileImage: '',
    countryCode: '',
    departmentId: '',
    department: '',
    description: '',
    roleId: '',
    eventsGroups: [],
    memberSince: '',
    address: {
        postCode: '',
        city: '',
        streetNumber: ''
    },
    paymentData: {
        bic: '',
        bank: '',
        holderName: '',
        iban: ''
    }
}

/**
 * Gets logged-in user information from the users-service and makes them available for use.
 * @function
 * @category Hooks
 * @param {Object} initialUserData
 * @returns {Array<*>} - [userData: User, isLoading: boolean]
 * @example
 * const [userData, setUserData] = useUserData(initialFullUserData)
 */
const useUserData = (initialUserData) => {
    const [userData, setUserData] = useState(initialUserData)
    const [isLoading, setIsLoading] = useState(true)

    // Fetch User
    useEffect(() => {
        let isCancelled = false
        const fetchUserData = () => {
            Mesh.getUserData()
                .then((response) => {
                    console.debug('User data received', response)
                    !isCancelled && setUserData(response)
                    !isCancelled && setIsLoading(false)
                })
                .catch((err) => {
                    console.error(err)
                    setIsLoading(false)
                })
        }
        !isCancelled && fetchUserData()
        return () => {
            isCancelled = true
        }
    }, [])

    return [userData, setUserData, isLoading]
}
export default useUserData
