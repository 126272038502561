import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { language } from '../../../../../../theme/commons/language/language'
import './formDetails.scss'
import { IDS } from '../../utils/inputFieldsList'

/**
 *
 * @param {JSX.Element} props.familyElement
 * @param {Map<string, JSX.Element>} props.detailsElements
 * @return {JSX.Element}
 * @constructor
 */
const FormDetails = (props) => {
    const { familyElement, detailsElements } = props
    const renderInputById = (id) => detailsElements && detailsElements[id] ? detailsElements[id] : <></>
    return (
        <Col className={'details-container'} lg={6}>
            {familyElement || <></>}
            <h5>{language.membership.address}</h5>
            {renderInputById(IDS.STREET_ADDRESS)}
            <Row>
                <Col lg={3}>{renderInputById(IDS.POSTCODE)}</Col>
                <Col lg={9}>{renderInputById(IDS.CITY)}</Col>
            </Row>

            <h5>{language.membership.contact}</h5>
            <Row>
                <Col lg={7}>{renderInputById(IDS.PHONE)}</Col>
            </Row>
            {renderInputById(IDS.EMAIL)}
            <h5>{language.membership.paymentInfo}</h5>
            <Row>
                <Col lg={7}>{renderInputById(IDS.IBAN)}</Col>
                <Col lg={5}>{renderInputById(IDS.BIC)}</Col>
            </Row>

            {renderInputById(IDS.BANK)}
            {renderInputById(IDS.ACC_HOLDER)}
        </Col>
    )
}

export default FormDetails
