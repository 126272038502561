// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".add-cms-modal-content{display:block}.add-cms-modal-content h4{text-transform:capitalize}.add-cms-modal-content img{height:100%;width:50%;margin-left:3.75em;padding:.3125em;border:solid 1px #b8b8b8}.add-cms-modal-content .add-content-dropdown-div{display:flex}.add-cms-modal-content .cms-section{border:1px solid #64b42d;padding:.9375em .9375em 1.875em .9375em;width:100%;display:grid;grid-row-gap:1.875em;margin-bottom:.9375em}.add-cms-modal-inputs{margin-top:.9375em}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
