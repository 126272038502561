import React from 'react'
import './TopBar.scss'
import { LOGGING_URL } from '../../../backend/mesh/Constants'
import NavigationWrapper from '../../../theme/commons/navigation_wrapper/NavigationWrapperContainer'
import { popPathElement } from '../../../utils/CommonReformatter'
import ProfileWithMenu from '../profile/ProfileWithMenu'
import camelcase from 'camelcase'
import { cmsLanguage } from '../../../cms/commons/cms_language/cmsLanguage'

/**
 * @function
 * @memberOf TopBar
 * @returns {JSX.Element}
 */
const Login = () => (
    <a href={LOGGING_URL} className="top-bar-item login-logout-button" data-testid={'login'}>
        LOGIN
    </a>
)

/**
 * @typedef TopBarProps
 * @memberOf TopBar
 * @property {Menu} topMenu
 * @property {boolean} [fullDesktopMenu]
 * @property {boolean} [headerOpened]
 * @property {User} [user]
 */

/**
 * Application Top Bar component.
 * @class TopBar
 * @category Components
 * @subcategory Header
 * @param {TopBarProps} props
 * @returns {React.ReactNode}
 * @example
 * <TopBar topMenu={topMenu} />
 */
const TopBar = (props) => {
    const { topMenu, fullDesktopMenu, headerOpened, user } = props
    const ProfileManagement = () => fullDesktopMenu
        ? <ProfileWithMenu />
        : <></>

    const parseName = (menuName) => {
        const key = camelcase(menuName) + 'MenuName'
        return cmsLanguage.initializerData[key] || menuName
    }

    return (
        <div
            className={`top-bar ${
                fullDesktopMenu ? 'top-bar-big' : 'top-bar-collapsed'
            } ${headerOpened ? 'dark-background' : ''}`}
        >
            {topMenu &&
                topMenu.map((item) => {
                    return (
                        <NavigationWrapper
                            key={item.id}
                            className="top-bar-item"
                            href={'/' + popPathElement(item.path)}
                            type='LINK'
                        >
                            {parseName(item.name)}
                        </NavigationWrapper>
                    )
                })}

            {!user
                ? <Login/>
                : <ProfileManagement />
            }
        </div>
    )
}

export default TopBar
