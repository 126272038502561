import React from 'react'
import './BlockNavigationAlertModal.scss'
import AdminButton from '../../../cms/commons/admin_button/AdminButton'
import { cmsLanguage } from '../../../cms/commons/cms_language/cmsLanguage'
import { faTimes, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/**
 * @typedef BlockNavigationAlertModalProps
 * @memberOf BlockNavigationAlertModal
 * @property {function} onContinue
 * @property {function} onClose
 */

/**
 * Modal body that will be shown when some warning happens
 * @class BlockNavigationAlertModal
 * @category Components
 * @subcategory theme / commons
 * @param {BlockNavigationAlertModalProps} props
 * @returns {React.ReactNode}
 * @example
 * <BlockNavigationAlertModal onClose={handleOnClose} onContinue={handleOnContinue} />
 */
const BlockNavigationAlertModal = (props) => {
    const { onContinue, onClose } = props

    return (
        <div className="cms-block-nav-modal" data-testid="info-block-nav-modal">
            <FontAwesomeIcon icon={faExclamationTriangle} className="cms-block-nav-modal-triangle"/>

            <div className={'cms-block-nav-modal-title'}>
                <div className="cms-block-nav-modal-title-headline">
                    <h3 className="cms-block-nav-modal-title-headline-text" data-testid="deleteContent">{cmsLanguage.cms.blockNavigation}</h3>
                </div>
            </div>

            <pre className={'cms-block-nav-modal-description'}>
                {cmsLanguage.cms.blockNavigationDescription}
            </pre>

            <div className="cms-block-nav-modal-buttons">
                <div className="cms-block-nav-modal-buttons-container">
                    <div className="cms-block-nav-modal-buttons-values">
                        <AdminButton
                            text={cmsLanguage.cms.continue}
                            type={'border-solid'}
                            color="red"
                            icon={faTrashAlt}
                            onClick={onContinue}/>
                        <AdminButton
                            text={cmsLanguage.cms.cancel}
                            type={'border-solid'}
                            color="black"
                            icon={faTimes}
                            onClick={onClose}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlockNavigationAlertModal
