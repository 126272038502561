/**
 *
 * @module storage
 * @category Utils
 * @subcategory General
 */

/**
 *
 * @type {{LAST_URL: string, JOIN_COURSE: string, USER: string, TERMS_AND_CONDITIONS: String}}
 * @example
 * LAST_URL: 'lastUrl',
 * JOIN_COURSE: 'join-course',
 * USER: 'user',
 * TERMS_AND_CONDITIONS: 'IsTermsAndConditionsAccepted'
 */
const STORAGE_KEYS = {
    LAST_URL: 'lastUrl',
    JOIN_COURSE: 'join-course',
    USER: 'user',
    IS_TERMS_AND_CONDITIONS_ACCEPTED: 'IsTermsAndConditionsAccepted'
}

export default STORAGE_KEYS
