/* eslint-disable react/no-unknown-property */
import React from 'react'
import './DropdownList.scss'
import NoData from '../NoData/Nodata'
import get from 'lodash/get'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { translateValue } from '../../../utils/elementsUtils'

/**
 * @typedef DropDownListProps
 * @memberOf DropDownList
 * @property {Array<Menu>} data
 * @property {Array<Menu>} [searchData]
 * @property {string} [selected]
 * @property {boolean} [printHeader] - if true then child dropdown will have header info
 * @property {boolean} [headerOnly] - if true then child dropdown will have only header info, without content
 * @property {string} [data-testid]
 */

/**
 * Content info to the Dropdown
 * @class DropDownList
 * @category Components
 * @subcategory theme / commons
 * @param {DropDownListProps} props
 * @returns {React.ReactNode}
 * @example
 * const props = {
 *  data: [{ id: '60b4f4', name: 'Acrobatics', courses: [{...},{...}]}],
 *  searchData: [],
 *  selected: null
 * }
 * <DropdownList {...props} />
 */
function DropDownList ({
    data,
    searchData = [],
    selected,
    printHeader,
    headerOnly,
    'data-testid': testId = 'dropdown-list'
}) {
    /**
     * Returns the class name based on item information
     * @function
     * @memberOf DropDownList
     * @param {Object} item
     * @returns {string}
     */
    const getListItemClassNames = (item) => {
        const { disabled, name } = item
        let result = 'dropdown-item-list '
        result += disabled ? 'dropdown-item-list-disabled ' : ''
        result += name === selected ? 'dropdown-item-list-selected ' : ''
        return result
    }
    /**
     * Verifies if not have a selected element and if search data is not empty,
     * then returns the search data or the original data
     * @function
     * @memberOf DropDownList
     * @returns {Array<Menu>}
     */
    const getOptions = () => (!selected && searchData.length > 0) ? searchData : data

    /**
     * Returns the header class name based on category list information
     * @function
     * @memberOf DropDownList
     * @param {Menu} categoryList
     * @returns {string}
     */
    const getHeaderClassNames = (categoryList) => {
        const { disabled } = categoryList
        let res = ''
        res += disabled ? 'dropdown-item-list-disabled ' : ''
        res += categoryList.fixed ? 'dropdown-item-list-fixed ' : ''
        res += categoryList.icon ? 'dropdown-item-list-with-icon ' : ''
        res += categoryList.iconPosition ? 'dropdown-delete-content ' : ''
        res += headerOnly ? 'dropdown-item-list' : 'dropdown-item-list-header'
        return res
    }

    /**
     * @function
     * @memberOf DropDownList
     * @param {Menu} categoryList
     * @returns {Array<JSX.Element>}
     */
    const getHeader = (categoryList) => {
        if (get(categoryList, 'line', false)) { return [<hr className="dropdown-line" key={`line-${Math.random()}`}/>] }
        if (!categoryList || Object.keys(categoryList).length === 0) return [<NoData key="header"/>]
        return [
            <span
                key={`${categoryList.name}_${Math.random()}`}
                id="dropDownHeader"
                data-id={categoryList.id || Math.random()}
                data-testid={`${testId}-${categoryList.id}`}
                value={translateValue(categoryList.name)}
                content-id={categoryList.contentId}
                className={getHeaderClassNames(categoryList)}
            >
                {categoryList.iconPosition && categoryList.iconPosition === 'right'
                    ? (
                        <>
                            {translateValue(categoryList.name)}
                            {categoryList.icon && !categoryList.icon.name &&
                    <FontAwesomeIcon style={{ float: 'right' }} icon={categoryList.icon}/>}
                        </>

                    )
                    : (
                        <>
                            {categoryList.icon && !categoryList.icon.name && <FontAwesomeIcon icon={categoryList.icon}/>}
                            {translateValue(categoryList.name)}
                        </>
                    )}
            </span>
        ]
    }

    /**
     * @function
     * @memberOf DropDownList
     * @param {Menu} params
     * @returns {Array<JSX.Element>}
     */
    const getChildListItem = ({ courses }) => {
        if (!courses) return []
        if (courses.length === 0) return [<NoData key="child"/>]
        return courses.map((item, index) => (
            <li
                key={`${item.name}_${index}`}
                data-id={item.id}
                data-testid={`${testId}-${item.id}`}
                value={translateValue(item.name)}
                content-id={item.contentId}
                className={getListItemClassNames(item)}
            >
                {translateValue(item.name)}
            </li>
        ))
    }

    /**
     * @function
     * @memberOf DropDownList
     * @param {Menu} categoryList
     * @returns {Array<JSX.Element>}
     */
    const getDropDown = (categoryList) => {
        const header = getHeader(categoryList)
        const content = getChildListItem(categoryList)
        if (headerOnly) return header
        else if (printHeader) return [...header, ...content]
        else return content
    }

    return (
        <>
            {data.length > 0
                ? (
                    getOptions().map((categoryList) => {
                        return getDropDown(categoryList)
                    })
                )
                : (
                    <NoData/>
                )}
        </>
    )
}

export default DropDownList
