import { cmsLanguage } from './cms/commons/cms_language/cmsLanguage'

/**
 * @module initializerData
 */

/**
 *
 * @type {Menu}
 */
export const defaultSportHeadingGroup = {
    path: 'sports',
    name: cmsLanguage.initializerData.sportsMenuName,
    contentId: null,
    icon: {
        name: 'fas_child',
        type: ''
    },
    parentId: '',
    children: []
}

/**
 * defaultCoursesHeadingGroup
 * @type {Menu}
 */
export const defaultCoursesHeadingGroup = {
    path: 'courses',
    name: cmsLanguage.initializerData.coursesMenuName,
    contentId: null,
    icon: {
        name: 'fas_child',
        type: ''
    },
    parentId: '',
    children: []
}

/**
 *
 * @type {Array<Menu>}
 */
const defaultHeadingGroups = [defaultSportHeadingGroup, defaultCoursesHeadingGroup]

/**
 * Only the ids of the defaultHeadingGroups
 * @type {Array<string>}
 */
const defaultHeadingGroupsNames = defaultHeadingGroups.map(m => m.name)

/**
 * Filtered menuItems -> defaultHeadingGroupsIds no includes menuItems ids
 * @param {Array<Object>} menuItems
 * @returns {Array<Object>}
 */
export const hideDefaultHeadingGroups = menuItems => menuItems.filter(m => !defaultHeadingGroupsNames.includes(m.name))

/**
 * initializerMenuData - All menu initial structure
 * @type {MenuData}
 * @example
 *  {
 *    topMenu: {
 *       id: 'TOP_MENU',
 *       path: 'top_menu',
 *       name: 'topMenu',
 *       ...
 *    },
 *    headerMenu: {
 *       id: 'TOP_MENU',
 *       path: 'top_menu',
 *       name: 'topMenu',
 *       ...
 *    },
 *    ...
 *  }
 */

export const sportsMenuHeaderName = cmsLanguage.initializerData.sportsMenuName
export const coursesMenuHeaderName = cmsLanguage.initializerData.coursesMenuName
export const initializerMenuData = {
    topMenu: {
        path: 'top_menu',
        name: cmsLanguage.menuNames.topMenu,
        main: true,
        children: [
            {
                path: 'members',
                name: cmsLanguage.initializerData.membersMenuName,
                contentId: 'uuid-members-area',
                children: []
            },
            {
                path: 'become_a_member',
                name: cmsLanguage.initializerData.becomeAMemberMenuName,
                contentId: 'uuid-become-a-member',
                children: []
            }
        ]
    },
    headerMenu: {
        path: 'header_menu',
        name: cmsLanguage.menuNames.headerMenu,
        main: true,
        children: [
            {
                path: 'sports',
                name: cmsLanguage.initializerData.sportsMenuName,
                children: [
                    defaultSportHeadingGroup
                ]
            },
            {
                path: 'courses',
                name: cmsLanguage.initializerData.coursesMenuName,
                children: [
                    defaultCoursesHeadingGroup
                ]
            },
            {
                path: 'who_we_are',
                name: cmsLanguage.initializerData.whoWeAreMenuName,
                children: []
            },
            {
                path: 'culture',
                name: cmsLanguage.initializerData.cultureMenuName,
                children: []
            },
            {
                path: 'training',
                name: cmsLanguage.initializerData.trainingScheduleName,
                contentId: 'uuid-training-schedule-content',
                children: []
            }
        ]
    },
    footerMenu: {
        path: 'footer_menu',
        name: cmsLanguage.menuNames.footerMenu,
        contentId: '',
        main: true,
        children: [
            {
                path: 'contact_us',
                name: cmsLanguage.initializerData.contactUsMenuName,
                children: []
            },
            {
                path: 'information',
                name: cmsLanguage.initializerData.informationMenuName,
                children: [
                    {
                        path: 'news',
                        name: 'News',
                        contentId: 'non existing page id',
                        parentId: '',
                        children: []
                    }
                ]
            }
        ]
    },
    homeMenu: {
        path: 'home_menu',
        name: 'homeMenu',
        contentId: '',
        main: true,
        children: []
    },
    socialMediaMenu: {
        path: 'social_media_menu',
        name: 'socialMediaMenu',
        contentId: 'uuid-social-media-content',
        main: true
    }
}

/**
 *
 * @type {string}
 * @example
 *  const initializerTrainingScheduleDataName = 'Training Schedule Image Banner Content'
 */
export const initializerTrainingScheduleDataName = 'Training Schedule Image Banner Content'
