// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".accordion>.card{border:none;border-radius:0}.accordion>.card>.card-header{background-color:#595959;font-family:'Open Sans', sans-serif;font-weight:normal;font-style:normal;font-size:.875em;color:#fff;display:flex;justify-content:space-between;align-items:center;min-height:2.5em;min-width:3.75em;height:2.8125em;border-radius:0}.accordian-position-icon{font-size:.9375em}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
