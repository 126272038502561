import React from 'react'
import { Link } from 'react-router-dom'
import './buttonLink.scss'
import { withAutoWidth } from '../button/Button'
import { cmsLanguage } from '../../../cms/commons/cms_language/cmsLanguage'

/**
 * @typedef ButtonLinkProps
 * @memberOf ButtonLink
 * @property {string} [link]
 * @property {function} [onClick]
 * @property {string} text
 * @property {null|undefined|"cancel"} [type]
 * @property {string} [width]
 */

/**
 * Generic button link component
 * @class ButtonLink
 * @category Components
 * @subcategory theme / commons
 * @param {ButtonLinkProps} props
 * @returns {React.ReactNode}
 * @example
 * <ButtonLink text="Click here to not see something awesome" onClick={handleOnClick} type="cancel" />
 */
const ButtonLink = ({ link, onClick, text, type, width }) => {
    const setClassName = type || 'cancel'
    return (
        <div className={'button-link'} style={{ width: width }}>
            {link
                ? <Link to={link} />
                : <div className={setClassName} onClick={onClick}>{text || cmsLanguage.cms.cancel}</div>}
        </div>
    )
}

export default withAutoWidth(ButtonLink)
