import React, { useCallback, useEffect, useState } from 'react'
import './SportPicturesBanner.scss'
import SportPictureCard from './components/sport_picture_card/SportPictureCard'
import { widthLg, widthXs } from '../../../theme/styles/_responsive_breakpoints.scss'
import { Carousel } from 'react-bootstrap'

/**
 * @typedef SportPicturesBannerProps
 * @memberOf SportPicturesBanner
 * @property {Array<Object>} sportPicturesContent
 */

/**
 * Carousel with sport pictures to be showed in the Home page.
 * @class SportPicturesBanner
 * @category Components
 * @subcategory Home
 * @param {SportPicturesBannerProps} props
 * @returns {React.ReactNode}
 * @example
 * <SportPicturesBanner sportPicturesContent={[{...}]} />
 */
const SportPicturesBanner = ({ sportPicturesContent }) => {
    const [screenSize, setScreenSize] = useState(document.body.offsetWidth)
    const [responsiveSize, setResponsiveSize] = useState('desktop')
    const [itemsNumber, setItemsNumber] = useState(5)

    const responsiveSportPicturesBanner = useCallback(() => {
        if (screenSize > widthLg) {
            setResponsiveSize('desktop')
            setItemsNumber(5)
        } else if (screenSize <= widthLg && screenSize > widthXs) {
            setResponsiveSize('tablet')
            setItemsNumber(3)
        } else if (screenSize <= widthXs) {
            setResponsiveSize('mobile')
            setItemsNumber(1)
        }
    }, [screenSize])

    useEffect(() => {
        let isCancelled = false

        !isCancelled && responsiveSportPicturesBanner()

        return () => {
            isCancelled = true
            return null
        }
    }, [responsiveSportPicturesBanner])

    const sportPictureLength = sportPicturesContent.length

    const sportsOrder = sportPicturesContent
    const getScreenSize = () => {
        setScreenSize(document.body.offsetWidth)
        responsiveSportPicturesBanner()
    }

    window.addEventListener('resize', getScreenSize)

    const mainCarouselItemsNumber = Math.ceil(sportPictureLength / itemsNumber)

    return (
        <Carousel id="sport-pictures-banner">
            {Array(mainCarouselItemsNumber)
                .fill()
                .map((_, i) => {
                    return (
                        <Carousel.Item key={i}>
                            <div className="sport-pictures-container">
                                {sportsOrder
                                    .slice(i * itemsNumber, i * itemsNumber + itemsNumber)
                                    .map((item, _) => {
                                        return (
                                            <div
                                                key={item.element.id}
                                                data-testid={'sport-' + item.element.id}
                                                className={`sport-picture-item-${responsiveSize}`}
                                            >
                                                <SportPictureCard
                                                    sport={item.H1}
                                                    picture={item.PHOTO}
                                                    link={item.LINK}
                                                    testId={item.element.id}
                                                />
                                            </div>
                                        )
                                    })}
                            </div>
                        </Carousel.Item>
                    )
                })}
        </Carousel>
    )
}

export default SportPicturesBanner
