import isEmpty from 'lodash/isEmpty'
import React from 'react'
import InfoError from '../../../cms/commons/cms_input/components/info_error/InfoError'

/**
 * High-order component - shows the component child with an error message
 * @class wrapErrorComponent
 * @category Components
 * @subcategory theme / commons
 * @param {React.ReactNode} Component
 * @returns {React.ReactNode}
 */
export default function wrapErrorComponent (Component) {
    return function errorComponent (props) {
        return (
            <>
                <Component {...props} />
                {!isEmpty(props.error) && props.error[0].trim() && (
                    <InfoError
                        infoError={props.error}
                        tooltip={props.error}
                        valueId={props.error}
                    />
                )}
            </>
        )
    }
}
