import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import './ProfileAvatar.scss'

/**
 * @static
 * @const AVATAR_ID
 * @memberOf ProfileAvatar
 * @type {string}
 * @example
 * const AVATAR_ID = 'avatar-id'
 */
export const AVATAR_ID = 'avatar-id'

/**
 * @typedef ProfileAvatarProps
 * @memberOf ProfileAvatar
 * @property {function} [onClick]
 */

/**
 * Shows the profile avatar (user icon)
 * @class ProfileAvatar
 * @category Components
 * @subcategory Header
 * @param {ProfileAvatarProps} props
 * @returns {React.ReactNode}
 * @example
 * <ProfileAvatar />
 */
const ProfileAvatar = ({ onClick = () => {} }) => {
    return (
        <div id={AVATAR_ID} className={'profile-avatar'} onClick={onClick} data-testid={'profile-avatar-wrapper'}>
            <FontAwesomeIcon className="edit-icon" icon={['far', 'user']} data-testid={'icon'}/>
        </div>
    )
}

export default ProfileAvatar
