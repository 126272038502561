import React, { useState } from 'react'
import './contactTemplate.scss'
import ContactItem from './components/contact-item/ContactItem'
import ContactHours from './components/contact-hours/ContactHours'
import { Col, Container, Row } from 'react-bootstrap'
import InputField from '../members-area/components/input-field/InputField'
import Button from '../../theme/commons/button/Button'
import MessageField from './components/message-field/MessageField'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'
import Mesh from '../../backend/mesh/Mesh'
import InfoModal from '../../theme/commons/infoModal/InfoModal'
import { cmsLanguage } from '../../cms/commons/cms_language/cmsLanguage'

/**
 * @typedef ContactTemplateProps
 * @memberOf ContactTemplate
 * @property {string} pageName
 * @property {string} contactName
 * @property {Array<Object>} contactInfo
 */

/**
 * Shows the Contact template component, with information and a form.
 * @class ContactTemplate
 * @category Components
 * @subcategory Templates / Contact
 * @param {ContactTemplateProps} props
 * @returns {React.ReactNode}
 * @example
 * <ContactTemplate actionButtonBannerContent={actionButtonBannerContent} />
 */
const ContactTemplate = ({ pageName, contactName, contactInfo }) => {
    const [userData, setUserData] = useState({
        name: '',
        email: '',
        message: ''
    })
    const [alert, setAlert] = useState({})
    const [showsModal, setShowsModal] = useState({
        show: false
    })

    /**
     * @function
     * @memberOf ContactTemplate
     * @param {string} inputKey
     * @param {string} value
     */
    const setInputValue = (inputKey, value) => {
        if (inputKey === 'name') {
            value = capitalizeFirstLetter(value)
        }
        setUserData({ ...userData, [inputKey]: value })
    }

    /**
     * Prepares the data and sends it to the `push-notification-service`.
     * @function
     * @memberOf ContactTemplate
     */
    const sendContactData = () => {
        const content = {
            to: ['57'],
            messageProperties: {
                page: pageName,
                name: userData.name,
                email: userData.email,
                message: userData.message
            },
            emailTemplateEnum: 'JOIN_COURSE'
        }

        Mesh.sendEmail(content)
            .then((response) => {
                if (response.status === 200) {
                    setUserData({})
                    setShowsModal({
                        show: true,
                        type: 'success',
                        optionalMessage: cmsLanguage.content.messageSent
                    })
                } else {
                    setShowsModal({
                        show: true,
                        type: 'fail',
                        optionalMessage: cmsLanguage.error.unexpectedError
                    })
                }
            })
            .catch(() => {
                setShowsModal({
                    show: true,
                    type: 'fail',
                    optionalMessage: cmsLanguage.error.unexpectedError
                })
            })
    }

    /**
     * When clicks on the button, validate and sends the form data.
     * @function
     * @memberOf ContactTemplate
     */
    const handleButtonClick = () => {
        let validInputs = {}
        let createAlerts = []

        const checks = (keyValue, boolean, errorMessage) => {
            validInputs = { ...validInputs, [keyValue]: boolean }
            createAlerts = { ...createAlerts, [keyValue]: errorMessage }
        }

        const checkName = (keyValue) => {
            const errorMessageEmpty = '*' + cmsLanguage.error.fieldEmpty
            const errorMessageName = '*' + cmsLanguage.error.textInvalid
            const regexName = /^[a-zA-ZåäöüÅÄÖÜß ]{2,30}$/

            const name = userData[keyValue]

            name !== ''
                ? regexName.test(name)
                    ? checks(keyValue, true, null)
                    : checks(keyValue, false, errorMessageName)
                : checks(keyValue, false, errorMessageEmpty)
        }

        const checkEmail = (keyValue) => {
            const regexEmail =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            const errorMessage = '*' + cmsLanguage.error.noValidEmail

            userData[keyValue] !== '' && regexEmail.test(userData[keyValue])
                ? checks(keyValue, true, null)
                : checks(keyValue, false, errorMessage)
        }

        const checkMessage = (keyValue) => {
            const errorMessageEmpty = '*' + cmsLanguage.error.fieldEmpty
            const message = userData[keyValue]

            message !== ''
                ? checks(keyValue, true, null)
                : checks(keyValue, false, errorMessageEmpty)
        }

        checkName('name')
        checkEmail('email')
        checkMessage('message')

        setAlert(createAlerts)

        if (
            validInputs.name &&
            validInputs.email &&
            validInputs.message
        ) {
            sendContactData()
            setAlert({})
        }
    }

    return (
        <>
            {showsModal.show &&
                <InfoModal type={showsModal.type}
                    optionalMessage={showsModal.optionalMessage}
                    onModalClick={() => setShowsModal({ show: false })}/>}
            <Container fluid>
                <Row className="contact-template" xs={1} sm={1} lg={2}>
                    <Col className="contact-template-left">
                        <h2 data-testid="contact-name">{contactName}.</h2>
                        {contactInfo.map((item, index) => {
                            switch (item.SHORT_TEXT_1) {
                            case 'item':
                                return <ContactItem key={index} item={item}/>
                            case 'hours':
                                return <ContactHours key={index} hours={item}/>
                            default:
                                return <></>
                            }
                        })}
                    </Col>
                    <Col className="contact-template-right">
                        <h2>Let's talk.</h2>
                        <form className="contact-form">
                            <div className="contact-fields">
                                <InputField
                                    title={cmsLanguage.inputFields.name}
                                    defaultInput={userData.name ? userData.name : ''}
                                    inputKey="name"
                                    alert={alert.name}
                                    setInputValue={setInputValue}
                                />
                                <InputField
                                    title={cmsLanguage.inputFields.email}
                                    defaultInput={userData.email ? userData.email : ''}
                                    inputKey="email"
                                    alert={alert.email}
                                    setInputValue={setInputValue}
                                />
                                <MessageField
                                    title={cmsLanguage.inputFields.message}
                                    defaultInput={userData.message ? userData.message : ''}
                                    inputKey="message"
                                    alert={alert.message}
                                    setInputValue={setInputValue}
                                />
                            </div>
                            <Button
                                className="contact-button"
                                text={cmsLanguage.inputFields.send}
                                onClick={handleButtonClick}
                            />
                        </form>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ContactTemplate
