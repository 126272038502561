import React, { useEffect, useState } from 'react'
import './CmsInput.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AdminButton from '../../commons/admin_button/AdminButton'
import InputText from './components/input_text/InputText'
import InputLink from './components/input_link/InputLinkContainer'
import InputTextarea from './components/input_textarea/InputTextarea'
import InputTextareaEditor from './components/input_textarea_editor/InputTextareaEditor'
import InputPhoto from './components/input_photo/InputPhoto'
import InputVideo from './components/input_video/InputVideo'
import InputDate from './components/input_date/InputDate'
import InputPhone from './components/input_phone/InputPhone'
import InputEmail from './components/input_email/InputEmail'
import InputPrice from './components/input_price/InputPrice'
import InfoError from './components/info_error/InfoError'
import { cmsLanguage } from '../cms_language/cmsLanguage'
import { isEmpty } from '../../util/Util'
import InputCardNumber from './components/input_card_number/InputCardNumber'
import InputNumber from './components/input_number'
import InputExpiryDate from './components/input_expiry_date/InputExpiryDate'
import Dropdown from '../../../theme/commons/dropdown/Dropdown'
import { makeLowercaseFirstLetter } from '../../../utils/makeLowercaseFirstLetter'
import TimePicker from '../../../theme/commons/timePicker/TimePicker'
import { types } from '../../../theme/commons/AddEditClass/useReducer/types'
import { isEqual } from 'lodash'
import { DROPDOWN_CONFIG_DATA } from './constants/constants'
import InputCheckbox from './components/input_checkbox'

const CmsInput = (props) => {
    const {
        name,
        type,
        value,
        mandatory,
        valueId,
        placeholder,
        onChange,
        defaultValue,
        dataTestId = 'cms-input-field'
    } = props

    const [focused, setFocused] = useState(false)
    const [errorMessage, setErrorMessage] = useState()
    const [tooltip, setTooltip] = useState()
    const [isRevertAvailable, setIsRevertAvailable] = useState(false)

    useEffect(() => {
        const isRevert = isRevertValue()
        setIsRevertAvailable(isRevert)
    }, [value])

    const isRevertValue = () => {
        return (
            (!defaultValue && !isEmpty(value)) ||
            (!isEmpty(value) && !isEqual(value, defaultValue?.value)))
    }

    const onFocus = () => setFocused(true)
    const onBlur = () => setFocused(false)

    const handleRevert = () => {
        let hasError = false
        let revertValue
        if (typeof value === 'object') {
            revertValue = defaultValue ? defaultValue.value : { hour: '', minute: '' }
        } else {
            revertValue = defaultValue ? defaultValue.value : ''
        }
        if (mandatory && isEmpty(revertValue)) {
            setErrorMessage(cmsLanguage.error.mandatory)
            hasError = true
        }

        onChange(valueId, revertValue, hasError)
    }

    const changeCurrentValue = (newValue, hasError = false) => {
        if (!hasError) {
            setErrorMessage()
        }
        if (mandatory && isEmpty(newValue)) {
            setErrorMessage(cmsLanguage.error.mandatory)
            hasError = true
        }
        onChange(valueId, newValue, hasError)
    }

    const changeCurrentTimeValue = (type, value) => {
        let hasError = isEmpty(value)
        if (!hasError) {
            setErrorMessage()
        } else if (mandatory && hasError) {
            setErrorMessage(cmsLanguage.error.mandatory)
            hasError = true
        }
        onChange(valueId, value, hasError)
    }

    const handleOnError = (errorMessage, tooltip) => {
        setErrorMessage(errorMessage)
        setTooltip(tooltip)
    }

    const defaultInputProps = {
        value,
        onFocus,
        onChange: changeCurrentValue,
        onBlur
    }

    const extendedInputProps = {
        ...defaultInputProps,
        onError: handleOnError,
        valueId
    }

    const timeDropdownProps = {
        value,
        'data-testid': 'timePicker-schedule',
        size: ''
    }

    const dropdownProps = {
        onError: handleOnError,
        title: cmsLanguage.inputFields.choose,
        data: DROPDOWN_CONFIG_DATA[makeLowercaseFirstLetter(name)],
        headerOnly: 'false',
        printHeader: true,
        providedValue: value
    }

    return (
        <div className="cms-input">
            <label className="cms-input-name">
                {mandatory && (
                    <div className="cms-input-icon">
                        <FontAwesomeIcon
                            icon={['fa', 'asterisk']}
                            data-testid="cms-input-mandatory"
                        />
                    </div>
                )}
                <span data-testid="cms-input-name">{cmsLanguage.inputFields[name] || name}</span>
            </label>

            <div
                data-testid={dataTestId}
                className={`cms-input-field ${errorMessage ? 'cms-input-error' : ''} ${
                    focused ? 'cms-input-focus' : ''
                }`}
            >
                {
                    {
                        CHECK_BOX: <InputCheckbox {...defaultInputProps} checked={value === true || value === 'true'}/>,
                        H1: <InputText {...defaultInputProps} maxLength={60} />,
                        H2: <InputText {...defaultInputProps} maxLength={100} />,
                        SHORT_TEXT: <InputText {...defaultInputProps} maxLength={40} />,
                        TEXT: (
                            <InputTextarea
                                {...defaultInputProps}
                                maxLength={400}
                                placeholder={placeholder && placeholder}
                            />
                        ),
                        LONG_TEXT: <InputTextarea {...defaultInputProps} />,
                        TEXT_EDITOR: (
                            <InputTextareaEditor
                                {...defaultInputProps}
                                maxLength={400}
                                placeholder={placeholder && placeholder}
                            />
                        ),
                        BUTTON_TEXT: <InputText {...defaultInputProps} maxLength={20} />,
                        BUTTON_LINK: <InputLink {...extendedInputProps} />,
                        LINK: <InputLink {...extendedInputProps} />,
                        PHOTO: <InputPhoto {...extendedInputProps} />,
                        VIDEO: <InputVideo {...extendedInputProps} />,
                        DATE: <InputDate {...defaultInputProps} />,
                        PHONE: <InputPhone {...extendedInputProps} />,
                        EMAIL: <InputEmail {...extendedInputProps} />,
                        PRICE: <InputPrice {...extendedInputProps} maxLength={100} />,
                        CARD_NAME: <InputText
                            {...extendedInputProps}
                            maxLength={40}
                            isCardName={true}
                        />,
                        CARD_NUMBER: <InputCardNumber {...extendedInputProps} maxLength={16} />,
                        EXPIRY_DATE: <InputExpiryDate {...extendedInputProps} />,
                        SECURITY_CODE: <InputNumber
                            {...extendedInputProps}
                            maxLength={3}
                            isSecurityCode={true}
                        />,
                        NUMBER: <InputNumber {...extendedInputProps} inputProps={{ min: 0 }} />,
                        DROPDOWN: <Dropdown
                            { ...dropdownProps }
                            onSelect={(value, id) => changeCurrentValue(id, isEmpty(id))}
                        />,
                        TIME: <TimePicker
                            {...timeDropdownProps}
                            onTimeChange={(hour, minute) =>
                                changeCurrentTimeValue(types.UPDATE_START_TIME, { hour, minute })
                            }
                        />
                    }[type]
                }
                <span className={'cms-input-on-revert'}>
                    <AdminButton
                        data-testid="revert-button"
                        type={isRevertAvailable ? 'solid' : 'disable'}
                        color="red"
                        icon={['fa', 'history']}
                        onClick={handleRevert}
                        tooltip={cmsLanguage.tooltip.inputRevert + '.'}
                    />
                </span>
            </div>
            <div className={`${errorMessage ? 'cms-input-info-error' : 'cms-input-no-display-info-error'}`}>
                <InfoError infoError={errorMessage} tooltip={tooltip} />
            </div>
        </div>
    )
}

export default CmsInput
