import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import './Checkbox.scss'

const Checkbox = ({ checked = false }) => {
    if (checked) {
        return <FontAwesomeIcon
            icon={['far', 'check-square']}
            className={'checkbox__checked'}
        />
    }

    return <FontAwesomeIcon icon={['far', 'square']} className="checkbox" />
}

export default Checkbox
