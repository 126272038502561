import React, { useEffect, useState } from 'react'
import './NewsTemplate.scss'
import { Col, Container, Row } from 'react-bootstrap'
import NewsCard from '../../theme/commons/news_card/NewsCard'
import Mesh from '../../backend/mesh/Mesh'

/**
 * Gets the latest 12 news from the `news-service` and displays them on the screen.
 * @class NewsTemplate
 * @category Components
 * @subcategory Templates
 * @returns {React.ReactNode}
 * @example
 * const instagramData = reducedElementsPro( template.elements[0], template.fields, null, 'name', true )
 * <NewsTemplate />
 */
const NewsTemplate = () => {
    const [newsContent, setNewsContent] = useState([])

    useEffect(() => {
        const _getNewsContent = async () => {
            const response = await Mesh.getNewsContent(0, 12)
            const news = response.news
            setNewsContent(news)
        }
        _getNewsContent()
    }, [])

    return (
        <div className="news-template">
            <Container
                fluid
                className="vertical-website-padding horizontal-website-padding"
            >
                <Row>
                    {newsContent.map((item) => {
                        return (
                            <Col xl={3} sm={6} className="news-col" key={item.newsId}>
                                <NewsCard item={item} />
                            </Col>
                        )
                    })}
                </Row>
            </Container>
        </div>
    )
}

export default NewsTemplate
