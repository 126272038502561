/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import './Footer.scss'
import SocialMedia from '../theme/commons/social_media/SocialMediaContainer'
import ClassicLogo from './classic-logo/ClassicLogo'
import FooterMenu from './footer-menu/FooterMenuContainer'
import { Col, Container, Row } from 'react-bootstrap'
import { trackPromise } from 'react-promise-tracker'
import MeshContent from '../backend/mesh/content_service/MeshContent'
import { isEmptyObject } from '../utils/objectUtils'
import { CONTENT_FOOTER } from '../cms/types'
import { cmsLanguage } from '../cms/commons/cms_language/cmsLanguage'
import TermsAndConditionsModalContainer from '../termsModal/TermsAndConditionsModalContainer'
import STORAGE_KEYS from '../utils/storageKeys'

export function resetTermsAndConditions () {
    localStorage.setItem(STORAGE_KEYS.IS_TERMS_AND_CONDITIONS_ACCEPTED, '{}')
    window.location.reload()
}

/**
 * @typedef FooterProps
 * @memberOf Footer
 * @property {Menu} footerMenu
 * @property {TemplateSection} footerContent
 * @property {function} setContent
 */

/**
 * Application Footer component.
 * It shows footer and social media information.
 * @class Footer
 * @category Components
 * @subcategory Footer
 * @param {FooterProps} props
 * @returns {React.ReactNode}
 * @example
 * <Footer
 *     footerMenu={footerMenu.children}
 *     contact={footerContent && footerContent.templates && footerContent.templates[0]}
 * />
 */
const Footer = (props) => {
    const { footerMenu, footerContent } = props
    const footerContentId = footerMenu && footerMenu.contentId

    useEffect(() => {
        let isCancelled = false

        const fetchContactTemplateContent = async () => {
            try {
                const footerContentResponse = await trackPromise(
                    MeshContent.fetchContent(footerContentId)
                )
                props.setContent(footerContentResponse, false, CONTENT_FOOTER)
                props.setContent(footerContentResponse, true, CONTENT_FOOTER)
            } catch (e) {
                console.log(e)
            }
        }
        !isCancelled && footerContentId && isEmptyObject(footerContent) && fetchContactTemplateContent()
        return () => {
            isCancelled = true
        }
    }, [footerContentId, footerContent])

    return (
        <Container fluid className="footer-container horizontal-website-padding">
            <TermsAndConditionsModalContainer />
            <Row>
                <Col className='col-12 col-xl-5 footer-logo-container'>
                    <ClassicLogo/>
                </Col>
                <Col className='col-12 col-xl-7'>
                    <FooterMenu
                        footerMenu={footerMenu.children}
                        contact={footerContent && footerContent.templates && footerContent.templates[0]}
                    />
                </Col>
            </Row>
            <Row className='footer-container-bottom'>
                <Col className='col-12 col-md-auto'>
                    <SocialMedia/>
                </Col>
                <Col className='col-12 col-md-auto'>
                    <div data-testid="company-name" className="company-name">
                        VfL <span>Wolfsburg</span> e.V.
                    </div>
                </Col>
            </Row>
            <Row className='align-content-center'>
                <div className="col-xl-12 align-content-center company-reset-terms-and-conditions" onClick={ resetTermsAndConditions }>
                    {cmsLanguage.termsAndConditions.resetAcceptance}
                </div>
            </Row>
        </Container>
    )
}

export default Footer
