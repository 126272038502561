import React from 'react'
import './CourseArea.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolderOpen } from '@fortawesome/free-regular-svg-icons'
import { cmsLanguage } from '../../../../cms/commons/cms_language/cmsLanguage'
import Button from '../../../../theme/commons/button/Button'
import { usePromiseTracker } from 'react-promise-tracker'

/**
 * @typedef CourseAreaProps
 * @memberOf CourseArea
 * @property {Array<Object>} data
 */

/**
 * Course Area component for the {@link MembersArea}
 * @class CourseArea
 * @category Components
 * @subcategory Templates / MembersArea
 * @param {CourseAreaProps} props
 * @returns {React.ReactNode}
 * @example
 * <CourseArea
 *     type={type}
 *     field={field}
 *     value={value}
 *     onChange={handleOnChange}
 *     onError={handleOnError}
 * />
 */
const CourseArea = ({ data }) => {
    const { promiseInProgress } = usePromiseTracker()

    return (
        <div className="course-area">
            <div className="course-area__table">
                {data.map((element) => (
                    <div key={element.label} className="course-area__column" data-testid={`column-${element.label}`}>
                        <div className="column__label column__item">{element.label}</div>

                        {element.values.map((value) => (
                            <div className="column__item column__item--row" key={value.id} data-testid={`row-${value.id}`}>
                                {value.name}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            {!promiseInProgress && data[0].values.length === 0 && (
                <div className="course-area__empty">
                    <div className="course-area__empty__message" role="comment">
                        <FontAwesomeIcon className="folder-open" icon={faFolderOpen} />
                        <span>{cmsLanguage.profile.noCourses}</span>
                    </div>
                    <div className="members-area__button">
                        <Button
                            text={cmsLanguage.profile.joinCourse.toUpperCase()}
                            type='success'
                            role="button"
                            link={'/training'}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default CourseArea
