import { cmsLanguage } from '../cms/commons/cms_language/cmsLanguage'

export const getPageError404Content = () => {
    return Promise.resolve({
        contentId: '1',
        errorText: cmsLanguage.content.pageNotFoundErrorText,
        text: cmsLanguage.content.pageNotFoundText,
        buttonText: cmsLanguage.content.pageNotFoundButtonText,
        buttonLink: '/'
    })
}
