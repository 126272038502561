import React from 'react'
import './modal.scss'

/**
 * @typedef ModalProps
 * @memberOf Modal
 * @property {React.ReactNode} children
 */

/**
 * Modal component - shows the children wrapper by a div with a generic modal class
 * @class Modal
 * @category Components
 * @subcategory theme / commons
 * @param {ModalProps} props
 * @returns {React.ReactNode}
 * @example
 * <Modal>
 *   ...
 * </Modal>
 */
export default function Modal ({ children }) {
    return (
        <div className="generic-modal">
            <div className="model-center">{children}</div>
        </div>
    )
}
