import React, { useState } from 'react'
import './accordian.scss'
import BsAccordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/**
 * @typedef AccordianProps
 * @memberOf Accordian
 * @property {number} day
 * @property {string} header
 * @property {boolean} isOpenProp
 */

/**
 * Accordion component using react-bootstrap library
 * @class Accordian
 * @category Components
 * @subcategory theme / commons
 * @param {AccordianProps} props
 * @returns {React.ReactNode}
 * @example
 * <Accordian header="MONDAY" day={1} key="day0MONDAY" isOpenProp={shouldOpen(0)}>
 *  ...
 * </Accordian>
 */
export default function Accordian (props) {
    const { day, header, isOpenProp = false } = props
    const [isOpen, setIsOpen] = useState(isOpenProp)
    return (
        <BsAccordion defaultActiveKey={isOpen ? '0' : '-1'}>
            <Card>
                <BsAccordion.Toggle
                    onClick={() => setIsOpen((prev) => !prev)}
                    as={Card.Header}
                    eventKey="0"
                >
                    {header}
                    {day && ` - ${day}`}
                    <FontAwesomeIcon
                        className="accordian-position-icon"
                        icon={isOpen ? ['fa', 'angle-up'] : ['fa', 'angle-down']}
                    />
                </BsAccordion.Toggle>
                <BsAccordion.Collapse eventKey="0">
                    <>{props.children}</>
                </BsAccordion.Collapse>
            </Card>
        </BsAccordion>
    )
}
