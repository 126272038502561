/**
 * @module getNameWithoutUnderscore
 * @category Utils
 * @subcategory General
 */

/**
 * Takes a string and returns it with the first letter capitalized
 * @function
 * @param {string} string
 * @returns {string|undefined}
 * @example
 * const text = 'text_test'
 * const name = getNameWithoutUnderscore(text) // 'text'
 */
export const getNameWithoutUnderscore = (name) => {
    return (name && typeof name === 'string') ? name.split('_')[0] : ''
}
