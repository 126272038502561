import React from 'react'
import Button from '@theme/commons/button/Button'
import { language } from '@theme/commons/language/language'
import './loginStep.scss'

/**
 * @typedef LoginStepProps
 * @memberOf LoginStep
 * @property {function} onNext
 * @property {function} onLogin
 * @property {boolean} isIdentified
 * @property {string} name
 */

/**
 * Login Step Component (see {@link LoginStepContainer}.
 * @class LoginStep
 * @category Components
 * @subcategory Templates / CourseInfo
 * @param {LoginStepProps} props
 * @returns {React.ReactNode}
 * @example
 * <LoginStep {...props} isIdentified={isIdentified} name={name} onLogin={goToAndReturn} />
 */
const LoginStep = ({ onNext, onLogin, isIdentified, name }) => {
    /**
     * If `isIdentified` is true, returns the registered user.
     * @function
     * @memberOf LoginStep
     * @returns {JSX.Element|string}
     */
    const loginStatus = () => isIdentified
        ? <>
            <span data-testid={'status-welcome'}>{language.modals.loggedIn}</span>
            <div className="status__name">{name}</div>
        </>
        : language.modals.registeredUser

    /**
     * If `isIdentified` is true, returns `Continue`, else `Login`.
     * @function
     * @memberOf LoginStep
     * @returns {string|*}
     */
    const ctaText = () => isIdentified ? language.modals.continue : language.modals.login

    /**
     * If `isIdentified` is true, calls the `onNext` function, else the `onLogin`.
     * @function
     * @memberOf LoginStep
     * @returns {Function}
     */
    const ctaAction = () => isIdentified ? onNext : onLogin

    /**
     * If `isIdentified` is true, returns `Not you?`, else `Don't have an account?`.
     * @function
     * @memberOf LoginStep
     * @returns {string|*}
     */
    const otherText = () => isIdentified ? language.modals.notYou : language.modals.haveAccount

    /**
     * If `isIdentified` is true, returns `Login`, else `Sign Up`.
     * @function
     * @memberOf LoginStep
     * @returns {string|*}
     */
    const otherLinkText = () => isIdentified ? language.modals.login : language.modals.signUp

    /**
     * If `isIdentified` is true, returns `logged-in`, else `logged-out`.
     * @function
     * @memberOf LoginStep
     * @returns {string}
     */
    const loggedClass = () => isIdentified ? 'logged-in' : 'logged-out'

    return (
        <div className="login-step">
            <div
                className={`login-step__status login-step__status--${loggedClass()}`}
                data-testid={'status'}
            >
                {loginStatus()}
            </div>
            <div className="login-step__cta-container">
                <Button text={ctaText()} onClick={ctaAction()}/>
            </div>
            <div
                className={`login-step__other login-step__other--${loggedClass()}`}
                data-testid={'other'}
            >
                {otherText()} <a onClick={onLogin} data-testid={'link'}>{otherLinkText()}</a>
            </div>
        </div>
    )
}

export default LoginStep
