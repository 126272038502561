import React, { useEffect } from 'react'
import './SocialMedia.scss'
import SocialMediaItem from './components/social_media_item/SocialMediaItem'
import { reducedElementsPro } from '../../../utils/elementsUtils'
import { trackPromise } from 'react-promise-tracker'
import MeshContent from '../../../backend/mesh/content_service/MeshContent'
import CmsTemplatesBars from '../../../cms/cms_templates_bars/CmsTemplatesBars'
import { isEmptyObject } from '../../../utils/objectUtils'
import { CONTENT_SOCIAL_MEDIA } from '../../../cms/types'
import { faTiktok } from '@fortawesome/free-brands-svg-icons'

/**
 * @typedef SocialMediaProps
 * @memberOf SocialMedia
 * @property {string} color
 * @property {TemplateSection} socialMediaContent
 * @property {Menu} socialMediaMenu
 * @property {boolean} isEditModeOn
 */

/**
 * If it has template, render the {@link SocialMediaItem} component within {@link CmsTemplatesBars}
 * @class SocialMedia
 * @category Components
 * @subcategory theme / commons
 * @param {SocialMediaProps} props
 * @returns {React.ReactNode}
 * @example
 * <SocialMedia />
 */
const SocialMedia = (props) => {
    const { color, socialMediaContent, socialMediaMenu, isEditModeOn } = props
    const template = !!socialMediaContent && socialMediaContent?.templates && socialMediaContent?.templates[0]
    const setColor = color || '#FFFFFF'
    const socialMediaContentId = socialMediaMenu && socialMediaMenu.contentId

    useEffect(() => {
        let isCancelled = false

        const fetchSocialMediaTemplateContent = async () => {
            try {
                const socialMediaContentResponse = await trackPromise(
                    MeshContent.fetchContent(socialMediaContentId)
                )
                props.setContent(socialMediaContentResponse, false, CONTENT_SOCIAL_MEDIA)
                props.setContent(socialMediaContentResponse, true, CONTENT_SOCIAL_MEDIA)
            } catch (e) {
                console.log(e)
            }
        }

        !isCancelled && socialMediaContentId && isEmptyObject(socialMediaContent) && fetchSocialMediaTemplateContent()

        return () => {
            isCancelled = true
        }
    }, [socialMediaContentId, socialMediaContent])

    /**
     * @function
     * @memberOf SocialMedia
     * @param {string} templateId
     * @param {Template} template
     */
    const changeSocialMediaContent = (templateId, template) => {
        const newContent = {
            ...socialMediaContent,
            templates: [template]
        }
        props.setContent(newContent, true, CONTENT_SOCIAL_MEDIA)
    }

    /**
     * @function
     * @memberOf SocialMedia
     * @param {string} icon
     * @returns {Array<string>}
     */
    const getSocialMediaIcon = (icon) => {
        const typesArr = ['facebook', 'instagram', 'youtube', 'twitter', 'globe-americas', 'tiktok']
        let type = 'globe-americas'

        typesArr.forEach(element => {
            if (icon.includes(element)) type = element
        })

        return type === 'tiktok' ? faTiktok : [type === 'globe-americas' ? 'fas' : 'fab', type]
    }

    return (
        <div className="social-media">
            {
                !!template && <CmsTemplatesBars
                    key={template?.id + '-edit'}
                    template={template}
                    onChange={changeSocialMediaContent}
                    templatePosition={1}
                    availableTemplates={[]}
                    isEditModeOn={isEditModeOn}
                >
                    {
                        template?.elements?.map((element) => {
                            const reducedElement = reducedElementsPro(
                                element,
                                template.fields
                            )
                            reducedElement && delete reducedElement.element
                            return (
                                <SocialMediaItem
                                    key={element.id}
                                    icon={getSocialMediaIcon(reducedElement.LINK)}
                                    link={reducedElement.LINK}
                                    id={element.id}
                                    color={setColor}
                                />
                            )
                        })}
                </CmsTemplatesBars>
            }
        </div>
    )
}

export default SocialMedia
