import { ACTIONS } from '../actions/actions'
import { MESH_CONFIG } from '../../backend/mesh/Constants'

/**
 * Redux Reducers
 * @module reducers
 * @category Redux
 * @subcategory General
 */

/**
 * @typedef Action
 * @property {string} type
 * @property {*} [payload]
 * @example
 *  {
 *    type: 'SAVE_AUTHENTICATED_USER',
 *    payload: {
 *      organizationId: 4,
 *      email: 'frontend.mesh@meshplatform.io',
 *      ...
 *    }
 *  }
 */

/**
 *
 * @type {{organizationId: number, firstName: string, lastName: string, role: {organizationId: number, permissions: string[], name: string, description: string, id: number}, organization: string, departmentId: number, contactNumber: string, department: string, accessToken: string, userId: number, email: string}}
 */
const DEFAULT_USER_DATA = {
    organizationId: 4,
    organization: 'vfl-verein-public',
    departmentId: 4,
    department: 'GENERAL',
    email: 'frontend.mesh@meshplatform.io',
    contactNumber: '0000000001',
    firstName: 'frontend.mesh@meshplatform.io',
    lastName: 'frontend.mesh@meshplatform.io',
    accessToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI0Iiwicm9sZSI6IkZST05URU5EX1VTRVIiLCJwZXJtaXNzaW9ucyI6WyJBU1NJR05fTU9CSUxFX1VTRVJfUk9MRV9QRVJNSVNTSU9OIiwiQ1JFQVRFX1VTRVJTIiwiUkVBRF9PUkciLCJSRUFEX1VTRVJTIiwiUkVBRF9ST0xFUyJdLCJpc3MiOiJndU02clJ1MkMxSnd5VEtpZWlHUXc5bXBESEZKWWlMViIsImlkIjo1MywiZXhwIjoxNjI5OTY1MTg5LCJlbWFpbCI6ImZyb250ZW5kLm1lc2hAaGV4YWQuZGUifQ.yDdfkDiqWFJ3A7uYwjdkd4ZPb7E7Bnn0itGAt9tTwtc',
    userId: 53,
    role: {
        id: 23,
        organizationId: 4,
        name: 'FRONTEND_USER',
        description: 'Role to be used by the frontends',
        permissions: [
            'ASSIGN_MOBILE_USER_ROLE_PERMISSION',
            'CREATE_USERS',
            'READ_ORG',
            'READ_USERS',
            'READ_ROLES'
        ]
    }
}

/**
 * Organization reducer.
 * Receives the new organization ID and save it in the store.
 *
 * @function
 * @param {object} org - The current state with default value (4)
 * @param {Action} action - Any necessary data (including the action type)
 * @returns {object} The new state
 *
 *  ```
 *  organization(4, {
 *      type: ACTIONS.SET_ORGANIZATION
 *  })
 *  ```
 *
 * @example
 *  // It will dispatch the 'SET_ORGANIZATION' action
 *  const organizationId = 4
 *  dispatch(setOrganization(organizationId))
 *
 */
export const organization = (org = 4, action) => {
    switch (action.type) {
    case ACTIONS.SET_ORGANIZATION:
        return action.payload
    default:
        return org
    }
}

/**
 * User reducer
 * Receives the logged-in user and save it in the store.
 *
 * @function
 * @param {object} user - The current logged-in user
 * @param {Action} action - Any necessary data (including the action type)
 * @returns {object} The new state with the user and the status loggedIn (boolean)
 * ```
 * user(DEFAULT_USER_DATA, {
 *   type: ACTIONS.SAVE_AUTHENTICATED_USER,
 *   payload: {
 *     organizationId: 4,
 *     email: 'frontend.mesh@meshplatform.io',
 *     ...
 *   }
 * })
 * ```
 * @example
 *  // It will dispatch the 'SAVE_AUTHENTICATED_USER' action
 *  const loggedUser = {
 *    organizationId: 4,
 *    email: 'frontend.mesh@meshplatform.io',
 *    ...
 *  }
 *  dispatch(saveAuthenticatedUser(loggedUser))
 */
export const user = (user = DEFAULT_USER_DATA, action) => {
    switch (action.type) {
    case ACTIONS.SAVE_AUTHENTICATED_USER:
        return action.payload.email === MESH_CONFIG.ORGANIZATION.EMAIL
            ? { ...action.payload, loggedIn: false }
            : { ...action.payload, loggedIn: true }
    default:
        return user
    }
}

/**
 * Header menu reducer
 * Receives the header menu structure and save it in the store.
 *
 * @function
 * @param {object} menu - The current header menu state, with default value []
 * @param {Action} action - Any necessary data (including the action type)
 * @returns {object} The new state with the new menu structure
 * ```
 * headerMenu([], {
 *   type: ACTIONS.SET_HEADERMENU,
 *   payload: {
 *     id: 'HEADER_MENU',
 *     path: 'header_menu',
 *     ...
 *   }
 * })
 * ```
 * @example
 *  // It will dispatch the 'SET_HEADERMENU' action
 *  const headerMenu = {
 *    id: 'HEADER_MENU',
 *    path: 'header_menu',
 *    name: 'headerMenu',
 *    main: true,
 *    ...
 *  }
 *  dispatch(setHeaderMenu(headerMenu))
 */
export const headerMenu = (menu = [], action) => {
    switch (action.type) {
    case ACTIONS.SET_HEADERMENU:
        return action.payload
    default:
        return menu
    }
}

/**
 * Top menu reducer
 * Receives the top menu structure and save it in the store.
 *
 * @function
 * @param {object} menu - The current menu state, with default value {}
 * @param {Action} action - Any necessary data (including the action type)
 * @returns {object} The new state with the new menu structure
 * ```
 * topMenu({}, {
 *   type: ACTIONS.SET_TOPMENU,
 *   payload: {
 *     id: 'TOP_MENU',
 *     path: 'top_menu',
 *     name: 'topMenu',
 *     main: true,
 *     ...
 *   }
 * })
 * ```
 * @example
 *  // It will dispatch the 'SET_TOPMENU' action
 *  const topMenu = {
 *     id: 'TOP_MENU',
 *     path: 'top_menu',
 *     name: 'topMenu',
 *     main: true,
 *     ...
 *   }
 *  dispatch(setTopMenu(topMenu))
 */
export const topMenu = (menu = {}, action) => {
    switch (action.type) {
    case ACTIONS.SET_TOPMENU:
        return action.payload
    default:
        return menu
    }
}

/**
 * Footer menu reducer
 * Receives the footer menu structure and save it in the store.
 *
 * @function
 * @param {object} menu - The current menu state, with default value {}
 * @param {Action} action - Any necessary data (including the action type)
 * @returns {object} The new state with the new menu structure
 * ```
 * footerMenu({}, {
 *   type: ACTIONS.SET_FOOTERMENU,
 *   payload: {
 *     id: 'FOOTER_MENU',
 *     path: 'footer_menu',
 *     name: 'footerMenu',
 *     contentId: '',
 *     main: true,
 *     ...
 *   }
 * })
 * ```
 * @example
 *  // It will dispatch the 'SET_FOOTERMENU' action
 *  const footerMenu = {
 *     id: 'FOOTER_MENU',
 *     path: 'footer_menu',
 *     name: 'footerMenu',
 *     contentId: '',
 *     main: true,
 *     ...
 *   }
 *  dispatch(setFooterMenu(footerMenu))
 */
export const footerMenu = (menu = {}, action) => {
    switch (action.type) {
    case ACTIONS.SET_FOOTERMENU:
        return action.payload
    default:
        return menu
    }
}

/**
 * Social media menu reducer
 * Receives the social media menu structure and save it in the store.
 *
 * @function
 * @param {object} menu - The current menu state, with default value {}
 * @param {Action} action - Any necessary data (including the action type)
 * @returns {object} The new state with the new menu structure
 * ```
 * socialMediaMenu({}, {
 *   type: ACTIONS.SET_SOCIAL_MEDIA_MENU,
 *   payload: {
 *     id: 'SOCIAL_MEDIA_MENU',
 *     path: 'social_media_menu',
 *     name: 'socialMediaMenu',
 *     contentId: 'uuid-social-media-content',
 *     main: true
 *   }
 * })
 * ```
 * @example
 *  // It will dispatch the 'SET_SOCIAL_MEDIA_MENU' action
 *  const socialMediaMenu = {
 *     id: 'SOCIAL_MEDIA_MENU',
 *     path: 'social_media_menu',
 *     name: 'socialMediaMenu',
 *     contentId: 'uuid-social-media-content',
 *     main: true
 *   }
 *  dispatch(setSocialMediaMenu(socialMediaMenu))
 */
export const socialMediaMenu = (menu = {}, action) => {
    switch (action.type) {
    case ACTIONS.SET_SOCIAL_MEDIA_MENU:
        return action.payload
    default:
        return menu
    }
}

/**
 * Custom navigation reducer
 * Receives the new path and save it in the store.
 * Once the new path is stored it will be used to redirect the user to a new page using the history
 * E.g.: history.push(customNavigation.path)
 *
 * @function
 * @param {object} customNavigation - The current custom navigation state, with default value {}
 * @param {Action} action - Any necessary data (including the action type)
 * @returns {object} The new custom navigation state with the new path
 * ```
 * getCustomNavigation({}, {
 *   type: ACTIONS.SET_CUSTOM_NAVIGATION_PATH,
 *   payload: '/newPath'
 * })
 * ```
 * @example
 *  // It will dispatch the 'SET_CUSTOM_NAVIGATION_PATH' action
 *  const newPath = '/contact'
 *  dispatch(setCustomNavigation(newPath))
 */
export const getCustomNavigation = (customNavigation = {}, action) => {
    switch (action.type) {
    case ACTIONS.SET_CUSTOM_NAVIGATION_PATH:
        if (typeof action.payload === 'object') {
            return { ...action.payload }
        } else {
            return { path: action.payload }
        }
    default:
        return customNavigation
    }
}

/**
 * Current routes reducer
 * Receives the array with all routes without duplicates and save it in the store.
 *
 * @function
 * @param {object} currentRoutes - The current routes state, with default value []
 * @param {Action} action - Any necessary data (including the action type)
 * @returns {object} The new current routes state
 * ```
 * getCurrentRoutes([], {
 *   type: ACTIONS.SET_CURRENT_ROUTES,
 *   payload: [
 *     { route: '/become_a_member', contentId: '6448f8d537fcb049e79322fa' },
 *     { route: '/members', contentId: '6448f8d437fcb049e79322d1' }
 *   ]
 * })
 * ```
 * @example
 *  // It will dispatch the 'SET_CURRENT_ROUTES' action
 *  const routes = [
 *    { route: '/become_a_member', contentId: '6448f8d537fcb049e79322fa' },
 *    { route: '/members', contentId: '6448f8d437fcb049e79322d1' }
 *  ]
 *  dispatch(setCurrentRoutes(routes))
 */
export const getCurrentRoutes = (currentRoutes = [], action) => {
    switch (action.type) {
    case ACTIONS.SET_CURRENT_ROUTES:
        return { ...currentRoutes, path: action.payload }
    default:
        return currentRoutes
    }
}

/**
 * userCanWriteContent function
 *
 * @function
 * @param {User} user - The logged-in user saved in the store
 * @returns {boolean} The user has role permission 'WHITE_CONTENT'
 * @example
 *  // user obtained from store
 *  if (userCanWriteContent(user)) {
 *   // do something
 *  }
 */
export const userCanWriteContent = user => user.role.permissions.includes('WRITE_CONTENT')

/**
 * userCanWriteCalendar function
 *
 * @function
 * @param {User} user - The logged-in user saved in the store
 * @returns {boolean} The user has role permission 'WRITE_CALENDAR'
 * @example
 *  // user obtained from store
 *  if (userCanWriteCalendar(user)) {
 *   // do something
 *  }
 */
export const userCanWriteCalendar = user => user.role.permissions.includes('WRITE_CALENDAR')
