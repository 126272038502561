import moment from 'moment'

/**
 * @typedef ClassDataModified
 * @property {string} [id]
 * @property {string} eventsGroupId
 * @property {string} startDateTime
 * @property {string} endDateTime
 * @property {string} duration
 * @property {Object} recurrence
 * @property {string} description
 * @property {string} coach
 * @property {{minute: string, hour: string}} time
 * @property {string} place
 * @property {Array<ClassData>} [classes]
 */

/**
 * @alias formatDataForAPI
 * @memberOf AddEditClassRedux
 * @param {ClassData} state
 * @returns {Array<ClassDataModified>|ClassDataModified}
 */

const currentYear = new Date().getFullYear()
const DEFAULT_START_DATE = new Date()
const DEFAULT_END_DATE = new Date()
DEFAULT_START_DATE.setFullYear(currentYear - 1)
DEFAULT_END_DATE.setFullYear(currentYear + 1)

export const formatEventsGroupDataForAPI = (eventsGroup, courseData, contentId, templateIndex) => {
    // set - startDateTime, endDateTime
    const startDate = courseData.date ? courseData.date : DEFAULT_START_DATE
    const endingDate = courseData.endingDate ? formatDate(moment(courseData.endingDate)) : formatDate(moment(DEFAULT_END_DATE))

    const { startDateTime, endDateTime } = formatScheduleDate(
        startDate,
        courseData.time,
        courseData.duration,
        courseData.unit
    )

    return {
        ...eventsGroup,
        name: !eventsGroup.id && templateIndex > 0 ? `${eventsGroup.name}_${templateIndex}` : eventsGroup.name,
        users: eventsGroup.users ? eventsGroup.users : [],
        recurrence: {
            repeatPeriod: courseData.repeatPeriod,
            repeatType: courseData.repeatType,
            daysToRepeat: courseData.daysToRepeat,
            endingDate: endingDate,
            seed: {
                startDateTime: startDateTime,
                endDateTime: endDateTime,
                eventsGroupId: eventsGroup.id ? eventsGroup.id : '',
                coach: courseData.trainers,
                place: courseData.location,
                description: courseData.description,
                shortDescription: courseData.shortDescription
            }
        },
        contentId
    }
}

const formatScheduleDate = (localDate, time, duration, durationUnit = 'minutes') => {
    const date = moment.utc(localDate)
    const startDateWithTz = moment([date.year(), date.month(), date.date(), time.hour, time.minute, date.second()])
    const endDateWithTz = moment([date.year(), date.month(), date.date(), time.hour, time.minute, date.second()])
    const endDateWithTzPlusDuration = endDateWithTz.add(duration, durationUnit)

    return {
        startDateTime: formatDate(startDateWithTz),
        endDateTime: formatDate(endDateWithTzPlusDuration)
    }
}

export const formatDate = (date) => {
    return date.format().replace('.000', '')
}

const scheduleMapper = {
    eventsGroupId: 'eventsGroupId',
    date: 'startDateTime',
    location: 'place',
    trainer: 'coach',
    description: 'description',
    repeat: 'recurrence',
    id: 'id'
}

export const formatDataForAPI = (scheduleData) => {
    /**
     * @type {Array<ClassData>}
     */

    /**
     * @type {ClassDataModified}
     */
    const classObject = {}
    Object.keys(scheduleData).forEach((i) => {
        if (scheduleMapper[i] === 'eventsGroupId') {
            classObject[scheduleMapper[i]] = scheduleData[i]
        } else if (scheduleMapper[i] === 'recurrence') {
            classObject[scheduleMapper[i]] = null
        } else if (scheduleMapper[i]) {
            classObject[scheduleMapper[i]] = scheduleData[i]
        }
    })

    const { startDateTime, endDateTime } = formatScheduleDate(
        scheduleData.date,
        scheduleData.time,
        scheduleData.duration,
        'minutes'
    )

    classObject.startDateTime = startDateTime
    classObject.endDateTime = endDateTime

    return classObject
}

/**
 * @typedef Errors
 * @property {Array<string>} errors
 * @property {string} path
 */

/**
 * Returns an object with errors parsed and having the path as object key
 * @function
 * @alias formatError
 * @memberOf AddEditClassRedux
 * @param {Array<Errors>} errors
 * @returns {Object} - Errors object `path -> [errors]`
 * @example
 * const errors = {
 *     0: { errors: ['course is a required field'], path: 'course' },
 *     1: { errors: ['location is a required field'], path: 'location' }
 * }
 * const res = formatError(errors)
 * // { course: ['course is a required field'], location: ['location is a required field']}
 */
export const formatError = (errors) => {
    const formattedError = {}
    Object.keys(errors).forEach((i) => {
        formattedError[errors[i].path] = errors[i].errors
    })
    return formattedError
}
