import React from 'react'
import Modal from '@theme/commons/Modal/Modal'
import Button from '@theme/commons/button/Button'
import TrainerComponent from '../TrainerComponent/TrainerComponent'
import { getNameWithoutUnderscore } from '../../../../../src/utils/getNameWithoutUnderscore'

import './classDetailsModal.scss'
import { cmsLanguage } from '../../../../cms/commons/cms_language/cmsLanguage'

/**
 * @typedef ClassDetailsModalProps
 * @memberOf ClassDetailsModal
 * @property {boolean} isOpen
 * @property {boolean} currentClassDetails
 * @property {function} setIsOpen
 */

/**
 * Application Class Details Modal.
 * @class ClassDetailsModal
 * @category Components
 * @subcategory Pages / trainingSchedule
 * @param {ClassDetailsModalProps} props
 * @returns {React.ReactNode}
 * @example
 * <ClassDetailsModal isOpen={currentClassDetails} currentClassDetails={currentClassDetails} setIsOpen={setCurrentClassDetails} />
 */
export default function ClassDetailsModal ({
    isOpen,
    setIsOpen,
    currentClassDetails
}) {
    const {
        date,
        time,
        duration,
        image,
        trainer,
        description,
        location,
        name,
        path
    } = currentClassDetails
    return (
        <>
            {isOpen && (
                <Modal>
                    <div className="class-modal">
                        <div className="class-modal-title">
                            <h1>{cmsLanguage.inputFields.classInfo}</h1>
                        </div>
                        <div className="class-modal-container">
                            <div className="class-modal-section">
                                <div className="class-modal-item">
                                    <label className="class-modal-name">
                                        <span>{cmsLanguage.inputFields.className}</span>
                                    </label>
                                    <span className="class-modal-value">{getNameWithoutUnderscore(name)}</span>
                                </div>
                                <div className="class-modal-item">
                                    <label className="class-modal-name">
                                        <span>{cmsLanguage.inputFields.date}</span>
                                    </label>
                                    <span className="class-modal-value">{date}</span>
                                </div>

                                <div className="class-modal-item">
                                    <label className="class-modal-name">
                                        <span>{cmsLanguage.inputFields.time}</span>
                                    </label>
                                    <span className="class-modal-value">{time}</span>
                                </div>

                                <div className="class-modal-item">
                                    <label className="class-modal-name">
                                        <span>{cmsLanguage.inputFields.duration}</span>
                                    </label>
                                    <span className="class-modal-value">{duration}</span>
                                </div>
                            </div>

                            <div className="class-modal-section">
                                <div className="class-modal-item">
                                    <TrainerComponent trainer={trainer} image={image} />
                                </div>

                                <div className="class-modal-item">
                                    <label className="class-modal-name">
                                        <span>{cmsLanguage.inputFields.description}</span>
                                    </label>
                                    <span className="class-modal-value" dangerouslySetInnerHTML={{ __html: description }}/>
                                </div>

                                <div className="class-modal-item">
                                    <label className="class-modal-name">
                                        <span>{cmsLanguage.inputFields.location}</span>
                                    </label>
                                    <span className="class-modal-value">{location}</span>
                                </div>
                            </div>
                        </div>
                        <div className="class-modal-buttons">
                            {path && (
                                <Button lightButton={true} text={cmsLanguage.cms.goToCourse} link={path} />
                            )}
                            <Button
                                text={cmsLanguage.cms.okButton}
                                onClick={() => setIsOpen(false)}
                            />
                        </div>
                    </div>
                </Modal>
            )}
        </>
    )
}
