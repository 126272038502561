import React from 'react'
import './paymentMethodFulfillment.scss'
import CmsInput from '../../../../../../../cms/commons/cms_input/CmsInput'
import { getInputType } from '../../../../../../../cms/util/Util'
import { sortElementValues } from '../../../../../../../cms/util/sortElementValues'
import { ExternalCardPayment } from '../index'
import { PAYMENT_METHODS } from '../../constants/constants'
import PayPal from '../paypal-method-payment/PayPal'

/**
 * @typedef PaymentMethodFulfillmentProps
 * @memberOf PaymentMethodFulfillment
 * @property {function} onChange
 * @property {Array<Object>} paymentData
 * @property {string} type
 */

/**
 * This component renders the payment methods.
 * @class PaymentMethodFulfillment
 * @category Components
 * @subcategory Templates / CourseInfo
 * @param {PaymentMethodFulfillmentProps} props
 * @return {React.ReactNode}
 * @example
 * <PaymentMethodFulfillment
 *     onChange={handleCardDataOnChange}
 *     paymentData={paymentData}
 *     type={type}
 * />
 */
const PaymentMethodFulfillment = ({ onChange, paymentData, price, concept, type }) => {
    const handleOnChange = (valueId, newValue) => {
        const valueIndex = paymentData.findIndex(value => value.id === valueId)
        onChange([
            ...paymentData.slice(0, valueIndex),
            newValue,
            ...paymentData.slice(valueIndex + 1)
        ])
    }

    const handleExternalPaymentSuccess = (result) => {
        onChange(result)
    }

    const selectComponent = () => {
        switch (type) {
        case PAYMENT_METHODS.PAYPAL: return <PayPal
            price={price}
            concept={concept}
            onCompleted={handleExternalPaymentSuccess}/>
        case PAYMENT_METHODS.CHECKOUT: return <ExternalCardPayment
            returnUrl={window.location.href}
            price={price}
            concept={concept}
            paymentData={paymentData}
            type={type}
            onChange={handleExternalPaymentSuccess}
        />
        default: return sortElementValues(type, paymentData).map(value => {
            return paymentData !== undefined && (
                <CmsInput
                    key={value.id}
                    name={value.field.name}
                    type={getInputType(value.field.type)}
                    value={value.value}
                    dataTestId={`cms-input-field-${value.id}`}
                    valueId={value.id}
                    mandatory={value.field.isMandatory}
                    onChange={(valueId, newValue, hasError) => handleOnChange(valueId, { ...value, value: newValue }, hasError)}
                    defaultValue={value.defaultValue && value.defaultValue}
                    placeholder={value.placeholder && value.placeholder}
                />
            )
        })
        }
    }

    return selectComponent()
}
export default PaymentMethodFulfillment
