import React from 'react'
import { language } from '../../../../../../theme/commons/language/language'
import { Col, Row } from 'react-bootstrap'
import {
    birthdayField,
    firstNameField,
    formPropsById,
    IDS
} from '../../utils/inputFieldsList'
import FamilyMember from '../family_member'
import {
    AddMemberIcon,
    RemoveMemberIcon
} from '../add_remove_family_member_icon'
import Counter from '../../../../../../theme/commons/counter'
import FormInput from '../form_input'

/**
 * @param { object} props
 * @param { number } props.minFamily  how many members should have the membership request at least
 * @param { number } props.maxFamily  how many members should have the membership request at most
 * @param { function } props.onClickAdd  to include a new member
 * @param { function } props.onClickRemove  to remove a member
 * @param { string } props.gender  describing the gender of the main member
 * @param { function } props.onChangeField  on update global information
 * @param { function } props.onChangeMembers  on update the information of a member
 * @param { string } props.familyName  describing the common name of the family
 * @param  { array } props.familyMembers objects describing a family member: its birthday and its first name
 * @returns {JSX.Element}
 * @constructor
 */
const Family = (props) => {
    const {
        minFamily,
        maxFamily,
        onClickAdd,
        onClickRemove,
        gender,
        onChangeField,
        onChangeMembers,
        familyName,
        familyMembers,
        readOnly
    } = props
    const familySize = familyMembers.length
    const canEdit = minFamily !== maxFamily
    const canAdd = (index) =>
        canEdit && familySize < maxFamily && index === familySize - 1
    const canRemove = (index) =>
        canEdit && familySize > minFamily && index === familySize - 2
    const lastInList = (index) => index === (familyMembers.length - 1 || 0)

    const handleMemberChange = (index, fieldId, newValue, hasError) =>
        onChangeMembers(newValue, hasError, fieldId, index)

    const counterElement = (index) => {
        const isLastEditable = !!(lastInList(index) &&
            canEdit)
        return isLastEditable && <Counter current={index + 1} total={maxFamily} />
    }

    const familyEditButton = (index) => {
        return (
            <>
                {canAdd(index) && <AddMemberIcon onClick={onClickAdd} />}
                {canRemove(index) && (
                    <RemoveMemberIcon onClick={() => onClickRemove(index)} />
                )}
            </>
        )
    }

    return (
        <>
            <h5>{language.membership.personal}</h5>
            <Row>
                <Col>
                    <FormInput
                        type={formPropsById(IDS.FAMILY_NAME).type}
                        label={formPropsById(IDS.FAMILY_NAME).label}
                        value={familyName}
                        onChange={onChangeField.bind(null, IDS.FAMILY_NAME)}
                        validationTypes={formPropsById(IDS.FAMILY_NAME).validationTypes}
                        readOnly={readOnly}
                    />
                </Col>
                <Col>
                    <FormInput
                        type={formPropsById(IDS.GENDER).type}
                        label={formPropsById(IDS.GENDER).label}
                        value={gender}
                        onChange={onChangeField.bind(null, IDS.GENDER)}
                        disabled={readOnly}
                    />

                </Col>
            </Row>

            {familyMembers.map((memberData, index) => {
                const firstName = firstNameField().id
                const birthDay = birthdayField().id

                return (
                    <FamilyMember
                        key={index}
                        firstNameElement={
                            <FormInput
                                label={firstNameField().label}
                                type={firstNameField().type}
                                value={memberData[firstName]}
                                onChange={handleMemberChange.bind(null, index, IDS.FIRSTNAME)}
                                validationTypes={firstNameField().validationTypes}
                                readOnly={readOnly}
                            />
                        }
                        birthDayElement={
                            <FormInput
                                label={birthdayField().label}
                                type={birthdayField().type}
                                value={memberData[birthDay]}
                                onChange={handleMemberChange.bind(null, index, IDS.BIRTHDAY)}
                                validationTypes={birthdayField().validationTypes}
                                readOnly={readOnly}
                            />
                        }
                        counterElement={counterElement(index)}
                        addRemoveElement={
                            <Col xs={1} className={'family-member-container'}>
                                {familyEditButton(index)}
                            </Col>
                        }
                    />
                )
            }
            )}
        </>
    )
}

export default Family
