import { findInTreeAndApply } from './utils/objectUtils'
import MeshEventCategory from './backend/mesh/calendar_service/MeshEventCategory'
import MeshContentMenu from './backend/mesh/content_service/MeshContentMenu'
import MeshContent from './backend/mesh/content_service/MeshContent'
import { reducedElementsPro } from './utils/elementsUtils'

/**
 * @module trainingCategoriesInitializer
 */

/**
 * @type {string}
 * @example
 *  const COURSE_KEY = 'courses/'
 */
export const COURSE_KEY = 'courses/'

/**
 * Function that checks if the `eventGroup` has the url with the `COURSE_KEY`
 * @function
 * @param {Object} eventGroup
 * @returns {boolean} - URL has COURSE_KEY
 */
export const shouldCreateProductRelated = (eventGroup) => {
    return eventGroup.url.indexOf(COURSE_KEY) === 0
}

/**
 * Function that gets the course image of the content that will be fetched from the MeshContent backend based on the contentId
 * @function
 * @param {string} contentId - Content UUID
 * @returns {Promise<string>}
 */
export const fetchCourseImageFromContent = async (contentId) => {
    if (contentId) {
        try {
            const response = await MeshContent.fetchContent(contentId)

            const elementsParsed = reducedElementsPro(response.templates[0].elements[0], response.templates[0].fields)
            return elementsParsed.PHOTO
        } catch (e) {
            console.warn('Error while parsing image', e)
        }
    }

    return Promise.resolve('')
}

/**
 * @typedef MenuPath
 * @property {string} path
 * @property {boolean} shouldCreateCategory
 * @property {boolean} shouldCreateEventsGroup
 */

/**
 * (1) This function gets categories from the calendar service and uses them to change the menu object.
 * (2) If the category has not been found, and in the menuPath this category has the 'shouldCreateCategory' property, the new category will be created (calendar-service).
 * (3) The same will be done with the eventGroup. If the eventGroup has not been found, it will be created (calendar-service)
 * @function
 * @param {Menu} menu - Object to be modified
 * @param {MenuPath} menuPath - Object that will be used to check if it will be modified
 * @returns {Promise<void>}
 */
export const initTrainingCategories = async (menu, menuPath) => {
    try {
        const { data: categories } = await MeshEventCategory.getCategories()
        // Creation
        await findInTreeAndApply(
            menu,
            menu => menuPath.find(it => it.path === menu.path) !== undefined,
            async categoryMenu => {
                const calendarDataItem = menuPath.find(it => it.path === categoryMenu.path)
                let categoryFound = categories.find(it => it.name === categoryMenu.name)
                if (!categoryFound && calendarDataItem.shouldCreateCategory) {
                    categoryFound = (await MeshEventCategory.createCategory({ name: categoryMenu.name })).data
                }
                categoryFound.existsInMenu = true
                if (categoryMenu.categoryId !== categoryFound.id) {
                    categoryMenu.categoryId = categoryFound.id
                    MeshContentMenu.updateMenu(categoryMenu).catch()
                }
                return categoryMenu
            }
        )

        // Deletion
        categories.map(category => {
            if (category.existsInMenu !== true) {
                MeshEventCategory.deleteCategory(category.id).catch()
            }
            return category
        })
    } catch (e) {
        console.error('Error while initializing categories')
    }
}
