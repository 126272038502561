/**
 * @module dateTimePickerUtils
 * @category Utils
 * @subcategory General
 */

/**
 * Generates an array with 31 elements (days)
 * @function
 * @returns {Array<{id: string, name: string}>} - array of objects
 * ```
 * [
 *  { id: "1date", name: "01" },
 *  ...,
 *  { id: "31time", name: "31" }
 * ]
 * ```
 * @example
 * const result = generateDate()
 */
export const generateDate = () => {
    return generateNumbersToN(1, 31, 'date')
}

/**
 * Generates an array with 12 elements (month)
 * @function
 * @returns {Array<{id: string, name: string}>} - array of objects
 * ```
 * [
 *  { id: "1month", name: "01" },
 *  ...,
 *  { id: "12month", name: "12" }
 * ]
 * ```
 * @example
 * const result = generateMonth()
 */
export const generateMonth = () => {
    return generateNumbersToN(1, 12, 'month')
}

/**
 * Generates an array with 24 elements (hours)
 * @function
 * @returns {Array<{id: string, name: string}>} - array of objects
 * ```
 * [
 *  { id: "0time", name: "00" },
 *  ...,
 *  { id: "23time", name: "23" }
 * ]
 * ```
 * @example
 * const result = generateHours()
 */
export const generateHours = () => {
    return generateNumbersToN(0, 23, 'time')
}

/**
 * Generates an array with 60 elements (minutes)
 * @function
 * @returns {Array<{id: string, name: string}>} - array of objects
 * ```
 * [
 *  { id: "0time", name: "00" },
 *  ...,
 *  { id: "59time", name: "59" }
 * ]
 * ```
 * @example
 * const result = generateMinutes()
 */
export const generateMinutes = () => {
    return generateNumbersToN(0, 59, 'time')
}

/**
 * Generates an array with 161 elements (years)
 * @function
 * @returns {Array<{id: string, name: string}>} - array of objects
 * ```
 * [
 *  { id: "1900year", name: "1900" },
 *  ...,
 *  { id: "2060year", name: "2060" }
 * ]
 * ```
 * @example
 * const result = generateYear()
 */
export const generateYear = (start = 1900, end = 2060) => {
    return generateNumbersToN(start, end, 'year')
}

/**
 * Takes a starting number and an ending number, then generates an array of objects with incremental information
 * @function
 * @param {number} start - starting number
 * @param {number} end - ending number
 * @param {string} type - string with the name to compose the id
 * @returns {Array<{id: string, name: string}>} - array of objects
 * ```
 * [
 *  { id: "0time", name: "00" },
 *  { id: "1time", name: "01" }
 * ]
 * ```
 * @example
 * const result = generateNumbersToN(0, 2, 'time')
 */
export const generateNumbersToN = (start = 0, end, type) => {
    const dates = []
    for (let i = start; i <= end; i++) {
        dates.push({ id: i + type, name: padZero(i) + '' })
    }
    return dates
}

/**
 * Takes a starting number and an ending number, then generates an array of objects with incremental information with a custom increment (step)
 * @function
 * @param {number} start - starting number
 * @param {number} end - ending number
 * @param {number} increment - step number
 * @param {string} type - string with the name
 * @returns {Array<{id: string, name: string}>} - array of objects
 * ```
 * [
 *  { id: "0time", name: "00" },
 *  { id: "2time", name: "02" }
 * ]
 * ```
 * @example
 * const result = generateIncrementalExp(0, 2, 2, 'time')
 */
export const generateIncrementalExp = (start, end, increment, type) => {
    const exp = []
    for (let i = start; i <= end; i = i + increment) {
        exp.push({ id: i + type, name: padZero(i) + '' })
    }
    return exp
}

/**
 * Takes a number and returns that number starting with `0` when it is less than 10
 * @function
 * @param {number} number
 * @returns {string} - string with the number, but starting with `0`
 * @example
 * const result = padZero(2) // "02"
 * const result = padZero(11) // "11"
 */
export function padZero (number) {
    return number < 100 ? ('0' + number).slice(-2) : number.toString()
}
