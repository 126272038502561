import { cmsLanguage } from '../../../../cms/commons/cms_language/cmsLanguage'

export const repeatDropdownData = [
    { id: 'doesNotRepeat', name: cmsLanguage.inputFields.repeatDropdownDoesNotRepeat },
    { id: 'daily', name: cmsLanguage.inputFields.repeatDropdownDaily },
    { id: 'weeklyOnMonday', name: cmsLanguage.inputFields.repeatDropdownWeeklyOnMonday },
    { id: 'everyWeekDayMondayToFriday', name: cmsLanguage.inputFields.repeatDropdownEveryWeekDayMondayToFriday },
    { id: 'custom', name: cmsLanguage.inputFields.repeatDropdownCustom } // update code if you update this
]

export const repeatScheduleDataMap = {
    doesNotRepeat: null,
    daily: {
        daysToRepeat: [
            'MONDAY',
            'TUESDAY',
            'WEDNESDAY',
            'THURSDAY',
            'FRIDAY',
            'SATURDAY',
            'SUNDAY'
        ],
        oneYear: true,
        repeatType: 'DAILY',
        repeatPeriod: 1
    },
    weeklyOnMonday: {
        daysToRepeat: ['MONDAY'],
        oneYear: true,
        repeatType: 'WEEKLY',
        repeatPeriod: 1
    },
    everyWeekDayMondayToFriday: {
        daysToRepeat: ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'],
        oneYear: true,
        repeatType: 'WEEKLY',
        repeatPeriod: 1
    }
}

export const weekMonthDropDownData = [
    { id: 'daily', name: 'Daily' },
    { id: 'weekly', name: 'Weekly' },
    { id: 'monthly', name: 'Monthly' }
]
