import React from 'react'
import './NewsCard.scss'
import { Container, Row, Col } from 'react-bootstrap'
import { convertDate } from '../../../utils/CommonReformatter'
import { Link } from 'react-router-dom'

/**
 * @typedef NewsCardProps
 * @memberOf NewsCard
 * @property {News} item
 */

/**
 * News details, with the content and buttons to share and comment
 * @class NewsCard
 * @category Components
 * @subcategory theme / commons
 * @param {NewsCardProps} props
 * @returns {React.ReactNode}
 * @example
 * const item = {
 *     newsId: 'asd9a8sd9a8sd',
 *     headline: 'New headline',
 *     publishedAt: '2023-05-18',
 *     media: 'http://image.com/image.jpg',
 *     category: 'Sports'
 * }
 * <NewsCard item={item}/>
 */
const NewsCard = (props) => {
    const { media, category, publishedAt, headline, newsId } = props.item
    const convertedDate = convertDate(publishedAt)
    const convertedCategory = category.split(';')[1]

    return (
        <div
            data-testid="background-news-card"
            className="news-card-container"
            style={{ backgroundImage: `url(${media[0]})` }}
        >
            <div className="picture-gradient">
                <Link
                    className="nested-links__father"
                    to={`/news/${newsId}`}
                >
                    <Container fluid className="card-info">
                        <Row className="card-details">
                            <Col md={12} className="category">
                                <span data-testid="category-news-card">
                                    {convertedCategory}
                                </span>
                            </Col>
                        </Row>
                        <Row className="card-title">
                            <Col>
                                <span data-testid="title-news-card">{headline}</span>
                                <div className="date">
                                    <span data-testid="date-news-card">{convertedDate}</span>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Link>
            </div>
        </div>
    )
}

export default NewsCard
