// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".sub-page-menu-container{background-color:rgba(0,0,0,0.4);padding:0 3.75em 0 2.8125em}.sub-page-menu-container.animate-background{transition:all 1000ms ease}.sub-page-menu-container:hover,.sub-page-menu-container.dark-background{background-color:#191919}.header-sub-page-navigation{width:100%;padding-right:2.8125em}@media screen and (max-width: 576px){.navbar-nav{display:block;overflow-x:scroll;overflow-y:hidden;white-space:nowrap;display:block}.navbar-nav::-webkit-scrollbar{display:none}.navbar-nav{-ms-overflow-style:none;scrollbar-width:none}}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
