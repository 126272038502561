import React from 'react'
import './InputPrice.scss'
import { cmsLanguage } from '../../../cms_language/cmsLanguage'
import { PRICE_PATTERN_DE, PRICE_PATTERN_EN } from './constants'
import { getLanguage } from '../../../../../utils/languageUtils'
import InputPriceToolTip from './InputPriceToolTip'

const InputPrice = ({ value, onChange, onFocus, onBlur, maxLength, onError }) => {
    const isValidPrice = (price) => price ? getPatterByLang().test(price) : true

    const getPatterByLang = () => {
        if (getLanguage() === 'en') {
            return PRICE_PATTERN_EN
        } else {
            return PRICE_PATTERN_DE
        }
    }

    const handleOnChange = (e) => {
        const newValue = e.target.value
        const hasError = !isValidPrice(newValue)
        showError(hasError, newValue)
        onChange(newValue, hasError)
    }

    const handleOnBlur = e => {
        const { target: { value } } = e
        const hasError = !isValidPrice(value)
        showError(hasError, value)
        onBlur()
        onChange(value, hasError)
    }

    const showError = (hasError, value) => {
        if (hasError && value.length) {
            onError(cmsLanguage.error.noValidPrice, <InputPriceToolTip/>)
        } else {
            onError()
        }
    }

    return (
        <div className="cms-input-box cms-input-box-text cms-input-price">
            <input
                data-testid="cms-input-price"
                type="text"
                value={value}
                onChange={handleOnChange}
                onFocus={onFocus}
                onBlur={handleOnBlur}
                maxLength={maxLength}
                placeholder={cmsLanguage.cms.pricePlaceholder + '...'}
            />
            {maxLength && (
                <div>
                    <span data-testid="cms-input-price-counter">
                        {value.length} / {maxLength}
                    </span>
                </div>
            )}
        </div>
    )
}

export default InputPrice
