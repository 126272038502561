import React from 'react'
import TermsAndConditionsWarning from './TermsAndConditionsWarning'
import { get } from '../utils/storage'
import STORAGE_KEYS from '../utils/storageKeys'

import { getInitialTACValues } from '../utils/modalTermsAndConditions'

const TermsAndConditionsGuard = (props) => {
    const optionTAC = props.optionTAC
    const initialTACValues = getInitialTACValues()
    const currentTac = get(STORAGE_KEYS.IS_TERMS_AND_CONDITIONS_ACCEPTED) ||
    initialTACValues

    return currentTac[optionTAC] ? props.children : <TermsAndConditionsWarning/>
}

export default TermsAndConditionsGuard
