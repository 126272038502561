// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".members-area{background-color:#F0F0F0;width:100%;font-family:'Open Sans', sans-serif}.members-area__container{display:flex;flex-direction:row;flex-wrap:wrap;gap:6.25%;max-width:85%;margin-left:auto;margin-right:auto;padding-top:7.5em;padding-bottom:7.5em}.members-area__member-data{background-color:#fff;padding:6.25em 3.75em;border-bottom:solid .3125em #64b42d;margin-bottom:10em;position:relative;flex-grow:1}.members-area__member-data :first-child.cms-info-error{text-align:center}.members-area__course-data{flex-grow:2}.members-area .member-since{display:flex;flex-direction:row;align-items:center;gap:.9375em}.members-area .member-since__label{color:#595959;font-size:.875em}.members-area .member-since__value{font-size:1em;color:#191919}.members-area__button{margin-top:4.375em;margin-bottom:3.75em}.members-area__button__errors{color:#f03}.members-area__modal{display:flex;align-items:center;justify-content:space-between}.members-area__modal:nth-child(2){margin-top:.625em}.members-area__modal>svg{margin-left:.9375em;font-size:1.375em}.members-area__modal__check{color:#64b42d}.members-area__modal__times{color:#f03}.member-data__form{display:flex;flex-direction:column}.members-area-input{display:flex;flex-direction:column}.members-area-input>label{color:#595959;font-size:.875em;margin-top:.9375em;margin-bottom:0}.members-area-input input{border:none;font-size:1.25em;overflow:hidden;text-overflow:ellipsis}.members-area-input input+div{display:none}.members-area-input .cms-input-box{border:none;border-bottom:1px solid rgba(0,0,0,0.1)}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
