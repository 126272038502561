/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useState } from 'react'
import './AddEditClassModal.scss'
import { initialState, reducer } from '../../useReducer/AddEditClassReducer'
import { types } from '../../useReducer/types'
import { cmsLanguage } from '../../../../../cms/commons/cms_language/cmsLanguage'
import { repeatScheduleDataMap } from '../../dataUtils/staticData'
import { addClassSchema } from '../../validators/addClassValidator'
import CustomRecurrenceModal from '../CustomRecurrenceModel/CustomRecurrenceModal'
import { formatDataForAPI, formatError } from '../../dataUtils/dataFormatter'
import CmsModal from '../../../../../cms/commons/cms_modal/CmsModal'
import AddEditClassContent from './components/AddEditClassContent/AddEditClassContent'
import CmsModalTitle from '../../../../../cms/commons/cms_modal/CmsModalTitle'

/**
 * @class AddEditClassModal
 * @category Components
 * @subcategory theme / commons / AddEditClass
 * @param {Object} selectedCourse
 * @param {function} postSchedule
 * @param {function} putSchedule
 * @param {function} deleteSchedule
 * @param {function} onClose
 * @param {boolean} disableRepeat
 * @param {ClassData} currentClassDetails
 * @returns {React.ReactNode}
 */
export default function AddEditClassModal ({
    selectedCourse,
    postSchedule,
    putSchedule,
    deleteSchedule,
    onClose,
    disableRepeat = false,
    currentClassDetails
}) {
    const [classData, setClassData] = useReducer(reducer, currentClassDetails || initialState)
    const [error, setError] = useState()
    const [validate, setValidate] = useState(true)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isSubModalOpen, setSubModal] = useState(false)
    const [globalError, setGlobalError] = useState()

    useEffect(() => {
        selectedCourse && setClassData({ type: types.UPDATE_SELECTED_COURSE_ID, value: selectedCourse.id })
    }, [selectedCourse])

    useEffect(() => {
        validateForm().then((result) => {
            setValidate(!result)
        })
    }, [classData])

    /**
     * Validates the form data
     * @function
     * @returns {Promise<{}>}
     */
    const validateForm = async () => {
        let newError
        try {
            await addClassSchema.validate(classData, { abortEarly: false })
        } catch (err) {
            newError = formatError({ ...err.inner })
        }
        setError(newError)
        return newError
    }

    /**
     * Changes the fields value and dispatch it to the redux store
     * @function
     * @param {AddEditTypes} type
     * @param {string} value
     * @param {string} id
     */
    const handleChange = (type, value, id) => {
        if (type === types.UPDATE_REPEAT_SCHEDULE && value === cmsLanguage.inputFields.repeatDropdownCustom) {
            setSubModal((prev) => !prev)
        } else {
            const classDataValue = { type, value }
            if (type === types.UPDATE_REPEAT_SCHEDULE) {
                classDataValue.value = repeatScheduleDataMap[id] === null ? null : repeatScheduleDataMap[id] || value
            } else {
                if (type === types.UPDATE_TRAINER) {
                    classDataValue.value = value.split(',')
                }
            }
            setClassData(classDataValue)
        }
    }

    /**
     * Creates recurrence
     * @function
     * @param {Object} data
     */
    const handleCustomRecurrences = (data) => {
        handleChange(types.UPDATE_REPEAT_SCHEDULE, data)
        setSubModal(false)
    }

    /**
     * Deletes a class
     * @function
     */
    const handleDeleteClass = () => {
        deleteSchedule(classData.id)
        onClose()
    }

    /**
     * Sends a `POST` or a `PUT` request to the `calendar-service` to create or update the scheduler
     * @function
     * @returns {Promise<void>}
     */
    const handleSubmit = async () => {
        try {
            setIsSubmitting(true)
            const error = await validateForm()
            if (!error) {
                let result
                if (currentClassDetails) {
                    result = await putSchedule(formatDataForAPI(classData))
                } else {
                    result = await postSchedule(formatDataForAPI(classData))
                }

                if (!result) setGlobalError('Unable to Store Data. Please Try again.')
                else onClose()
            }
        } catch (error) {
            setGlobalError(cmsLanguage.error.overlappingClasses)
        }
    }

    const modalTitle = currentClassDetails ? cmsLanguage.cms.editClass : cmsLanguage.cms.addClass

    return (
        <>
            {!isSubModalOpen
                ? <CmsModal
                    title={
                        <CmsModalTitle
                            title={modalTitle}
                        />}
                    onClose={onClose}
                    onSave={handleSubmit}
                    onCancel={onClose}
                    onDelete={handleDeleteClass}
                    continueText={cmsLanguage.cms.acceptChanges}
                    cancelText={currentClassDetails ? cmsLanguage.cms.revertChanges : cmsLanguage.cms.cancelChanges}
                    saveAvailable={validate}
                    deleteText={cmsLanguage.cms.deleteClass}
                >
                    <AddEditClassContent
                        classData={classData}
                        error={isSubmitting && error}
                        globalError={globalError}
                        disableRepeat={disableRepeat}
                        onChange={handleChange}/>
                </CmsModal>
                : (
                    <CustomRecurrenceModal
                        handleCustomRecurrences={handleCustomRecurrences}
                        handleClose={setSubModal}
                    />
                )
            }
        </>

    )
}
