// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".progress-breadcrumb__item{position:relative;background-color:#fff}.progress-breadcrumb__item svg{font-size:1.25em}.progress-breadcrumb__item::after{content:' ';width:2.8125em;height:1px;background-color:#b8b8b8;right:2.8125em;position:absolute;transform:translateX(1px)}.progress-breadcrumb__item:first-child:after{content:'';width:0;height:0}.progress-breadcrumb__item.item--in-progress,.progress-breadcrumb__item.item--completed{color:#64b42d;border-color:#64b42d}.progress-breadcrumb__item.item--in-progress svg,.progress-breadcrumb__item.item--completed svg{color:#64b42d}.progress-breadcrumb__item.item--in-progress::after,.progress-breadcrumb__item.item--completed::after{background-color:#64b42d}.progress-breadcrumb__item.item--pending{color:#b8b8b8}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
