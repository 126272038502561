import React, { useState } from 'react'
import CmsModal from '../commons/cms_modal/CmsModal'
import { cmsLanguage } from '../commons/cms_language/cmsLanguage'
import { addNewGroupMenuItem, isHeadingGroup } from './menusList'
import './AddCMSContentModal.scss'
import { trackPromise } from 'react-promise-tracker'
import MeshContent from '../../backend/mesh/content_service/MeshContent'
import PageMenuSelection from './components/add_cms_content_modal_components/page_menu/PageMenuSelection'
import MenuSelection from './components/add_cms_content_modal_components/menu/MenuSelection'
import MainMenuItemSelection from './components/add_cms_content_modal_components/main_menu/MainMenuItemSelection'
import InfoBoxSection, { BOLD_TAG } from './components/add_cms_content_modal_components/info_box_container/InfoBoxSection'
import { initializerMenuData } from '../../initializerData'
import CmsModalTitle from '../commons/cms_modal/CmsModalTitle'

/**
 * @typedef AddCmsContentModalProps
 * @memberOf AddCmsContentModal
 * @property {function} onClose
 * @property {function} onSave
 * @property {function} setNewContentMenuConfiguration
 * @property {Menu} headerMenu
 * @property {Menu} topMenu
 * @property {Menu} footerMenu
 */

/**
 * @class AddCmsContentModal
 * @category Components
 * @subcategory Templates / CmsContent
 * @param {AddCmsContentModalProps} props
 * @returns {React.ReactNode}
 * @example
 * <AddCmsContentModal onClose={() => setShowModal(false)} onSave={handleOpenModalTwo} />
 */
const AddCmsContentModal = ({
    onClose,
    onSave,
    setNewContentMenuConfiguration,
    headerMenu,
    topMenu,
    footerMenu
}) => {
    const [menuConfigSelection, setMenuConfigSelection] = useState()
    const [pageMenuOptions, setPageMenuOptions] = useState()

    const saveAvailable =
    menuConfigSelection &&
    (menuConfigSelection.menu.path === initializerMenuData.topMenu.path ||
      (menuConfigSelection.menu.path === initializerMenuData.headerMenu.path &&
        // New heading group option and new page name has been selected.
        ((menuConfigSelection.menuItem &&
          menuConfigSelection.menuItem.group &&
          menuConfigSelection.menuItem.group.name === addNewGroupMenuItem.name &&
          menuConfigSelection.menuItem.group.name !==
            addNewGroupMenuItem.name &&
          menuConfigSelection.menuItem.group.name !== '') ||
          // Existing page has been selected.
          (menuConfigSelection.pageMenu &&
            menuConfigSelection.pageMenu.name !== addNewGroupMenuItem.name) ||
          // Heading group has been selected but no page was selected.
          (menuConfigSelection.menuItem &&
            menuConfigSelection.menuItem.group &&
            menuConfigSelection.menuItem.group.name !== addNewGroupMenuItem.name) ||
          // Selected menu without heading groups in it.
          (menuConfigSelection.menuItem &&
            menuConfigSelection.menuItem.heading &&
            !menuConfigSelection.menuItem.heading.hasHeadingGroups))) ||
      menuConfigSelection.menu.path === initializerMenuData.footerMenu.path + '/' +
      initializerMenuData.footerMenu.children.find(
          (m) => m.name === cmsLanguage.initializerData.informationMenuName
      ).path)
    /**
     * Select menu
     * @function
     * @memberOf AddCmsContentModal
     * @param {Menu}menuSelection
     */
    const handleMenuSelection = (menuSelection) => {
        setMenuConfigSelection({
            menu: menuSelection
        })
        setPageMenuOptions()
    }

    /**
     * Select main menu options
     * @function
     * @memberOf AddCmsContentModal
     * @param {Object} menuItemSelection
     * @returns {Promise<void>}
     */
    const handleMenuItemSelection = async (menuItemSelection) => {
        setPageMenuOptions()
        setMenuConfigSelection({
            menu: menuConfigSelection.menu,
            menuItem: menuItemSelection
        })
        if (menuItemSelection.page && menuItemSelection.page.contentId) {
            const contentResponse = await trackPromise(
                MeshContent.fetchContent(menuItemSelection.page.contentId)
            )
            contentResponse.menu
                ? setPageMenuOptions([
                    ...contentResponse.menu.children.filter((c) => isHeadingGroup(c)),
                    addNewGroupMenuItem
                ])
                : setPageMenuOptions([addNewGroupMenuItem])
        }
    }

    /**
     * Select page menu options
     * @function
     * @memberOf AddCmsContentModal
     * @param {Object} pageMenuSelection
     */
    const handlePageMenuChange = (pageMenuSelection) => {
        setMenuConfigSelection({
            menu: menuConfigSelection.menu,
            menuItem: menuConfigSelection.menuItem,
            pageMenu: pageMenuSelection
        })
    }

    /**
     * @function
     * @memberOf AddCmsContentModal
     * @param {Object} pageMenu
     * @returns {boolean}
     */
    const pageMenuHeadingHasNewName = ({ pageMenu }) => {
        return (
            (!!pageMenu &&
            pageMenu?.id === 'ADD_SECTION' &&
            pageMenu?.name !== 'Add New One' &&
            pageMenu?.name !== '') ||
        (!!pageMenu && pageMenu?.id !== 'ADD_SECTION' && pageMenu?.contentId === null)
        )
    }

    /**
     * @function
     * @memberOf AddCmsContentModal
     * @param {Menu} menu
     * @param {Object} pageMenu
     * @param {Object} menuItem
     * @returns {string}
     */
    const getParent = ({ menu, pageMenu, menuItem }) => {
        if (menu?.name === initializerMenuData.topMenu.name) {
            return ''
        } else if (menu?.name === 'Information') {
            return cmsLanguage.initializerData.informationMenuName
        } else if (pageMenuHeadingHasNewName({ pageMenu })) {
            return pageMenu.name
        } else if (menuItem) {
            if (menuItem?.page) {
                return menuItem.page.name
            } else {
                if (menuItem?.group) {
                    return menuItem.group.name
                } else {
                    return menuItem.heading.name
                }
            }
        } else {
            return ''
        }
    }

    /**
     * @function
     * @memberOf AddCmsContentModal
     * @param {Menu} menu
     * @param {Object} pageMenu
     * @param {Object} menuItem
     * @returns {*|string}
     */
    const getMenuType = ({ menu, pageMenu, menuItem }) => {
        let result = cmsLanguage.menuNames.mainMenu
        if (menu?.name === initializerMenuData.topMenu.name) {
            result = cmsLanguage.menuNames.topMenu
        } else if (menu?.name === 'Information') {
            result = cmsLanguage.menuNames.footerMenu
        } else if (
            menu?.name === initializerMenuData.headerMenu.name &&
            pageMenuHeadingHasNewName({ menu, pageMenu, menuItem })
        ) {
            result = cmsLanguage.menuNames.pageMenu
        }
        return result
    }

    /**
     * @function
     * @memberOf AddCmsContentModal
     * @param {Object} menuConfigSelection
     * @returns {{containerDescription: string, location: string}}
     */
    const getLocationAndContainerDescription = (menuConfigSelection) => {
        let location = ''
        let finalContainerDescription = ''
        if (menuConfigSelection) {
            const { pageMenu, menu, menuItem } = menuConfigSelection

            const menuType = getMenuType({ menu, menuItem, pageMenu }) || ''
            const parent = menu ? getParent({ pageMenu, menu, menuItem }) : 'menu'
            const isTopMenu = menu?.name === initializerMenuData.topMenu.name
            location = isTopMenu ? menuType : parent
            finalContainerDescription = isTopMenu ? '' : menuType
        }

        return { location, containerDescription: finalContainerDescription }
    }

    const { location, containerDescription } = getLocationAndContainerDescription(menuConfigSelection)

    /**
     * @function
     * @memberOf AddCmsContentModal
     * @param {string} location
     * @param {string} containerDescription
     * @returns {string}
     */
    const infoBoxFullText = (location, containerDescription) => {
        const maybeContainerDescription = containerDescription
            ? `${cmsLanguage.infoBox.parentText2}${containerDescription}`
            : ''
        return `${cmsLanguage.infoBox.parentText1}${BOLD_TAG}${maybeContainerDescription}${cmsLanguage.infoBox.parentText3}`
    }

    return (
        <CmsModal
            title={
                <CmsModalTitle
                    title={cmsLanguage.cms.createNewContent}
                    detail={cmsLanguage.cms.selectLocation}
                />
            }
            secondTitle={cmsLanguage.cms.selectLocation}
            onClose={onClose}
            onSave={() => {
                setNewContentMenuConfiguration(menuConfigSelection)
                onSave()
            }}
            saveAvailable={saveAvailable}
            saveTooltip={cmsLanguage.tooltip.chooseAContent}
            infoSpace={
                menuConfigSelection &&
        menuConfigSelection.menu.name &&
        saveAvailable &&
        true
            }
            continueText={cmsLanguage.cms.continue}
            cancelText={cmsLanguage.cms.cancel}
        >
            <div
                className="add-cms-modal-content"
                data-testid="add-cms-modal-content"
            >
                {/* --- Menu --- */}
                <MenuSelection
                    menuOptions={[topMenu, headerMenu, footerMenu]}
                    onMenuSelection={handleMenuSelection}
                />

                {/* --- Main Menu --- */}
                {menuConfigSelection &&
          menuConfigSelection.menu &&
          menuConfigSelection.menu.path === initializerMenuData.headerMenu.path && (
                    <MainMenuItemSelection
                        headingOptions={headerMenu.children.filter(
                            (menu) => menu.contentId == null
                        )}
                        onMenuItemSelection={handleMenuItemSelection}
                    />
                )}

                {/* --- Page Menu --- */}
                {pageMenuOptions && (
                    <PageMenuSelection
                        pageMenuOptions={pageMenuOptions}
                        onPageMenuChange={handlePageMenuChange}
                    />
                )}

                {/* --- Info Box --- */}
                {menuConfigSelection &&
          menuConfigSelection.menu.name &&
          saveAvailable && (
                    <InfoBoxSection
                        boldText={location}
                        fullText={infoBoxFullText(location, containerDescription)}
                    />
                )}
            </div>
        </CmsModal>
    )
}
export default AddCmsContentModal
