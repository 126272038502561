import React from 'react'
import './FooterMenu.scss'
import { Row } from 'react-bootstrap'

import { reducedElementsPro } from '../../utils/elementsUtils'
import CmsTemplatesBars from '../../cms/cms_templates_bars/CmsTemplatesBars'
import { CONTENT_FOOTER } from '../../cms/types'
import FooterColumn from './FooterColumn'

/**
 * @const
 * @memberOf FooterMenu
 * @type {number}
 * @example
 * const INFO_COLUMN = 1
 */
const INFO_COLUMN = 1

/**
 * @typedef FooterMenuProps
 * @memberOf FooterMenu
 * @property {Array<Menu>} footerMenu
 * @property {Template} contact
 * @property {boolean} isEditModeOn
 */

/**
 * Footer menu component
 * @class FooterMenu
 * @category Components
 * @subcategory Footer
 * @param {FooterMenuProps} props
 * @returns {React.ReactNode}
 * @example
 * <FooterMenu
 *     footerMenu={footerMenu.children}
 *     contact={footerContent && footerContent.templates && footerContent.templates[0]}
 * />
 */
const FooterMenu = (props) => {
    const { footerMenu, contact, isEditModeOn } = props

    /**
     * @function
     * @memberOf FooterMenu
     * @param {Object} column
     * @returns {Array<*>}
     */
    const parseInfoMenuItems = (column) => {
        return column?.children?.filter((it) => it.contentId).map(({ id, name, path }) => ({ id, name, path }))
    }

    /**
     * @function
     * @memberOf FooterMenu
     * @param {Template} contact
     * @returns {Array<*>}
     */
    const parseContactMenuItems = (contact) => {
        const menuItems = []
        if (contact) {
            const record = reducedElementsPro(contact.elements[0], contact.fields, false)
            menuItems.push(...Object.values(record).map((item, index) => ({
                name: item,
                id: index
            })))
        }
        return menuItems
    }

    /**
     * @function
     * @memberOf FooterMenu
     * @param {string} templateId
     * @param {Template} template
     */
    const changeFooterContent = (templateId, template) => {
        props.updateTemplate(templateId, template, true, CONTENT_FOOTER)
    }

    /**
     * @function
     * @memberOf FooterMenu
     * @param {Object} column
     * @param {boolean} isInformationMenu
     * @returns {*}
     */
    const menuItems = (column, isInformationMenu) =>
        isInformationMenu
            ? parseInfoMenuItems(column)
            : parseContactMenuItems(contact)

    /**
     * @function
     * @memberOf FooterMenu
     * @param {{children: JSX.Element}} params
     * @returns {JSX.Element}
     */
    const editableWrapper = ({ children }) => (
        <CmsTemplatesBars
            key={contact && contact.id + '-edit'}
            template={contact}
            onChange={changeFooterContent}
            templatePosition={1}
            availableTemplates={[]}
            isEditModeOn={isEditModeOn}
        >
            {children}
        </CmsTemplatesBars>
    )

    /**
     * @function
     * @memberOf FooterMenu
     * @param {boolean} isEditable
     * @returns {JSX.Element|function}
     */
    const wrapper = (isEditable) => (isEditable ? editableWrapper : ({ children }) => children)

    return (
        <Row className='footer-menu'>
            {footerMenu &&
                footerMenu.map((column, index) => {
                    const isInfo = index === INFO_COLUMN
                    return (
                        <FooterColumn
                            key={column.id}
                            id={column.id}
                            name={column.name}
                            menuItems={menuItems(column, isInfo)}
                            wrapper={wrapper(!isInfo)}
                        />
                    )
                })}
        </Row>
    )
}

export default FooterMenu
