import * as yup from 'yup'
import { cmsLanguage } from '../../cms/commons/cms_language/cmsLanguage'
import { postcodeValidator } from 'postcode-validator'
import { EMAIL_PATTERN } from '../../cms/commons/cms_input/components/input_email/constants'
import { PHONE_PATTERN } from '../../cms/commons/cms_input/components/input_phone/constants'
import { NAME_PATTERN } from '../../cms/commons/cms_input/components/input_component/constants'

const invalidText = cmsLanguage.error.invalidField
const nameValidation = yup.string().matches(NAME_PATTERN, invalidText)

yup.addMethod(yup.string, 'postCode', function (errorMessage) {
    return this.test('de-post-code-type', errorMessage, function (postCode) {
        const { path, createError } = this
        return postCode !== '' && postCode
            ? postcodeValidator(postCode, 'DE') || createError({ path, message: errorMessage })
            : true
    })
})

export const isMandatory = (field) => {
    return profileFormValidator.fields[field]?.exclusiveTests.required
}

const profileFormValidator = yup.object().shape({
    firstName: nameValidation.required(cmsLanguage.error.mandatory),
    lastName: nameValidation.required(cmsLanguage.error.mandatory),
    displayName: yup.string().required(cmsLanguage.error.mandatory),
    birthDate: yup.date().nullable(),
    gender: yup.string(),
    email: yup.string().matches(EMAIL_PATTERN, cmsLanguage.error.noValidEmail).required(cmsLanguage.error.mandatory),
    contactNumber: yup.string().matches(PHONE_PATTERN, cmsLanguage.error.noValidPhone).required(cmsLanguage.error.mandatory),
    postCode: yup.string().postCode(cmsLanguage.error.noValidPostCode).when(['streetNumber'], {
        is: (streetNumber) => !!streetNumber,
        then: yup.string().postCode(cmsLanguage.error.noValidPostCode).required(cmsLanguage.error.mandatory),
        otherwise: yup.string().postCode(cmsLanguage.error.noValidPostCode)
    }),
    streetNumber: yup.string().when(['city'], {
        is: (city) => !!city,
        then: yup.string().required(cmsLanguage.error.mandatory),
        otherwise: yup.string()
    }),
    city: nameValidation,
    sports: yup.string(),
    profileImage: yup.string()
})

export default profileFormValidator
