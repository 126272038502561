import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import store from './redux/store'
import LoadingIndicator from './theme/commons/loading_indicator/LoadingIndicator'
import AppContainer from './AppContainer'
import Mesh from './backend/mesh/Mesh'
import { saveAuthenticatedUser, setOrganization } from './redux/actions/actions'
import { MESH_CONFIG } from './backend/mesh/Constants'
import Root from './Root'
import STORAGE_KEYS from './utils/storageKeys'
import './typedef'

/**
 * Function that save the logged-in user in the redux store
 * @memberof AuthenticatedApp
 * @returns {Promise<void>}
 */
const frontendLogin = async () => {
    const currentUser = JSON.parse(localStorage.getItem(STORAGE_KEYS.USER))

    if (!currentUser) {
        console.log('User not logged in, using frontend user')
        let organization
        try {
            organization = await Mesh.getOrganization()
            store.dispatch(setOrganization(organization))
        } catch (e) {
            console.error('Failed to identify organization')
        }
        if (organization) {
            try {
                const authentication = await Mesh.getAuthentication(MESH_CONFIG.ORGANIZATION, organization)
                store.dispatch(saveAuthenticatedUser(authentication))
            } catch (e) {
                console.error('Failed to authenticate')
            }
        }
    }
}

/**
 * AuthenticatedApp component. Wrap the entire app inside the `BrowserRoute` provider and a redux provider (`Root` component).
 * @class AuthenticatedApp
 * @category Components
 * @subcategory General
 * @returns {JSX.Element} The entire app inside the BrowserRoute provider and a redux provider.
 * ```
 * <BrowserRouter>
 *  <Root>
 *      <LoadingIndicator />
 *      {!loading && <AppContainer />}
 *  </Root>
 * </BrowserRouter>
 * ```
 */
const AuthenticatedApp = () => {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        frontendLogin()
            .then(() => setLoading(false))
    }, [])

    return (
        <BrowserRouter>
            <Root>
                <LoadingIndicator />
                {!loading &&
                <AppContainer />}
            </Root>
        </BrowserRouter>
    )
}

ReactDOM.render(<AuthenticatedApp/>, document.getElementById('root'))

serviceWorker.unregister()
