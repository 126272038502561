import React from 'react'
import { bindActionCreators, compose } from 'redux'
import { setCustomNavigation } from '../../../../../redux/actions/actions'
import { connect } from 'react-redux'
import SuccessStep from './SuccessStep'

/**
 * Returns the {@link SuccessStep} component with the function `onNext={handleNext}`.
 * @class SuccessStepContainer
 * @category Containers
 * @subcategory Templates / CourseInfo
 * @param {Object} props
 * @returns {React.ReactNode}
 */
export const SuccessStepContainer = (props) => {
    const handleNext = () => {
        props.onNext()
        props.setCustomNavigation('/members')
    }
    return <SuccessStep {...props} onNext={handleNext} />
}

/**
 * Passes certain Redux actions ({@link module:actions.setCustomNavigation})
 * to the {@link SuccessStep} component as props.
 * This function is used only by the react-redux connect function.
 * @function
 * @memberOf SuccessStepContainer
 * @alias mapDispatchToProps
 * @param {function} dispatch - The react-redux dispatch function
 * @returns {Object} - Redux actions used in the {@link SuccessStep} component
 */
const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ setCustomNavigation }, dispatch)

export default compose(connect(null, mapDispatchToProps))(
    SuccessStepContainer
)
