/**
 *
 * @module objectUtils
 * @category Utils
 * @subcategory General
 */

/**
 * This function will modify the tree `menuEntry` using the `apply` function, if the return of the `predicate` function is `true`.
 * @function
 * @param {Menu} menuEntry - Original tree to be modified
 * @param {function(*): boolean} predicate - Function that receives menuEntry and returns true if the condition to be modified is true
 * @param {function(*): Promise<Menu>} apply - Function that receives menuEntry and apply changes to it
 * @returns {Promise<{children}|*>}
 * @example
 *  await findInTreeAndApply(menuEntry, predicate, apply)
 */
export const findInTreeAndApply = async (menuEntry, predicate, apply) => {
    let menuEntryResult = menuEntry
    if (predicate(menuEntry)) {
        try {
            menuEntryResult = await apply(menuEntry)
        } catch (e) {
            console.log(e)
        }
    }
    if (menuEntryResult.children && menuEntryResult.children.length > 0) {
        try {
            menuEntryResult.children = await Promise.all(menuEntry.children.map(child => findInTreeAndApply(child, predicate, apply)))
        } catch (e) {
            console.log(e)
        }
    }
    return menuEntryResult
}

/**
 * It returns a boolean if the object is null/undefined or if it doesn't have any key => value
 * @function
 * @param {Object} obj - Object to be verified
 * @returns {boolean} - The return is true if the object is null/undefined or without key => value
 * @example
 *  isEmptyObject(obj)
 */
export const isEmptyObject = (obj) => !obj || Object.keys(obj).length === 0
