import React from 'react'
import './NextButton.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cmsLanguage } from '../../../cms/commons/cms_language/cmsLanguage'

/**
 * @typedef NextButtonProps
 * @memberOf NextButton
 * @property {function} handleSelect
 */

/**
 * Next button of the carousel Home banner
 * @class NextButton
 * @category Components
 * @subcategory Home
 * @param {NextButtonProps} props
 * @returns {React.ReactNode}
 * @example
 * <NextButton handleSelect={() => ({})} />
 */
const NextButton = ({ handleSelect }) => {
    return (
        <div onClick={handleSelect} className="next-button">
            <span>{cmsLanguage.cms.nextTitle}</span>
            <FontAwesomeIcon
                className="right-arrow-icon"
                icon={['fas', 'arrow-right']}
                color="white"
            />
        </div>
    )
}

export default NextButton
