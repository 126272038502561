/**
 * @module navigationType
 * @category Theme
 * @subcategory constants
 */

/**
 * @typedef {string} NavigationType
 **/

/**
 * Enum for navigation types
 * @readonly
 * @category Enums
 * @enum {NavigationType}
 * @example
 * button: 'BUTTON'
 * link: 'LINK'
 */
export const NAVIGATION_TYPE = {
    button: 'BUTTON',
    link: 'LINK'
}
