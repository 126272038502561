// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".success-step h3{font-family:'Open Sans', sans-serif;font-weight:700;font-style:normal;text-transform:uppercase;font-size:1em;color:#191919;margin-bottom:1.875em;text-align:center}.success-step .button-container{width:fit-content;margin-left:auto;margin-right:auto}.success-step .button-container--schedule{margin-top:3.75em}.success-step .button-container--done{margin-top:1.875em}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
