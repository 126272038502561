import React from 'react'
import './CmsModal.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AdminButton from '../admin_button/AdminButton'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { cmsLanguage } from '../cms_language/cmsLanguage'
import { Modal } from 'react-bootstrap'

/**
 * @typedef CmsModalProps
 * @memberOf CmsModal
 * @property {React.ReactNode} children
 * @property {string|JSX.Element} title
 * @property {boolean} [saveAvailable]
 * @property {string} [saveTooltip]
 * @property {boolean} [infoSpace]
 * @property {string} continueText
 * @property {string} cancelText
 * @property {boolean} [showError]
 * @property {function} onSave
 * @property {function} [onClose]
 * @property {function} [onDelete]
 * @property {function} [onCancel]
 * @property {string} [deleteText]
 */

/**
 * Creates a modal structure with title, children (text or a component), call to action and error message.
 * @class CmsModal
 * @category Components
 * @subcategory cms / commons
 * @param {CmsModalProps} props
 * @returns {React.ReactNode}
 * @example
 * <CmsModal
 *      title="Modal Title"
 *      secondTitle="Select the location"
 *      onClose={handleOnClose}
 *      onSave={handleOnSave}
 *      saveAvailable={true}
 *      infoSpace={true}
 *      continueText="Continue"}
 *      cancelText="Cancel">
 *      ...
 * </CmsModal>
 */
const CmsModal = (props) => {
    const {
        children,
        title,
        saveAvailable,
        saveTooltip,
        infoSpace,
        continueText,
        cancelText,
        showError,
        onSave,
        onClose,
        onDelete,
        onCancel,
        deleteText
    } = props

    return (
        <Modal
            className="modal-common-config"
            size="lg"
            show={true}
            onHide={onClose}
        >
            <div className="cms-modal" data-testid="cms-modal">
                <div className="cms-modal-title">
                    {title}
                    <button onClick={onClose} data-testid="close-button">
                        <FontAwesomeIcon icon={['fa', 'times']} />
                    </button>
                </div>
                <div className={!infoSpace ? 'cms-modal-content' : ''}>
                    {children}
                </div>
                <div className="cms-modal-buttons">
                    {showError && (
                        <div className="cms-modal-mandatory-warning">
                            <p data-testid="error-message">{cmsLanguage.error.shouldFixAllErrors}</p>
                        </div>
                    )}
                    <div className="cms-modal-buttons-container">
                        <div className="cms-modal-buttons-values">
                            <AdminButton
                                data-testid="save-button"
                                text={continueText || cmsLanguage.cms.acceptChanges}
                                type={saveAvailable ? 'border-solid' : 'disable'}
                                color={saveAvailable ? 'green' : ''}
                                icon={['fa', 'check']}
                                onClick={onSave}
                                tooltip={!saveAvailable && saveTooltip && saveTooltip + '.'}
                            />
                            <AdminButton
                                data-testid="cancel-button"
                                text={cancelText || cmsLanguage.cms.cancelChanges}
                                type="border-solid"
                                color="red"
                                icon={['fa', 'times']}
                                onClick={onCancel || onClose}
                            />
                        </div>
                        {onDelete && (
                            <div className="cms-modal-buttons-delete">
                                <AdminButton
                                    data-testid="delete-button"
                                    text={deleteText || cmsLanguage.cms.deleteTemplate}
                                    type={'border-solid'}
                                    color="black"
                                    icon={faTrashAlt}
                                    onClick={onDelete}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default CmsModal
