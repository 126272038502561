import React from 'react'
import './ActionButtonBanner.scss'
import NavigationWrapper from '../../theme/commons/navigation_wrapper/NavigationWrapperContainer'
import { NAVIGATION_TYPE } from '../../theme/constants/navigationType'

/**
 * @typedef ActionButtonBannerProps
 * @memberOf ActionButtonBanner
 * @property {Array<Object>} actionButtonBannerContent
 */

/**
 * Shows the Action Button Banner template component, with a text and a button (Call to action).
 * @class ActionButtonBanner
 * @category Components
 * @subcategory Templates
 * @param {ActionButtonBannerProps} props
 * @returns {React.ReactNode}
 * @example
 * <ActionButtonBanner actionButtonBannerContent={actionButtonBannerContent} />
 */
const ActionButtonBanner = (props) => {
    const { actionButtonBannerContent } = props

    return (
        <div className="action-button-banner-container vertical-website-padding horizontal-website-padding">
            {actionButtonBannerContent.map((item, index) => {
                return (
                    <div key={index}>
                        {item.TEXT && (
                            <div className="action-button-banner-text">
                                <span>{item.TEXT}</span>
                            </div>
                        )}
                        <div className="action-button-banner-button">
                            <NavigationWrapper type={NAVIGATION_TYPE.button} text={item.BUTTON_TEXT} href={item.BUTTON_LINK}/>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default ActionButtonBanner
