/**
 *
 * @module patchUtils
 * @category Utils
 * @subcategory General
 */

/**
 *
 * @function
 * @param {"add" | "remove" | "replace" | "copy" | "move" | "test"} op
 * @param {string} path
 * @param {string} value
 * @return {{op: string, path: string, value: string}}
 */
export const patchOperation = (op, path, value) => ({ op, path: `/${path}`, value })

/**
 *
 * @function
 * @param {string} path
 * @param {any} value
 * @return {{op: string, path, value}}
 */
export const replace = (path, value) => patchOperation('replace', path, value)

/**
 * If `typeOfOp === 'replace'`, loops through an array looking for a pattern to be replaced, else returns an empty array.
 * @function
 * @param {Array<any>} array
 * @param {"add" | "remove" | "replace" | "copy" | "move" | "test"} typeOfOp
 * @return {Array<*>}
 */
const adaptToPatch = (array, typeOfOp) => {
    const newValuesArray = []
    if (typeOfOp === 'replace') {
        array
            .filter(([_, value]) => typeof value !== 'undefined')
            .forEach(([path, value]) => newValuesArray.push(replace(path, value)))
    }
    return newValuesArray
}

export default adaptToPatch
