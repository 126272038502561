import React, { useState } from 'react'
import { convertNameToRoute } from '../../../../../utils/CommonReformatter'
import MeshContentMenu from '../../../../../backend/mesh/content_service/MeshContentMenu'
import { setCustomNavigation } from '../../../../../redux/actions/actions'
import { Col } from 'react-bootstrap'
import AdminButton from '../../../../../cms/commons/admin_button/AdminButton'
import CmsModal from '../../../../../cms/commons/cms_modal/CmsModal'
import { cmsLanguage } from '../../../../../cms/commons/cms_language/cmsLanguage'
import CmsInput from '../../../../../cms/commons/cms_input/CmsInput'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { toggleEditMode } from '../../../../../cms/actions/CMSActions'
import { templateTypes } from '../../../../../cms/cms_templates_bars/components/cms_modal_multiple_elements/constants'
import MeshContent from '../../../../../backend/mesh/content_service/MeshContent'
import MeshEventsGroup from '../../../../../backend/mesh/calendar_service/MeshEventsGroup'

const EditableMenuItem = ({ menuItem, isEditModeOn, setCustomNavigation, toggleEditMode }) => {
    const [showModal, setShowModal] = useState(false)
    const [newMenuItemName, setNewMenuItemName] = useState(menuItem.name)
    const isTopMenuItem = menuItem.path.split('/').length < 3
    const handleAdminButton = () => {
        setShowModal(true)
    }
    const handleSaveEdit = async () => {
        const newPath = menuItem.path.split('/').map(it => it === convertNameToRoute(menuItem.name) ? convertNameToRoute(newMenuItemName) : it).join('/')
        await MeshContentMenu.updateMenu({
            ...menuItem,
            path: newPath,
            name: newMenuItemName
        })
        const content = await MeshContent.fetchContent(menuItem.contentId)
        const courseTemplate = content.templates.find(template => template.type === templateTypes.COURSE)
        if (courseTemplate && courseTemplate.metadata && courseTemplate.metadata.eventsGroupId) {
            const { data: eventsGroup } = await MeshEventsGroup.findByEventsGroupId(courseTemplate.metadata.eventsGroupId)
            MeshEventsGroup.updateEventsGroup({ ...eventsGroup, name: newMenuItemName, url: newPath.split('/').splice(1).join('/') })
        }
        toggleEditMode()
        setCustomNavigation('/')
        setShowModal(false)
    }
    return isEditModeOn && !isTopMenuItem
        ? (
            <Col xs={4}>
                <AdminButton
                    text=""
                    onClick={() => handleAdminButton(menuItem)}
                    type={'border-solid'}
                    color='green'
                    icon={'edit'}
                    data-testid='edit-template-button'
                />
                {showModal && (
                    <CmsModal
                        title={cmsLanguage.cms.editNavigation}
                        onSave={handleSaveEdit}
                        onClose={() => setShowModal(false)}
                        onCancel={() => setShowModal(false)}
                        showError={false}
                        saveAvailable={true}
                        continueText={cmsLanguage.cms.saveChanges}
                        cancelText={cmsLanguage.cms.cancelChanges}
                    >
                        <CmsInput
                            name="Name"
                            type="SHORT_TEXT"
                            value={newMenuItemName}
                            mandatory={true}
                            onChange={(valueId, newValue, hasError) => setNewMenuItemName(newValue)}
                            defaultValue={newMenuItemName}
                            placeholder="Name"
                        />
                    </CmsModal>
                )}
            </Col>
        )
        : ''
}

const mapStateToProps = (state) => {
    return { isEditModeOn: state.cms.isEditModeOn }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ setCustomNavigation, toggleEditMode }, dispatch)

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditableMenuItem)
