import React from 'react'
import MeshWallets from '../../backend/mesh/wallets_service/MeshWallets'

/**
 * Gets logged-in user wallet information from the wallets-service and makes them available for use.
 * @function
 * @category Hooks
 * @returns {Array<*>} - [wallet: Wallet, isLoading: boolean]
 * @example
 * const [wallet, isLoading] = useUserWallet()
 */
export default function useUserWallet () {
    const [wallet, setWallet] = React.useState({})
    const [isLoading, setIsLoading] = React.useState(false)

    React.useEffect(() => {
        let isMounted = true
        const fetchWallet = () => {
            MeshWallets.fetchUserWallet()
                .then(({ data }) => {
                    console.debug('User wallet received', data)
                    if (isMounted) {
                        setWallet(data)
                        setIsLoading(false)
                    }
                })
                .catch((err) => {
                    console.warn('Could not get wallet', err)
                    setIsLoading(false)
                })
        }
        if (isMounted && !isLoading) {
            setIsLoading(true)
            fetchWallet()
        }

        return () => {
            isMounted = false
        }
    }, [])

    return [wallet, setWallet, isLoading]
}
