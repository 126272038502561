/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Dropdown from '@theme/commons/dropdown/Dropdown'
import { monthToString } from '@utils/CommonReformatter'
import { getStartAndEndTimeByWeek, getWeekNumber, incrementDecrementDay } from '@utils/timeUtils'
import { WeekCalendar } from './components'
import './trainingPage.scss'
import { cmsLanguage } from '../../cms/commons/cms_language/cmsLanguage'
import { translateValue } from '../../utils/elementsUtils'
import { useLocation } from 'react-router-dom'
import { NAVIGATION_TYPE } from '../../theme/constants/navigationType'
import NavigationWrapper from '../../theme/commons/navigation_wrapper/NavigationWrapperContainer'
import { trackPromise } from 'react-promise-tracker'
import MeshEventsGroup from '../../backend/mesh/calendar_service/MeshEventsGroup'
import { getNameWithoutUnderscore } from '../../utils/getNameWithoutUnderscore'

/**
 * @typedef TrainingPageProps
 * @memberOf TrainingPage
 * @property {Array<Object>} categories
 * @property {function} getCategories
 * @property {function} getMyCourses
 * @property {function} clearSchedule
 * @property {function} setSelectedCourse
 * @property {function} getPublicSchedule
 * @property {function} toggleTrainingPage
 * @property {Array<Object>} schedules
 * @property {boolean} isEditModeOn
 * @property {Object} selectedCourse
 */

/**
 * Become a member component page.
 * It shows the steps with the forms to become a member.
 * @class TrainingPage
 * @category Components
 * @subcategory Pages / trainingSchedule
 * @param {TrainingPageProps} props
 * @returns {React.ReactNode}
 * @example
 * <TrainingPage />
 */
const TrainingPage = (props) => {
    const {
        categories,
        getCategories,
        getMyCourses,
        clearSchedule,
        getPublicSchedule,
        schedules,
        isEditModeOn,
        selectedCourse
    } = props

    const location = useLocation()
    const [selectedCategory, setSelectedCategory] = useState()
    const [selectedCourses, setSelectedCourses] = useState()

    const [currentWeek, setCurrentWeek] = useState(1)
    const [currentMonth, setCurrentMonth] = useState('November')
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()

    const searchEventsGroupByCategoryIdAndContentIdNotEmpty = (categoryId) => {
        return trackPromise(
            MeshEventsGroup.searchEventsGroups({
                sample: {
                    categoryId: categoryId,
                    contentId: ''
                },
                matchers: [{
                    field: 'contentId',
                    matcherType: 'NOT_EMPTY'
                }]
            })
        )
    }

    useEffect(() => {
        const today = new Date()
        const monthNumber = monthToString(today.getMonth())
        const { startDateTime, endDateTime } = getStartAndEndTimeByWeek(
            today.toISOString()
        )
        setStartDate(startDateTime)
        setEndDate(endDateTime)
        setCurrentMonth(monthNumber)
        setCurrentWeek(getWeekNumber(today))
        getCategories()
        props.toggleTrainingPage(true)
    }, [])

    useEffect(() => {
        const _getEventsGroup = async () => {
            if (location.state && location.state.categoryId && location.state.categoryId !== '') {
                const category = categories && categories.find((it) => it.id === location?.state?.categoryId)
                if (category) {
                    setSelectedCategory(category)
                    const response = await searchEventsGroupByCategoryIdAndContentIdNotEmpty(category.id)
                    setSelectedCourses(response.data.filter(it => !it.name.match(/_\d+$/)))
                }
            }
        }
        _getEventsGroup()
    }, [categories])

    useEffect(() => {
        if (location.state && location.state.eventsGroupId && location.state.eventsGroupId !== '' && selectedCategory && selectedCategory.eventsGroups) {
            const selectedCourseTemp = selectedCategory.eventsGroups.find(it => it.id === location?.state?.eventsGroupId)
            if (selectedCourseTemp) {
                props.setSelectedCourse(selectedCourseTemp)
                if (startDate && endDate) {
                    getPublicSchedule(startDate, endDate, selectedCourseTemp.id)
                }
            }
        }
    }, [selectedCourses])

    /**
     * @function
     * @memberOf TrainingPage
     * @param {string} name
     * @param {string} id
     */
    const handleCategorySelection = async (name, id) => {
        const category = categories && categories.find((it) => translateValue(it.name) === name)
        console.debug({ categories, category, name, id })
        setSelectedCategory(category)
        const response = await searchEventsGroupByCategoryIdAndContentIdNotEmpty(category.id)
        setSelectedCourses(response.data.filter(ev => ev.contentId && ev.contentId !== '' && !ev.name.match(/_\d+$/)))
        props.setSelectedCourse(null)
        if (id === 'myCourses' && categories[0]?.eventsGroups?.length === 0) {
            getMyCourses()
        }
        clearSchedule()
    }

    /**
     * @function
     * @memberOf TrainingPage
     * @param {string} name
     * @param {string} _id
     */
    const handleCourseSelection = (name, _id) => {
        const selectedCourseTemp = selectedCourses.find(it => translateValue(it.name) === name)
        console.debug({ selectedCourses, selectedCourseTemp, name, _id })
        props.setSelectedCourse(selectedCourseTemp)
        getPublicSchedule(startDate, endDate, selectedCourseTemp.id)
    }

    /**
     * @function
     * @memberOf TrainingPage
     * @param {number} week
     * @param {string} month
     * @param {string} operation
     */
    const changeWeek = (week, month, operation) => {
        if (!startDate || !endDate || !selectedCourse) {
            // TODO: add toast for error
            console.log('Please provide enough information')
            return
        }
        const newDate =
            operation === '+'
                ? incrementDecrementDay(endDate, 2, '+')
                : incrementDecrementDay(startDate, 2, '-')

        setCurrentMonth(monthToString(newDate.getMonth()))
        setCurrentWeek(getWeekNumber(newDate))
        const { startDateTime, endDateTime } = getStartAndEndTimeByWeek(newDate)
        setStartDate(startDateTime)
        setEndDate(endDateTime)
        getPublicSchedule(startDateTime, endDateTime, selectedCourse.id)
    }

    return (
        <div className="training-container">
            <div className="training-subContainer">
                <div className="training-header-row training-header">
                    <div className="training-header-row training-dropbox-container">
                        <Dropdown
                            data-testid="category-dropdown"
                            title={cmsLanguage.cms.categories}
                            data={categories}
                            headerOnly="true"
                            onSelect={handleCategorySelection}
                            printHeader={true}
                            providedValue={selectedCategory ? translateValue(selectedCategory.name) : ''}
                        />
                        {selectedCourses &&
                        <Dropdown
                            data-testid="courses-dropdown"
                            title={`${cmsLanguage.cms.selectCourses}...`}
                            data={selectedCourses}
                            onSelect={handleCourseSelection}
                            printHeader={true}
                            headerOnly="true"
                            providedValue={selectedCourse ? translateValue(getNameWithoutUnderscore(selectedCourse.name)) : ''}
                        />}
                    </div>
                    {selectedCourse &&
                      <NavigationWrapper
                          type={NAVIGATION_TYPE.button}
                          text={cmsLanguage.cms.viewCourse}
                          href={`/${selectedCourse.url}`}
                      />
                    }
                </div>
                <div className="training-timetable-container">
                    <WeekCalendar
                        course={selectedCourse || {}}
                        path={selectedCourse ? selectedCourse.path : ''}
                        startDate={startDate}
                        data={schedules}
                        week={currentWeek}
                        month={currentMonth}
                        changeWeek={changeWeek}
                        isEditMode={isEditModeOn}
                    />
                </div>
            </div>
        </div>
    )
}
export default TrainingPage
