/**
 * Redux Actions
 * @module actions
 * @category Redux
 * @subcategory General
 */

/**
 * @typedef {string} Actions
 **/

/**
 * Names of the actions that will be dispatched and checked on the reducers
 * @readonly
 * @enum {Actions}
 */
export const ACTIONS = {
    SAVE_AUTHENTICATED_USER: 'SAVE_AUTHENTICATED_USER',
    SET_ORGANIZATION: 'SET_ORGANIZATION',
    SET_TOPMENU: 'SET_TOPMENU',
    SET_HEADERMENU: 'SET_HEADERMENU',
    SET_FOOTERMENU: 'SET_FOOTERMENU',
    SET_SOCIAL_MEDIA_MENU: 'SET_SOCIAL_MEDIA_MENU',
    SET_CUSTOM_NAVIGATION_PATH: 'SET_CUSTOM_NAVIGATION_PATH',
    SET_CURRENT_ROUTES: 'SET_CURRENT_ROUTES',
    SET_PRODUCTS: 'SET_PRODUCTS',
    SET_PRODUCT: 'SET_PRODUCT',
    UPDATE_PRODUCT: 'UPDATE_PRODUCT'
}

/**
 * Return object for the saveAuthenticatedUser function
 * @typedef SaveAuthenticatedUserReturnObject
 * @property {ACTIONS} type - An action type
 * @property {User} payload - The user object
 */
/**
 * Store user logged in the redux store, with the property 'loggedIn' as true or false
 * @function
 * @param {User} user - The user object
 * @returns {SaveAuthenticatedUserReturnObject} Prepared action to be dispatched
 */
export const saveAuthenticatedUser = (user) => {
    return {
        type: ACTIONS.SAVE_AUTHENTICATED_USER,
        payload: user
    }
}

/**
 * Return object for the setOrganization function
 * @typedef SetOrganizationReturnObject
 * @property {ACTIONS} type - An action type
 * @property {number} payload - The organization ID
 */
/**
 * Store the organization ID
 * @function
 * @param {number} organization - The organization ID
 * @returns {SetOrganizationReturnObject} Prepared action to be dispatched
 */
export const setOrganization = organization => {
    return {
        type: ACTIONS.SET_ORGANIZATION,
        payload: organization
    }
}

/**
 * Return object for the setHeaderMenu function
 * @typedef SetHeaderMenuReturnObject
 * @property {ACTIONS} type - An action type
 * @property {Menu} payload - The menu to be stored
 */
/**
 * Store the header menu
 * @function
 * @param {Menu} menu - Header menu to be stored
 * @returns {SetHeaderMenuReturnObject} Prepared action to be dispatched
*/
export const setHeaderMenu = menu => {
    return {
        type: ACTIONS.SET_HEADERMENU,
        payload: menu
    }
}

/**
 * Return object for the setTopMenu function
 * @typedef SetTopMenuReturnObject
 * @property {ACTIONS} type - An action type
 * @property {Menu} payload - The menu to be stored
 */
/**
 * Store the top menu
 * @function
 * @param {Menu} menu - Top menu to be stored
 * @returns {SetTopMenuReturnObject} Prepared action to be dispatched
 */
export const setTopMenu = menu => {
    return {
        type: ACTIONS.SET_TOPMENU,
        payload: menu
    }
}

/**
 * Return object for the setFooterMenu function
 * @typedef SetFooterMenuReturnObject
 * @property {ACTIONS} type - An action type
 * @property {Menu} payload - The menu to be stored
 */
/**
 * Store the footer menu
 * @function
 * @param {Menu} menu - Footer menu to be stored
 * @returns {SetFooterMenuReturnObject} Prepared action to be dispatched
 */
export const setFooterMenu = menu => {
    return {
        type: ACTIONS.SET_FOOTERMENU,
        payload: menu
    }
}

/**
 * Return object for the setSocialMediaMenu function
 * @typedef SetSocialMediaMenuReturnObject
 * @property {ACTIONS} type - An action type
 * @property {Menu} payload - The menu to be stored
 */
/**
 * Store the social media menu
 * @function
 * @param {Menu} menu - Social media menu to be stored
 * @returns {SetSocialMediaMenuReturnObject} Prepared action to be dispatched
 */
export const setSocialMediaMenu = menu => {
    return {
        type: ACTIONS.SET_SOCIAL_MEDIA_MENU,
        payload: menu
    }
}

/**
 * Return object for the setCustomNavigation function
 * @typedef SetCustomNavigationReturnObject
 * @property {ACTIONS} type - An action type
 * @property {string} payload - URL
 */
/**
 * Store the new url for browsing
 * @function
 * @param {string} customNavigation - URL to be used on navigation
 * @returns {SetCustomNavigationReturnObject} Prepared action to be dispatched
 */
export const setCustomNavigation = customNavigation => {
    return {
        type: ACTIONS.SET_CUSTOM_NAVIGATION_PATH,
        payload: customNavigation
    }
}

/**
 * Return object for the setCurrentRoutes function
 * @typedef SetCurrentRoutesReturnObject
 * @property {ACTIONS} type - An action type
 * @property {Array<Route>} payload - Array with all routes
 */
/**
 * Store all the application routes
 * @function
 * @param {Array<Route>} currentRoutes - Routes to be stored
 * @returns {SetCurrentRoutesReturnObject} Prepared action to be dispatched
 */
export const setCurrentRoutes = currentRoutes => {
    return {
        type: ACTIONS.SET_CURRENT_ROUTES,
        payload: currentRoutes
    }
}
