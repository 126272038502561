import { v4 as uuidv4 } from 'uuid'
import { cmsLanguage } from '../../../commons/cms_language/cmsLanguage'

const carousel = () => {
    const fields = [
        {
            name: 'picture',
            type: 'PHOTO',
            id: uuidv4(),
            isMandatory: true
        },
        {
            name: 'title',
            type: 'H1',
            id: uuidv4(),
            isMandatory: true
        },
        {
            name: 'text',
            type: 'H2',
            id: uuidv4(),
            isMandatory: true
        },
        {
            name: 'linkText',
            type: 'SHORT_TEXT_1',
            id: uuidv4(),
            isMandatory: false
        },
        {
            name: 'link',
            type: 'LINK',
            id: uuidv4(),
            isMandatory: false
        },
        {
            name: 'firstHashtag',
            type: 'SHORT_TEXT_2',
            id: uuidv4(),
            isMandatory: false
        },
        {
            name: 'secondHashtag',
            type: 'SHORT_TEXT_3',
            id: uuidv4(),
            isMandatory: false
        }
    ]
    const values = fields.map(f => ({
        fieldId: f.id,
        id: uuidv4(),
        value: '',
        field: f
    }))
    return {
        id: uuidv4(),
        name: cmsLanguage.templatesNames.carousel,
        type: 'CARROUSEL',
        elements: [
            {
                id: uuidv4(),
                values: values,
                type: 'default'
            }
        ],
        fields: fields
    }
}

export const CARROUSEL_TEMPLATE_ORDER = { PHOTO: 0, H1: 1, H2: 2, LINK: 3, SHORT_TEXT_1: 4, SHORT_TEXT_2: 5, SHORT_TEXT_3: 6 }

export default carousel
