import React, { useRef } from 'react'
import wrapErrorComponent from '../errorHOC/ErrorHoc'
import './inputWithCount.scss'

/**
 * @typedef InputWithCountProps
 * @memberOf InputWithCount
 * @property {string} text
 * @property {string} placeholder
 * @property {function} setText
 * @property {string} [size] - medium, large, small, normal
 * @property {boolean} [canResize]
 * @property {string} [maxAllowedCount]
 * @property {string} [data-testid]
 */

/**
 * Modal that show a title, text and a button with some received action
 * @class InputWithCount
 * @category Components
 * @subcategory theme / commons
 * @param {InputWithCountProps} props
 * @returns {React.ReactNode}
 * @example
 * <InputWithCount text="" placeholder="Introduce name" setText={handleSetText} maxAllowedCount={20} />
 */
function InputWithCount ({
    text,
    placeholder,
    setText,
    size = 'normal',
    canResize,
    maxAllowedCount,
    'data-testid': testId = 'input-with-count'
}) {
    const count = useRef(text ? text.length : 0)

    /**
     * Changes the received class name based on the size property
     * @function
     * @memberOf InputWithCount
     * @param className
     * @returns {string}
     */
    const getTextAreaHeight = (className = '') => {
        className += size === 'large' ? ' input-count-textarea' : ''
        return className
    }

    /**
     * Changes the internal state called 'text' with the received event target value and changes the count with its length
     * @function
     * @memberOf InputWithCount
     * @param event - HTML event
     */
    const handleChange = (event) => {
        const { value } = event.target
        count.current = value.length
        setText(value)
    }

    /**
     * Changes the received class name based on the size property
     * @function
     * @memberOf InputWithCount
     * @param {string} className
     * @returns {string} - the new class name
     */
    const setDropdownWidth = (className) => {
        switch (size) {
        case 'medium':
            className += ' input-width-medium'
            break
        case 'large':
            className += ' input-width-large'
            break
        case 'small':
            className += ' input-width-small'
            break
        case 'normal':
            className += ' input-width-normal'
            break
        default:
            className += ' input-width-medium'
            break
        }
        return className
    }

    return (
        <div className={setDropdownWidth('input-count-wrapper')} data-testid="input-with-count-container">
            <textarea
                className={`${getTextAreaHeight('input-count')} ${canResize ? '' : 'hide-input-resize'}`}
                onChange={handleChange}
                value={text}
                placeholder={placeholder}
                maxLength={maxAllowedCount}
                data-testid={testId}
                rows={`${canResize ? '5' : '1'}`}
            />
            {maxAllowedCount && (
                <div className="word-count">
                    {count.current}/{maxAllowedCount}
                </div>
            )}
        </div>
    )
}

export default wrapErrorComponent(InputWithCount)
