import React from 'react'
import MenuItem from './components/menu_items/MenuItem'
import './MainMenu.scss'
import { Nav, Navbar } from 'react-bootstrap'
import Logo from '../../../theme/commons/logo/Logo'
import SocialMedia from '../../../theme/commons/social_media/SocialMediaContainer'
import TopBar from '../top_bar/TopBarContainer'
import ProfileAvatar from '../profile/ProfileAvatar'
import STORAGE_KEYS from '../../../utils/storageKeys'

/**
 * @typedef MainMenuProps
 * @memberOf MainMenu
 * @property {Menu} topMenu
 * @property {Menu} headerMenu
 * @property {boolean} fullDesktopMenu
 * @property {boolean} headerOpened
 * @property {boolean} profileOpened
 * @property {function} setHeaderOpened
 * @property {Menu} subpageMenu
 * @property {function} setProfileOpened
 * @property {function} onAvatarClick
 */

/**
 * Main menu component
 * @class MainMenu
 * @category Components
 * @subcategory Header
 * @param {MainMenuProps} props
 * @returns {React.ReactNode}
 * @example
 * <MainMenu
 *     topMenu={topMenu}
 *     headerMenu={headerMenu}
 *     fullDesktopMenu={fullDesktopMenu}
 *     subpageMenu={props.menu}
 *     headerOpened={headerOpened}
 *     setHeaderOpened={setHeaderOpened}
 *     setProfileOpened={setProfileOpened}
 *     profileOpened={profileOpened}
 *     onAvatarClick={onAvatarClick}
 * />
 */
const MainMenu = (props) => {
    const {
        topMenu,
        headerMenu,
        fullDesktopMenu,
        headerOpened,
        setHeaderOpened,
        subpageMenu,
        setProfileOpened,
        onAvatarClick
    } = props

    const isLoggedIn = localStorage.getItem(STORAGE_KEYS.USER)

    return (
        <Navbar
            expand="xl"
            expanded={fullDesktopMenu ? false : headerOpened}
            onToggle={() => setHeaderOpened(!headerOpened)}
            className={`main-menu-container ${headerOpened && 'dark-background'}`}
        >
            <Navbar.Brand href="/">
                <Logo/>
            </Navbar.Brand>
            <div className='mobile-avatar-menu-group'>
                {!fullDesktopMenu && isLoggedIn && <ProfileAvatar onClick={onAvatarClick}/>}
                <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    onClick={() => {
                        setHeaderOpened(!headerOpened)
                        setProfileOpened(false)
                    }}
                />
            </div>
            <Navbar.Collapse id="basic-navbar-nav">
                {!fullDesktopMenu && (
                    <TopBar className="collapsed-topBar" topMenu={topMenu}/>
                )}
                <Nav className="mr-auto header-main-navigation">
                    {headerMenu &&
                    headerMenu.map((item) => {
                        return (
                            <MenuItem
                                key={item.id}
                                menu={item}
                                fullDesktopMenu={fullDesktopMenu}
                                setHeaderOpened={setHeaderOpened}
                            />
                        )
                    })}
                </Nav>
                <Nav>
                    <div className="sub-page-menu">
                        {subpageMenu && subpageMenu.children && (
                            <>
                                <MenuItem
                                    menu={{
                                        path: subpageMenu.path,
                                        name: subpageMenu.name
                                    }}
                                    isSubPageMenu={true}
                                    setHeaderOpened={setHeaderOpened}
                                />
                                {subpageMenu.children.map((subItem, index) => {
                                    return (
                                        <MenuItem
                                            key={index}
                                            menu={subItem}
                                            className="sub-page-menu"
                                            isSubPageMenu={true}
                                            setHeaderOpened={setHeaderOpened}
                                        />
                                    )
                                })}
                            </>
                        )}
                    </div>
                </Nav>
                <div className="header-social-media">
                    <SocialMedia />
                </div>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default MainMenu
