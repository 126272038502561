import { cmsLanguage } from '../commons/cms_language/cmsLanguage'
import { templateTypes } from '../cms_templates_bars/components/cms_modal_multiple_elements/constants'

/**
 * Returns the template inputs based on the received type.
 * @module generateTemplate
 * @category Utils
 * @subcategory Cms
 * @param {string} templateType
 * @param {Array<Object>} values
 * @param {string} elementType
 * @param {number} elementIndex
 * @returns {Object}
 */
export const returnElementName = (templateType, values, elementType, elementIndex) => {
    let elementName

    switch (templateType) {
    case templateTypes.CARROUSEL:
        elementName = values[0]?.value
        break
    case templateTypes.SMALL_CARROUSEL:
        elementName = values[0]?.value
        break
    case templateTypes.IMAGE_LIBRARY:
        elementName = values[0]?.value
        break
    case templateTypes.BANNER_IMAGE_LIBRARY:
        elementName = values[0]?.value
        break
    case templateTypes.ACCORDION:
        elementName = values[0]?.value
        break
    case templateTypes.PROFILES:
        elementName = values[1]?.value
        break
    case templateTypes.MARQUESINA:
        elementName = values[1]?.value
        break
    case templateTypes.COURSE:
        if (elementType === 'class') {
            elementName = `${cmsLanguage.cms.class} ${elementIndex + 1}`
        } else {
            elementName = values[0]?.value
        }
        break
    default:
        elementName = values[0]?.value
    }

    return elementName
}
