import React from 'react'
import Radio from '../../../Radio/Radio'
import DatePicker from '../../../DatePicker/DatePicker'
import './endsOnComponent.scss'
import Dropdown from '../../../dropdown/Dropdown'
import { generateNumbersToN } from '../../../../../utils/dateTimePickerUtils'
import { cmsLanguage } from '../../../../../cms/commons/cms_language/cmsLanguage'

/**
 * @typedef EndsOnComponentProps
 * @memberOf EndsOnComponent
 * @property {function} setEndsOn - it will be used to manipulate the `endsOn` property
 * @property {{ type: string, value: string }} endsOn
 */

/**
 * @class EndsOnComponent
 * @category Components
 * @subcategory theme / commons / AddEditClass
 * @param {EndsOnComponentProps} props
 * @returns {JSX.Element}
 */
export default function EndsOnComponent ({ endsOn, setEndsOn }) {
    /**
    * @function
    * @memberOf EndsOnComponent
    * @param {string} type
    * @param {string} value
    */
    const updateEndsOn = (type, value) => {
        const currentVal = { ...endsOn }
        if (type) {
            currentVal.type = type
            currentVal.value = undefined
        }
        if (value) currentVal.value = value
        setEndsOn(currentVal)
    }
    return (
        <div className="endsOn-wrapper">
            <Radio
                name="Never"
                disabled={endsOn.type !== 'Never'}
                onClickHandler={(type) => updateEndsOn(type)}
                selected={endsOn.type}
            />
            <div className="endsOn-row">
                <Radio
                    name="On"
                    onClickHandler={(type) => setEndsOn({ type: type })}
                    selected={endsOn.type}
                />
                <DatePicker
                    onChangeDate={(value) => updateEndsOn(null, value)}
                    disabled={endsOn.type !== 'On'}
                    value={endsOn.type === 'On' && endsOn.value}
                />
            </div>
            <div className="endsOn-row">
                <Radio
                    name="After"
                    onClickHandler={(type) => updateEndsOn(type)}
                    selected={endsOn.type}
                />
                <div className="endsOn-row">
                    <Dropdown
                        title={cmsLanguage.inputFields.select}
                        width="small"
                        printHeader={true}
                        data={generateNumbersToN(1, 30)}
                        headerOnly={true}
                        onSelect={(value) => updateEndsOn(null, value)}
                        disabled={endsOn.type !== 'After'}
                        providedValue=""
                    />
                    <span className="endsOn-text">{cmsLanguage.inputFields.occurances}</span>
                </div>
            </div>
        </div>
    )
}
