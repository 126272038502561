import { compose } from 'redux'
import { connect } from 'react-redux'
import CmsTemplatesBars from './CmsTemplatesBars'

const mapStateToProps = (state) => {
    const { cms, user, training } = state

    return {
        isAddNewTemplateModeOn: cms.isAddNewTemplateModeOn,
        isTrainingPage: cms.isTrainingPage,
        selectedCourse: training.selectedCourse,
        user: user
    }
}

export default compose(connect(mapStateToProps))(
    CmsTemplatesBars
)
