/**
 * @typedef {string} ActionsTrainingTypes
 **/

/**
 * Names of the actions that will be dispatched and checked on the training reducer.
 * @readonly
 * @enum {ActionsTrainingTypes}
 */
export const types = {
    FETCH_FETCH_CATEGORIES: 'GET/FETCH_CATEGORIES',
    SET_PUBLIC_SCHEDULE_TIMES: 'SET_PUBLIC_SCHEDULE_TIMES',
    ERROR: 'TRAINING/ERROR',
    FETCH_MY_COURSES: 'GET/FETCH_MY_COURSES',
    CLEAR_SCHEDULE: 'REMOVE/CLEAR_SCHEDULE',
    DELETE_PUBLIC_SCHEDULE: 'DELETE_PUBLIC_SCHEDULE',
    SET_SELECTED_COURSE: 'SET_SELECTED_COURSE'
}
