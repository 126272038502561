// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".payment-method-input{display:flex;justify-content:space-between;align-items:center;cursor:pointer}.payment-method-input__container{display:flex;align-items:center;column-gap:.625em;font-family:'Open Sans', sans-serif;font-weight:normal;font-style:normal;font-size:1.125em;color:#191919;line-height:1em}.payment-method-input__container input{position:relative;-webkit-appearance:none;appearance:none;margin:0;width:.9375em;height:.9375em;cursor:pointer}.payment-method-input__container input:after{content:' ';position:absolute;left:0;top:0;width:.9375em;height:.9375em;border-radius:50%;border:1px solid #191919}.payment-method-input__container input:checked:after{border-color:#64b42d}.payment-method-input__container input:checked:before{content:' ';position:absolute;left:50%;top:50%;width:.3125em;height:.3125em;border-radius:50%;transform:translate(-50%, -50%);background-color:#64b42d;border:1px solid #64b42d}.payment-method-input__logos{display:flex;column-gap:.625em;height:1.875em}.payment-method-input__logos .logo-container img{object-fit:contain;max-width:100%;max-height:100%}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
