import { useEffect } from 'react'
import MeshMultimedia from '../../backend/mesh/multimedia_service/MeshMultimedia'
import { initialFullUserData } from './utils'
import useUserData from '../../hooks/useUserData'

/**
 * Gets the eventGroup from the `calendar-service` by the `eventGroupID` ({@link EventsGroup}.
 * @function
 * @category Hooks
 * @subcategory Templates / CourseInfo
 * @returns {Array<Object>} - [course: EventsGroup]
 * @example
 */
const useFullUserData = () => {
    const [userData, setUserData] = useUserData(initialFullUserData)

    // Fetch Image according user id
    useEffect(() => {
        let isMounted = true
        const fetchProfileImage = () => {
            MeshMultimedia.getImage(undefined, userData.id)
                .then((res) => {
                    console.log(res)
                    // setUserData({ ...userData, profileImage: res })
                })
                .catch((error) => {
                    console.error('Profile Image not found', { error })
                })
        }
        if (isMounted && userData.id) {
            fetchProfileImage()
        }

        return () => {
            isMounted = false
        }
    }, [userData.id])

    return [userData, setUserData]
}

export default useFullUserData
