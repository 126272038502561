/**
 * @module isMobile
 * @category Utils
 * @subcategory General
 */

/**
 * Returns true if `iphone|ipod|android|ie|blackberry|fennec` is found in navigator.userAgent or if the width is less the 450
 * @returns {boolean}
 */
export const isMobile = () => {
    // str.test() is more efficent than str.match()
    const isMobile = /iphone|ipod|android|ie|blackberry|fennec/.test(
        navigator.userAgent.toLowerCase()
    )
    const width = window.innerWidth
    return isMobile || width < 450
}
