import TEMPLATE_FIELD_POSITIONS from '../cms_templates_bars/components/cms_modal_multiple_elements/constants'
import { PAYMENT_FIELD_POSITIONS } from '../../templates/course_info/components/steps/payment-step/constants/constants'

/**
 * @module sortElementValues
 * @category Utils
 * @subcategory Cms
 * @param {string} templateType
 * @param {Object} values
 * @returns {Array<*>}
 */
export const sortElementValues = (templateType, values) => {
    const orderedFields = []
    const isCard = templateType === 'VISA_MASTER' || templateType === 'PAYPAL' ||
        templateType === 'KLARNA' || templateType === 'SEPA' || templateType === 'GIROPAY'

    values.forEach(value => {
        if (isCard) {
            orderedFields[PAYMENT_FIELD_POSITIONS[templateType][value.field.type]] = value
        } else {
            orderedFields[TEMPLATE_FIELD_POSITIONS[templateType][value.field.type]] = value
        }
    })

    return orderedFields.filter(field => field)
}
