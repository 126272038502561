import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { setCurrentRoutes } from '../../../../../redux/actions/actions'
import InputLink from './InputLink'

const mapStateToProps = (state) => {
    const { getCurrentRoutes } = state

    return {
        currentRoutes: getCurrentRoutes
    }
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ setCurrentRoutes }, dispatch)

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    InputLink
)
