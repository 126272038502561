// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dropdown-open{display:flex;justify-content:space-between}.dropdown-open .edit-cms-modal-element{position:relative;border:solid 1px rgba(100,180,45,0.6);padding:.9375em;width:100%;font-family:'Open Sans', sans-serif;font-weight:normal;font-style:normal;font-size:1em;color:#191919;line-height:2em;margin-top:0;margin-bottom:.9375em}.dropdown-open .edit-cms-modal-element:only-child{padding:inherit;margin-bottom:initial;border:inherit}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
