import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import EditTemplateBar from './EditTemplateBar'
import * as actions from '../../../../pages/trainingSchedule/actions/trainingActions'

const mapDispatchToProps = (dispatch) => bindActionCreators({ ...actions }, dispatch)

export default compose(connect(null, mapDispatchToProps))(
    EditTemplateBar
)
