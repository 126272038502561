import { CONTENT_FOOTER, CONTENT_PAGE, CONTENT_SOCIAL_MEDIA, DEFAULT_CONTENT_VARIANT, types } from '../types'

/**
 * @module ContentActions
 * @category Redux
 * @subcategory Cms
 */

/**
 * @function
 * @inner
 * @static
 * @param {TemplateSection} content
 * @param {boolean} applyOnDraft
 * @param {string} variant
 * @returns {function} - A React Redux dispatch function
 */
export const setContent = (content, applyOnDraft = true, variant = DEFAULT_CONTENT_VARIANT) => async (dispatch, getState) => {
    dispatch({
        type: types.SET_CONTENT,
        applyOnDraft,
        variant,
        payload: { content }
    })
}
/**
 * @function
 * @inner
 * @static
 * @param {number} templatePosition
 * @param {Template} template
 * @param {boolean} applyOnDraft
 * @param {string} variant
 * @returns {function} - A React Redux dispatch function
 */
export const addTemplate = (templatePosition, template, applyOnDraft = true, variant = DEFAULT_CONTENT_VARIANT) => (dispatch, getState) => {
    dispatch({
        type: types.ADD_TEMPLATE,
        applyOnDraft,
        variant,
        payload: { templatePosition, template }
    })
}
/**
 * @function
 * @inner
 * @static
 * @param {string} templateId
 * @param {Template} template
 * @param {boolean} applyOnDraft
 * @param {string} variant
 * @returns {function} - A React Redux dispatch function
 */
export const updateTemplate = (templateId, template, applyOnDraft = true, variant = DEFAULT_CONTENT_VARIANT) => (dispatch, getState) => {
    dispatch({
        type: types.UPDATE_TEMPLATE,
        applyOnDraft,
        variant,
        payload: { templateId, template }
    })
}
/**
 * @function
 * @inner
 * @static
 * @param {string} templateId
 * @param {boolean} applyOnDraft
 * @param {string} variant
 * @returns {function} - A React Redux dispatch function
 */
export const deleteTemplate = (templateId, applyOnDraft = true, variant = DEFAULT_CONTENT_VARIANT) => (dispatch, getState) => {
    dispatch({
        type: types.DELETE_TEMPLATE,
        applyOnDraft,
        variant,
        payload: { templateId }
    })
}
/**
 * @function
 * @inner
 * @static
 * @param {string} templateId
 * @param {Element} element
 * @param {boolean} applyOnDraft
 * @param {string} variant
 * @returns {function} - A React Redux dispatch function
 */
export const addElement = (templateId, element, applyOnDraft = true, variant = DEFAULT_CONTENT_VARIANT) => (dispatch, getState) => {
    dispatch({
        type: types.ADD_ELEMENT,
        applyOnDraft,
        variant,
        payload: { templateId, element }
    })
}
/**
 * @function
 * @inner
 * @static
 * @param {string} templateId
 * @param {string} elementId
 * @param {Element} element
 * @param {boolean} applyOnDraft
 * @param {string} variant
 * @returns {function} - A React Redux dispatch function
 */
export const updateElement = (templateId, elementId, element, applyOnDraft = true, variant = DEFAULT_CONTENT_VARIANT) => (dispatch, getState) => {
    dispatch({
        type: types.UPDATE_ELEMENT,
        applyOnDraft,
        variant,
        payload: { templateId, elementId, element }
    })
}
/**
 * @function
 * @inner
 * @static
 * @param {string} templateId
 * @param {string} elementId
 * @param {boolean} applyOnDraft
 * @param {string} variant
 * @returns {function} - A React Redux dispatch function
 */
export const deleteElement = (templateId, elementId, applyOnDraft = true, variant = DEFAULT_CONTENT_VARIANT) => (dispatch, getState) => {
    dispatch({
        type: types.DELETE_ELEMENT,
        applyOnDraft,
        variant,
        payload: { templateId, elementId }
    })
}
/**
 * @function
 * @inner
 * @static
 * @param {string} templateId
 * @param {string} elementId
 * @param {TemplateValue} value
 * @param {boolean} applyOnDraft
 * @param {string} variant
 * @returns {function} - A React Redux dispatch function
 */
export const addValue = (templateId, elementId, value, applyOnDraft = true, variant = DEFAULT_CONTENT_VARIANT) => (dispatch, getState) => {
    dispatch({
        type: types.ADD_VALUE,
        applyOnDraft,
        variant,
        payload: { templateId, elementId, value }
    })
}
/**
 * @function
 * @inner
 * @static
 * @param {string} templateId
 * @param {string} elementId
 * @param {string} valueId
 * @param {TemplateValue} value
 * @param {boolean} applyOnDraft
 * @param {string} variant
 * @returns {function} - A React Redux dispatch function
 */
export const updateValue = (templateId, elementId, valueId, value, applyOnDraft = true, variant = DEFAULT_CONTENT_VARIANT) => (dispatch, getState) => {
    dispatch({
        type: types.UPDATE_VALUE,
        applyOnDraft,
        variant,
        payload: { templateId, elementId, valueId, value }
    })
}
/**
 * @function
 * @inner
 * @static
 * @param {string} templateId
 * @param {string} elementId
 * @param {string} valueId
 * @param {boolean} applyOnDraft
 * @param {string} variant
 * @returns {function} - A React Redux dispatch function
 */
export const deleteValue = (templateId, elementId, valueId, applyOnDraft = true, variant = DEFAULT_CONTENT_VARIANT) => (dispatch, getState) => {
    dispatch({
        type: types.DELETE_VALUE,
        applyOnDraft,
        variant,
        payload: { templateId, elementId, valueId }
    })
}

/**
 * @function
 * @inner
 * @static
 * @type {{'[CONTENT_SOCIAL_MEDIA]': string, '[CONTENT_PAGE]': string, '[CONTENT_FOOTER]': string}}
 */
export const ContentStoreMapping = {
    [CONTENT_PAGE]: 'content',
    [CONTENT_FOOTER]: 'footer',
    [CONTENT_SOCIAL_MEDIA]: 'socialMedia'
}

/**
 * @function
 * @inner
 * @static
 * @param {string} variant
 * @returns {function} - A React Redux dispatch function
 */
export const restoreDefaultValues = (variant = DEFAULT_CONTENT_VARIANT) => (dispatch, getState) => {
    const content = getState()[ContentStoreMapping[variant]]
    setContent(content, true, variant)(dispatch, getState)
}

/**
 * @function
 * @inner
 * @static
 * @param {Menu} menu
 * @param {boolean} applyOnDraft
 * @param {string} variant
 * @returns {function} - A React Redux dispatch function
 */
export const setContentMenu = (menu, applyOnDraft, variant = DEFAULT_CONTENT_VARIANT) => async (dispatch, getState) => {
    try {
        dispatch({
            type: types.SET_CONTENT_MENU,
            applyOnDraft,
            variant,
            payload: menu
        })
    } catch (e) {
        console.log('Something went wrong', e)
    }
}
