import React from 'react'
import './textTemplate.scss'

/**
 * @typedef TextTemplateProps
 * @memberOf TextTemplate
 * @property {string} textContent
 */

/**
 * Receives a `textContent` and displays it on the screen.
 * @class TextTemplate
 * @category Components
 * @subcategory Templates
 * @param {TextTemplateProps} props
 * @returns {React.ReactNode}
 * @example
 * <TextTemplate textContent={textAreaContent[0]?.TEXT_EDITOR} />
 */
const TextTemplate = ({ textContent }) => {
    return (
        <div
            data-testid="text-template"
            className="text-template"
            dangerouslySetInnerHTML={{ __html: textContent }}
        />
    )
}

export default TextTemplate
