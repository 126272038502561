import React from 'react'
import { postcodeValidator } from 'postcode-validator'
import { setDisplayName } from '../../../../../utils/getDisplayName'
import { EMAIL_PATTERN } from '../input_email/constants'
import { cmsLanguage } from '../../../cms_language/cmsLanguage'
import InputPhoneToolTip from '../input_phone/InputPhoneToolTip'
import { PHONE_PATTERN } from '../input_phone/constants'

export const VALIDATION_TYPES = {
    PHONE: 'phone',
    EMAIL: 'email',
    NOT_EMPTY: 'empty',
    POSTCODE: 'postCode',
    TEXT_CHARS_2_30: 'textChars_2_30',
    BIC: 'bic'
}

export const POSTCODE_COUNTRY_CODE = 'DE'

const withValidation = (BaseComponent) => {
    const WithValidation = (props) => {
        const { onChange, onError, validationTypes } = props
        const validationOnChange = (newValue) => {
            let hasError = false
            const emptyErrorValidator = () => {
                const errorMessageEmpty = '*' + cmsLanguage.error.fieldEmpty
                onError(errorMessageEmpty)
                hasError = true
            }

            if (validationTypes.includes(VALIDATION_TYPES.NOT_EMPTY)) {
                if (newValue !== '' && validationTypes.length === 1) {
                    onError(false)
                    hasError = false
                } else {
                    emptyErrorValidator()
                }
            }

            if (validationTypes.includes(VALIDATION_TYPES.EMAIL)) {
                const errorMessage = '*' + cmsLanguage.error.noValidEmail.toLowerCase()
                const isValid = EMAIL_PATTERN.test(newValue.toLowerCase())
                if (newValue !== '') {
                    !isValid ? onError(errorMessage) : onError(false)
                    hasError = !isValid
                } else {
                    emptyErrorValidator()
                }
            }

            if (validationTypes.includes(VALIDATION_TYPES.PHONE)) {
                const errorMessage = '*' + cmsLanguage.error.noValidPhone.toLowerCase()
                const isValid = PHONE_PATTERN.test(newValue)
                if (newValue !== '') {
                    !isValid ? onError(errorMessage, <InputPhoneToolTip/>) : onError(false)
                    hasError = !isValid
                } else {
                    emptyErrorValidator()
                }
            }

            if (validationTypes.includes(VALIDATION_TYPES.POSTCODE)) {
                const errorMessagePostCode = '*' + cmsLanguage.error.postCodeInvalid
                const isPostCodeValid = postcodeValidator(newValue, POSTCODE_COUNTRY_CODE)

                if (newValue !== '') {
                    !isPostCodeValid ? onError(errorMessagePostCode) : onError(false)
                    hasError = !isPostCodeValid
                } else {
                    emptyErrorValidator()
                }
            }

            if (validationTypes.includes(VALIDATION_TYPES.TEXT_CHARS_2_30)) {
                const errorMessageName = '*' + cmsLanguage.error.textInvalid
                const regexName = /^[a-zA-ZåäöüÅÄÖÜß ]{2,30}$/
                const isTextValid = regexName.test(newValue)
                if (newValue !== '') {
                    !isTextValid ? onError(errorMessageName) : onError(false)
                    hasError = !isTextValid
                } else {
                    emptyErrorValidator()
                }
            }

            if (validationTypes.includes(VALIDATION_TYPES.BIC)) {
                const errorMessageName = '*' + cmsLanguage.error.bicInvalid
                const isBicValid = /^([A-Z]{6}[A-Z2-9][A-NP-Z1-2])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/.test(newValue.toUpperCase())

                if (newValue !== '') {
                    !isBicValid ? onError(errorMessageName) : onError(false)
                    hasError = !isBicValid
                } else {
                    emptyErrorValidator()
                }
            }

            onChange(newValue, hasError)
        }

        const handleOnChange = validationTypes?.length > 0 ? validationOnChange : onChange

        return <BaseComponent {...props} onChange={handleOnChange} />
    }

    setDisplayName(BaseComponent, WithValidation, 'withValidation')
    return WithValidation
}

export default withValidation
