// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".accordion-item-container{margin-bottom:1.875em}.accordion-item-card{border:solid 1px #595959;border-radius:inherit}.accordion-item-header{padding:.9375em 1.875em;background-color:#fff;border-bottom:none}.accordion-item-button{width:inherit;padding:0;border:none;border-radius:inherit;text-decoration:none;text-align:inherit;background-color:transparent;cursor:pointer;color:inherit;font:inherit;width:100%;display:flex;justify-content:space-between}.accordion-item-button:focus{outline:none}.accordion-item-left-icon{width:2.8125em;text-align:left;font-size:1.875em;color:#64b42d}.accordion-item-right-icon{width:2.8125em;text-align:right;font-size:1.875em;color:#191919}.accordion-item-right-icon:after{font-family:'Font Awesome 5 Free';font-weight:900;content:\"\"}.accordion-item-right-icon.active:after{font-family:'Font Awesome 5 Free';font-weight:900;content:\"\"}.accordion-item-title{align-self:center;width:100%;text-align:left}.accordion-item-title span{font-family:'Open Sans', sans-serif;font-weight:normal;font-style:normal;font-size:1.25em;color:#191919}.accordion-item-collapse{padding-top:0;padding-bottom:1.875em;padding-left:6.5625em;padding-right:4.6875em}.accordion-item-body{padding:1.875em 0 0 0;border-top:solid 1px #b8b8b8;white-space:pre-line}.accordion-item-body p{font-family:'Open Sans', sans-serif;font-weight:normal;font-style:normal;font-size:1em;color:#191919;line-height:2em}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
