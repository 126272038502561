import { MESH_CONFIG } from '../Constants'
import store from '../../../redux/store'
import { StoragesControllerApi, VendorsControllerApi } from '@hexad/vendors-client-ts-axios'

function getAuthToken () {
    return store.getState().user.accessToken
}

const customFetchApi = (input, init) => {
    return fetch(input, init).then((response) => {
        if (!response.ok) {
            return response.json().then((error) => {
                throw new Error(error.message || response.statusText)
            })
        }
        return response
    })
}

const vendorControllerApi = new VendorsControllerApi({
    basePath: `${MESH_CONFIG.SERVER_URL}/vendors-service`,
    middleware: [],
    fetchApi: customFetchApi
})

const vendorController = {
    getVendors: (organizationId, options) => vendorControllerApi.getVendors(`Bearer ${getAuthToken()}`, organizationId, options).then(response => response.data),
    getVendor: (organizationId, id, options) => vendorControllerApi.getVendor(`Bearer ${getAuthToken()}`, organizationId, id, options).then(response => response.data),
    createVendor: (organizationId, vendorDTO, options) => vendorControllerApi.createVendor(`Bearer ${getAuthToken()}`, organizationId, vendorDTO, options).then(response => response.data),
    deleteVendor: (organizationId, id, options) => vendorControllerApi.deleteVendor(`Bearer ${getAuthToken()}`, organizationId, id, options).then(response => response.data),
    searchVendors: (organizationId, vendorSearchDTO, options) => vendorControllerApi.searchVendors(`Bearer ${getAuthToken()}`, organizationId, vendorSearchDTO, options).then(response => response.data),
    patchVendor: (organizationId, id, jSONPatchDTOInner, options) => vendorControllerApi.patchVendor(`Bearer ${getAuthToken()}`, organizationId, id, jSONPatchDTOInner, options).then(response => response.data)
}

const storageControllerApi = new StoragesControllerApi({
    basePath: `${MESH_CONFIG.SERVER_URL}/vendors-service`,
    middleware: [],
    fetchApi: customFetchApi
})

const storageController = {
    getStorages: (organizationId, options) => storageControllerApi.getStorages(`Bearer ${getAuthToken()}`, organizationId, options).then(response => response.data),
    getStorage: (organizationId, id, options) => storageControllerApi.getStorage(`Bearer ${getAuthToken()}`, organizationId, id, options).then(response => response.data),
    createStorage: (organizationId, vendorDTO, options) => storageControllerApi.createStorage(`Bearer ${getAuthToken()}`, organizationId, vendorDTO, options).then(response => response.data),
    deleteStorage: (organizationId, id, options) => storageControllerApi.deleteStorage(`Bearer ${getAuthToken()}`, organizationId, id, options).then(response => response.data),
    searchStorages: (organizationId, vendorSearchDTO, options) => storageControllerApi.searchStorages(`Bearer ${getAuthToken()}`, organizationId, vendorSearchDTO, options).then(response => response.data),
    patchStorage: (organizationId, id, jSONPatchDTOInner, options) => storageControllerApi.patchStorage(`Bearer ${getAuthToken()}`, organizationId, id, jSONPatchDTOInner, options).then(response => response.data)
}

export { vendorController, storageController }
