/**
 * @module makeLowercaseFirstLetter
 * @category Utils
 * @subcategory General
 */

/**
 * Takes a string and returns it with the first letter lowered case
 * @function
 * @param {string} string
 * @returns {string}
 * @example
 * const text = 'Hello'
 * const capitalized = makeLowercaseFirstLetter(text) // 'hello'
 */
export const makeLowercaseFirstLetter = (string) => {
    if (typeof string === 'string' && !!string) {
        const stringArr = string?.split(' ')

        for (let i = 0; i < stringArr?.length; i++) {
            stringArr[i] =
                stringArr[i].charAt(0).toLowerCase() + stringArr[i].slice(1)
        }

        return stringArr.join(' ')
    }
    return ''
}
