import React from 'react'
import { DaysEnum } from '@utils/DaysEnum'
import './weekDayHeader.scss'
import { cmsLanguage } from '../../../../cms/commons/cms_language/cmsLanguage'

/**
 * @typedef WeekDayHeaderProps
 * @memberOf WeekDayHeader
 * @property {string} startDate
 */

/**
 * Application Week Day Header component.
 * (It is used in {@link WeekCalendar}).
 * @class WeekDayHeader
 * @category Components
 * @subcategory Pages / trainingSchedule
 * @param {WeekDayHeaderProps} props
 * @returns {React.ReactNode}
 * @example
 * <WeekDayHeader startDate={startDate}/>
 */
export default function WeekDayHeader ({ startDate }) {
    const getDate = (_index) => {
        const startTempDate = new Date(startDate)
        startTempDate.setDate(startTempDate.getDate() + _index)
        return startDate ? startTempDate.getDate() : ''
    }
    const parseWeekDay = (day) => (cmsLanguage.weekday[day.toLowerCase()]).toUpperCase()
    return Object.keys(DaysEnum).map((weekday, _index) => (
        <div key={weekday + _index} className="weekday-header">
            {`${parseWeekDay(weekday).substring(0, 3)} ${getDate(_index)}`}
        </div>
    ))
}
