import { v4 as uuidv4 } from 'uuid'
import { cmsLanguage } from '../../../commons/cms_language/cmsLanguage'

const marquesina = () => {
    const fields = [
        {
            name: 'picture',
            type: 'PHOTO',
            id: uuidv4(),
            isMandatory: true
        },
        {
            name: 'link',
            type: 'LINK',
            id: uuidv4(),
            isMandatory: false
        }
    ]
    const values = fields.map(it => {
        return {
            field: it,
            fieldId: it.id,
            id: uuidv4(),
            value: ''
        }
    })
    return {
        id: uuidv4(),
        name: cmsLanguage.templatesNames.marquesina,
        type: 'MARQUESINA',
        elements: [
            {
                id: uuidv4(),
                values: values,
                type: 'default'
            }
        ],
        fields: fields
    }
}

export const MARQUESINA_TEMPLATE_ORDER = { PHOTO: 0, LINK: 1 }

export default marquesina
