import { cmsLanguage } from '../../../cms_language/cmsLanguage'

export const MAX_PICTURE_SIZE_NUMBER = 5000000

export const MAX_PICTURE_SIZE = '5mb'
export const PICTURE_ERROR =
    cmsLanguage.error.pictureLarge +
    '. ' +
    cmsLanguage.cms.maxPictureSize +
    ' ' +
    MAX_PICTURE_SIZE +
    '.'
