import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import AddCmsContentModal from './AddCmsContentModal'
import * as actions from '../actions/CMSActions'

const mapStateToProps = (state) => {
    const { headerMenu, topMenu, footerMenu } = state

    return {
        headerMenu,
        topMenu,
        footerMenu
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch)

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    AddCmsContentModal
)
