import BICFromIBAN from 'bic-from-iban'
import { IDS } from './inputFieldsList'
import { familySize, newFamilyMember } from './familyUtils'

/**
 *
 * @param {string} id
 * @param {Object|string} value
 * @param {boolean} hasError
 * @returns {Array<{ id: string, value: Object|string, hasError: boolean }>}
 */
export const handleFormUpdate = (id, value, hasError) => {
    if (id === IDS.IBAN && value.length > 0 && !hasError) {
        const newBicValue = BICFromIBAN.getBIC(value)
        return [{ id: IDS.IBAN, hasError, value }, { id: IDS.BIC, value: newBicValue, hasError: false }]
    } else {
        return [{ id, value, hasError }]
    }
}

export const handleMembershipTypeChange = (familyMembers, typeDetail, hasError) => {
    const { max, min } = typeDetail
    const size = familySize(familyMembers)
    const changes = []
    const hasFamilyMembersError = false
    if (max < size) {
        const updatedFamily = familyMembers.value.slice(0, max - 1)
        changes.push({ id: IDS.FAMILY_MEMBERS, value: updatedFamily, hasError: false })
        console.debug('It should remove extra members', updatedFamily)
    } else if (min > size) {
        const newMembers = []
        for (let i = 0; i < min - size; i++) {
            newMembers.push(newFamilyMember())
        }
        const updatedFamily = familyMembers.value.concat(newMembers)
        console.debug('It should include new members', updatedFamily)
        changes.push({ id: IDS.FAMILY_MEMBERS, value: updatedFamily, hasError: hasFamilyMembersError })
    }
    changes.push({ id: IDS.TYPES, value: typeDetail, hasError })

    return changes
}
