/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react'
import '../../../commons/cms_modal/CmsModal.scss'
import { groupBy, objectMap } from '../../../util/Util'
import './CmsModalMultipleElements.scss'
import ElementDropdown from './element_dropdown/ElementDropdown'
import { filterElementByType, generateNewElement } from '../empty_element_inputs/generateNewElement'
import { cmsLanguage } from '../../../commons/cms_language/cmsLanguage'
import AdminButton from '../../../commons/admin_button/AdminButton'
import { updateElement, updateElements } from '../../../reducers/ContentReducers'
import { types } from '../../../types'
import { isMultiElementTemplate } from './constants'
import { isEqual } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CmsModalMultipleElements = (props) => {
    const {
        template,
        onChange,
        contentDB
    } = props
    const maxItems = template.type === 'SOCIAL_MEDIA' ? 8 : undefined

    const getInitialElementsGroup = (template) => {
        const isTemplateComingFromBackend = contentDB.templates.find(templateDB => templateDB.id === template.id && templateDB.type === template.type) !== undefined
        const elementsGroupByType = groupBy(template.elements, 'type')
        const isSocialMediaTemplate = () => !!(template.type === 'SOCIAL_MEDIA' && template.elements.length > 1)
        return objectMap(elementsGroupByType, (key, value) => {
            return {
                elements: value.map(v => ({ ...v, hasError: isTemplateComingFromBackend || isSocialMediaTemplate() ? false : undefined })),
                selectedElementId: value[value.length - 1].id
            }
        })
    }

    const [elementsGroup, setElementsGroup] = useState(getInitialElementsGroup(template))
    const [templateType, setTemplateType] = useState(template.type)

    const getElementsStatus = () => {
        const selectedElements = Object.entries(elementsGroup)
            .map(([key, { elements, selectedElementId }]) => {
                return elements.find(el => el.id === selectedElementId)
            })
            .flat()
        const elements = Object.entries(elementsGroup)
            .map(([key, { elements }]) => elements)
            .flat()

        let hasError
        const errorsList = selectedElements.map(it => it.hasError)
        if (errorsList.some(it => it)) {
            hasError = true
        } else if (errorsList.some(it => it === undefined)) {
            hasError = undefined
        } else {
            hasError = false
        }
        return { elements, hasError }
    }

    useEffect(() => {
        const { elements, hasError } = getElementsStatus()

        const updatedTemplate = {
            ...template,
            elements: elements
        }
        const templateClone = JSON.parse(JSON.stringify(updatedTemplate))
        templateClone.elements.forEach(element => delete element.hasError)
        const originalElementsGroup = getInitialElementsGroup(template)
        if (!isEqual(originalElementsGroup, elementsGroup)) {
            onChange(templateClone, hasError)
        }
    }, [elementsGroup])

    useEffect(() => {
        setElementsGroup(getInitialElementsGroup(template))
        setTemplateType(template.type)
    }, [template.type])

    // UseEffect used when add a new element
    useEffect(() => {
        if (template.type === templateType) {
            const isTemplateComingFromBackend = contentDB.templates.find(templateDB => templateDB.id === template.id) !== undefined
            Object.entries(elementsGroup).forEach(([key, value]) => {
                if (!template.elements.find((element) => element.id === elementsGroup[key].selectedElementId)) {
                    const filteredElementByType = filterElementByType(key, template)
                    setElementsGroup({
                        ...elementsGroup,
                        [key]: {
                            elements: filteredElementByType.map(element => ({ ...element, hasError: isTemplateComingFromBackend ? false : undefined })),
                            selectedElementId: filteredElementByType[filteredElementByType.length - 1]?.id
                        }
                    })
                }
            })
        }
    }, [template.elements.length])

    const handleOnAddElement = (elementType) => {
        if (canAddAnElement(elementType)) {
            const hasError = false
            const elements = Object.entries(elementsGroup)
                .map(([key, { elements }]) => elements)
                .flat()
            const element = generateNewElement(template, elementType)
            const newElement = updateElement(element, {
                type: types.UPDATE_ELEMENT,
                payload: {
                    element
                }
            })
            const updatedTemplate = {
                ...template,
                elements: [...elements, { ...newElement, hasError: undefined }]
            }

            onChange(updatedTemplate, hasError)

            const filteredElementByType = filterElementByType(elementType, updatedTemplate)
            setElementsGroup({
                ...elementsGroup,
                [elementType]: {
                    selectedElementId: filteredElementByType[filteredElementByType.length - 1].id,
                    elements: filteredElementByType
                }
            })
        }
    }

    const handleOnChangeValue = (elementId, valueId, newValue, hasError, elementType) => {
        const elements = elementsGroup[elementType].elements
        const indexToUpdate = elements.findIndex(it => it.id === elementId)
        const element = elements[indexToUpdate]
        let newElement = updateElement(element, {
            type: types.UPDATE_VALUE,
            payload: {
                elementId,
                valueId,
                value: newValue
            }
        })
        newElement = updateElement(newElement, {
            type: types.UPDATE_ELEMENT,
            payload: {
                element: {
                    ...newElement,
                    hasError
                }
            }
        })
        const newElements = [
            ...elements.slice(0, indexToUpdate),
            newElement,
            ...elements.slice(indexToUpdate + 1)
        ]

        setElementsGroup({
            ...elementsGroup,
            [elementType]: {
                ...elementsGroup[elementType],
                elements: newElements
            }
        })
    }

    const handleDeleteElement = (elementId, elementType) => {
        const elements = Object.entries(elementsGroup)
            .map(([key, { elements }]) => elements)
            .flat()

        const newElements = updateElements(elements, {
            type: types.DELETE_ELEMENT,
            payload: { elementId }
        })
        const filteredElementByType = newElements.filter(element => element.type === elementType)

        if (filteredElementByType[filteredElementByType.length - 1].id !== elementId) {
            setElementsGroup({
                ...elementsGroup,
                [elementType]: {
                    elements: filteredElementByType,
                    selectedElementId: filteredElementByType[filteredElementByType.length - 1].id
                }
            })
        }
        onChange({
            ...template,
            elements: newElements
        }, false)
    }

    const handleOnDropdownClick = (elementId, elementType) => {
        const filteredElementByType = elementsGroup[elementType].elements

        if (canAddAnElement(elementType)) {
            if (filteredElementByType[filteredElementByType.length - 1].id === elementId) {
                setElementsGroup({
                    ...elementsGroup,
                    [elementType]: {
                        elements: [...filteredElementByType],
                        selectedElementId: filteredElementByType[filteredElementByType.length - 1].id
                    }
                })
            } else {
                setElementsGroup({
                    ...elementsGroup,
                    [elementType]: {
                        elements: [...filteredElementByType],
                        selectedElementId: elementId
                    }
                })
            }
        }
    }

    const canAddAnElement = (elementType) => {
        const selectedElementId = (elementsGroup ?? {})[elementType].selectedElementId
        const selectedElement = (elementsGroup ?? {})[elementType]?.elements.find(it => it.id === selectedElementId)
        return (
            (selectedElement.hasError === false)
        ) && (maxItems === undefined || template.elements.length < maxItems)
    }

    const isAMultipleElement = template.elements.length > 1

    const isLastElement = (elements, selectedElement) => {
        let result
        if (selectedElement.type !== 'default') {
            result = elements.filter(element => element.type === selectedElement.type)
            result = result[result.length - 1]
            return (selectedElement?.id === result?.id)
        }
        return false
    }

    return (
        <div className={isAMultipleElement ? 'add-cms-modal-inputs-multiple' : null}>
            <div className={`element-dropdown template-type-${template.type.toLowerCase()}`} data-testid={`element-dropdown-container-${template.elements.length}`}>
                {template && template.elements && Object.values(objectMap(elementsGroup, (key, value) =>
                    value.elements.map((element, index) =>
                        <Fragment key={element.id}>

                            {(element.type === 'trainer') && index === 0
                                ? <label className="cms-input-name">
                                    <div className="cms-input-icon">
                                        <FontAwesomeIcon
                                            icon={['fa', 'asterisk']}
                                            data-testid="cms-input-mandatory"
                                        />
                                    </div>
                                    <span data-testid="cms-input-name">{cmsLanguage.inputFields.trainers}</span>
                                </label>
                                : <div></div>
                            }

                            <ElementDropdown
                                template={template}
                                element={element}
                                onClick={handleOnDropdownClick}
                                isOpen={value.selectedElementId === element.id}
                                onChange={handleOnChangeValue}
                                onDelete={template.elements.length > 1 ? handleDeleteElement : null}
                                //  isMultiple boolean to set whether it is a dropdown(open/close)
                                // or an AddCmsTemplateValue
                                isMultiple={isMultiElementTemplate(template.type)}
                            />

                            {element.type !== 'default' && isLastElement(template.elements, element) &&
                                <AdminButton
                                    data-testid="add-new-element"
                                    text={`${cmsLanguage.cms.addNewElement} ${element.type}`}
                                    icon={['fa', 'plus']}
                                    type={canAddAnElement(element.type) ? 'border-solid' : 'disable'}
                                    color={canAddAnElement(element.type) && 'green'}
                                    onClick={() => handleOnAddElement(element.type)}
                                    elementType={element.type}
                                />
                            }
                        </Fragment>
                    )
                ))
                }
                {maxItems &&
                    <div className="element-dropdown-counter">
                        <span>{template.elements.length} - {maxItems}</span>
                    </div>
                }
            </div>
            {/* This is shown only when the template can be multiple */}
            {isMultiElementTemplate(template.type) && (
                <AdminButton
                    data-testid="add-new-element"
                    text={cmsLanguage.cms.addNewElement}
                    icon={['fa', 'plus']}
                    type={canAddAnElement('default') ? 'border-solid' : 'disable'}
                    color={canAddAnElement('default') && 'green'}
                    onClick={() => handleOnAddElement('default')}
                />
            )}
        </div>
    )
}

export default CmsModalMultipleElements
