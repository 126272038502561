import React from 'react'
import './InputTextarea.scss'
import { cmsLanguage } from '../../../cms_language/cmsLanguage'

const InputTextarea = (props) => {
    const { value, onChange, onFocus, onBlur, maxLength, placeholder } = props

    let newValue

    const handleOnChange = (e) => {
        newValue = e.target.value
        onChange(newValue)
    }
    return (
        <>
            <div className="cms-input-box cms-input-box-text cms-input-textarea">
                <textarea
                    data-testid="cms-input-textarea"
                    value={value}
                    onChange={handleOnChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    maxLength={maxLength}
                    placeholder={placeholder ? placeholder + '...' : cmsLanguage.cms.textPlaceholder + '...'}
                />
                {maxLength && (
                    <div>
                        <span data-testid="cms-input-textarea-counter">
                            {value.length} / {maxLength}
                        </span>
                    </div>
                )}
            </div>
        </>
    )
}

export default InputTextarea
