import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import App from './App'
import { saveAuthenticatedUser, setHeaderMenu, setTopMenu, setFooterMenu, setSocialMediaMenu } from './redux/actions/actions'

/**
 * {@link App} container that binds the App with the {@link module:reduxSetup}
 * @class AppContainer
 * @category Containers
 * @subcategory App
 */

/**
 * Gets the `user, customNavigation, headerMenu, topMenu, footerMenu`
 * from redux state and makes available to the {@link App} as props.
 * @function
 * @memberOf AppContainer
 * @alias mapStateToProps
 * @param {Object} state - redux state
 * @returns {Object}
 */
const mapStateToProps = (state) => {
    const { user, headerMenu, topMenu, footerMenu, getCustomNavigation: customNavigation } = state

    return { user, customNavigation, headerMenu, topMenu, footerMenu }
}

/**
 * Passes certain Redux actions ({@link module:actions})
 * to the {@link App} component as props.
 * This function is used only by the react-redux connect function.
 * @function
 * @memberOf AppContainer
 * @alias mapDispatchToProps
 * @param {function} dispatch - The react-redux dispatch function
 * @returns {Object} - Redux actions used in the {@link App} component
 */
const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ setHeaderMenu, setTopMenu, setFooterMenu, setSocialMediaMenu, saveAuthenticatedUser }, dispatch)

export default compose(connect(mapStateToProps, mapDispatchToProps))(App)
