import React from 'react'
import TopBar from './TopBar'
import STORAGE_KEYS from '@utils/storageKeys'

/**
 * {@link TopBar} container that binds the TopBar with the {@link module:reduxSetup}
 * @class TopBarContainer
 * @category Containers
 * @subcategory Header
 */

/**
 * Returns an enhanced component, with the logged-in user information.
 * @function
 * @memberOf TopBarContainer
 * @alias withLocalUser
 * @param Component
 * @returns {function(*): JSX.Element}
 */
const withLocalUser = (Component) => {
    const WithLocalUser = (props) => {
        const user = localStorage.getItem(STORAGE_KEYS.USER)
        return <Component {...props} user={user}/>
    }
    return WithLocalUser
}

export default withLocalUser(TopBar)
