import { cmsLanguage } from '../../../commons/cms_language/cmsLanguage'
import carousel from '../../../assets/pictures/templates_preview/carousel.jpg'
import greyBanner from '../../../assets/pictures/templates_preview/Grey-banner.jpg'
import textArea from '../../../assets/pictures/templates_preview/Text-area.jpg'
import actionButtonBanner from '../../../assets/pictures/templates_preview/Action-button-banner.jpg'
import videoBanner from '../../../assets/pictures/templates_preview/Video-banner.jpg'
import smallCarousel from '../../../assets/pictures/templates_preview/Small-Carousel.jpg'
import parallax from '../../../assets/pictures/templates_preview/Parallax.jpg'
import marquesina from '../../../assets/pictures/templates_preview/Marquesina.jpg'
import commonBanner from '../../../assets/pictures/templates_preview/Common-banner.jpg'
import accordion from '../../../assets/pictures/templates_preview/Accordion.jpg'
import profiles from '../../../assets/pictures/templates_preview/Profiles.jpg'
import imageLibrary from '../../../assets/pictures/templates_preview/PhotoGallery.jpg'
import newsBanner from '../../../assets/pictures/templates_preview/NewsBanner.jpg'
import instagramBanner from '../../../assets/pictures/templates_preview/Instagram.jpg'
import courseInfo from '../../../assets/pictures/templates_preview/Course-info.jpg'
import membersForm from '../../../assets/pictures/templates_preview/Members-Form.png'
import { templateTypes } from '../cms_modal_multiple_elements/constants'

export const COURSE_TEMPLATE = {
    id: templateTypes.COURSE,
    name: cmsLanguage.templatesNames.courseInfo,
    picture: courseInfo
}
export const MEMBERS_TEMPLATE = {
    id: templateTypes.MEMBERS_FORM,
    name: cmsLanguage.templatesNames.membersForm,
    picture: membersForm
}

const allTemplatesList = [
    {
        id: templateTypes.CARROUSEL,
        name: cmsLanguage.templatesNames.carousel,
        picture: carousel
    },
    {
        id: templateTypes.COMMON_BANNER,
        name: cmsLanguage.templatesNames.commonBanner,
        picture: commonBanner
    },
    {
        id: templateTypes.TEXT_AREA,
        name: cmsLanguage.templatesNames.textArea,
        picture: textArea
    },
    {
        id: templateTypes.GREY_BANNER,
        name: cmsLanguage.templatesNames.greyBanner,
        picture: greyBanner
    },
    {
        id: templateTypes.ACTION_BUTTON_BANNER,
        name: cmsLanguage.templatesNames.actionButtonBanner,
        picture: actionButtonBanner
    },
    {
        id: templateTypes.SMALL_CARROUSEL,
        name: cmsLanguage.templatesNames.smallCarousel,
        picture: smallCarousel
    },
    {
        id: templateTypes.PARALLAX,
        name: cmsLanguage.templatesNames.parallax,
        picture: parallax
    },
    {
        id: templateTypes.VIDEO,
        name: cmsLanguage.templatesNames.video,
        picture: videoBanner
    },
    {
        id: templateTypes.ACCORDION,
        name: cmsLanguage.templatesNames.accordion,
        picture: accordion
    },
    {
        id: templateTypes.PROFILES,
        name: cmsLanguage.templatesNames.profiles,
        picture: profiles
    },
    {
        id: templateTypes.MARQUESINA,
        name: cmsLanguage.templatesNames.marquesina,
        picture: marquesina
    },
    {
        id: templateTypes.IMAGE_LIBRARY,
        name: cmsLanguage.templatesNames.photoGallery,
        picture: imageLibrary
    },
    {
        id: templateTypes.GRID,
        name: cmsLanguage.templatesNames.homeNews,
        picture: newsBanner
    },
    {
        id: templateTypes.INSTAGRAM,
        name: 'Instagram',
        picture: instagramBanner
    },
    COURSE_TEMPLATE,
    MEMBERS_TEMPLATE
]

// TODO: remove filter for course template once feature is fully available
export const templates = {
    list: allTemplatesList
}

const headerTemplatesIds = ['COMMON_BANNER', 'CARROUSEL']
export const headerTemplates = {
    list: headerTemplatesIds.map(ht => templates.list.find(t => t.id === ht))
}
