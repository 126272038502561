import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'

import AdministrationBar from './AdministrationBar'
import * as CMSactions from '../actions/CMSActions'
import * as ContentActions from '../actions/ContentActions'

/**
 * {@link AdministrationBar} container that binds the AdministrationBar with the {@link module:reduxSetup}
 * @class AdministrationBarContainer
 * @category Containers
 * @subcategory AdministrationBar
 *
 * @mermaid
 *   sequenceDiagram
 *   Parent->>AdministrationBarContainer: Request the AdministrationBar via AdministrationBarContainer.
 *   Note right of AdministrationBarContainer: AdministrationBarContainer gets information from redux store.
 *   AdministrationBarContainer->>AdministrationBar: AdministrationBarContainer sends redux store information to AdministrationBar as props.
 *   AdministrationBar-->>Parent: AdministrationBar is rendered in the parent component.
 */

/**
 * Gets the `user, cms, content, contentDraft, products`
 * from redux state and makes available to the {@link AdministrationBar} as a prop
 * `{user, isAddNewTemplateModeOn, isEditModeOn, isTrainingPage, isEditTrainingPageModeOn, isHomePage, content, contentDraft, products}`.
 * @function
 * @memberOf AdministrationBarContainer
 * @alias mapStateToProps
 * @param {Object} state - redux state
 * @returns {Object}
 */
const mapStateToProps = (state) => {
    const { user, cms, content, contentDraft } = state
    const {
        isEditModeOn,
        isAddNewTemplateModeOn,
        isTrainingPage,
        isEditTrainingPageModeOn,
        isHomePage
    } = cms
    return {
        user,
        isAddNewTemplateModeOn,
        isEditModeOn,
        isTrainingPage,
        isEditTrainingPageModeOn,
        isHomePage,
        content,
        contentDraft
    }
}

/**
 * Passes certain Redux actions ({@link module:ContentActions}, {@link module:CmsActions} and {@link TrainingActions})
 * to the {@link AdministrationBar} component as props.
 * This function is used only by the react-redux connect function.
 * @function
 * @memberOf AdministrationBarContainer
 * @alias mapDispatchToProps
 * @param {function} dispatch - The react-redux dispatch function
 * @returns {Object} - Redux actions used in the {@link AdministrationBar} component
 */
const mapDispatchToProps = (dispatch) => bindActionCreators({ ...CMSactions, ...ContentActions }, dispatch)

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    AdministrationBar
)
