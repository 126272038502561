import React from 'react'
import './ProfileItem.scss'
import ProfileWhiteCard from '../../../../theme/commons/profile_white_card/ProfileWhiteCard'
import { cmsLanguage } from '../../../../cms/commons/cms_language/cmsLanguage'

/**
 * @typedef ProfileItemProps
 * @memberOf ProfileItem
 * @property {string} [picture]
 * @property {string} [name]
 * @property {string} [role]
 * @property {string} [address]
 * @property {string} [phone]
 * @property {string} [email]
 */

/**
 * Profile Item Template component.
 * It will be rendered inside {@link ProfilesTemplate} component.
 * @class ProfileItem
 * @category Components
 * @subcategory Templates / ProfileItem
 * @param {ProfileItemProps} props
 * @returns {React.ReactNode}
 * @example
 * <ProfileItem
 *     picture={item.PHOTO && item.PHOTO}
 *     name={item.H1}
 *     role={item.H2}
 *     address={item.TEXT && item.TEXT}
 *     phone={item.PHONE && item.PHONE}
 *     email={item.EMAIL && item.EMAIL}
 * />
 */
const ProfileItem = (props) => {
    const { picture, name, role, address, phone, email } = props

    return (
        <ProfileWhiteCard
            picture={picture && picture}
            internalComponent={
                <div className="profile-item-info">
                    <div className="profile-item-header">
                        <h1 data-testid="profile-item-name">{name}</h1>

                        <h2 data-testid="profile-item-role">{role}</h2>
                    </div>
                    {!!address && (
                        <div className="profile-item-field profile-item-address-container">
                            <h2>{cmsLanguage.inputFields.address}:</h2>
                            <span data-testid="profile-item-address">{address}</span>
                        </div>
                    )}
                    {!!phone && (
                        <div className="profile-item-field profile-item-phone-container">
                            <h2>{cmsLanguage.inputFields.phone}:</h2>
                            <span data-testid="profile-item-phone">{phone}</span>
                        </div>
                    )}
                    {!!email && (
                        <div className="profile-item-field profile-item-email-container">
                            <h2>{cmsLanguage.inputFields.email}:</h2>
                            <span data-testid="profile-item-email">{email}</span>
                        </div>
                    )}
                </div>
            }
        />
    )
}

export default ProfileItem
