import React from 'react'
import AddTemplateBar from './components/add_template_bar/AddTemplateBar'
import EditTemplateBar from './components/edit_template_bar/EditTemplateBarContainer'
import AddClassTemplateBar from './components/add_class_template_bar/AddClassTemplateBarContainer'
import { headerTemplates, templates } from './components/cms_edit_template/templatesList'

const CmsTemplatesBars = (props) => {
    const {
        children,
        template,
        isAddNewTemplateModeOn,
        selectedCourse,
        user,
        isEditModeOn,
        templatePosition,
        isTrainingPage,
        onAdd,
        onChange,
        onDelete,
        availableTemplates
    } = props

    const isAddTemplateAllowed = isAddNewTemplateModeOn && !isTrainingPage && (!availableTemplates || (!!availableTemplates && availableTemplates.length > 0))

    return (
        <>
            {isEditModeOn && !!template && template.type !== 'TRAINING_SCHEDULE' && (
                <EditTemplateBar
                    template={template}
                    templatePosition={templatePosition}
                    onSave={onChange}
                    onDelete={onDelete}
                    availableTemplates={availableTemplates || (templatePosition === 0 ? headerTemplates.list : templates.list)}
                />
            )}

            {isEditModeOn && template.type === 'TRAINING_SCHEDULE' && !!selectedCourse && user.role.permissions.includes('WRITE_CALENDAR') &&
            <AddClassTemplateBar
                templatePosition={templatePosition}
            />}

            {children}

            {isAddTemplateAllowed && (
                <AddTemplateBar
                    onSave={onAdd}
                    availableTemplates={templates.list}
                />
            )}
        </>
    )
}

export default CmsTemplatesBars
