// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".cloud-photos.modal .modal-header{border-bottom:solid 0.3125em #64b42d}.cloud-photos-dialog{padding:2.8125em}.cloud-photos h3{text-align:center;padding:.625em}.cloud-photos-items-wrapper{justify-content:center;display:flex;padding:.9375em;grid-gap:.9375em;flex-wrap:wrap}.cloud-photos-item{cursor:pointer}.cloud-photos-item:focus,.cloud-photos-item:active,.cloud-photos-item:hover{opacity:0.8}.cloud-photos-search-wrapper{display:flex;gap:.625em;margin-left:auto;margin-right:auto;justify-content:center}.cloud-photos_empty{text-align:center;margin-top:.625em}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
