import { v4 as uuidv4 } from 'uuid'
import { cmsLanguage } from '../../../commons/cms_language/cmsLanguage'

const generateTemplate = () => {
    const fields = [
        {
            name: 'title',
            type: 'H1',
            id: uuidv4(),
            isMandatory: true
        },
        {
            name: 'text',
            type: 'H2',
            id: uuidv4(),
            isMandatory: false
        },
        {
            name: 'picture',
            type: 'PHOTO',
            id: uuidv4(),
            isMandatory: true
        }
    ]
    const values = fields.map(f => ({
        fieldId: f.id,
        id: uuidv4(),
        value: '',
        field: f
    }))

    return {
        id: uuidv4(),
        name: cmsLanguage.templatesNames.actionButtonBanner,
        type: 'COMMON_BANNER',
        elements: [
            {
                id: uuidv4(),
                values: values,
                type: 'default'
            }
        ],
        fields: fields
    }
}

export const COMMON_BANNER_TEMPLATE_ORDER = { PHOTO: 0, H1: 1, H2: 2 }

export default generateTemplate
