import React from 'react'
import { DaysEnum } from '@utils/DaysEnum'
import Accordian from '@theme/commons/accordian/Accordian'
import { ScheduleCard } from '../index'
import './weekCalendarMobile.scss'
import { calculateFrom, calculateTo } from '../../helpers/calculateTimeScheduledCard'

/**
 * @typedef WeekCalendarMobileProps
 * @memberOf WeekCalendarMobile
 * @property {string} [month]
 * @property {number} [week]
 * @property {function} [changeWeek]
 * @property {Object} [data]
 * @property {Object} [course]
 * @property {string} [path]
 * @property {string} startDate
 * @property {boolean} [isEditMode]
 */

/**
 * Application Week Calendar Mobile component.
 * (It is used in {@link TrainingPage}).
 * @class WeekCalendarMobile
 * @category Components
 * @subcategory Pages / trainingSchedule
 * @param {WeekCalendarMobileProps} props
 * @returns {React.ReactNode}
 * @example
 * <WeekCalendarMobile {...props} />
 */
export default function WeekCalendarMobile ({ data, course, startDate }) {
    const startDay = new Date(startDate).getDate()
    const shouldOpen = (index) => new Date().getDate() === startDay + index

    return Object.keys(DaysEnum).map((day, _index) => (
        <Accordian
            header={day}
            day={startDate && startDay + _index}
            key={'day' + _index + day}
            isOpenProp={shouldOpen(_index)}
        >
            <>
                {data.map((cardRow, j) => {
                    const cardsData = data[j][_index]
                    if (cardsData !== 0) {
                        return cardsData.map(cardData => (
                            <ScheduleCard
                                key={'card_' + cardData.id + _index + j}
                                from={calculateFrom(cardData)}
                                to={calculateTo(cardData)}
                                data={cardData}
                                title={course.name}
                            ></ScheduleCard>
                        ))
                    } else {
                        return ''
                    }
                })}
            </>
        </Accordian>
    ))
}
