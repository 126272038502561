// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".button-link{display:flex;align-items:center;justify-content:space-between;text-align:center;padding-left:2.8125em;padding-right:2.8125em;height:2.8125em;cursor:pointer;margin:0 auto}.button-link .cancel{font-family:rucksack, sans-serif;font-weight:700;font-style:normal;font-size:1.125em;text-transform:uppercase;white-space:nowrap;color:#f03;border-bottom:1px solid #f03;padding-bottom:2px;margin:0 auto}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
