
import React, { useState } from 'react'
import { setDisplayName } from '../../../../../utils/getDisplayName'

const withBlur = (BaseComponent) => {
    const WithBlur = (props) => {
        const [focused, setFocused] = useState(false)
        const onFocus = () => setFocused(true)
        const onBlur = () => setFocused(false)
        return <BaseComponent {...props} onFocus={onFocus} onBlur={onBlur} focused={focused} />
    }
    setDisplayName(BaseComponent, WithBlur, 'withBlur')
    return WithBlur
}

export default withBlur
