// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".menu{padding:1.875em 1.875em 3.75em 1.875em;background-color:#191919;position:absolute;display:none;z-index:2;color:white}.menu.open{display:flex;flex-direction:column;align-items:center;gap:1.875em}.menu.mobile{width:100%;margin-top:0.25rem;padding:2.8125em 2.8125em 5.625em 2.8125em}.menu.mobile.open .profile-items{gap:.3125em}.menu.desktop{top:2.8125em;right:-3.75em;width:max-content}.menu.desktop.divider{width:100%}.menu .profile-items{display:flex;flex-direction:column;gap:.625em;align-items:start;width:100%}.menu .profile-items a{font-family:rucksack, sans-serif;font-weight:400;font-style:normal;line-height:1.875em;font-size:1.125em;color:#fff;cursor:pointer}.menu .profile-items a:hover{color:#64b42d}.menu .divider{height:1px;background-color:white;width:100%}.menu .button-container{width:fit-content}@media screen and (max-width: 1200px){.menu.mobile{padding:1.875em 1.875em 3.75em 1.875em}}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
