import React, { useState } from 'react'
import './AddTemplateBar.scss'
import AdminButton from '../../../commons/admin_button/AdminButton'
import { cmsLanguage } from '../../../commons/cms_language/cmsLanguage'
import TemplateEditorModal from '../cms_edit_template/TemplateEditorModalContainer'

const AddTemplateBar = (props) => {
    const {
        onSave,
        availableTemplates
    } = props

    const [showEditModal, setShowEditModal] = useState(false)

    const toggleAddTemplateModal = () => {
        setShowEditModal(!showEditModal)
    }

    const handleSave = template => {
        onSave(template)
        toggleAddTemplateModal()
    }

    return (
        <>
            {showEditModal && (
                <TemplateEditorModal
                    onSave={handleSave}
                    onClose={toggleAddTemplateModal}
                    availableTemplates={availableTemplates}
                />
            )}

            <div className="add-template-bar">
                <div className="add-template-bar-container horizontal-website-padding">
                    <AdminButton
                        text={cmsLanguage.cms.addNewTemplate}
                        icon={['fa', 'plus']}
                        onClick={toggleAddTemplateModal}
                        type="solid"
                        color="blue"
                    />
                </div>
            </div>
        </>
    )
}

export default AddTemplateBar
