import React from 'react'
import './InputLink.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cmsLanguage } from '../../../cms_language/cmsLanguage'

const InputLink = (props) => {
    const { value, onFocus, onBlur, onChange, currentRoutes, onError } = props

    let newValue
    let isValidUrl

    const urlRegularExpression = /^(http|https)?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}$/

    const relativeRegularExpression = /^(\/.+)$/

    const validateUrl = (url) => {
        if (urlRegularExpression.test(String(url))) {
            isValidUrl = true
        } else if (relativeRegularExpression.test(String(url)) && isExistingPath(String(url))) {
            isValidUrl = true
        } else isValidUrl = false
    }

    const isExistingPath = (relativeUrl) => {
        return currentRoutes.path.some(function (routeElement) {
            return routeElement.route === relativeUrl
        })
    }

    const handleOnChange = (e) => {
        newValue = e.target.value
        onChange(newValue)

        validateUrl(newValue)
        isValidUrl && onError('')
    }

    const handleError = () => {
        validateUrl(value)
        !isValidUrl && value.length
            ? onError(cmsLanguage.error.noValidUrl + '.')
            : onError()
        onChange(value, !isValidUrl)
        onBlur()
    }

    return (
        <div className="cms-input-box cms-input-box-icon cms-input-link">
            <input
                data-testid="cms-input-link"
                type="url"
                value={value}
                onChange={handleOnChange}
                onFocus={onFocus}
                onBlur={handleError}
                placeholder={cmsLanguage.cms.linkPlaceholder + '...'}
            />
            <div>
                <FontAwesomeIcon icon={['fa', 'link']} data-testid="cms-input-link-icon" />
            </div>
        </div>
    )
}

export default InputLink
