import React from 'react'
import './PhotoGalleryBanner.scss'
import { Col, Container, Row } from 'react-bootstrap'
import PhotoCard from '../../theme/commons/photo_card/PhotoCard'

/**
 * @typedef PhotoGalleryBannerProps
 * @memberOf PhotoGalleryBanner
 * @property {Array<Object>} photoContent
 */

/**
 * Receives an array `photoContent` with photo elements and displays it using the {@link PhotoCard} component.
 * @class PhotoGalleryBanner
 * @category Components
 * @subcategory Templates
 * @param {PhotoGalleryBannerProps} props
 * @returns {React.ReactNode}
 * @example
 * <PhotoGalleryBanner photoContent={photoBannerContent} />
 */
const PhotoGalleryBanner = (props) => {
    const { photoContent } = props

    return (
        <Container fluid className="photo-gallery-banner">
            <Row>
                {photoContent.slice(0, 8).map((element, index) => {
                    return (
                        <Col sm={6} md={4} lg={3} key={index}>
                            <PhotoCard
                                picture={element.PHOTO}
                                text={element.TEXT}
                                date={element.DATE}
                            />
                        </Col>
                    )
                })}
            </Row>
        </Container>
    )
}

export default PhotoGalleryBanner
