// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".grey-banner-container{min-height:24.1875em;padding:3.75em 0;display:flex;flex-direction:column;align-items:center;justify-content:center;background-color:#f2f2f2}.grey-banner-container .info-container{width:60vw;margin-bottom:2.8125em}.grey-banner-container .info-container span{display:block;text-align:center}.grey-banner-container .info-container .title{font-family:rucksack, sans-serif;font-weight:900;font-style:normal;font-size:2.8125em;color:#191919;text-transform:uppercase}.grey-banner-container .info-container .text{margin-top:.9375em;font-family:'Open Sans', sans-serif;font-weight:normal;font-style:normal;font-size:1.25em;color:#191919}@media screen and (max-width: 992px){.grey-banner-container .info-container{width:80vw}}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
