import React from 'react'
import { cmsLanguage } from '../../../cms_language/cmsLanguage'
import { CVV_PATTERN } from '../input_component/constants'
import { isEmpty } from '../../../../util/Util'

const InputNumber = (props) => {
    const { value, onChange, onFocus, onBlur, maxLength, disabled, isSecurityCode, onError, inputProps } = props

    const isValidCvv = (cvvNumber) => cvvNumber.length === maxLength && CVV_PATTERN.test(cvvNumber)
    const isValidNumber = (value) => {
        let valid = true
        if (!isEmpty(value) && inputProps) {
            const { min, max } = inputProps
            if (!isEmpty(min)) {
                if (Number(value) < Number(min)) {
                    valid = false
                }
            }
            if (!isEmpty(max)) {
                if (valid && Number(value) > Number(max)) {
                    valid = false
                }
            }
        }
        return valid
    }

    const handleOnChange = (event) => {
        const {
            value: newValue
        } = event.target

        let hasError = !isValidNumber(newValue)
        if (isSecurityCode) {
            hasError = !isValidCvv(newValue)
            showError(hasError, newValue)
        } else {
            showError(hasError, newValue)
        }

        onChange(newValue, hasError)
    }

    const showError = (error, value) => {
        if (error && !isEmpty(value)) {
            if (isSecurityCode) {
                onError(
                    cmsLanguage.error.notValidSecurityNumber + '.'
                )
            } else {
                onError(
                    cmsLanguage.error.notValidNumber + '.'
                )
            }
        } else {
            onError()
        }
    }

    return (
        <div className="cms-input-box cms-input-box-text cms-input-number">
            <input
                data-testid="cms-input-number"
                type={isSecurityCode ? 'tel' : 'number'}
                value={value}
                name={isSecurityCode && 'securityCode'}
                onChange={handleOnChange}
                disabled={disabled}
                maxLength={maxLength}
                onFocus={onFocus}
                onBlur={onBlur}
                placeholder={isSecurityCode
                    ? cmsLanguage.inputFields.securityCodePlaceholder
                    : cmsLanguage.cms.numberPlaceholder + '...' }
                {...inputProps}
            />
        </div>
    )
}

export default InputNumber
