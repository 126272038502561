import store from '../../../redux/store'
import { CONFIG, MESH_CONFIG } from '../Constants'
// eslint-disable-next-line no-unused-vars
import axios, { AxiosResponse } from 'axios'

/**
 * @typedef ScheduleResponse
 * @memberOf MeshEventSchedule
 * @property {Array<Schedule>} savedSchedules
 * @property {Array<Schedule>} errorSchedules
 */

/**
 * MeshEventSchedule
 * @class MeshEventSchedule
 * @category Backend
 * @subcategory calendar_service
 */
export default class MeshEventSchedule {
    /**
     * Gets schedules between dates
     * @function
     * @memberOf MeshEventSchedule
     * @param {string} startDateWithoutTime
     * @param {string} endDateWithoutTime
     * @param {string} courseId
     * @returns {Promise<AxiosResponse<Schedule>>}
     */
    static getPublicSchedule (startDateWithoutTime, endDateWithoutTime, courseId) {
        const { accessToken, organizationId } = store.getState().user
        return axios.get(`${MESH_CONFIG.SERVER_URL}/calendar-service/organizations/${organizationId}/schedule?startDateTime=${startDateWithoutTime}&endDateTime=${endDateWithoutTime}&eventsGroupId=${courseId}`, {
            headers: {
                ...CONFIG.DEFAULT_HEADERS,
                CONTENT_TYPE: 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        })
    }

    /**
     * Saves a schedule
     * @function
     * @memberOf MeshEventSchedule
     * @param schedule
     * @returns {Promise<AxiosResponse<ScheduleResponse>>}
     */
    static postSchedule (schedule) {
        const { accessToken, organizationId } = store.getState().user
        const url = `${MESH_CONFIG.SERVER_URL}/calendar-service/organizations/${organizationId}/schedule`
        const config = {
            headers: {
                ...CONFIG.DEFAULT_HEADERS,
                CONTENT_TYPE: 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        return axios.post(url, schedule, config)
    }

    /**
     * Saves one or more schedules
     * @function
     * @memberOf MeshEventSchedule
     * @param schedules
     * @returns {Promise<AxiosResponse<Array<ScheduleResponse>>>}
     */
    static postScheduleList (schedules) {
        const { accessToken, organizationId } = store.getState().user
        const url = `${MESH_CONFIG.SERVER_URL}/calendar-service/organizations/${organizationId}/schedule/list`
        const config = {
            headers: {
                ...CONFIG.DEFAULT_HEADERS,
                CONTENT_TYPE: 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        return axios.post(url, schedules, config)
    }

    /**
     * Update a specific schedule
     * @function
     * @memberOf MeshEventSchedule
     * @param {Schedule} schedule
     * @returns {Promise<AxiosResponse<Schedule>>}
     */
    static putSchedule (schedule) {
        const { accessToken, organizationId } = store.getState().user
        const url = `${MESH_CONFIG.SERVER_URL}/calendar-service/organizations/${organizationId}/schedule`
        const config = {
            headers: {
                ...CONFIG.DEFAULT_HEADERS,
                CONTENT_TYPE: 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        return axios.put(url, schedule, config)
    }

    /**
     * Deletes a schedule by its ID
     * @function
     * @memberOf MeshEventSchedule
     * @param {string} scheduleId
     * @returns {Promise<AxiosResponse<void>>}
     */
    static deleteSchedule (scheduleId) {
        const { accessToken, organizationId } = store.getState().user
        const url = `${MESH_CONFIG.SERVER_URL}/calendar-service/organizations/${organizationId}/schedule/${scheduleId}`
        const config = {
            headers: {
                ...CONFIG.DEFAULT_HEADERS,
                CONTENT_TYPE: 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        return axios.delete(url, config)
    }

    /**
     * Fetches a schedule based on received schedule data
     * @function
     * @memberOf MeshEventSchedule
     * @param {Schedule} schedule
     * @returns {Promise<AxiosResponse<Array<Schedule>>>}
     */
    static searchScheduleByField (schedule) {
        const { accessToken, organizationId } = store.getState().user
        const url = `${MESH_CONFIG.SERVER_URL}/calendar-service/organizations/${organizationId}/schedule/search`
        const config = {
            headers: {
                ...CONFIG.DEFAULT_HEADERS,
                CONTENT_TYPE: 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        return axios.post(url, schedule, config)
    }
}
