// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".next-button{position:absolute;bottom:0;right:0;height:3.75em;display:flex;align-items:center;justify-content:center;width:15em;margin-bottom:4.21875em;background-color:#64b42d;cursor:pointer}.next-button span{font-family:'Open Sans', sans-serif;font-weight:700;font-style:normal;font-size:1em;text-transform:uppercase;color:#fff;padding-right:.9375em;transition:all 1000ms ease}.next-button span:hover{padding-right:1.875em}@media only screen and (max-width: 300px){.next-button{height:2.8125em;width:9.375em;margin-bottom:4.6875em}.next-button span{padding-right:.3125em}.next-button span:hover{padding-right:.9375em}}@media only screen and (max-width: 640px) and (min-width: 300px){.next-button{height:2.8125em;width:11.25em;margin-bottom:4.6875em}.next-button span{padding-right:.625em}}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
