import React from 'react'
import './GreyBanner.scss'
import NavigationWrapper from '../../theme/commons/navigation_wrapper/NavigationWrapperContainer'
import { NAVIGATION_TYPE } from '../../theme/constants/navigationType'

/**
 * @typedef GreyBannerProps
 * @memberOf GreyBanner
 * @property {string} H1
 * @property {string} TEXT
 * @property {string} BUTTON_TEXT
 * @property {string} BUTTON_LINK
 */

/**
 * Shows a banner with title, text and a button.
 * @class GreyBanner
 * @category Components
 * @subcategory Templates
 * @param {GreyBannerProps} props
 * @returns {React.ReactNode}
 * @example
 * const greyBannerProps = reducedElementsPro(element, template.fields)
 * <GreyBanner {...greyBannerProps} />
 */
const GreyBanner = (props) => {
    const { H1, TEXT, BUTTON_TEXT, BUTTON_LINK } = props

    return (
        <div className="grey-banner-container">
            <div className="info-container">
                <span className="title">{H1}.</span>
                <span className="text">{TEXT && TEXT}</span>
            </div>
            <div className="button-container">
                <NavigationWrapper type={NAVIGATION_TYPE.button} text={BUTTON_TEXT} href={BUTTON_LINK}/>
            </div>
        </div>
    )
}

export default GreyBanner
