import React from 'react'
import './modalHeader.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CmsModalTitle from '../../../../cms/commons/cms_modal/CmsModalTitle'

/**
 * @typedef ModalHeaderProps
 * @memberOf ModalHeader
 * @property {string} title
 * @property {function} onClose
 */

/**
 * Modal header component - shows the modal title with a button
 * @class ModalHeader
 * @category Components
 * @subcategory theme / commons
 * @param {ModalHeaderProps} props
 * @returns {React.ReactNode}
 * @example
 * <ModalHeader title="Modal header" onClose={handleModalClose} />
 */
const ModalHeader = ({ title, onClose }) => {
    return (
        <div className="modal-title">
            <CmsModalTitle title={title} />
            <button onClick={onClose}>
                <FontAwesomeIcon icon={['fas', 'times']} />
            </button>
        </div>
    )
}

export default ModalHeader
