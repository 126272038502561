// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".footer-column:first-child{padding-right:5.625em}.footer-column-title{font-family:rucksack, sans-serif;font-weight:700;font-style:normal;text-transform:uppercase;font-size:1.25em;color:#fff;padding-right:1.875em}@media screen and (min-width: 992px){.footer-column-title{text-align:right}}@media screen and (min-width: 768px) and (max-width: 992px){.footer-column:first-child{padding-bottom:2.8125em}}@media screen and (max-width: 767px){.footer-column:first-child{padding-right:inherit;padding-bottom:2.8125em}}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
