import { IDS } from './inputFieldsList'

export default function adaptWalletDataToForm (walletData) {
    const form = {
        [IDS.IBAN]: walletData?.iban || '',
        [IDS.BIC]: walletData?.bic || '',
        [IDS.BANK]: walletData?.bank || '',
        [IDS.ACC_HOLDER]: walletData?.holderName || ''
    }
    return form
}
