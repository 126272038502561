import React, { useEffect, useState } from 'react'
import './PageError404.scss'
import error404picture from './../theme/assets/pictures/error404picture.png'
import { getPageError404Content } from './PageError404ContentService'
import NavigationWrapper from '../theme/commons/navigation_wrapper/NavigationWrapperContainer'
import { NAVIGATION_TYPE } from '../theme/constants/navigationType'

/**
 * Not found a page - fallback navigation component.
 * @class PageError404
 * @category Components
 * @subcategory General
 * @param {Object} [props]
 * @returns {React.ReactNode}
 * @example
 * <PageError404 />
 */
const PageError404 = (props) => {
    const [error404Content, setError404Content] = useState({})

    useEffect(() => {
        let isCancelled = false
        const _getPageError404Content = async () => {
            const errorContent = await getPageError404Content()
            !isCancelled && setError404Content(errorContent)
        }
        _getPageError404Content()
        return () => {
            isCancelled = true
            return null
        }
    }, [])

    return (
        <div className="error-404-container horizontal-website-padding">
            <div
                className="error-404-picture-container"
                data-testid="error-404-picture-container"
                style={{ backgroundImage: `url(${error404picture})` }}
            >
                <div className="error-404-text">
                    <h2 data-testid="error-404-errorText">{error404Content.errorText}</h2>
                    <p data-testid="error-404-text">{error404Content.text}</p>
                </div>
            </div>
            <div className="error-404-button">
                <NavigationWrapper
                    type={NAVIGATION_TYPE.button}
                    text={error404Content.buttonText}
                    href={error404Content.buttonLink}/>
            </div>
        </div>
    )
}

export default PageError404
