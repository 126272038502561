import * as yup from 'yup'
import { cmsLanguage } from '../../../../cms/commons/cms_language/cmsLanguage'

/**
 * Yup add class data validation
 */
export const addClassSchema = yup.object().shape({
    eventsGroupId: yup.string().label('eventsGroupId').required(),
    duration: yup.string().max(3).label('duration').required().typeError(cmsLanguage.error.notValidDuration),
    trainer: yup.array(yup.string().required()),
    repeat: yup.object().nullable(),
    date: yup
        .string()
        .test((dateString) => new Date(dateString).toString() !== 'Invalid Date'),
    time: yup
        .object()
        .shape({
            hour: yup.string().required(),
            minute: yup.string().required()
        })
        .required().typeError(cmsLanguage.cms.selectFromDropdown),
    location: yup.string().required()
})
