import React from 'react'
import './InfoError.scss'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const InfoError = (props) => {
    const { infoError, tooltip } = props
    return (
        <>
            {infoError && (
                <div className="cms-info-error">
                    <span data-testid="cms-info-error-text">{infoError}</span>
                    {tooltip && (
                        <OverlayTrigger
                            placement={'right'}
                            overlay={
                                <Tooltip
                                    id={`${infoError}-tooltip`}
                                    data-testid="cms-info-error-tooltip"
                                >
                                    {tooltip}
                                </Tooltip>
                            }
                        >
                            <FontAwesomeIcon
                                data-testid="cms-info-error-icon"
                                icon={['fa', 'exclamation-circle']}
                            />
                        </OverlayTrigger>
                    )}
                </div>
            )}
        </>
    )
}

export default InfoError
