import React from 'react'
import './PhotoGalleryTemplate.scss'
import { Col, Container, Row } from 'react-bootstrap'
import PhotoCard from '../../theme/commons/photo_card/PhotoCard'
import { sortContent } from './sortContent'

/**
 * @typedef PhotoGalleryTemplateProps
 * @memberOf PhotoGalleryTemplate
 * @property {Array<Object>} photoContent
 * @property {string} name
 */

/**
 * Receives an array `photoContent` with the photo gallery and displays it using the {@link PhotoCard} component.
 * @class PhotoGalleryTemplate
 * @category Components
 * @subcategory Templates
 * @param {PhotoGalleryTemplateProps} props
 * @returns {React.ReactNode}
 * @example
 * <PhotoGalleryTemplate photoContent={photoGalleryContent} name={language.templates.images} />
 */
const PhotoGalleryTemplate = (props) => {
    const { photoContent, name } = props
    const orderedContent = sortContent(photoContent)

    return (
        orderedContent.map((years, index) =>
            <div key={index} className="photo-gallery horizontal-website-padding">
                <div
                    className={
                        name
                            ? 'photo-gallery-date-title'
                            : 'photo-gallery-date-title photo-gallery-date-title-line'
                    }
                >
                    <h1 data-testid="photo-gallery-name">{name || Object.keys(years)}</h1>
                </div>
                <Container fluid className="photo-gallery-container">
                    <Row>
                        {Object.values(years).map(year =>
                            year.map((element, index) =>
                                <Col sm={6} md={4} lg={3} key={index}>
                                    <PhotoCard
                                        picture={element.PHOTO ? element.PHOTO : element}
                                        text={element.TEXT && element.TEXT}
                                        date={!!element.DATE && !name ? element.DATE : null}
                                    />
                                </Col>
                            )
                        )}
                    </Row>
                </Container>
            </div>
        )
    )
}

export default PhotoGalleryTemplate
