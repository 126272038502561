import React from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import Template1 from './Template1'
import * as cmsActions from '@src/cms/actions/CMSActions'
import * as contentActions from '@src/cms/actions/ContentActions'

/**
 * {@link Template1} container that binds the Template1 with the {@link module:reduxSetup}
 * @class TemplateContainer
 * @category Containers
 * @subcategory Template1
 *
 * @mermaid
 *   sequenceDiagram
 *   Parent->>TemplateContainer: Request the Template1 via TemplateContainer.
 *   Note right of TemplateContainer: TemplateContainer gets information from redux store.
 *   TemplateContainer->>Template1: TemplateContainer sends redux store information to Template1 as props.
 *   Template1-->>Parent: Template1 is rendered in the parent component.
 */

/**
 * Gets the `contentDraft, cms, content`
 * from redux state and makes available to the {@link Template1} as a prop called `content, contentDB, isEditModeOn`.
 * @function
 * @memberOf TemplateContainer
 * @alias mapStateToProps
 * @param {Object} state - redux state
 * @returns {Object}
 */
const mapStateToProps = (state) => {
    const { contentDraft, cms, content } = state

    return {
        content: contentDraft,
        contentDB: content,
        isEditModeOn: cms.isEditModeOn
    }
}

/**
 * Passes certain Redux actions ({@link module:ContentActions} and {@link module:CmsActions})
 * to the {@link Template1} component as props.
 * This function is used only by the react-redux connect function.
 * @function
 * @memberOf TemplateContainer
 * @alias mapDispatchToProps
 * @param {function} dispatch - The react-redux dispatch function
 * @returns {Object} - Redux actions used in the {@link Template1} component
 */
const mapDispatchToProps = (dispatch) => bindActionCreators({ ...cmsActions, ...contentActions }, dispatch)

const TemplateContainer = (props) => {
    return <Template1 {...props} />
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    TemplateContainer
)
