// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".avatar-image{border-radius:50%;object-fit:cover;height:2.8125em;margin-right:.9375em;display:flex;align-items:center;background-color:#fff;background-size:cover;background-position:center}.avatar-image-small{height:2.8125em;width:2.8125em}.avatar-image-small .avatar-icon{font-size:.9375em}.avatar-image-medium{height:3.75em;width:3.75em}.avatar-image-medium .avatar-icon{font-size:1.875em}.avatar-image-large{height:5.625em;width:5.625em}.avatar-image-large .avatar-icon{font-size:2.8125em}.avatar-image-border{border:2px solid #64b42d}.avatar-image .avatar-icon{color:#b8b8b8}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
