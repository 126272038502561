import React, { useState } from 'react'
import './subpageMenu.scss'
import MenuItem from '../main_menu/components/menu_items/MenuItem'
import { Nav, Navbar } from 'react-bootstrap'

/**
 * @typedef SubpageMenuProps
 * @memberOf SubpageMenu
 * @property {Menu} menu
 */

/**
 * Navbar with {@link MenuItem}
 * @class SubpageMenu
 * @category Components
 * @subcategory Header
 * @param {SubpageMenuProps} props
 * @returns {React.ReactNode}
 * @example
 * <SubpageMenu menu={contentMenu}/>
 */
const SubpageMenu = ({ menu }) => {
    const [submenuOpened, setSubmenuOpened] = useState(menu.showSubMenu)

    return (
        <>
            <Navbar
                expand="sm"
                className={`sub-page-menu-container animate-background ${
                    submenuOpened && 'dark-background'
                }`}
            >
                <Nav
                    className={'mr-auto header-sub-page-navigation'}
                    onClick={() => setSubmenuOpened(!submenuOpened)}
                >
                    <MenuItem
                        menu={menu}
                        isSubPageMenu={true}
                    />
                    {(menu.children || []).map((subItem, index) => (
                        <MenuItem menu={subItem} mainPath={menu.path} key={index} isSubPageMenu={true} />
                    ))}
                </Nav>
            </Navbar>
        </>
    )
}

export default SubpageMenu
