import React from 'react'

export default function useDataAdaptor (isLoading, setter, data, adaptor) {
    React.useEffect(() => {
        let isMounted = true
        if (!isLoading) {
            const formValues = adaptor(data)
            const formWithUserValues = {}
            Object.keys(formValues).forEach(key => {
                formWithUserValues[key] = formValues[key]
            })
            if (isMounted) {
                setter(lastForm => ({ ...lastForm, ...formWithUserValues }))
            }
        }
        return () => {
            isMounted = false
        }
    }, [isLoading])
}
