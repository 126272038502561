import React, { useState } from 'react'
import './PhotoCard.scss'
import PhotoModal from './components/photo_modal/PhotoModal'
import { Modal } from 'react-bootstrap'

/**
 * @typedef PhotoCardProps
 * @memberOf PhotoCard
 * @property {string} picture
 * @property {string} [text]
 * @property {string} [date]
 */

/**
 * The photo card that shows the photos with a link to see it with more details ({@link PhotoModal})
 * @class PhotoCard
 * @category Components
 * @subcategory theme / commons
 * @param {PhotoCardProps} props
 * @returns {React.ReactNode}
 * @example
 * <PhotoCard picture="http://image.com/image.jpg" text="Photo headline" date="2023-05-18" />
 */
const PhotoCard = ({ picture, text, date }) => {
    const [showPhotoModal, setShowPhotoModal] = useState(false)
    const handleShowPhotoModal = () => setShowPhotoModal(true)
    const handleHidePhotoModal = () => setShowPhotoModal(false)

    return (
        <>
            <Modal
                className="photo-card-modal modal-common-config"
                data-testid="photo-card-modal"
                size="lg"
                show={showPhotoModal}
                onHide={handleHidePhotoModal}
            >
                <Modal.Header closeButton />
                <Modal.Body>
                    <PhotoModal
                        picture={picture}
                        text={text && text}
                        date={date && date}
                    />
                </Modal.Body>
            </Modal>

            <button
                className="photo-card-container"
                data-testid="photo-card-container"
                onClick={picture && handleShowPhotoModal}
            >
                <div
                    className="photo-card-picture"
                    data-testid="photo-card-picture"
                    style={picture && { backgroundImage: `url(${picture})` }}
                >
                    <div
                        className={`photo-card-gradient photo-card-gradient-${
                            picture ? 'hover' : 'no-picture'
                        }`}
                    />
                </div>
                <div className="photo-card-info">
                    {text && <p data-testid="photo-card-text">{text}</p>}
                    {!!date && <span data-testid="photo-card-date">{date}</span>}
                </div>
            </button>
        </>
    )
}

export default PhotoCard
