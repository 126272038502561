import React, { useEffect, useState } from 'react'
import './MembersArea.scss'
import { LOGGING_URL } from '../../backend/mesh/Constants'
import store from '../../redux/store'
import { cmsLanguage } from '../../cms/commons/cms_language/cmsLanguage'
import useUserData from './useFullUserData'
import { ddMMyyyy, formattedUserData } from './utils'
import InfoError from '../../cms/commons/cms_input/components/info_error/InfoError'
import profileFormValidator, { isMandatory } from './profileFormValidator'
import InputPhoneToolTip from '../../cms/commons/cms_input/components/input_phone/InputPhoneToolTip'
import ProfileImageField from './components/profile-image-field/ProfileImageField'
import MeshMultimedia from '../../backend/mesh/multimedia_service/MeshMultimedia'
import FormField from './components/form-field/FormField'
import CoursesAreaContainer from './components/course-area/CoursesAreaContainer'
import Mesh from '../../backend/mesh/Mesh'
import InfoModal from '../../theme/commons/infoModal'
import Button from '../../theme/commons/button/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const formFields = [
    { label: cmsLanguage.inputFields.name, field: 'firstName', type: 'text' },
    { label: cmsLanguage.inputFields.surname, field: 'lastName', type: 'text' },
    { label: cmsLanguage.inputFields.displayName, field: 'displayName', type: 'text' },
    { label: cmsLanguage.inputFields.birthDate, field: 'birthDate', type: 'date' },
    { label: cmsLanguage.inputFields.gender, field: 'gender', type: 'radio' },
    { label: cmsLanguage.inputFields.email, field: 'email', type: 'email' },
    { label: cmsLanguage.inputFields.phone, field: 'contactNumber', type: 'tel' },
    { label: cmsLanguage.inputFields.streetNumner, field: 'streetNumber', type: 'text' },
    { label: cmsLanguage.inputFields.postCode, field: 'postCode', type: 'text' },
    { label: cmsLanguage.inputFields.city, field: 'city', type: 'text' }
]
const initialUpdatedData = {
    firstName: { hasChanged: false, value: '' },
    lastName: { hasChanged: false, value: '' },
    displayName: { hasChanged: false, value: '' },
    birthDate: { hasChanged: false, value: '' },
    gender: { hasChanged: false, value: '' },
    email: { hasChanged: false, value: '' },
    contactNumber: { hasChanged: false, value: '' },
    streetNumber: { hasChanged: false, value: '' },
    postCode: { hasChanged: false, value: '' },
    city: { hasChanged: false, value: '' },
    profileImage: { hasChanged: false, value: '' }
}
const initialFieldValidations = {
    firstName: { errorMessage: '', tooltip: '' },
    lastName: { errorMessage: '', tooltip: '' },
    displayName: { errorMessage: '', tooltip: '' },
    birthDate: { errorMessage: '', tooltip: '' },
    gender: { errorMessage: '', tooltip: '' },
    email: { errorMessage: '', tooltip: '' },
    contactNumber: { errorMessage: '', tooltip: '' },
    streetNumber: { errorMessage: '', tooltip: '' },
    postCode: { errorMessage: '', tooltip: '' },
    city: { errorMessage: '', tooltip: '' },
    profileImage: { errorMessage: '', tooltip: '' }
}

/**
 * @typedef MembersAreaProps
 * @memberOf MembersArea
 * @property {Array<Object>} mySports
 * @property {Array<Object>} categories
 * @property {function} getMyCourses
 * @property {function} getCategories
 */

/**
 * Member area component.
 * @class MembersArea
 * @category Components
 * @subcategory Templates / MembersArea
 * @param {MembersAreaProps} props
 * @returns {React.ReactNode}
 * @example
 * <MembersArea {...newProps} />
 */
const MembersArea = (props) => {
    const { mySports, categories } = props
    const [fetchedUserDataDB, setUserDataDB] = useUserData()
    const userDataDB = formattedUserData(fetchedUserDataDB)
    const [updatedData, setUpdatedData] = useState(initialUpdatedData)
    const [fieldValidations, setFieldValidations] = useState(initialFieldValidations)
    const [updatedProfileImageFile, setUpdatedProfileImageFile] = useState(undefined)
    const [showModal, setShowModal] = useState(false)
    const [modalData, setModalData] = useState({ optionalMessage: [] })

    useEffect(() => {
        const user = store.getState().user
        if (!user || !user.loggedIn) {
            window.location.href = LOGGING_URL
        }
    }, [])

    useEffect(() => {
        props.getCategories()
    }, [])

    useEffect(() => {
        if (categories.length > 0) { props.getMyCourses() }
    }, [categories])

    /**
     * If the form was changed, gets the changed value (controlled by `updatedData`).
     * @function
     * @memberOf MembersArea
     * @returns {Object}
     */
    const getForm = () => {
        const form = {}
        Object.keys(updatedData).forEach((key) => {
            form[key] = updatedData[key].hasChanged ? updatedData[key].value || undefined : userDataDB[key]
        })
        return form
    }

    /**
     * Changes the `fieldValidations`, that will be used to show the message validation with the optional Tooltip.
     * @function
     * @memberOf MembersArea
     * @param {string} field
     * @param {string} errorMessage
     * @param {string|JSX.Element} tooltip
     */
    const setFieldValidation = (field, errorMessage = '', tooltip = '') => {
        setFieldValidations(lastFieldValidations => ({
            ...lastFieldValidations,
            [field]: {
                errorMessage,
                tooltip
            }
        }))
    }

    /**
     * Closes the modal and resets the modal data state.
     * @function
     * @memberOf MembersArea
     */
    const closeModal = () => {
        setShowModal(false)
        setModalData({ optionalMessage: [] })
    }

    /**
     * @function
     * @memberOf MembersArea
     * @param {string} field
     * @param {string|Object} error
     */
    const showOnError = (field, error) => {
        console.log('We got an error', {
            field,
            error
        })
        setModalData(lastData => ({
            type: 'fail',
            optionalMessage: lastData.optionalMessage.concat(
                <>
                    {cmsLanguage.profile.modal.persistFailed.replace('%FIELD%', field)}
                    <FontAwesomeIcon className='members-area__modal__times' icon='times'/>
                </>
            ),
            buttonText: cmsLanguage.inputFields.retryButton,
            onModalClick: () => {
                closeModal()
                submitForm()
            },
            onModalTimesClick: closeModal
        }))
        setShowModal(true)
    }

    /**
     * @function
     * @memberOf MembersArea
     * @param {string} field
     * @param {string|Object} res
     */
    const showOnSuccess = (field, res) => {
        console.debug('It was saved successfully', {
            field,
            res
        })
        setModalData(lastData => ({
            type: 'success',
            optionalMessage: lastData.optionalMessage.concat(
                <>
                    {cmsLanguage.profile.modal.persistSuccess.replace('%FIELD%', field)}
                    <FontAwesomeIcon className='members-area__modal__check' icon='check' />
                </>),
            onModalClick: closeModal,
            onModalTimesClick: closeModal
        }))
        setShowModal(true)
    }

    /**
     * @function
     * @memberOf MembersArea
     */
    const submitForm = () => {
        profileFormValidator
            .validate(getForm())
            .then(() => {
                // Upload image
                if (updatedProfileImageFile) {
                    const field = cmsLanguage.profile.modal.image
                    MeshMultimedia.uploadProfileImage(
                        updatedProfileImageFile,
                        userDataDB.id.toString()
                    )
                        .then(showOnSuccess.bind(null, field))
                        .catch(showOnError.bind(null, field))
                }

                // adapt our data to the request
                let {
                    firstName,
                    lastName,
                    email,
                    contactNumber,
                    displayName,
                    birthDate,
                    gender,
                    streetNumber = '-',
                    postCode,
                    city
                } = getForm()
                let {
                    countryCode,
                    department,
                    description,
                    eventsGroups,
                    memberSince,
                    address,
                    roleId
                } = userDataDB

                if (contactNumber) {
                    countryCode = contactNumber.substring(0, 3)
                    contactNumber = contactNumber.substring(3)
                }

                if (city && postCode && streetNumber) {
                    address = {
                        ...address,
                        streetNumber,
                        postCode,
                        city
                    }
                } else if (!address) {
                    address = null
                }

                const userDataToBeSaved = {
                    firstName,
                    lastName,
                    fullName: `${firstName} ${lastName}`,
                    orgId: undefined,
                    email,
                    countryCode,
                    contactNumber,
                    department,
                    description,
                    displayName,
                    roleId,
                    eventsGroups,
                    address,
                    memberSince: memberSince ? new Date(memberSince) : memberSince,
                    birthDate: new Date(birthDate),
                    gender
                }

                // Save
                const field = cmsLanguage.profile.modal.user
                Mesh.updateUserData(userDataToBeSaved)
                    .then((res) => {
                        setUserDataDB(formattedUserData(res))
                        // reset form
                        setUpdatedData(initialUpdatedData)
                        setFieldValidations(initialFieldValidations)
                        showOnSuccess(cmsLanguage.profile.modal.user, res)
                    }).catch(showOnError.bind(null, field))
            })
            .catch((e) => {
                Object.values(formFields).forEach(field => {
                    validateField(field.field, getForm()[field.field], field.type)
                })
                validateAddress(getForm())
            })
    }

    /**
     * @function
     * @memberOf MembersArea
     * @param {string} field
     * @param {string} value
     * @param {string} type
     */
    const validateField = (field, value, type) => {
        console.log('validateField ' + field + value + type)
        profileFormValidator
            .validateAt(field, { [field]: value !== null ? value : undefined })
            .then(() => {
                console.log('validateField SUCCESS ' + field)
                setFieldValidation(field, '', '')
            })
            .catch((error) => {
                console.log('validateField ERROR ' + error.message)
                let tooltip
                if (type === 'tel') {
                    tooltip = <InputPhoneToolTip/>
                }
                setFieldValidation(field, error.message, tooltip)
            })
    }

    /**
     * @function
     * @memberOf MembersArea
     * @param {Object} form
     */
    const validateAddress = (form) => {
        const fieldsToValidate = {
            city: form.city,
            postCode: form.postCode,
            streetNumber: form.streetNumber
        }

        console.log(fieldsToValidate)

        profileFormValidator
            .validate(fieldsToValidate, { abortEarly: false })
            .then(() => {
            })
            .catch((errors) => {
                setFieldValidation('city', cmsLanguage.error.addressMandatory, '')
                setFieldValidation('postCode', cmsLanguage.error.addressMandatory, '')
                setFieldValidation('streetNumber', cmsLanguage.error.addressMandatory, '')
            })
    }

    /**
     * @function
     * @memberOf MembersArea
     * @param {string} field
     * @param {string} value
     * @param {string} type
     */
    const handleOnChange = (field, value, type) => {
        // save value
        setUpdatedData(lastUpdatedData => ({
            ...lastUpdatedData,
            [field]: { hasChanged: true, value }
        }))

        // validate new value
        validateField(field, value, type)
    }

    /**
     * @function
     * @memberOf MembersArea
     * @param {string} field
     * @param {string} errorMessage
     * @param {string|JSX.Element} tooltip
     */
    const handleOnError = (field, errorMessage, tooltip) => {
        setFieldValidation(field, errorMessage, tooltip)
    }

    /**
     * @function
     * @memberOf MembersArea
     * @param {string} field
     * @returns {string}
     */
    const inputValue = (field) => {
        const { hasChanged, value } = updatedData[field]
        return hasChanged ? value : userDataDB[field] || ''
    }

    /**
     * @function
     * @memberOf MembersArea
     * @type {boolean}
     */
    const hasChanges = Object.values(updatedData)
        .filter((value) => value.hasChanged).length > 0

    /**
     * @function
     * @memberOf MembersArea
     * @type {boolean}
     */
    const hasErrors = Object.values(fieldValidations)
        .filter((validation) => validation.errorMessage).length > 0

    return (
        <>
            {showModal &&
                <InfoModal
                    type={modalData.type}
                    optionalMessage={modalData.optionalMessage.map((message, index) => <span key={index} className='members-area__modal'>{message}</span>)}
                    onModalClick={modalData.onModalClick}
                    onModalTimesClick={modalData.onModalTimesClick}
                    buttonText={modalData.buttonText}
                />
            }
            <div className="members-area">
                <div className="members-area__container">
                    <div className="members-area__member-data">
                        {fieldValidations.profileImage?.errorMessage && (
                            <InfoError
                                infoError={fieldValidations.profileImage.errorMessage}
                                tooltip={fieldValidations.profileImage.tooltip}
                            />
                        )}
                        <div className="member-since">
                            <span className={'member-since__label'}>
                                {cmsLanguage.profile.memberSince}:{' '}
                            </span>
                            <span className={'member-since__value'}>
                                {ddMMyyyy(userDataDB.memberSince)}
                            </span>
                        </div>
                        <form className={'member-data__form'}>
                            {formFields.map(({ label, field, type }) => {
                                const value = inputValue(field)
                                return (
                                    <div className="members-area-input" key={label}>
                                        <label>
                                            {isMandatory(field) && '*'} {label}
                                        </label>
                                        <FormField
                                            type={type}
                                            field={field}
                                            value={value}
                                            onChange={handleOnChange}
                                            onError={handleOnError}
                                        />
                                        {fieldValidations[field]?.errorMessage && (
                                            <InfoError
                                                infoError={fieldValidations[field].errorMessage}
                                                tooltip={fieldValidations[field].tooltip}
                                            />
                                        )}
                                    </div>
                                )
                            })}
                            <div className="members-area-input" data-testid="sports-form-field">
                                <label>
                                    {cmsLanguage.sectionNames.sports}
                                </label>
                                <FormField
                                    type={'text'}
                                    disabled
                                    onChange={() => {}}
                                    value={mySports.map(sport => sport.name).join(', ')}
                                />
                            </div>
                            <div className="members-area__button">
                                {hasErrors && (
                                    <div className="members-area__button__errors">
                                        {cmsLanguage.error.invalidFields}
                                    </div>
                                )}
                                <Button
                                    data-testid='template-button'
                                    text ={cmsLanguage.cms.saveChanges.toUpperCase()}
                                    onClick={() => submitForm()}
                                    type={(!hasChanges || hasErrors) && 'disabled'}
                                />
                            </div>
                        </form>
                        <ProfileImageField
                            value={inputValue('profileImage')}
                            onError={(errorMessage) =>
                                handleOnError('profileImage', errorMessage)
                            }
                            onChange={(newValue, pictureFile) => {
                                handleOnChange('profileImage', newValue)
                                setUpdatedProfileImageFile(pictureFile)
                            }}
                        />
                    </div>
                    <div className="members-area__course-data">
                        <CoursesAreaContainer/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MembersArea
