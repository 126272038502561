/**
 * @module joinCourseStep
 * @category Components
 * @subcategory Templates / CourseInfo
 */

/**
 * Enum for join course steps
 * @readonly
 * @inner
 * @category Enums
 * @enum {number}
 * @example
 * INITIAL: 0
 * LOGIN: 1
 * PAYMENT: 2
 * SUCCESSFUL: 3
 */
const JOIN_COURSE_STEP = {
    INITIAL: 0,
    LOGIN: 1,
    PAYMENT: 2,
    SUCCESSFUL: 3
}

/**
 * Total of join courses step ({@link JOIN_COURSE_STEP})
 * @const
 * @inner
 * @type {number}
 * @example
 * const TOTAL_STEPS = Object.keys(JOIN_COURSE_STEP).length
 */
export const TOTAL_STEPS = Object.keys(JOIN_COURSE_STEP).length

export default JOIN_COURSE_STEP
