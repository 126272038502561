import { compose } from 'redux'
import { connect } from 'react-redux'
import CmsModalMultipleElements from './CmsModalMultipleElements'

const mapStateToProps = (state) => {
    const { content } = state

    return {
        contentDB: content
    }
}

export default compose(connect(mapStateToProps, null))(
    CmsModalMultipleElements
)
