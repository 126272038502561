import { language } from '../../../../theme/commons/language/language'

const SINGLE = { max: 1, min: 1 }
const PAIR = { min: 2, max: 2 }
const FAMILY = { min: 2, max: 4 }

export const membershipTypes = [
    { label: language.membership.forMe, id: 1, ...SINGLE },
    { label: language.membership.formMySon, id: 2, ...PAIR, disabled: true },
    { label: language.membership.formMyDaughter, id: 3, ...PAIR, disabled: true },
    { label: language.membership.coupleContribution, id: 4, ...PAIR, disabled: true },
    { label: language.membership.familyContribution, id: 5, ...FAMILY, disabled: true },
    { label: language.membership.student, id: 6, ...SINGLE, disabled: true },
    { label: language.membership.trainee, id: 7, ...SINGLE, disabled: true },
    { label: language.membership.military, id: 8, ...SINGLE, disabled: true },
    { label: language.membership.pensioner, id: 9, ...SINGLE, disabled: true },
    { label: language.membership.unemployed, id: 10, ...SINGLE, disabled: true }
]
