// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".cms-input{margin-bottom:.9375em}.cms-input h4{text-transform:capitalize}.cms-input-name{height:1.875em;display:flex;align-items:center;margin-bottom:.625em}.cms-input-name span{font-family:'Open Sans', sans-serif;font-size:1.25em;text-transform:capitalize}.cms-input-icon{height:100%;width:.9375em;display:flex;align-items:center}.cms-input-icon svg{font-size:.625em}.cms-input-field{display:flex;align-items:center;justify-content:space-between}.cms-input-on-revert{margin-left:.9375em}.cms-input-box{width:100%;padding:.625em;border:solid 1px #b8b8b8}.cms-input-box input,.cms-input-box textarea{border:none;background-image:none;background-color:transparent;-webkit-box-shadow:none;-moz-box-shadow:none;box-shadow:none;width:100%;font-family:'Open Sans', sans-serif;font-size:.875em;color:#191919}.cms-input-box input:focus:hover,.cms-input-box input:focus,.cms-input-box input:hover,.cms-input-box textarea:focus:hover,.cms-input-box textarea:focus,.cms-input-box textarea:hover{outline:none;border-radius:0}.cms-input-box-text{display:flex}.cms-input-box-text>div{justify-self:flex-end;text-align:right;margin-left:.9375em}.cms-input-box-text>div span{font-family:'Open Sans', sans-serif;font-size:.75em;color:#595959}.cms-input-box-icon{display:flex;justify-content:space-between;align-items:center}.cms-input-box-icon>div:last-child{margin-left:.9375em;font-size:1.125em;color:#191919}.cms-input-focus .cms-input-box{border-color:#64b42d}.cms-input-error .cms-input-box{border-color:#f03}.cms-input-info-error{height:1.875em;display:flex;align-items:center}.cms-input-no-display-info-error{height:.625em}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
