import React from 'react'
import './inputGender.scss'
import { language } from '../../../../../theme/commons/language/language'

const InputGender = ({
    value,
    onChange,
    disabled = false
}) => {
    const onChangeValue = (event) => {
        const genderValue = event.target.value
        const hasError = genderValue === ''
        onChange(genderValue, hasError)
    }

    return (
        <div className="members-gender-field" data-testid="gender-field">
            <div className="gender-field-area">
                <label className="gender-container" htmlFor="male">
                    {language.membership.male}
                    <input
                        type="radio"
                        id="male"
                        name="gender"
                        value="Male"
                        checked={value === 'Male'}
                        onChange={onChangeValue}
                        disabled={disabled}
                    />
                    <span className={'checkmark'} />
                </label>

                <label className="gender-container" htmlFor="female">
                    {language.membership.female}
                    <input
                        type="radio"
                        id="female"
                        name="gender"
                        value="Female"
                        checked={value === 'Female'}
                        onChange={onChangeValue}
                        disabled={disabled}
                    />
                    <span className={'checkmark'} />
                </label>
            </div>
        </div>
    )
}

export default InputGender
