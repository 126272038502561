import React from 'react'
import './avatar.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/**
 * Avatar component.
 * Show profile picture or an avatar icon if src is null
 * @class Avatar
 * @category Components
 * @subcategory theme / commons
 * @param {string} [src] - profile image path
 * @param {'small'|'medium'|'large'} [size]
 * @param {boolean} [border] - has border (true/false)
 * @returns {React.ReactNode}
 * @example
 * <Avatar src="public/images/profile.png" size="small" border={true} />
 */
export default function Avatar ({ src, size, border }) {
    const sizeMap = {
        small: 'avatar-image-small',
        medium: 'avatar-image-medium',
        large: 'avatar-image-large'
    }

    /**
     * Gets a specific class based on size and border props
     * @function
     * @returns {string}
     */
    const getClass = () => {
        let className = `avatar-image ${sizeMap[size] || sizeMap.medium}`
        className += border ? ' avatar-image-border' : ''
        return className
    }

    return (
        <div
            className={getClass()}
            style={{
                backgroundImage: src ? `url('${src}')` : undefined,
                border: src ? 'none' : '1px solid #B8B8B8'
            }}
        >
            {!src && (
                <FontAwesomeIcon
                    className='avatar-icon'
                    icon={['far', 'user']}
                />
            )}
        </div>
    )
}
