import React from 'react'
import InfoBox from '../../../../../theme/commons/info_box/InfoBox'

/**
 * @const
 * @memberOf InfoBoxSection
 * @type {string}
 * @example
 * const BOLD_TAG = '%BOLD%'
 */
export const BOLD_TAG = '%BOLD%'

/**
 * @typedef InfoBoxSectionProps
 * @memberOf InfoBoxSection
 * @property {string} boldText
 * @property {string} fullText
 */

/**
 * @class InfoBoxSection
 * @category Components
 * @subcategory Templates / CmsContent
 * @param {InfoBoxSectionProps} props
 * @returns {React.ReactNode}
 * @example
 * <InfoBoxSection
 *     boldText={location}
 *     fullText={infoBoxFullText(location, containerDescription)}
 * />
 */
const InfoBoxSection = ({ boldText, fullText }) => {
    const __html = fullText.replace(BOLD_TAG, `<span data-testid="info-box_bold">${boldText}</span>`)
    return (
        <InfoBox>
            <p dangerouslySetInnerHTML={{ __html }} data-testid={'info-box_text'}/>
        </InfoBox>
    )
}

InfoBoxSection.defaultProps = {
    boldText: '',
    fullText: ''
}

export default InfoBoxSection
