/**
 * @module capitalizeFirstLetter
 * @category Utils
 * @subcategory General
 */

/**
 * Takes a string and returns it with the first letter capitalized
 * @function
 * @param {string} string
 * @returns {string|undefined}
 * @example
 * const text = 'hello'
 * const capitalized = capitalizeFirstLetter(text) // 'Hello'
 */
export const capitalizeFirstLetter = (string) => {
    if (typeof string === 'string' && !!string) {
        const stringArr = string?.split(' ')

        for (let i = 0; i < stringArr?.length; i++) {
            stringArr[i] =
                stringArr[i].charAt(0).toUpperCase() + stringArr[i].slice(1)
        }

        return stringArr.join(' ')
    }
    return undefined
}
