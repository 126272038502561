import { cmsLanguage } from '../../../../cms/commons/cms_language/cmsLanguage'

/**
 * @const
 * @alias pricelistItems
 * @memberOf MembershipFirstStep
 * @type {Array<{price: number, name: (string)}>}
 * @example
 * const pricelistItems = [
 *     { name: 'Adults', price: 19.50 },
 *     { name: 'Married Couples', price: 30 },
 *     { name: 'Family', price: 35 },
 *     { name: 'Children / Young People', price: 10 },
 *     { name: 'Pupils / Students / Trainees', price: 10 },
 *     { name: 'Federal Volunteer Service', price: 10 },
 *     { name: 'People With Disabilities', price: 35 },
 *     { name: 'People With Disabilities Married', price: 19.5 },
 *     { name: 'people With Disabilities Family', price: 22 },
 *     { name: 'Pensioner', price: 10 }
 * ]
 */
export const pricelistItems = [
    {
        name: cmsLanguage.priceListItem.adults,
        price: 19.50
    },
    {
        name: cmsLanguage.priceListItem.marriedCouples,
        price: 30
    },
    {
        name: cmsLanguage.priceListItem.family,
        price: 35
    },
    {
        name: cmsLanguage.priceListItem.childrenYoungPeople,
        price: 10
    },
    {
        name: cmsLanguage.priceListItem.pupilsStudentsTrainees,
        price: 10
    },
    {
        name: cmsLanguage.priceListItem.federalVolunteerService,
        price: 10
    },
    {
        name: cmsLanguage.priceListItem.peopleWithDisabilities,
        price: 10
    },
    {
        name: cmsLanguage.priceListItem.peopleWithDisabilitiesMarried,
        price: 19.5
    },
    {
        name: cmsLanguage.priceListItem.peopleWithDisabilitiesFamily,
        price: 22
    },
    {
        name: cmsLanguage.priceListItem.pensioner,
        price: 10
    }
]
