// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".generic-modal{position:fixed;z-index:1000;left:0;top:0;width:100%;height:100%;overflow:auto;background-color:#3f3f3f9e;display:flex;align-items:center}.model-center{padding:2.8125em 3.75em 5.625em;border-bottom:solid .3125em #64b42d;background-color:#fefefe;margin:10% auto;padding:20px;width:40%}@media screen and (max-width: 992px){.model-center{width:70%}}@media screen and (max-width: 768px){.model-center{width:80%}}@media screen and (max-width: 576px){.model-center{width:90%;margin:5% auto}}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
