import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { isMobile } from '@utils/isMobile'
import Nodata from '@theme/commons/NoData/Nodata'
import {
    ScheduleCard,
    WeekDayHeader,
    CalendarHeaderButton,
    WeekCalendarMobile
} from '../index'
import './weekCalendar.scss'
import ClassDetailsModal from '../ClassDetailsModal/ClassDetailsModal'
import { cmsLanguage } from '../../../../cms/commons/cms_language/cmsLanguage'
import AddEditClassModal from '../../../../theme/commons/AddEditClass/AddEditClassContainer'
import Modal from '../../../../theme/commons/Modal/Modal'
import { calculateFrom, calculateTo } from '../../helpers/calculateTimeScheduledCard'

/**
 * @typedef WeekCalendarProps
 * @memberOf WeekCalendar
 * @property {string} [month]
 * @property {number} [week]
 * @property {function} [changeWeek]
 * @property {Object} [data]
 * @property {Object} [course]
 * @property {string} [path]
 * @property {string} startDate
 * @property {boolean} [isEditMode]
 */

/**
 * Application Week Calendar component.
 * (It is used in {@link TrainingPage}).
 * @class WeekCalendar
 * @category Components
 * @subcategory Pages / trainingSchedule
 * @param {WeekCalendarProps} props
 * @returns {React.ReactNode}
 * @example
 * <WeekCalendar
 *     course={selectedCourse || {}}
 *     path={selectedCourse ? selectedCourse.path : ''}
 *     startDate={startDate}
 *     data={schedules}
 *     week={currentWeek}
 *     month={currentMonth}
 *     changeWeek={changeWeek}
 *     isEditMode={isEditModeOn}
 * />
 */
export default function WeekCalendar (props) {
    const { data, week, month, changeWeek, course, path, startDate, isEditMode } = props

    const [currentClassDetails, setCurrentClassDetails] = useState(false)

    const CreateRow = () => {
        if (data.length === 0) {
            return (
                <div className="calendar-day-empty" data-testid="calendar-day-empty">
                    {course.name ? <Nodata/> : cmsLanguage.error.noCourseSelected}
                </div>
            )
        }

        return (
            <div className="calendar-day-container" data-testid="week-calendar">
                {data.map((row, _index) =>
                    row.map((items) => {
                        return items !== 0
                            ? (
                                <div className="multi-schedule-card">
                                    {
                                        items.map(item =>
                                            <ScheduleCard
                                                clickHandler={setCurrentClassDetails}
                                                key={'card_' + item.id + uuidv4()}
                                                from={calculateFrom(item)}
                                                to={calculateTo(item)}
                                                title={course.name}
                                                path={path}
                                                data={item}
                                            />
                                        )
                                    }
                                </div>
                            )
                            : (
                                <div
                                    className="calendar-card-empty"
                                    data-testid="calendar-card-empty"
                                    key={'blank_card_' + uuidv4() + _index}
                                >
                                </div>
                            )
                    })
                )}
                {isEditMode
                    ? (currentClassDetails &&
                        <Modal>
                            <AddEditClassModal
                                isOpen={currentClassDetails}
                                currentClassDetails={currentClassDetails}
                                setIsOpen={setCurrentClassDetails}
                                onClose={() => setCurrentClassDetails(false)}
                                disableRepeat={true}
                            />
                        </Modal>)
                    : (<ClassDetailsModal
                        isOpen={currentClassDetails}
                        currentClassDetails={currentClassDetails}
                        setIsOpen={setCurrentClassDetails}
                    />)
                }
            </div>
        )
    }
    const WeekCalendarBody = () => {
        return isMobile()
            ? (
                <WeekCalendarMobile {...props} />
            )
            : (
                <>
                    <div className="calendar-row">
                        <WeekDayHeader startDate={startDate}/>
                    </div>
                    <CreateRow/>
                </>
            )
    }
    return (
        <div className="calendar-container">
            <CalendarHeaderButton month={month} week={week} changeWeek={changeWeek}/>
            <WeekCalendarBody/>
        </div>
    )
}
