import React from 'react'
import './contactItem.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter'

/**
 * @typedef ContactItemProps
 * @memberOf ContactItem
 * @property {Object} item
 */

/**
 * Shows a text with contact information and a contact icon.
 * @class ContactItem
 * @category Components
 * @subcategory Templates / Contact
 * @param {ContactItemProps} props
 * @returns {React.ReactNode}
 * @example
 * <ContactItem item={item}/>
 */
const ContactItem = ({ item }) => {
    const description = item.TEXT_1.split(';')

    return (
        <div className="contact-item">
            <div className="contact-item-title-row">
                <div className="contact-icon-container">
                    <FontAwesomeIcon
                        className="contact-icon"
                        data-testid="contact-icon"
                        icon={item.SHORT_TEXT_2}
                    />
                </div>
                <h4 data-testid="contact-item-title">{capitalizeFirstLetter(item.H1)}</h4>
            </div>
            {description.map((row, index) =>
                <p key={index} data-testid={`contact-item-${row}`}>{row}</p>
            )}
        </div>
    )
}

export default ContactItem
