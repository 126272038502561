import { combineReducers } from 'redux'
import {
    user,
    organization,
    headerMenu,
    topMenu,
    footerMenu,
    socialMediaMenu,
    getCustomNavigation,
    getCurrentRoutes
} from './reducers/reducers'
import trainingReducer from '@pages/trainingSchedule/reducer/trainingReducer'
import cmsReducer from '../cms/reducers/CMSReducers'
import createContentReducer from '../cms/reducers/ContentReducers'
import { CONTENT_FOOTER, CONTENT_PAGE, CONTENT_SOCIAL_MEDIA } from '../cms/types'
import { createProductReducer } from '../cms/reducers/ProductReducers'

const rootReducer = combineReducers({
    user,
    organization,
    headerMenu,
    topMenu,
    footerMenu,
    socialMediaMenu,
    training: trainingReducer,
    cms: cmsReducer,
    content: createContentReducer(false, CONTENT_PAGE),
    contentDraft: createContentReducer(true, CONTENT_PAGE),
    products: createProductReducer,
    footer: createContentReducer(false, CONTENT_FOOTER),
    footerDraft: createContentReducer(true, CONTENT_FOOTER),
    socialMedia: createContentReducer(false, CONTENT_SOCIAL_MEDIA),
    socialMediaDraft: createContentReducer(true, CONTENT_SOCIAL_MEDIA),
    getCustomNavigation,
    getCurrentRoutes
})

export default rootReducer
