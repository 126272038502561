import React from 'react'
import './membershipFourthStep.scss'
import { language } from '../../../../theme/commons/language/language'
import { Col, Container, Row } from 'react-bootstrap'

/**
 * The fourth step of the {@link MembersForm}.
 * @class MembershipFourthStep
 * @category Components
 * @subcategory Pages / become-a-member
 * @returns {React.ReactNode}
 * @example
 * <MembershipFourthStep />
 */
const MembershipFourthStep = () => {
    return (
        <Container className={'membership-success'}>
            <Row>
                <Col>
                    <h5>{language.membership.success}</h5>
                    <p className="membership-success__details">{language.membership.attention}</p>
                </Col>
            </Row>
        </Container>
    )
}

export default MembershipFourthStep
