import React from 'react'
import Button from '../button/Button'

/**
 * @typedef NavigationWrapperProps
 * @memberOf NavigationWrapperContainer
 * @property {React.ReactNode} children
 * @property {NavigationType} [type] - BUTTON, LINK
 * @property {string} href
 * @property {React.ReactNode} children
 * @property {string} [data-testid]
 * @property {string} [className]
 * @property {string} [text]
 * @property {string} [id]
 */

/**
 * Navigation wrapper - receives a component and shows it as a link or a button bases on received type
 * @class NavigationWrapperContainer
 * @category Components
 * @subcategory theme / commons
 * @param {NavigationWrapperProps} props
 * @returns {React.ReactNode}
 * @example
 * <NavigationWrapper type="LINK" href='/'>Click here</NavigationWrapper>
 */
const NavigationWrapper = ({
    type,
    href,
    children,
    'data-testid': testId = 'navigation-testId',
    className = '',
    text,
    id,
    state,
    setCustomNavigation,
    autoWidth
}) => {
    /**
   * Prepare the href property to be called (internal or external destination)
   * @function
   * @memberOf NavigationWrapperContainer
   * @param {Object} event - HTML event
   */
    const handleOnClick = event => {
        if (href[0] === '/') {
            setCustomNavigation({ path: href, state })
            event.preventDefault()
        } else {
            let link = href
            if (!href.startsWith('http') || !href.startsWith('https')) {
                link = href
            }
            window.open(link, '_blank', 'noopener,noreferrer')
            event.preventDefault()
        }
    }

    switch (type) {
    case 'BUTTON':
        return <Button
            autoWidth={autoWidth}
            onClick={handleOnClick}
            data-testid={testId}
            className={className}
            text={text}
            link={href}
            id={id}
        />
    case 'LINK':
        return <a
            onClick={handleOnClick}
            data-testid={testId}
            className={className}
            id={id}>
            <span>{children}</span>
        </a>
    default:
        return null
    }
}

export default NavigationWrapper
