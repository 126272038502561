// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".cms-input-photo-container{height:5.625em;width:100%;display:flex;align-items:center;gap:.9375em}.cms-input-photo-container *{margin-bottom:0 !important}.cms-input-photo-container .search-images-container .search-images-buttons{display:flex;justify-content:space-between;align-items:center}.cms-input-photo-container .search-images-container .search-images-buttons p{padding:0 .9375em}.cms-input-photo-preview{height:100%;width:auto;margin-right:.625em}.cms-input-photo-icon{background-color:#f2f2f2;width:15%;display:flex;justify-content:center;align-items:center;color:#b8b8b8;font-size:1.625em}.cms-input-photo-browse--cloud{margin-bottom:.625em;font-size:.9375em;border-width:1px;border-radius:2px;width:max-content}.cms-input-photo-browse{width:max-content;position:relative}.cms-input-photo-browse input{margin-bottom:.625em}.cms-input-photo-browse #cms-input-photo{color:rgba(0,0,0,0);width:auto}.cms-input-photo .cms-input-photo-container span{width:max-content;font-family:'Open Sans', sans-serif;font-size:.75em;color:#595959}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
