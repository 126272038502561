/**
 * @module location
 * @category Utils
 * @subcategory General
 */

/**
 * Returns the current location `window.location.href`
 * @function
 * @returns {string}
 */
export const getCurrentLocation = () => {
    return window.location.href
}

/**
 * Sets the new location (redirect) `window.location`
 * @function
 * @param {string} url
 */
export const setCurrentLocation = (url) => {
    window.location = url
}
