import React from 'react'
import './GenderRadioInput.scss'
import { cmsLanguage } from '../../../../cms/commons/cms_language/cmsLanguage'

/**
 * @typedef GenderRadioInputProps
 * @memberOf GenderRadioInput
 * @property {function} onChange
 * @property {string} value
 */

/**
 * Gender radio buttons.
 * @class GenderRadioInput
 * @category Components
 * @subcategory Templates / MembersArea
 * @param {GenderRadioInputProps} props
 * @returns {React.ReactNode}
 */
const GenderRadioInput = ({ value, onChange }) => {
    const handleOnChange = (event) => {
        const { value } = event.target
        onChange(value)
    }
    const parseLabel = (gender) => cmsLanguage.inputFields[gender.toLowerCase()]
    return <div className='gender-radio-input'>
        {['Male', 'Female'].map(gender => {
            const isGender = gender.toLowerCase() === value.toLowerCase()
            return <div key={gender} className='gender-radio-input__radio'>
                <input className='gender-radio-input__radio__circle' type='radio' checked={isGender} value={gender} onChange={handleOnChange}/>
                <label className='gender-radio-input__radio__label'>{parseLabel(gender)}</label>
            </div>
        })}
    </div>
}

export default GenderRadioInput
