// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".input-count-wrapper{position:relative}.input-count-wrapper .input-count{width:100%;border:1px solid #b8b8b8;font-family:rucksack, sans-serif;font-weight:400;font-style:normal;font-size:1em;color:#595959;font-weight:lighter;padding:.625em;font-family:rucksack, sans-serif;font-weight:300;font-style:normal;border-radius:0}.input-count-wrapper .input-count:focus{outline:none}.input-count-wrapper .hide-input-resize{resize:none}.input-count-wrapper textarea{display:block}.input-count-wrapper .word-count{font-family:rucksack, sans-serif;font-weight:300;font-style:normal;position:absolute;font-size:.625em;letter-spacing:.3125em;bottom:.9375em;right:.625em}.input-width-medium{width:16.875em}.input-width-large{width:22.5em}.input-width-small{width:6.5625em}.input-width-normal{width:100%}@media screen and (max-width: 576px){.input-width-small,input-width-medium,input-width-large{width:100%}}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
