// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".error-404-container{height:100vh;display:flex;flex-direction:column;justify-content:center;align-items:center}.error-404-picture-container{height:60%;width:100%;background-size:contain;background-position:bottom;background-repeat:no-repeat;display:flex;flex-direction:column;align-items:center;justify-content:flex-end}.error-404-text{text-align:center;padding-bottom:3.75em}.error-404-text>h2{font-family:rucksack, sans-serif;font-weight:500;font-style:normal;font-size:1.625em;color:#191919;text-transform:uppercase;margin-bottom:.9375em}.error-404-text>p{font-family:'Open Sans', sans-serif;font-weight:normal;font-style:normal;font-size:1.25em;color:#191919;margin-bottom:inherit}@media screen and (min-width: 768px){.error-404-container{padding-top:10.3125em}.error-404-text{width:60vw}}@media screen and (max-width: 768px){.error-404-container{padding-top:5.625em}}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
