import React from 'react'
import * as noDataImage from '@assets/no-data.png'
import './noData.scss'
import { cmsLanguage } from '../../../cms/commons/cms_language/cmsLanguage'

/**
 * No data component
 * @class Nodata
 * @category Components
 * @subcategory theme / commons
 * @returns {React.ReactNode}
 * @example
 * <Nodata />
 */
export default function Nodata () {
    return (
        <div className="nodata-container" onClick={(e) => e.stopPropagation()}>
            <img className="nodata-image" src={noDataImage} alt={cmsLanguage.content.noData} />
            <div className="nodata-description">{cmsLanguage.content.noData}</div>
        </div>
    )
}
