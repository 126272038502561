// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".login-step{display:flex;flex-direction:column;align-items:center;margin-bottom:2.8125em}.login-step__status--logged-out{padding-bottom:.9375em}.login-step__status--logged-in{display:flex;flex-direction:column;align-items:center;gap:.9375em}.login-step__status--logged-in .status__name{color:#64b42d;margin-bottom:.9375em;height:1.5625em}.login-step__cta-container a.button>span{font-family:rucksack, sans-serif;font-weight:700;font-style:normal}.login-step__other--logged-in{margin-top:.9375em}.login-step__other--logged-out{margin-top:1.875em}.login-step__other>a{color:#64b42d !important;border-bottom:1px solid #64b42d}.login-step .cancel-button{margin-top:2.8125em}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
