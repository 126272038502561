import React from 'react'
import './Button.scss'
import { sizeL } from './../../styles/_sizes.scss'
import { fontP2 } from './../../styles/_fonts.scss'
import ButtonTypes from './buttonTypes'
import { cmsLanguage } from '../../../cms/commons/cms_language/cmsLanguage'

/**
 * Translated 'view' text.
 * @const
 * @memberOf Button
 * @type {string}
 */
const DEFAULT_TEXT = cmsLanguage.cms.view

/**
 * Calculates the width and applies it to the received component
 * @function
 * @alias withAutoWidth
 * @memberOf Button
 * @param {React.ReactNode} BaseComponent
 * @returns {function} - enhanced component
 */
export const withAutoWidth = (BaseComponent) => {
    /**
     * @function
     * @param {boolean} autoWidth
     * @param {Object} props
     * @returns {JSX.Element}
     */
    const WithAutoWidth = ({ autoWidth = true, ...props }) => {
        let width
        if (autoWidth) {
            const buttonText = props.text || DEFAULT_TEXT
            const buttonTextLength = buttonText.length
            const fixBigTextSize = buttonTextLength <= 10 ? 0 : sizeL
            const finalButtonTextLength =
                buttonTextLength < 10
                    ? buttonTextLength * 1.5
                    : buttonTextLength
            const buttonWidth = finalButtonTextLength * fontP2 - fixBigTextSize
            width = !buttonWidth || buttonWidth < 150 ? 150 : buttonWidth
        }

        return <BaseComponent {...props} width={width} />
    }

    const displayName = BaseComponent.displayName || BaseComponent.name || 'Component'
    WithAutoWidth.displayName = `withAutoWidth(${displayName})`
    return WithAutoWidth
}
/**
 * @typedef ButtonProps
 * @memberOf Button
 * @property {string} text
 * @property {boolean} [lightButton]
 * @property {function} [onClick]
 * @property {string} [type] - fail, success, menu, reverted, disabled, other
 * @property {string} [link]
 * @property {string} [width]
 */

/**
 * Generic button component
 * @class Button
 * @category Components
 * @subcategory theme / commons
 * @param {ButtonProps} props
 * @returns {React.ReactNode}
 * @example
 * <Button text="Click here to see something awesome" onClick={handleOnClick} type="success" />
 */
const Button = (props) => {
    const { text: buttonText = DEFAULT_TEXT, lightButton, onClick, type, link, width } = props

    let buttonColor = 'green'
    if (type) {
        switch (type) {
        case ButtonTypes.FAIL: buttonColor = 'red'
            break
        case ButtonTypes.SUCCESS: buttonColor = 'green'
            break
        case ButtonTypes.MENU: buttonColor = 'white-lined'
            break
        case ButtonTypes.REVERTED: buttonColor = 'white'
            break
        case ButtonTypes.DISABLED: buttonColor = 'grey'
            break
        default: buttonColor = 'yellow'
        }
    }

    /**
     * @type {boolean}
     */
    const isDisabled = type === ButtonTypes.DISABLED

    /**
     * @type {null|string}
     */
    const href = isDisabled ? null : link

    /**
     * If disable, stop propagation, else calls onClick function
     * @type {function}
     */
    const onClickFn = isDisabled ? (e) => { e.preventDefault(); e.stopPropagation() } : onClick

    return (
        <a
            data-testid="template-button"
            className={`web-button button 
                button-${lightButton ? 'light' : 'dark'} 
                button-${buttonColor}`
            }
            href={href}
            onClick={onClickFn}
            style={{ width: width }}
        >
            <span>{buttonText}</span>
        </a>
    )
}

export default withAutoWidth(Button)
