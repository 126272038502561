import React, { useState } from 'react'
import { generateNumbersToN } from '@utils/dateTimePickerUtils'
import Dropdown from '../../../dropdown/Dropdown'
import { weekMonthDropDownData } from '../../dataUtils/staticData'
import EndsOnComponent from '../EndsOnComponent/EndsOnComponent'
import { Modal } from 'react-bootstrap'
import Button from '../../../button/Button'
import { RepeatOnComponent } from '../index'
import './customRecurrenceModel.scss'
import { cmsLanguage } from '../../../../../cms/commons/cms_language/cmsLanguage'

/**
 * @typedef CustomRecurrenceModalProps
 * @property {function} handleClose
 * @property {function} handleCustomRecurrences
 */

/**
 * @class CustomRecurrenceModal
 * @category Components
 * @subcategory theme / commons / AddEditClass
 * @param {CustomRecurrenceModalProps} props
 * @returns {React.ReactNode}
 */
export default function CustomRecurrenceModal ({
    handleClose,
    handleCustomRecurrences
}) {
    const [endsOn, setEndsOn] = useState({ type: 'Never', value: 'Never' })
    const [repeatOnVal, setRepeatOnVal] = useState(['MONDAY'])
    const [repeatEvery, setRepeatEvery] = useState()
    const [repeatEveryType, setRepeatEveryType] = useState()

    /**
     * Receives a weekday name and adds it to an array
     * @function
     * @memberOf CustomRecurrenceModal
     * @param {string} newVal
     * @returns {Array<string>}
     */
    const onRepeatChangeHandler = (newVal) => {
        const indexOfElem = repeatOnVal.indexOf(newVal)
        setRepeatOnVal((prev) => {
            if (indexOfElem !== -1) {
                const temp = [...prev]
                temp.splice(indexOfElem, 1)
                return temp
            } else {
                return [...prev, newVal]
            }
        })
    }

    /**
     * @typedef RecurrenceData
     * @property {number} repeatPeriod
     * @property {string} repeatType
     * @property {Array<string>} daysToRepeat
     * @property {boolean} [oneYear]
     * @property {string} [endingDate]
     * @property {number} [numberOfOcurrences]
     */

    /**
     * Receives a recurrence data and enhances it
     * @function
     * @memberOf CustomRecurrenceModal
     * @param {RecurrenceData} data
     */
    const prepareEndsOnData = (data) => {
        switch (endsOn.type) {
        case 'Never':
            data.oneYear = true
            break
        case 'On':
            data.endingDate = endsOn.value
            break
        case 'After':
            data.numberOfOcurrences = parseInt(endsOn.value)
            break
        default:
            break
        }
    }

    /**
     * Creates a recurrence data, enhances it and sends it to the parent using the `handleCustomRecurrences` function
     * @function
     * @memberOf CustomRecurrenceModal
     */
    const prepareDataAndSave = () => {
        const data = {}
        data.repeatPeriod = parseInt(repeatEvery)
        data.repeatType = repeatEveryType && repeatEveryType.toUpperCase()
        data.daysToRepeat = repeatOnVal
        prepareEndsOnData(data)
        handleCustomRecurrences(data)
    }
    return (
        <Modal>
            <h1 className="custom-model-title">
                {cmsLanguage.inputFields.customRecurrence}
            </h1>
            <div className="custom-model-content">
                <label className="custom-model-label">
                    <span>{cmsLanguage.inputFields.repeatEvery}</span>
                </label>
                <div className="custom-model-field">
                    <Dropdown
                        title={cmsLanguage.inputFields.numberAbbr}
                        width="small"
                        printHeader={true}
                        data={generateNumbersToN(1, 31)}
                        headerOnly={true}
                        onSelect={(value) => setRepeatEvery(value)}
                        providedValue={repeatEvery}
                    />
                    <Dropdown
                        title={cmsLanguage.inputFields.select}
                        width="small"
                        data={weekMonthDropDownData}
                        headerOnly={true}
                        onSelect={(value) => setRepeatEveryType(value)}
                        providedValue={repeatEveryType}
                    />
                </div>
            </div>

            <div className="custom-model-content">
                <label className="custom-model-label">
                    <span>{cmsLanguage.inputFields.repeatOn}</span>
                </label>
                <div className="custom-model-field">
                    <RepeatOnComponent
                        onRepeatChangeHandler={onRepeatChangeHandler}
                        selected={repeatOnVal}
                    />
                </div>
            </div>

            <div className="custom-model-content">
                <label className="custom-model-label">
                    <span>{cmsLanguage.inputFields.endsOn}</span>
                </label>
                <div className="custom-model-field">
                    <EndsOnComponent endsOn={endsOn} setEndsOn={setEndsOn} />
                </div>
            </div>
            <div className="custom-model-btnContainer">
                <Button
                    id="custom-cancel-btn"
                    text={cmsLanguage.cms.cancel}
                    lightButton={true}
                    onClick={handleClose}
                >
                    {cmsLanguage.cms.cancel}
                </Button>
                <Button
                    text={cmsLanguage.cms.done}
                    id="custom-submit-btn"
                    onClick={prepareDataAndSave}
                >
                    {cmsLanguage.cms.done}
                </Button>
            </div>
        </Modal>
    )
}
