import { CONTENT_FOOTER, CONTENT_PAGE, CONTENT_SOCIAL_MEDIA, DEFAULT_CONTENT_VARIANT, DRAFT, types } from '../types'
import { convertNameToRoute, popPathElement } from '../../utils/CommonReformatter'

import MeshContent from '../../backend/mesh/content_service/MeshContent'
import { ContentStoreMapping, setContent, setContentMenu } from './ContentActions'
import MeshContentMenu from '../../backend/mesh/content_service/MeshContentMenu'
import { setCustomNavigation } from '../../redux/actions/actions'
import { addNewGroupMenuItem } from '../cms_content/menusList'

/**
 * @module CmsActions
 * @category Redux
 * @subcategory Cms
 */

/**
 * @function
 * @inner
 * @static
 * @returns {function} - A React Redux dispatch function
 */
export const disableEditMode = () => (dispatch, getState) => {
    dispatch({
        type: types.DISABLE_EDIT_MODE
    })
}

/**
 * @function
 * @inner
 * @static
 * @returns {function} - A React Redux dispatch function
 */
export const disableAddNewTemplateMode = () => (dispatch, getState) => {
    dispatch({
        type: types.DISABLE_EDIT_TRAINING_PAGE
    })
}

/**
 * @function
 * @inner
 * @static
 * @returns {function} - A React Redux dispatch function
 */
export const toggleEditMode = () => (dispatch, getState) => {
    dispatch({
        type: types.TOGGLE_EDIT_MODE
    })
}

/**
 * @function
 * @inner
 * @static
 * @returns {function} - A React Redux dispatch function
 */
export const toggleAddNewTemplateMode = () => (dispatch, getState) => {
    dispatch({
        type: types.TOGGLE_ADD_NEW_TEMPLATE_MODE
    })
}

/**
 * @function
 * @inner
 * @static
 * @param {boolean} isTrainingPageOpen
 * @returns {function} - A React Redux dispatch function
 */
export const toggleTrainingPage = (isTrainingPageOpen) => (dispatch, getState) => {
    dispatch({
        type: types.TOGGLE_TRAINING_PAGE,
        payload: isTrainingPageOpen
    })
}

/**
 * @function
 * @inner
 * @static
 * @param {boolean} isHomePage
 * @returns {function} - A React Redux dispatch function
 */
export const setIsHomePage = (isHomePage) => (dispatch, getState) => {
    dispatch({
        type: types.IS_HOME_PAGE,
        payload: isHomePage
    })
}

/**
 * @function
 * @inner
 * @static
 * @returns {function} - A React Redux dispatch function
 */
export const deleteContent = () => async (dispatch, getState) => {
    const content = getState().content
    await MeshContent.deleteContent(content.id)
    dispatch(setContent({}))
    dispatch(setContent({}, false))
    disableEditMode()(dispatch, getState)
    disableAddNewTemplateMode()(dispatch, getState)
    dispatch(setCustomNavigation('/'))
}

// newContentMenuConfiguration is an object like this:
// const menuConfigSelectionSample = {
//     menu: {id, name, path},
//     menuItem: {
//         heading: {id, name, path},
//         group: {id, name, path},
//         page: {id, name, contentId, path}
//     },
//     pageMenu: {id, name, path, contentId}
// }
/**
 * @function
 * @inner
 * @static
 * @param {Object} newContentMenuConfiguration
 * @param {Object} newContent
 * @returns {function} - A React Redux dispatch function
 */
export const createContent = (newContentMenuConfiguration, newContent) => async (dispatch, getState) => {
    // 3. Check if its required to create a page menu
    let persistedPageMenu
    // Having contentId means that main page is already created and a new heading or menu item in the page menu will be created
    if (newContentMenuConfiguration.menuItem && newContentMenuConfiguration.menuItem.page && newContentMenuConfiguration.menuItem.page.contentId) {
        // Check if page menu already exists
        const actualContent = await MeshContent.fetchContent(newContentMenuConfiguration.menuItem.page.contentId)
        if (!actualContent.menu) {
            const newPageMenu = {
                name: actualContent.name,
                path: `${popPathElement(newContentMenuConfiguration.menuItem.page.path)}`,
                contentId: newContentMenuConfiguration.menuItem.page.contentId
            }
            persistedPageMenu = await MeshContentMenu.createMenu(newPageMenu)
            const { menuId, ...otherActualContent } = actualContent
            const actualContentUpdated = { ...otherActualContent, menuDto: persistedPageMenu }
            await MeshContent.createOrUpdateContent(actualContentUpdated, false)
        } else {
            persistedPageMenu = actualContent.menu
        }
    }

    // 4. Create the content
    const { menuId, templateIds, ...other } = newContent
    other.id = null
    other.managers = []
    other.menuDto = persistedPageMenu || null
    other.templates = newContent.templates.map(template => ({ ...template, id: null }))
    const createdContent = await MeshContent.createOrUpdateContent(other, true)

    // Possible cases for an existing page menu
    // 1. Create new page menu heading group and its menu item
    // 2. Create new page menu item
    // 3. Create new heading group with its page
    // 4. Create new page in existing heading. In a menu without heading group
    // 5. Create a menu item in either top menu or footer menu
    // Having pageMenu object, means that user has selected where to create the new content in the pageMenu
    let newMenuItemToBePersisted
    let newPath
    let newMenuItemToRedirectTo
    if (newContentMenuConfiguration.pageMenu) {
        // If pageMenu.id == addNewGroupMenuItem.id it means that new heading group will be created in the page menu
        if (newContentMenuConfiguration.pageMenu.id === addNewGroupMenuItem.id) {
            newPath = `${persistedPageMenu.path}/${convertNameToRoute(newContentMenuConfiguration.pageMenu.name)}/${convertNameToRoute(newContent.name)}`
            newMenuItemToRedirectTo = {
                contentId: createdContent.id,
                name: newContent.name,
                path: newPath,
                main: false
            }
            newMenuItemToBePersisted = [{
                contentId: null,
                name: newContentMenuConfiguration.pageMenu.name,
                parentId: persistedPageMenu.id,
                path: `${persistedPageMenu.path}/${convertNameToRoute(newContentMenuConfiguration.pageMenu.name)}`,
                children: [newMenuItemToRedirectTo]
            }]
        } else {
            // Here the new menuItem will be added in the current heading group of the page menu
            newPath = `${newContentMenuConfiguration.pageMenu.path}/${convertNameToRoute(newContent.name)}`
            newMenuItemToRedirectTo = {
                contentId: createdContent.id,
                name: newContent.name,
                parentId: newContentMenuConfiguration.pageMenu.id,
                path: newPath
            }
            newMenuItemToBePersisted = newMenuItemToRedirectTo
        }
    // Main page was already created and the user did not select any place where to create the new content. So it will be created as a simple page menu item
    } else if (newContentMenuConfiguration.menuItem && newContentMenuConfiguration.menuItem.page && newContentMenuConfiguration.menuItem.page.contentId) {
        newPath = `${persistedPageMenu.path}/${convertNameToRoute(newContent.name)}`
        newMenuItemToRedirectTo = {
            contentId: createdContent.id,
            name: newContent.name,
            parentId: persistedPageMenu.id,
            path: newPath
        }
        newMenuItemToBePersisted = newMenuItemToRedirectTo
    } else if (newContentMenuConfiguration.menuItem) {
    // New menu item will be created in global/header menu
    // Possible cases:
    // 1. Create new heading group with its new menu item
    // 2. Create the new menu item in an existing heading group
    // 3. Crete new menu item directly under a heading. No heading groups involved here
        if (newContentMenuConfiguration.menuItem.group) {
            if (newContentMenuConfiguration.menuItem.group.id === addNewGroupMenuItem.id) {
                newPath = `${newContentMenuConfiguration.menuItem.heading.path}/${convertNameToRoute(newContentMenuConfiguration.menuItem.group.name)}/${convertNameToRoute(newContent.name)}`
                newMenuItemToBePersisted = [{
                    name: newContentMenuConfiguration.menuItem.group.name,
                    parentId: newContentMenuConfiguration.menuItem.heading.id,
                    path: `${newContentMenuConfiguration.menuItem.heading.path}/${convertNameToRoute(newContentMenuConfiguration.menuItem.group.name)}`,
                    children: [{
                        contentId: createdContent.id,
                        name: newContent.name,
                        path: newPath,
                        main: false
                    }]
                }]
            } else {
                newPath = `${newContentMenuConfiguration.menuItem.group.path}/${convertNameToRoute(newContent.name)}`
                newMenuItemToBePersisted = {
                    contentId: createdContent.id,
                    name: newContent.name,
                    parentId: newContentMenuConfiguration.menuItem.group.id,
                    path: newPath
                }
            }
        } else if (newContentMenuConfiguration.menuItem.heading) {
            newPath = `${newContentMenuConfiguration.menuItem.heading.path}/${convertNameToRoute(newContent.name)}`
            newMenuItemToBePersisted = {
                contentId: createdContent.id,
                name: newContent.name,
                parentId: newContentMenuConfiguration.menuItem.heading.id,
                path: newPath
            }
        }
        newPath = popPathElement(newPath)
    // New menu item will be created in either top or footer
    } else if (newContentMenuConfiguration.menu) {
        newPath = `${newContentMenuConfiguration.menu.path}/${convertNameToRoute(newContent.name)}`
        newMenuItemToBePersisted = {
            contentId: createdContent.id,
            name: newContent.name,
            parentId: newContentMenuConfiguration.menu.id,
            path: newPath
        }
        newPath = popPathElement(newPath)
    }
    await MeshContentMenu.createMenu(newMenuItemToBePersisted)
    // Redirect preparation.
    createdContent.menuDto?.id && dispatch(setContentMenu(newMenuItemToRedirectTo, false))
    dispatch(setCustomNavigation('/' + newPath))
}

/**
 * @function
 * @inner
 * @static
 * @param {Object} newContentMenuConfiguration
 * @returns {function} - A React Redux dispatch function
 */
export const setNewContentMenuConfiguration = newContentMenuConfiguration => (dispatch, getState) => {
    dispatch({
        type: types.SET_NEW_CONTENT_MENU_CONFIGURATION,
        payload: newContentMenuConfiguration
    })
}

/**
 * @function
 * @inner
 * @static
 * @param {string} variant
 * @returns {function} - A React Redux dispatch function
 */
export const persistCurrentContent = (variant = DEFAULT_CONTENT_VARIANT) => async (dispatch, getState) => {
    const contentDB = { ...getState()[ContentStoreMapping[variant]] }
    const content = { ...getState()[ContentStoreMapping[variant].concat(DRAFT)] }
    if (variant === CONTENT_PAGE) {
        const { templates, menuId, menu, ...otherContent } = content
        const updatedTemplates = templates.map(template => ({ ...template, id: template.isNew ? null : template.id }))

        const newContent = {
            ...otherContent,
            templates: updatedTemplates
        }
        if (content.menu) {
            newContent.menuDto = content.menu
        }
        Object.assign(content, newContent)
    }

    const persistedContent = await MeshContent.createOrUpdateContent(content, false)
    if (variant === CONTENT_PAGE) {
        persistedContent.menu = content.menu
        persistedContent.menuId = content.menu?.id
    }

    dispatch(setContent({ ...persistedContent, eventGroupId: contentDB?.eventGroupId }, false, variant))
}

/**
 * @function
 * @inner
 * @static
 * @param {string} variant
 * @returns {function} - A React Redux dispatch function
 */
export const restoreDefaultFooterValues = (variant) => (dispatch, getState) => {
    const footerContent = getState().footerContent
    setContent(footerContent, false, CONTENT_FOOTER)(dispatch, getState)
}

/**
 * @function
 * @inner
 * @static
 * @returns {function} - A React Redux dispatch function
 */
export const persistCurrentFooterContent = () => async (dispatch, getState) => {
    const footerContent = { ...getState().footerDraft }

    const persistedFooterContent = await MeshContent.createOrUpdateContent(footerContent, false)

    dispatch(setContent(persistedFooterContent, false, CONTENT_FOOTER))
}

/**
 * @function
 * @inner
 * @static
 * @returns {function} - A React Redux dispatch function
 */
export const restoreDefaultSocialMediaValues = () => (dispatch, getState) => {
    setContent(getState().socialMedia, false, CONTENT_SOCIAL_MEDIA)(dispatch, getState)
}

/**
 * @function
 * @inner
 * @static
 * @returns {function} - A React Redux dispatch function
 */
export const persistCurrentSocialMediaContent = () => async (dispatch, getState) => {
    const socialMediaContent = { ...getState().socialMediaDraft }

    const persistedSocialMediaContent = await MeshContent.createOrUpdateContent(socialMediaContent, false)

    dispatch(setContent(persistedSocialMediaContent, false, CONTENT_SOCIAL_MEDIA))
}

/**
 * @function
 * @inner
 * @static
 * @param {Array<Product>} products
 * @returns {function} - A React Redux dispatch function
 */
export const addMultipleProduct = products => (dispatch, getState) => {
    dispatch({
        type: types.SET_PRODUCTS,
        payload: products
    })
}

/**
 * @function
 * @inner
 * @static
 * @param {Product} product
 * @returns {function} - A React Redux dispatch function
 */
export const addProduct = product => (dispatch, getState) => {
    dispatch({
        type: types.SET_PRODUCT,
        payload: product
    })
}

/**
 * @function
 * @inner
 * @static
 * @param {Product} product
 * @returns {function} - A React Redux dispatch function
 */
export const updateProduct = product => (dispatch, getState) => {
    dispatch({
        type: types.UPDATE_PRODUCT,
        payload: product
    })
}
