import React from 'react'
import './AdminButton.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

const AdminButton = (props) => {
    const { text, icon, onClick, type, color, tooltip, style, elementType = '' } = props
    const dataTestId = props['data-testid']

    // VALID COLORS: red, green, blue, black
    const addColor = color || 'black'

    let colorButton
    let styleButton

    switch (type) {
    case 'border':
        colorButton = addColor
        styleButton = 'border'
        break
    case 'solid':
        colorButton = addColor
        styleButton = 'solid'
        break
    case 'border-solid':
        colorButton = addColor
        styleButton = 'border-solid'
        break
    case 'disable':
        colorButton = 'grey'
        styleButton = 'disable'
        break
    case 'disable-dark':
        colorButton = 'dark-grey'
        styleButton = 'disable'
        break
    default:
        colorButton = 'white'
        styleButton = 'border'
    }

    const handleOnClick = () => {
        type !== 'disable' && type !== 'disable-dark' && onClick()
    }

    const buttonContent = (
        <button
            data-testid={dataTestId}
            data-text={text ? 'filled' : 'empty'}
            className={`admin-button ${
                text ? '' : 'admin-button-icon'
            } ${styleButton}-${colorButton} ${elementType}-button`}
            onClick={handleOnClick}
            style={style}
        >
            {text && (
                <span
                    className={`${icon && 'admin-button-text-icon'}`}
                    data-testid={`${dataTestId}-text`}
                >
                    {text}
                </span>
            )}
            {icon && <FontAwesomeIcon data-testid={`${dataTestId}-icon`} icon={icon} />}
        </button>
    )

    return tooltip
        ? (
            <OverlayTrigger
                overlay={
                    <Tooltip
                        id={`${type}-tooltip`}
                        className="admin-button-tooltip"
                        data-testid={`${dataTestId}-tooltip`}
                    >
                        {tooltip}
                    </Tooltip>
                }
            >
                <div data-testid={`${dataTestId}-hover-tooltip`}>{buttonContent}</div>
            </OverlayTrigger>
        )
        : (
            buttonContent
        )
}

export default AdminButton
