// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".gender-radio-input{display:flex;flex-direction:row;padding:.625em;gap:.9375em}.gender-radio-input__radio{display:flex;flex-direction:row;align-items:center;gap:.3125em}.gender-radio-input__radio__circle{display:none}.gender-radio-input__radio__label{margin-bottom:0}.gender-radio-input input[type=\"radio\"]{-webkit-appearance:none;display:inline-block;width:1.25em;height:1.25em;padding:6px;background-clip:content-box;border:1px solid grey;background-color:white;border-radius:50%}.gender-radio-input input[type='radio']:checked{border:1px solid #64b42d;background-color:#64b42d}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
