import { reducedElementsPro } from '../../utils/elementsUtils'

/**
 * @module adaptCourseData
 * @category Components
 * @subcategory Templates / CourseInfo
 * @param courseData
 * @param isTemplate1
 * @returns {{trainers: Array<*>, classes: Array<*>}}
 */
const adaptCourseData = (courseTemplate, isTemplate1 = false) => {
    const trainersList = []
    let defaultCourseData = {}

    const courseData = courseTemplate?.elements.map((element) => {
        const response = reducedElementsPro(element, courseTemplate.fields)
        response.metadata = courseTemplate.metadata?.eventsGroupId
        return response
    })

    courseData.forEach((courseElement, index) => {
        switch (courseElement.element.type) {
        case 'trainer':
            isTemplate1
                ? trainersList.push(
                    { trainer: courseElement.SHORT_TEXT_1, image: courseElement.PHOTO || '', id: index }
                )
                : trainersList.push(courseElement.SHORT_TEXT_1)
            break
        case 'default':
            defaultCourseData = {
                date: courseElement.DATE !== '' ? courseElement.DATE : undefined,
                time: courseElement.TIME,
                duration: courseElement.NUMBER,
                durationUnit: courseElement.DROPDOWN,
                location: courseElement.SHORT_TEXT,
                description: courseElement.TEXT_EDITOR,
                shortDescription: courseElement.TEXT_EDITOR_2,
                price: courseElement.PRICE,
                currency: courseElement.DROPDOWN_1,
                eventsGroupId: courseElement.metadata,
                repeatPeriod: courseElement.NUMBER_1, // From content
                repeatType: courseElement.DROPDOWN_2?.toUpperCase(), // From content
                daysToRepeat: [
                    courseElement.CHECK_BOX_1, // Monday
                    courseElement.CHECK_BOX_2, // Tuesday
                    courseElement.CHECK_BOX_3, // Wednesday
                    courseElement.CHECK_BOX_4, // Thursday
                    courseElement.CHECK_BOX_5, // Friday
                    courseElement.CHECK_BOX_6, // Saturday
                    courseElement.CHECK_BOX_7 // Sunday
                ].map((isSelected, i) => isSelected ? weekDaysMap[i] : '').filter(it => it !== ''),
                endingDate: courseElement.DATE_1 !== '' ? courseElement.DATE_1 : undefined // From content
            }
            break
        default:
            return {}
        }
    })

    return {
        ...defaultCourseData,
        trainers: trainersList
    }
}

const weekDaysMap = {
    0: 'MONDAY',
    1: 'TUESDAY',
    2: 'WEDNESDAY',
    3: 'THURSDAY',
    4: 'FRIDAY',
    5: 'SATURDAY',
    6: 'SUNDAY'
}

export default adaptCourseData
