import { compose } from 'redux'
import { connect } from 'react-redux'
import Header from './Header'

/**
 * {@link Header} container that binds the Header with the {@link module:reduxSetup}
 * @class HeaderContainer
 * @category Containers
 * @subcategory Header
 *
 * @mermaid
 *   sequenceDiagram
 *   Parent->>HeaderContainer: Request the Header via HeaderContainer.
 *   Note right of HeaderContainer: HeaderContainer gets information from redux store.
 *   HeaderContainer->>Header: HeaderContainer sends redux store information to Header as props.
 *   Header-->>Parent: Header is rendered in the parent component.
 */

/**
 * Gets the `content.menu` from redux state and makes available to the {@link Header} as a prop called `contentMenu`.
 * @function
 * @memberOf HeaderContainer
 * @alias mapStateToProps
 * @param {Object} state - redux state
 * @returns {Object}
 */
const mapStateToProps = (state) => {
    const { content } = state
    return {
        contentMenu: content.menu
    }
}

export default compose(connect(mapStateToProps, null))(
    Header
)
