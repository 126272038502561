import React, { useEffect, useState } from 'react'
import './modal.scss'
import { cmsLanguage } from '../cms/commons/cms_language/cmsLanguage'
import Modal from 'react-bootstrap/Modal'
import { Button } from 'react-bootstrap'
import STORAGE_KEYS from '../utils/storageKeys'
import { getInitialTACValues } from '../utils/modalTermsAndConditions'

const TermsModal = (props) => {
    const initialTACValues = getInitialTACValues()
    const setCustomNavigation = props.setCustomNavigation
    const [isConsentSaved, setIsConsentSaved] = useState(initialTACValues)
    const [isModalVisible, setIsModalVisible] = useState(true)
    const [shouldRefresh, setShouldRefresh] = useState(false)

    const isTACS = (localStorage.getItem(STORAGE_KEYS.IS_TERMS_AND_CONDITIONS_ACCEPTED))

    useEffect(() => {
        const saved = (isTACS || '{}') !== '{}'
        if (saved) {
            setIsConsentSaved(JSON.parse(isTACS))
        } else {
            setIsConsentSaved(initialTACValues)
        }
        setIsModalVisible(!saved)
    }, [])
    if (shouldRefresh && !isModalVisible) {
        setShouldRefresh(false)
    }

    const handleToggle = (id) => () => {
        // e.currentTarget.id
        const newIsConsentSaved = { ...isConsentSaved }
        newIsConsentSaved[id] = !isConsentSaved[id]
        setIsConsentSaved(newIsConsentSaved)
    }

    const handleSave = () => {
        localStorage.setItem(STORAGE_KEYS.IS_TERMS_AND_CONDITIONS_ACCEPTED, JSON.stringify(isConsentSaved))
        setIsModalVisible(false)
        if (isTACS === '') {
            setShouldRefresh(true)
        }
        setCustomNavigation(window.location.pathname.concat(window.location.search))
    }

    if (!isModalVisible) return null

    return (<Modal show={isModalVisible} centered>
        <Modal.Header>
            <Modal.Title style={{ textAlign: 'center w-100', margin: 'auto' }}>
                {cmsLanguage.termsAndConditions.title}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h3>{cmsLanguage.termsAndConditions.content.introduction.content.title}</h3>
            <p>{cmsLanguage.termsAndConditions.content.introduction.content.description}</p>
            <h3>{cmsLanguage.termsAndConditions.content.dataProtectionAndTools.title}</h3>
            <p>{cmsLanguage.termsAndConditions.content.dataProtectionAndTools.content.description}</p>
            <ul>
                {cmsLanguage.termsAndConditions.content.dataProtectionAndTools.content.list.map((item, index) => (
                    <div key={index} className="terms-container">
                        <div key={index} className="terms-block">
                            <li key={index}>
                                <strong>{item.title}</strong> {item.content.description}
                            </li>
                        </div>
                        <div className="button-block">
                            <label className="switch">
                                <input role={ 'checkbox'.concat('-').concat(item.id) }
                                    type="checkbox"
                                    checked={ isConsentSaved[item.id] }
                                    onChange={handleToggle(item.id)}
                                />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>))}
            </ul>
            <h3>{cmsLanguage.termsAndConditions.content.consentManagement.title}</h3>
            <p>{cmsLanguage.termsAndConditions.content.consentManagement.content.description}</p>
            <h3>{cmsLanguage.termsAndConditions.content.disclaimer.title}</h3>
            <p>{cmsLanguage.termsAndConditions.content.disclaimer.content.description}</p>
            <h3>{cmsLanguage.termsAndConditions.content.updates.title}</h3>
            <p>{cmsLanguage.termsAndConditions.content.updates.content.description}</p>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="success" onClick={handleSave}>
                {cmsLanguage.termsAndConditions.save}
            </Button>
        </Modal.Footer>
    </Modal>)
}

export default TermsModal
