import { padZero } from '@utils/dateTimePickerUtils'

export function calculateFrom (item) {
    return `${padZero(item.startDateTime.getHours())}:${padZero(
        item.startDateTime.getMinutes()
    )}`
}

export function calculateTo (item) {
    return `${padZero(item.endDateTime.getHours())}:${padZero(
        item.endDateTime.getMinutes()
    )}`
}
