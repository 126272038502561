// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".course-area{display:flex;flex-direction:column;background-color:transparent;width:100%}.course-area__table{display:flex;flex-direction:row;width:100%}.course-area__empty{padding:.9375em}.course-area__column{display:flex;flex-direction:column;flex-grow:1}.column__label{color:#191919;font-weight:bold;border-bottom:1px solid rgba(0,0,0,0.1)}.column__item{min-height:3.75em;padding-left:.9375em;padding-right:.9375em;display:flex;align-items:center;border-bottom:1px solid rgba(0,0,0,0.1)}.column__item--row:first-child{background-color:transparent}.column__item--row:nth-child(2n+1){background-color:white}.folder-open{margin-right:.3125em}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
