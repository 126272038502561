import React, { useEffect, useRef, useState } from 'react'
import { faCaretDown, faCaretUp, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './Dropdown.scss'
import DropDownList from '../dropdownList/DropDownList'
import useSearch from './useSearch'
import useOutsideClick from './useOutsideClick'
import wrapErrorComponent from '../errorHOC/ErrorHoc'

/**
 * @typedef DropdownProps
 * @memberOf Dropdown
 * @property {string} title
 * @property {Array<Menu>} data
 * @property {boolean} [headerOnly] - if true then child dropdown will have only header info, without content
 * @property {function} onSelect
 * @property {boolean} [printHeader] - if true then child dropdown will have header info
 * @property {('medium'|'large'|'small'|'auto')} [width]
 * @property {string} [providedValue]
 * @property {boolean} [disabled]
 * @property {boolean} [isRounded]
 * @property {string} [data-testid]
 * @property {string} [arrowColor]
 */

/**
 * Modal to get the confirmation before deleting a content
 * @class Dropdown
 * @category Components
 * @subcategory theme / commons
 * @param {DropdownProps} props
 * @returns {React.ReactNode}
 * @example
 * <Dropdown
 *   title="Select the course"
 *   data={[{...},{...}]}
 *   headerOnly={false}
 *   onSelect={handleOnSelect}
 *   printHeader={true}
 * />
 */
function Dropdown ({
    title,
    data = [],
    headerOnly = false,
    onSelect,
    printHeader = true,
    width = 'medium',
    providedValue = '',
    disabled = false,
    isRounded = false,
    'data-testid': testId = 'dropdown',
    arrowColor = ''
}) {
    const [dropDownOpen, setDropDownOpen] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [value, setValue] = useState(providedValue)
    const wrapperRef = useRef(null)
    const searchResult = useSearch(
        searchQuery,
        JSON.parse(JSON.stringify(data)),
        headerOnly
    )
    const inputRef = useRef()
    useEffect(() => {
        setSearchQuery(providedValue)
        setValue(providedValue)
    }, [providedValue])

    /**
     * Sets the Dropdown open property to false.
     * @function
     * @memberOf Dropdown
     */
    const outFocusWrapup = () => { setDropDownOpen(false) }

    useOutsideClick(wrapperRef, outFocusWrapup, 'dropdownInputContainer')

    /**
     * When clicks, set the value, set the search query, calls the onSelect function and closes the Dropdown
     * @function
     * @memberOf Dropdown
     * @param {Object} event - HTML event object
     */
    const onClickHandler = (event) => {
        const { target } = event
        const { className } = target
        const value = target.getAttribute('value')
        const id = target.getAttribute('data-id')
        const contentId = target.getAttribute('content-id')
        event.stopPropagation()
        if (
            target.id !== 'dropDownList' &&
            (headerOnly || target.id !== 'dropDownHeader') &&
            !className.includes('dropdown-item-list-disabled')
        ) {
            setValue(value)
            setSearchQuery(value)
            onSelect(value, id, contentId)
            setDropDownOpen(false)
        }
    }

    /**
     *  When searches, set the query value and resets the Dropdown state
     * @function
     * @memberOf Dropdown
     * @param {Object} event - HTML event object
     */
    const onSearch = (event) => {
        setValue('')
        setSearchQuery(event.target.value)
        if (!dropDownOpen) {
            setDropDownOpen(!dropDownOpen)
        }
    }

    /**
     * On press enter select the only option if available
     * @function
     * @memberOf Dropdown
     * @param {Object} event - HTML event key press, with the charCode
     */
    const handleKeyPress = (event) => {
        // On press enter select the only option if available
        if (event.charCode === 13) {
            const selectedMenu = data.filter(it => it.name.toLowerCase().includes(searchQuery.toLowerCase()))
            if (selectedMenu?.length === 1) {
                onSelect(selectedMenu[0].name, selectedMenu[0].id, selectedMenu[0].contentId)
                setSearchQuery(selectedMenu[0].name)
                setDropDownOpen(false)
            }
        }
    }

    /**
     * @function
     * @memberOf Dropdown
     * @param event - HTML event
     */
    const changeDropDownToggle = (event) => {
        if (disabled) return
        event.target.getAttribute('data-id') !== 'dropDownInput' &&
        setDropDownOpen(!dropDownOpen)
    }

    /**
     * Opens the dropdown when it receives the focus, and it is not opened
     * @function
     * @memberOf Dropdown
     * @param event - HTML event
     */
    const inputOnFocus = (event) => {
        if (disabled) return
        event.target.select()
        !dropDownOpen && setDropDownOpen(true)
    }

    /**
     * Receives a class name and enhance it based on width
     * @function
     * @memberOf Dropdown
     * @param {string} className
     * @returns {string}
     */
    const setDropdownWidth = (className) => {
        switch (width) {
        case 'medium':
            className += ' dropdown-width-medium'
            break
        case 'large':
            className += ' dropdown-width-large'
            break
        case 'small':
            className += ' dropdown-width-small'
            break
        case 'auto':
            break
        default:
            className += ' dropdown-width-medium'
            break
        }
        return className
    }

    /**
     * Prepares the class name based on the width and the received props (isRounded, disabled and dropDownOpen)
     * @function
     * @memberOf Dropdown
     * @returns {string}
     */
    const getInputClassName = () => {
        let className = ''
        if (isRounded) {
            className = `dropdown-input-rounded ${
                value ? 'dropdown-input-rounded-selected' : ''
            } `
        } else {
            className = `dropdown-input ${
                value ? 'dropdown-input-selected' : ''
            } `
        }

        if (dropDownOpen && isRounded) {
            className += ' dropdown-opened-style'
        }
        className += `${disabled ? ' dropdown-input-disabled' : ''}`

        return setDropdownWidth(className)
    }

    /**
     * Receives a class name and enhance it based on the arrow color property
     * @function
     * @memberOf Dropdown
     * @param {string} className
     * @returns {string}
     */
    const getArrowClassName = (className) => {
        if (arrowColor === 'white') {
            if (dropDownOpen) {
                className = 'social-icon-black dropdown-arrow-black'
            } else {
                className = 'social-icon-white dropdown-arrow-white'
            }
        } else {
            className = 'social-icon dropdown-arrow'
        }
        return className
    }

    return (
        <div className="dropdown-container-wrapper" data-testid={testId}>
            <div
                className={setDropdownWidth('dropdown-container-space')}
                id="dropdownInputContainer"
            >
                <div className="dropdown-container" onClick={changeDropDownToggle}
                    data-testid="dropdown-container">
                    <FontAwesomeIcon
                        id="dropDownIcon"
                        className={getArrowClassName('')}
                        icon={dropDownOpen ? (isRounded ? faChevronUp : faCaretUp) : (isRounded ? faChevronDown : faCaretDown)}
                    />
                    <input
                        onKeyPress={handleKeyPress}
                        data-id="dropDownInput"
                        id={`dropdown-${data[0]?.name}`}
                        data-testid={`${testId}-input`}
                        ref={inputRef}
                        autoComplete="off"
                        onClick={inputOnFocus}
                        value={searchQuery || value}
                        onChange={onSearch}
                        disabled={disabled ? 'disabled' : ''}
                        className={getInputClassName()}
                        placeholder={title}
                    />
                </div>
            </div>
            {dropDownOpen && (
                <div
                    ref={wrapperRef}
                    className={setDropdownWidth('dropdown-items-container scroll')}
                    id="dropDownList"
                    onClick={onClickHandler}
                    data-testid="div-dropdown"
                >
                    <DropDownList
                        data-testid={`${testId}-list`}
                        data={data}
                        setDropdownWidth={setDropdownWidth}
                        searchData={searchResult}
                        printHeader={printHeader}
                        headerOnly={headerOnly}
                        selected={value}
                    />
                </div>
            )}
        </div>
    )
}

export default wrapErrorComponent(Dropdown)
