
import { IDS } from './components/second-step/utils/inputFieldsList'
import { getCountryCode, getPhone } from '../../utils/phoneUtils'
import UserDataRequest from '../../backend/mesh/domain/UserDataRequest'
const mergeFormWithUserData = (formUserData, userData) => {
    const { address: formUserDataAddress, paymentData: formUserDataPaymentData, ...otherFormUserData } = formUserData
    const userDataToMerge = userData || { address: {}, paymentData: {} }
    return new UserDataRequest({
        ...userData,
        ...otherFormUserData,
        address: {
            ...userDataToMerge?.address,
            ...formUserDataAddress
        },
        paymentData: {
            ...formUserDataPaymentData,
            id: userData?.paymentData?.id
        }
    })
}

export const adaptFormToUserData = (form, userData) => {
    const phone = form[IDS.PHONE]
    return {
        firstName: form[IDS.FIRSTNAME],
        lastName: form[IDS.FAMILY_NAME],
        email: form[IDS.EMAIL],
        contactNumber: getPhone(phone),
        countryCode: getCountryCode(phone),
        memberSince: form[IDS.DATE],
        address: {
            streetNumber: form[IDS.STREET_ADDRESS],
            postCode: form[IDS.POSTCODE],
            city: form[IDS.CITY]
        },
        paymentData: {
            iban: form[IDS.IBAN],
            bic: form[IDS.BIC],
            holderName: form[IDS.ACC_HOLDER],
            bank: form[IDS.BANK]
        },
        birthDate: form[IDS.BIRTHDAY],
        gender: form[IDS.GENDER]
    }
}

export default mergeFormWithUserData
