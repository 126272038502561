import React from 'react'
import './CmsModalTitle.scss'

/**
 * @typedef CmsModalTitleProps
 * @memberOf CmsModalTitle
 * @property {string} title
 * @property {string} [detail]
 */

/**
 * Creates a header for the modals, with a title and a subtitle for details
 * @class CmsModalTitle
 * @category Components
 * @subcategory cms / commons
 * @param {CmsModalTitleProps} props
 * @returns {React.ReactNode}
 * @example
 * <CmsModalTitle title="Modal header" onClose={handleModalClose} />
 */
const CmsModalTitle = ({ title, detail }) => {
    return (
        <div className="cms-modal-title-headline">
            <h1 data-testid={'cms-modal-title-headline-main'}>{title}</h1>
            {detail && (
                <h4 data-testid={'cms-modal-title-headline-detail'}>
                    - {detail}
                </h4>
            )}
        </div>
    )
}

export default CmsModalTitle
