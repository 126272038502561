import { v4 as uuidv4 } from 'uuid'
import { cmsLanguage } from '../../../commons/cms_language/cmsLanguage'

const courseInfo = () => {
    const trainerInfo = () => {
        return [
            {
                name: 'name',
                type: 'SHORT_TEXT_1',
                id: uuidv4(),
                isMandatory: true
            },
            {
                name: 'picture',
                type: 'PHOTO',
                id: uuidv4(),
                isMandatory: false
            }
        ]
    }

    const fields = [
        {
            name: 'date',
            type: 'DATE',
            id: uuidv4(),
            isMandatory: false
        }, {
            name: 'time',
            type: 'TIME',
            id: uuidv4(),
            isMandatory: true
        }, {
            name: 'classDuration',
            type: 'NUMBER',
            id: uuidv4(),
            isMandatory: true
        }, {
            name: 'unit',
            type: 'DROPDOWN',
            id: uuidv4(),
            isMandatory: true
        }, {
            name: 'monday',
            type: 'CHECK_BOX_1',
            id: uuidv4(),
            isMandatory: false
        }, {
            name: 'tuesday',
            type: 'CHECK_BOX_2',
            id: uuidv4(),
            isMandatory: false
        }, {
            name: 'wednesday',
            type: 'CHECK_BOX_3',
            id: uuidv4(),
            isMandatory: false
        }, {
            name: 'thursday',
            type: 'CHECK_BOX_4',
            id: uuidv4(),
            isMandatory: false
        }, {
            name: 'friday',
            type: 'CHECK_BOX_5',
            id: uuidv4(),
            isMandatory: false
        }, {
            name: 'saturday',
            type: 'CHECK_BOX_6',
            id: uuidv4(),
            isMandatory: false
        }, {
            name: 'sunday',
            type: 'CHECK_BOX_7',
            id: uuidv4(),
            isMandatory: false
        }, {
            name: 'price',
            type: 'PRICE',
            id: uuidv4(),
            isMandatory: true
        }, {
            name: 'currency',
            type: 'DROPDOWN_1',
            id: uuidv4(),
            isMandatory: true
        }, {
            name: 'repeatPeriod',
            type: 'NUMBER_1',
            id: uuidv4(),
            isMandatory: true
        }, {
            name: 'repeat',
            type: 'DROPDOWN_2',
            id: uuidv4(),
            isMandatory: true
        }, {
            name: 'endingDate',
            type: 'DATE_1',
            id: uuidv4(),
            isMandatory: false
        }, {
            name: 'location',
            type: 'SHORT_TEXT',
            id: uuidv4(),
            isMandatory: true
        }, {
            name: 'description',
            type: 'TEXT_EDITOR',
            id: uuidv4(),
            isMandatory: false
        }, {
            name: 'shortDescription',
            type: 'TEXT_EDITOR_2',
            id: uuidv4(),
            isMandatory: false
        }
    ]

    const trainerFields = trainerInfo()
    const concatenatedFields = [...fields, ...trainerFields]

    const generateValues = (fields) => {
        return fields.map(field => {
            return {
                field: field,
                fieldId: field.id,
                id: uuidv4(),
                value: ''
            }
        })
    }

    return {
        id: uuidv4(),
        name: cmsLanguage.templatesNames.courseInfo,
        type: 'COURSE',
        fields: concatenatedFields,
        elements: [
            {
                id: uuidv4(),
                values: generateValues(fields),
                type: 'default'
            },
            {
                id: uuidv4(),
                values: generateValues(trainerFields),
                type: 'trainer'
            }
        ]
    }
}

export const COURSE_TEMPLATE_ORDER = {
    DATE: 0,
    TIME: 1,
    NUMBER: 2,
    DROPDOWN: 3,
    CHECK_BOX_1: 4,
    CHECK_BOX_2: 5,
    CHECK_BOX_3: 6,
    CHECK_BOX_4: 7,
    CHECK_BOX_5: 8,
    CHECK_BOX_6: 9,
    CHECK_BOX_7: 10,
    PRICE: 11,
    DROPDOWN_1: 12,
    NUMBER_1: 13,
    DROPDOWN_2: 14,
    DATE_1: 15,
    SHORT_TEXT: 16,
    TEXT_EDITOR_2: 17,
    TEXT_EDITOR: 18,
    SHORT_TEXT_1: 19,
    PHOTO: 20
}

export default courseInfo
