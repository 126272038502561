import React from 'react'
import Avatar from '../../../../theme/commons/Avatar/Avatar'
import './trainerComponent.scss'
import { cmsLanguage } from '../../../../cms/commons/cms_language/cmsLanguage'

/**
 * @typedef TrainerComponentProps
 * @memberOf TrainerComponent
 * @property {string} trainer
 * @property {string} image
 * @property {boolean} noTitle
 * @property {'small'|'medium'|'large'} size
 */

/**
 * Application Trainer component, to show the name with the image.
 * @class TrainerComponent
 * @category Components
 * @subcategory Pages / trainingSchedule
 * @param {TrainerComponentProps} props
 * @returns {React.ReactNode}
 * @example
 * <TrainerComponent trainer={trainer} image={image} />
 */
export default function TrainerComponent ({ trainer, image, noTitle, size }) {
    return (
        <>
            {!!trainer &&
                <div className="trainer-component">
                    <Avatar src={image} size={size || null} />
                    <div>
                        {noTitle
                            ? null
                            : <label className="class-modal-name">
                                <span>{cmsLanguage.inputFields.trainer}</span>
                            </label>
                        }
                        <span className="class-modal-value">{Array.isArray(trainer) ? trainer.join(' / ') : trainer}</span>
                    </div>
                </div>}
        </>
    )
}
