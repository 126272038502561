import React from 'react'
import './AccordionTemplate.scss'
import AccordionItem from './components/accordion_item/AccordionItem'
import { Accordion } from 'react-bootstrap'

/**
 * @typedef AccordionTemplateProps
 * @memberOf AccordionTemplate
 * @property {Object} accordionContent
 */

/**
 * Accordion Template component.
 * @class AccordionTemplate
 * @category Components
 * @subcategory Templates / Accordion
 * @param {AccordionTemplateProps} props
 * @returns {React.ReactNode}
 * @example
 * <AccordionTemplate accordionContent={accordionContent} />
 */
const AccordionTemplate = (props) => {
    const { accordionContent } = props

    return (
        <div className="accordion-full-container vertical-website-padding horizontal-website-padding">
            <Accordion className="accordion-container">
                {accordionContent.map((item, index) => {
                    return (
                        <AccordionItem
                            key={index}
                            id={index + 1}
                            title={item.TEXT}
                            text={item.TEXT_EDITOR}
                        />
                    )
                })}
            </Accordion>
        </div>
    )
}

export default AccordionTemplate
