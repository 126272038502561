import { useEffect, useState } from 'react'
import { EMPTY_COURSES } from './mockCoursesData'
import MeshEventsGroup from '../../../../backend/mesh/calendar_service/MeshEventsGroup'
import adaptEventsGroupsResponseToTable from './adaptEventsGroupsToTable'
import MeshWallets from '../../../../backend/mesh/wallets_service/MeshWallets'
import MeshEventSchedule from '../../../../backend/mesh/calendar_service/MeshEventSchedule'
import { map, sortBy } from 'lodash'
import { trackPromise } from 'react-promise-tracker'

/**
 * Gets the eventGroup from the `calendar-service` by the `organizationID` from the user ({@link EventsGroup}.
 * @function
 * @category Hooks
 * @subcategory Templates / CourseInfo
 * @returns {Array<Object>} - coursesData
 * @example
 * const coursesData = useCoursesData()
 */
const useCoursesData = () => {
    const [coursesData, setCoursesData] = useState(EMPTY_COURSES())

    useEffect(() => {
        let isCancelled = false
        const getCoursesData = async () => {
            trackPromise(MeshEventsGroup.getMyCourses()).then((result) => {
                if (result.data && result.data.length > 0) {
                    const listOfScheduleStartDate = []
                    result.data.forEach(async (eventsGroup) => {
                        const eventsGroupId = eventsGroup.id
                        const schedule = {
                            id: null,
                            startDateTime: '1970-01-01T00:00:00+00:00',
                            endDateTime: '1970-01-01T00:00:00+00:00',
                            eventsGroupId,
                            coach: [],
                            place: '',
                            description: '',
                            recurrence: null
                        }
                        try {
                            // search schedule by eventsGroupId
                            const scheduleList = await trackPromise(MeshEventSchedule.searchScheduleByField(schedule))
                            // get earliest date
                            const startDate = {}
                            const { name } = eventsGroup
                            startDate[name] = map(
                                sortBy(scheduleList.data, schedule => Date.parse(schedule.startDateTime)),
                                'startDateTime'
                            )[0]

                            listOfScheduleStartDate.push(startDate)
                        } catch (error) {
                            console.error(`No schedules were found: ${error}`)
                        }
                    })

                    trackPromise(MeshWallets.fetchUserWallet()).then((walletResult) => {
                        const walletStatementArray = walletResult.data.walletStatement.filter(wStatement => {
                            return result.data.some((course) => course.id === wStatement.message)
                        })
                        const adaptedData = adaptEventsGroupsResponseToTable(result, walletStatementArray, listOfScheduleStartDate)
                        !isCancelled && setCoursesData(adaptedData)
                    })
                }
            })
        }
        !isCancelled && getCoursesData()
        return () => {
            isCancelled = true
        }
    }, [])

    return coursesData
}

export default useCoursesData
