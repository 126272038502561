import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import Footer from './Footer'
import * as contentActions from '@src/cms/actions/ContentActions'
import * as cmsActions from '@src/cms/actions/CMSActions'

/**
 * {@link Footer} container that binds the Footer with the {@link module:reduxSetup}
 * @class FooterContainer
 * @category Containers
 * @subcategory Footer
 *
 * @mermaid
 *   sequenceDiagram
 *   Parent->>FooterContainer: Request the Footer via FooterContainer.
 *   Note right of FooterContainer: FooterContainer gets information from redux store.
 *   FooterContainer->>Footer: FooterContainer sends redux store information to Footer as props.
 *   Footer-->>Parent: Footer is rendered in the parent component.
 */

/**
 * Gets the `footerDraft` from redux state and makes available to the {@link Footer} as a prop called `footerContent`.
 * @function
 * @memberOf FooterContainer
 * @alias mapStateToProps
 * @param {Object} state - redux state
 * @returns {Object}
 */
const mapStateToProps = (state) => {
    const { footerDraft } = state

    return {
        footerContent: footerDraft
    }
}

/**
 * Passes certain Redux actions ({@link module:ContentActions} and {@link module:CmsActions})
 * to the {@link Footer} component as props.
 * This function is used only by the react-redux connect function.
 * @function
 * @memberOf FooterContainer
 * @alias mapDispatchToProps
 * @param {function} dispatch - The react-redux dispatch function
 * @returns {Object} - Redux actions used in the {@link Footer} component
 */
const mapDispatchToProps = (dispatch) => bindActionCreators({ ...contentActions, cmsActions }, dispatch)

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    Footer
)
