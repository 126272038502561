import React from 'react'
import './priceListItem.scss'

/**
 * @const
 * @alias PRICE_CURRENCY
 * @memberOf PriceListItem
 * @type {string}
 * @example
 * const PRICE_CURRENCY = '€'
 */
export const PRICE_CURRENCY = '€'

/**
 * @typedef PriceListItemProps
 * @memberOf PriceListItem
 * @property {Product} item
 */

/**
 * Component to show the price list on the {@link MembersForm}.
 * @class PriceListItem
 * @category Components
 * @subcategory Pages / become-a-member
 * @param {PriceListItemProps} props
 * @returns {React.ReactNode}
 * @example
 * <PriceListItem />
 */
const PriceListItem = ({ item }) => {
    return (
        <div className={'priceList-item'} data-testid={'priceList-item'}>
            <p>{item.name}</p>
            <hr />
            <p>{item.price.toFixed(2)}{PRICE_CURRENCY}</p>
        </div>
    )
}

export default PriceListItem
