import React from 'react'
import { DaysEnum } from '@utils/DaysEnum'
import './repeatOnComponent.scss'
import { cmsLanguage } from '../../../../../cms/commons/cms_language/cmsLanguage'

/**
 * @typedef RepeatOnComponentProps
 * @memberOf RepeatOnComponent
 * @property {function} onRepeatChangeHandler - it will be used to manipulate the `selected` property
 * @property {Array} selected - Array of weekday names
 */

/**
 * @class RepeatOnComponent
 * @category Components
 * @subcategory theme / commons / AddEditClass
 * @param {RepeatOnComponentProps} props
 * @returns {JSX.Element}
 */
export default function RepeatOnComponent ({ onRepeatChangeHandler, selected }) {
    /**
     * @function
     * @memberOf RepeatOnComponent
     * @param {string} className
     * @param {string} value
     * @returns {string} - new class name
     */
    const getClassName = (className, value) => {
        className += selected.includes(value) ? ' repeat-days-circle-selected' : ''
        return className
    }

    /**
     * Receives a week day and returns it translated.
     * It is used just to show the name on the screen
     * @function
     * @memberOf RepeatOnComponent
     * @param {string} day - weekday name
     * @returns {string}
     */
    const parseWeekDay = (day) => cmsLanguage.weekday[day.toLowerCase()]

    return (
        <div className="repeat-container">
            {Object.keys(DaysEnum).map((weekDay) => {
                return (
                    <span
                        value={weekDay}
                        key={'repeat-' + weekDay}
                        onClick={() => onRepeatChangeHandler(weekDay)}
                        className={getClassName('repeat-days-circle', weekDay)}
                    >
                        {parseWeekDay(weekDay).substring(0, 1)}
                    </span>
                )
            })}
        </div>
    )
}
