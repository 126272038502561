import React, { useState } from 'react'
import './InputTextareaEditor.scss'
import { cmsLanguage } from '../../../cms_language/cmsLanguage'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const Link = Quill.import('formats/link')
class MyLink extends Link {
    static create (value) {
        const node = super.create(value)
        value = this.sanitize(value)
        node.setAttribute('href', value)
        if (value.startsWith('/')) node.removeAttribute('target')
        return node
    }
}
Quill.register(MyLink)
Quill.register('modules/counter', function (quill, options) {
    const container = document.querySelector(options.container)
    quill.on('text-change', function () {
        const limit = quill.getLength() - 1
        let text = quill.getText().trim().length
        if (options.limit) {
            if (limit >= options.limit) quill.deleteText(options.limit, limit)
            text += ' / ' + options.limit
        }
        container.innerText = text
    })
})

const InputTextareaEditor = (props) => {
    const { value, onChange, onFocus, onBlur, maxLength, placeholder } = props
    const [content, setContent] = useState(value)

    const handleOnChange = (newValue) => {
        setContent(newValue)
        if (onChange) onChange(newValue)
    }

    return (
        <>
            <div className="cms-input-textarea-editor" data-testid="cms-input-textarea-editor">
                <ReactQuill
                    theme="snow"
                    value={content}
                    placeholder={placeholder ? placeholder + '...' : cmsLanguage.cms.textPlaceholder + '...'}
                    onChange={handleOnChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    modules={{
                        toolbar: [
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            ['link'],
                            ['clean']
                        ],
                        counter: { container: '#counter' }
                    }}
                    formats={['bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'link']}
                />
                {maxLength && (
                    <div className="cms-input-textarea-editor-counter">
                        <span data-testid="cms-input-textarea-editor-counter" id="counter"></span>
                    </div>
                )}
            </div>
        </>
    )
}

export default InputTextareaEditor
