import React from 'react'
import { cmsLanguage } from '../../../cms_language/cmsLanguage'

const InputPhoneToolTip = () => {
    return (
        <div className="cms-info-error-tooltip">
            <h1>{cmsLanguage.cms.validFormats}:</h1>
            <ul>
                <li>010534234234</li>
                <li>+4910534234234</li>
            </ul>
        </div>
    )
}

export default InputPhoneToolTip
