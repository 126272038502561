import React from 'react'
import './dropdownOpen.scss'
import AddCmsTemplateValues from '../../../../commons/add_cms_template/AddCmsTemplateValues'
import DeleteElementButton from '../drop_down_close/delete_element_button/DeleteElementButton'
import DropdownArrowButton from '../components/DropdownArrowButton'

function DropdownOpen ({
    template,
    element,
    onChange,
    onDelete,
    onClick,
    isOpen
}) {
    const isAMultipleElement = template.elements.length > 1

    return (
        <div className="dropdown-open" data-testid="dropdown-open-id" data-type={element.type}>
            <div className="edit-cms-modal-element">
                <AddCmsTemplateValues
                    templateType={template.type}
                    element={element}
                    onChange={onChange}
                />

                {isAMultipleElement && (
                    <DropdownArrowButton
                        onClick={onClick}
                        isOpen={isOpen}
                    />
                )}
            </div>
            <DeleteElementButton
                template={template}
                element={element}
                onDelete={onDelete}
            />
        </div>
    )
}

export default DropdownOpen
