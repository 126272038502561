import React from 'react'
import { Col } from 'react-bootstrap'
import { language } from '../../../../../../theme/commons/language/language'
import { membershipTypes } from '../../membershipTypes'

import './FormTypes.scss'
import InputCheckbox from '../../../../../../cms/commons/cms_input/components/input_checkbox'
import { IDS } from '../../utils/inputFieldsList'

/**
 * Returns the different
 * @param {number?} props.selectedType
 * @param {function} props.onSelectType
 * @param {JSX.Element} props.memberSinceElement
 * @return {JSX.Element}
 * @constructor
 */
const FormTypes = (props) => {
    const {
        selectedType,
        onSelectType,
        memberSinceElement
    } = props
    return (
        <Col className={'types-container'} lg={6}>
            {memberSinceElement}
            <p>{language.membership.pleaseCheck}</p>
            <div className="membership-type">
                {membershipTypes?.map((membershipType) => {
                    const { id, label, disabled } = membershipType
                    const selected = selectedType === id
                    return (
                        <InputCheckbox
                            key={id}
                            disabled={disabled}
                            checked={selected}
                            multiple
                            label={label}
                            name={IDS.TYPES}
                            id={id}
                            onClick={() => onSelectType(membershipType)}
                        />
                    )
                })}
            </div>
        </Col>
    )
}

export default FormTypes
