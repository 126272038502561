import React from 'react'
import '../../../../../theme/commons/dropdown/Dropdown.scss'
import './DropdownClose.scss'
import { returnElementName } from '../../../../util/returnElementName'
import DeleteElementButton from './delete_element_button/DeleteElementButton'
import { sortElementValues } from '../../../../util/sortElementValues'
import DropdownArrowButton from '../components/DropdownArrowButton'

function DropdownClose (props) {
    const {
        element,
        onClick,
        template,
        onDelete
    } = props
    const elementIndex = template.elements.filter(filterElement => filterElement.type === element.type)?.map(el => el.id).indexOf(element.id)

    const orderedTemplateFields = sortElementValues(template.type, element.values)
    const elementName = returnElementName(template.type, orderedTemplateFields, element.type, elementIndex)

    return (
        elementName
            ? (
                <div className="dropdown-close" data-testid="dropdown-close-id" data-type={element.type}>
                    <div className="dropdown-wrapper" onClick={onClick}>
                        <p>{elementName.length > 60 ? elementName.slice(0, 60) + '...' : elementName }</p>
                        <DropdownArrowButton />
                    </div>
                    <DeleteElementButton
                        element={element}
                        onDelete={onDelete}
                        template={template}
                    />
                </div>
            )
            : null
    )
}

export default DropdownClose
