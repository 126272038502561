import React from 'react'
import './infoBox.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/**
 * @typedef InfoBoxProps
 * @memberOf InfoBox
 * @property {React.ReactNode} children
 */

/**
 * Container that shows the child with an info icon inside a box
 * @class InfoBox
 * @category Components
 * @subcategory theme / commons
 * @param {InfoBoxProps} props
 * @returns {React.ReactNode}
 * @example
 * <InfoBox>Info message...</InfoBox>
 */
const InfoBox = ({ children }) => {
    return (
        <div className="info-box" data-testid="info-box">
            <FontAwesomeIcon className="info-icon" icon={['fa', 'info-circle']}/>
            {children}
        </div>
    )
}

export default InfoBox
