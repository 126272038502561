// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".contact-template .contact-template-left{padding:3.75em 7.5em 5.625em;background-color:#fff;display:grid;grid-row-gap:2.8125em}.contact-template .contact-template-left h2{font-family:rucksack, sans-serif;font-weight:900;font-style:normal;font-size:2.8125em;color:#191919;text-transform:uppercase}.contact-template .contact-template-right{padding:3.75em 7.5em 5.625em;background-color:#f2f2f2}.contact-template .contact-template-right h2{font-family:rucksack, sans-serif;font-weight:900;font-style:normal;font-size:2.8125em;color:#64b42d;text-transform:uppercase}.contact-template .contact-template-right input,.contact-template .contact-template-right select,.contact-template .contact-template-right textarea{font-family:'Open Sans', sans-serif;font-weight:normal;font-style:normal;font-size:1em;color:#595959;line-height:2em;background-color:transparent;border:none}.contact-template .contact-template-right input:focus,.contact-template .contact-template-right select:focus,.contact-template .contact-template-right textarea:focus{outline:none}.contact-template .contact-form{display:flex;flex-direction:column;justify-content:space-between;height:calc(100% - 5.9375em);margin-top:2.8125em}.contact-template .contact-form .contact-fields{display:grid;grid-template-columns:1fr;grid-column-gap:7.5em}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
