import React from 'react'
import InputText from '../../../../cms/commons/cms_input/components/input_text/InputText'
import DateField from '../date-field/DateField'
import GenderRadioInput from '../gender-radio-input/GenderRadioInput'
import InputEmail from '../../../../cms/commons/cms_input/components/input_email/InputEmail'
import InputPhone from '../../../../cms/commons/cms_input/components/input_phone/InputPhone'

/**
 * @const
 * @memberOf FormField
 * @type {Object}
 */
const ComponentDictionary = {
    text: InputText,
    date: DateField,
    radio: GenderRadioInput,
    email: InputEmail,
    tel: InputPhone
}

/**
 * @typedef FormFieldProps
 * @memberOf FormField
 * @property {function} onError
 * @property {function} onChange
 * @property {string} type
 * @property {string} field
 * @property {string} value
 * @property {boolean} [disabled]
 */

/**
 * It will render an input component based on the received type.
 * @class FormField
 * @category Components
 * @subcategory Templates / MembersArea
 * @param {FormFieldProps} props
 * @returns {React.ReactNode}
 * @example
 * <FormField
 *     type={type}
 *     field={field}
 *     value={value}
 *     onChange={handleOnChange}
 *     onError={handleOnError}
 * />
 */
const FormField = ({ onError, onChange, type, field, value, disabled }) => {
    const props = {
        onChange: (newValue) => onChange(field, newValue, type),
        value,
        disabled
    }
    if (type === 'email' || type === 'tel') {
        props.onError = (errorMessage, tooltip) => onError(field, errorMessage, tooltip)
        props.onBlur = () => {}
    }
    const Field = ComponentDictionary[type]
    return <Field {...props} />
}

export default FormField
