import React from 'react'
import { Modal } from 'react-bootstrap'
import ModalHeader from '../Modal/modal_header/ModalHeader'
import './standardModal.scss'
import { isMobile } from '@utils/isMobile'

/**
 * @typedef StandardModalProps
 * @memberOf StandardModal
 * @property {('sm'|'lg'|'xl')} size
 * @property {function} onClose
 * @property {string} title
 * @property {boolean} show
 * @property {React.ReactNode} children
 * @property {string} className
 * @property {boolean} [centered]
 * @property {boolean} [isMobileModalHidden]
 */

/**
 * Standard modal.
 * Render the child component inside the Modal from react-bootstrap
 * @class StandardModal
 * @category Components
 * @subcategory theme / commons
 * @param {StandardModalProps} props
 * @returns {React.ReactNode}
 * @example
 * <StandardModal
 *     onClose={handleOnClose}
 *     size="md"
 *     title="Join course"
 *     show={true}
 *     className="join-course-modal"
 *     centered
 *     isMobileModalHidden>
 *     <div>
 *       ...
 *     </div>
 * </StandardModal>
 */
const StandardModal = ({ size, onClose, title, show, children, className, centered = false }) => {
    const content = (view) => (
        <div className={view}>
            <ModalHeader title={title} onClose={onClose}/>
            { children }
        </div>
    )
    console.log()
    return (
        <>
            {isMobile() && content('cms-modal mobile-view')}
            {!isMobile() && (
                <Modal
                    onHide={onClose}
                    size={size}
                    className={className}
                    show={show}
                    centered={centered}
                >
                    {content('cms-modal')}
                </Modal>
            )}
        </>
    )
}

export default StandardModal
