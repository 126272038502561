import { IDS } from './inputFieldsList'

export const newFamilyMember = (birthDay = '', firstName = '') => ({
    [IDS.BIRTHDAY]: { value: birthDay, hasError: false },
    [IDS.FIRSTNAME]: { value: firstName, hasError: false }
})

export const getFamilyMemberValues = member => {
    return { [IDS.BIRTHDAY]: member[IDS.BIRTHDAY].value, [IDS.FIRSTNAME]: member[IDS.FIRSTNAME].value }
}

export const addFamilyMember = (familyMembers) => {
    const { value: previousMembers, hasError } = familyMembers
    return [{ id: IDS.FAMILY_MEMBERS, value: [...previousMembers, newFamilyMember()], hasError }]
}

export const familySize = (familyMembers) => {
    return familyMembers.value.length + 1
}
const ELEMENT_INDEX_TO_DELETE = -1
const NUMBER_OF_ELEMENTS_TO_DELETE = 1
export const removeFamilyMember = (familyMembers) => {
    const { value, hasError } = familyMembers
    const changes = []
    if (!familyMembers?.value?.length) {
        console.warn('No members to delete')
    } else {
        value?.splice(ELEMENT_INDEX_TO_DELETE, NUMBER_OF_ELEMENTS_TO_DELETE)
        changes.push({ id: IDS.FAMILY_MEMBERS, value, hasError })
    }

    return changes
}

/**
 *
 * @param familyMembers it is composite of the main member and its other family members
 * @param {Array<{birthDay: {hasError: boolean, value: string}, firstName: {hasError: boolean, value: string}}>} familyMembers.value it stores the information of these members, its firstName field and its birthday field
 * @param {boolean} familyMembers.hasError marks if this field has an error
 * @param {string} newValue
 * @param {boolean} hasError
 * @param {string} fieldId
 * @param {number} index
 * @return {Object[]}
 */
export const handleFamilyMembersChange = (familyMembers, newValue, hasError, fieldId, index) => {
    const familyCount = familySize(familyMembers)
    const familyMemberIndex = index - 1
    const changes = []
    if (familyMemberIndex < 0) {
        console.debug('Updating main member')
        changes.push({ id: fieldId, value: newValue, hasError })
    } else if (index > familyCount) {
        console.warn('Attempting to edit a members that is not saved')
    } else if (fieldId === IDS.FIRSTNAME || fieldId === IDS.BIRTHDAY) {
        console.debug('Editing member ' + familyMemberIndex)
        familyMembers.value[familyMemberIndex][fieldId] = {
            value: newValue,
            hasError: hasError
        }
        changes.push({ id: IDS.FAMILY_MEMBERS, value: familyMembers.value, hasError: false })
    }
    return changes
}

/**
 *
 * @param {string} birthdayValue
 * @param {string} firstNameValue
 * @param {Array<{birthDay: {hasError: boolean, value: string}, firstName: {hasError: boolean, value: string}}>} familyMembersValue
 * @return {Array<{"[IDS.BIRTHDAY]", "[IDS.FIRSTNAME]"}>}
 */
export const formToFamilyMembers = (birthdayValue, firstNameValue, familyMembersValue) => {
    return [{ [IDS.BIRTHDAY]: birthdayValue, [IDS.FIRSTNAME]: firstNameValue }].concat(familyMembersValue.map(member => {
        return getFamilyMemberValues(member)
    }))
}
