/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import './datepicker.scss'
import wrapErrorComponent from '../errorHOC/ErrorHoc'
import { padZero } from '../../../utils/dateTimePickerUtils'

/**
 * @typedef DatePickerProps
 * @memberOf DatePicker
 * @property {function} onChangeDate
 * @property {string} [value] - e.g.: '2023-05-18'
 * @property {string} [label]
 * @property {string} [data-testid]
 */

/**
 * Component with an input type date (default HTML input).
 * @class DatePicker
 * @category Components
 * @subcategory theme / commons
 * @param {DatePickerProps} props
 * @returns {React.ReactNode}
 * @example
 * <DatePicker onChangeDate={handleOnChange} value="2023-05-18" label="Start date" />
 */
function DatePicker ({ onChangeDate, value, label, 'data-testid': testId = 'datePicker-testId' }) {
    const [date, setDate] = useState('')

    useEffect(() => {
        if (value) {
            const currentValue = moment(value).toDate()
            const year = currentValue.getUTCFullYear().toString()
            const month = (padZero((currentValue.getUTCMonth() + 1).toString()))
            const day = (padZero((currentValue.getDate()).toString()))
            setDate(year + '-' + month + '-' + day)
        }
    }, [])

    useEffect(() => {
        if (date) {
            const selectedDate = moment.utc(
                `${date}`,
                'YYYY/MM/DD'
            ).startOf('day')
            onChangeDate(selectedDate.toISOString())
        }
    }, [date])

    const handleOnChange = (e) => {
        setDate(e.target.value)
    }

    return (
        <div className="date-picker">
            {label && <label htmlFor="date-picker" className={'date-picker-label'}>{label}</label>}
            <input
                data-testid="date-picker-input"
                id={'date-picker'}
                type="date"
                value={date}
                onChange={handleOnChange}
            />
        </div>
    )
}

export default wrapErrorComponent(DatePicker)
