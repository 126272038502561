import React from 'react'
import DropdownClose from '../drop_down_close/DropdownClose'
import DropdownOpen from '../drop_down_open/DropdownOpen'
import AddCmsTemplateValues from '../../../../commons/add_cms_template/AddCmsTemplateValues'
import './elementDropdown.scss'

const ElementDropdown = ({
    template,
    element,
    onChange,
    isOpen,
    onClick,
    onDelete,
    isMultiple
}) => {
    const handleDropdownClick = () => {
        onClick(element.id, element.type)
    }

    const handleOnChange = (valueId, newValue, hasError) => {
        onChange(element.id, valueId, newValue, hasError, element.type)
    }

    const handleDelete = (elementId) => {
        onDelete(elementId, element.type)
    }

    return (
        // When is CourseInformation template and Default
        !isMultiple && element.type === 'default'
            ? <div className="edit-cms-modal-element" data-testid="add-cms-template-values">
                <AddCmsTemplateValues
                    templateType={template.type}
                    element={element}
                    onChange={handleOnChange}
                />
            </div>
            : isOpen
                ? <DropdownOpen
                    onChange={handleOnChange}
                    onClick={handleDropdownClick}
                    isOpen={isOpen}
                    template={template}
                    element={element}
                    onDelete={handleDelete}
                />
                : <DropdownClose
                    onClick={handleDropdownClick}
                    isOpen={isOpen}
                    element={element}
                    template={template}
                    onDelete={handleDelete}
                />
    )
}
export default ElementDropdown
