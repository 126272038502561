/**
 * Receives a menu and returns it with the children sorted
 * @module sortMenuAlphabetically
 * @category Backend
 * @subcategory mesh_helper
 * @param {Menu} _menu
 * @returns {Menu}
 */
export const sortMenuAlphabetically = (_menu) => {
    const menus = Object.keys(_menu)
    for (const menu of menus) {
        _menu[menu].children = traverse(_menu[menu].children)
    }
    function traverse (child, sortBy = 'name') {
        child.sort((a, b) => {
            if (a.children.length !== 0) {
                a.children = traverse(a.children)
            }
            if (b.children.length !== 0) {
                b.children = traverse(b.children)
            }
            return a[sortBy].toLowerCase().localeCompare(b[sortBy].toLowerCase())
        })
        return child
    }
    return _menu
}
