import React from 'react'
import './InputField.scss'

/**
 * @typedef InputFieldProps
 * @memberOf InputField
 * @property {function} setInputValue
 * @property {string} title
 * @property {string|boolean} [alert]
 * @property {string} defaultInput
 * @property {string} inputKey
 */

/**
 * Input Field component.
 * @class InputField
 * @category Components
 * @subcategory Templates / MembersArea
 * @param {InputFieldProps} props
 * @returns {React.ReactNode}
 * @example
 * <InputField
 *     title={cmsLanguage.inputFields.name}
 *     defaultInput={userData.name ? userData.name : ''}
 *     inputKey="name"
 *     alert={alert.name}
 *     setInputValue={setInputValue}
 * />
 */
const InputField = ({
    setInputValue,
    title,
    alert,
    defaultInput,
    inputKey
}) => {
    const targetValue = (event) => {
        setInputValue(inputKey, event.target.value)
    }

    return (
        <div className="members-input" data-testid="input-field">
            <h4>{title}</h4>
            <div
                className={alert ? 'members-input-field alert' : 'members-input-field'}
            >
                {inputKey === 'paymentData.iban' && (
                    <input
                        className="bankCode"
                        type="text"
                        value="DE"
                        disabled={true}
                        size={2}
                    />
                )}
                <input
                    id="members-input-field"
                    data-testid="members-input-field"
                    type="text"
                    value={defaultInput}
                    onChange={targetValue}
                />
            </div>
            <p>{alert}</p>
        </div>
    )
}

export default InputField
