import { v4 as uuidv4 } from 'uuid'
import { cmsLanguage } from '../../../commons/cms_language/cmsLanguage'
import { templateTypes } from '../cms_modal_multiple_elements/constants'

const Instagram = () => {
    const fields = [
        {
            name: 'accessToken',
            type: 'TEXT',
            id: uuidv4(),
            isMandatory: true
        },
        {
            name: 'instagramUrl',
            type: 'LINK',
            id: uuidv4(),
            isMandatory: true
        }
    ]
    const values = fields.map(f => ({
        fieldId: f.id,
        id: uuidv4(),
        value: '',
        field: f
    }))
    return {
        id: uuidv4(),
        name: cmsLanguage.templatesNames.instagram,
        type: templateTypes.INSTAGRAM,
        elements: [
            {
                id: uuidv4(),
                values: values,
                type: 'default'
            }
        ],
        fields: fields
    }
}

export const INSTAGRAM_TEMPLATE_ORDER = { LINK: 0, TEXT: 1 }

export default Instagram
