import React from 'react'
import { LOGGING_URL } from '@backend/mesh/Constants'
import LoginStep from './LoginStep'
import { useUserName, saveCurrentLocation, saveStep } from './utils'
import JOIN_COURSE_STEP from '../../../joinCourseStep'
import { getCurrentLocation, setCurrentLocation } from '../../../../../utils/location'

/**
 * Returns the {@link LoginStep} component with the function `goToAndReturn`.
 * @class LoginStepContainer
 * @category Containers
 * @subcategory Templates / CourseInfo
 * @param {Object} props
 * @returns {React.ReactNode}
 */
const LoginStepContainer = (props) => {
    const [isIdentified, name] = useUserName()

    const goToAndReturn = (e) => {
        saveCurrentLocation(getCurrentLocation())
        saveStep(JOIN_COURSE_STEP.PAYMENT)
        e.preventDefault()
        setCurrentLocation(LOGGING_URL)
    }

    return <LoginStep {...props} isIdentified={isIdentified} name={name} onLogin={goToAndReturn} />
}

export default LoginStepContainer
