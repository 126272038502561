import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { language } from '../../../../theme/commons/language/language'
import './membershipThirdStep.scss'

/**
 * The third step of the {@link MembersForm}.
 * @class MembershipThirdStep
 * @category Components
 * @subcategory Pages / become-a-member
 * @returns {React.ReactNode}
 * @example
 * <MembershipThirdStep />
 */
const MembershipThirdStep = () => {
    return (
        <Container className={'membership-members-info'}>
            <Row className={'membership-members-info__title'}>
                <Col>
                    <h3>{language.membership.membersInfo}</h3>
                </Col>
            </Row>
            <Row className={'membership-members-info__paragraphs'}>
                <Col>
                    <p>
                        {`${language.membership.vfl}\n${language.membership.intro}`}
                    </p>
                    <p>{language.membership.exclusionClauseLabel}</p>
                    <p>{language.membership.exclusionClause}</p>
                    <p>{language.membership.bankClauseLabel}</p>
                    <p>{language.membership.bankClause}</p>
                </Col>
            </Row>
        </Container>
    )
}

export default MembershipThirdStep
