import { CONFIG, MESH_CONFIG } from './Constants'
// eslint-disable-next-line no-unused-vars
import axios, { AxiosResponse } from 'axios'
import store from '../../redux/store'

/**
 * Mesh
 * @class Mesh
 * @category Backend
 */
export default class Mesh {
    /**
     * Fetches the organization ID based on the URL hostname (e.g.: https://`vfl-verein`-public.k8s....)
     * @function
     * @memberof Mesh
     * @returns {Promise<number>}
     * @static
     */
    static async getOrganization () {
        const orgName = window.location.hostname.split('.')[0].replace(/-public$/, '')

        const response = await axios.get(
            `${MESH_CONFIG.SERVER_URL}/organizations-service/organizations?name=${orgName}`,
            {
                headers: {
                    ...CONFIG.DEFAULT_HEADERS,
                    CONTENT_TYPE: 'application/json'
                }
            }
        )
        return response.data.id
    }

    /**
     * Fetches the user based on the received credentials. (auth-service)
     * @function
     * @memberof Mesh
     * @param {Credentials} credentials
     * @param {number} orgId
     * @returns {Promise<User>}
     * @static
     * @example
     * const authentication = await Mesh.getAuthentication({EMAIL: 'test@test.com', PASSWORD: '123'}, 9)
     */
    static async getAuthentication (credentials, orgId) {
        const response = await axios.put(
            `${MESH_CONFIG.SERVER_URL}/auth-service/organizations/${orgId}/authentications`,
            {
                username: credentials.EMAIL,
                password: credentials.PASSWORD
            },
            {
                headers: {
                    ...CONFIG.DEFAULT_HEADERS,
                    CONTENT_TYPE: 'application/json'
                }
            }
        )

        return response.data
    }

    /**
     * Fetches the logged-in user based on the stored id. (users-service)
     * @function
     * @memberof Mesh
     * @returns {Promise<User>}
     * @static
     * @example
     * const userData = await Mesh.getUserData()
     */
    static async getUserData () {
        const { accessToken, organizationId, id } = store.getState().user
        const url = `${MESH_CONFIG.SERVER_URL}/users-service/organizations/${organizationId}/users/${id}`

        const config = {
            headers: {
                ...CONFIG.DEFAULT_HEADERS,
                CONTENT_TYPE: 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const userData = await axios.get(url, config)

        return userData.data
    }

    /**
     * Sends a `PUT` request to the `users-service` to update the user with the received data
     * @function
     * @memberof Mesh
     * @param {UserDataRequest} userData
     * @return {Promise<User>}
     * @static
     * @example
     * const result = await Mesh.updateUserData(userDataToBeSaved)
     */
    static async updateUserData (userData) {
        const { accessToken, organizationId, id } = store.getState().user
        const url = `${MESH_CONFIG.SERVER_URL}/users-service/organizations/${organizationId}/users/${id}`

        const config = {
            headers: {
                ...CONFIG.DEFAULT_HEADERS,
                CONTENT_TYPE: 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        userData.orgId = organizationId

        const updatedUserData = await axios.put(url, userData, config)

        return updatedUserData.data
    }

    /**
     * Fetches access token base on OAuth received code
     * @function
     * @memberOf Mesh
     * @param {string} code
     * @returns {Promise<AxiosResponse<{accessToken: string}>>}
     * @static
     */
    static fetchToken (code) {
        return axios.post(
            `${MESH_CONFIG.SERVER_URL}/auth-service/oauth2/exchange`,
            { code },
            {
                headers: {
                    ...CONFIG.DEFAULT_HEADERS,
                    CONTENT_TYPE: 'application/json'
                }
            }
        ).catch(err => {
            console.error(err)
        })
    }

    /**
     * Fetches paginated news from the `news-service` based on organization ID (from user data).
     * @function
     * @memberOf Mesh
     * @param {number} page
     * @param {number} size
     * @returns {Promise<Array<News>>}
     * @static
     * @example
     * const result = await Mesh.getNewsContent(0, 12)
     */
    static async getNewsContent (page, size) {
        const { accessToken, organizationId } = store.getState().user
        const url = `${MESH_CONFIG.SERVER_URL}/news-service/organizations/${organizationId}/news?page=${page}&size=${size}`

        const config = {
            headers: {
                ...CONFIG.DEFAULT_HEADERS,
                CONTENT_TYPE: 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const response = await axios.get(url, config)
        return response.data
    }

    /**
     * Fetches a specific news item from the `news-service` based on the received ID.
     * @function
     * @memberOf Mesh
     * @param {string} newsId
     * @returns {Promise<News>}
     * @static
     */
    static async getANewsDetails (newsId) {
        const { accessToken, organizationId } = store.getState().user
        const url = `${MESH_CONFIG.SERVER_URL}/news-service/organizations/${organizationId}/news/${newsId}`

        const config = {
            headers: {
                ...CONFIG.DEFAULT_HEADERS,
                CONTENT_TYPE: 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const response = await axios.get(url, config)
        return response.data
    }

    /**
     * Sends an email to the organization (email props retrieved from the database using the id that is in the service URL)
     * @memberof Mesh
     * @param {string} content
     * @returns {Promise<AxiosResponse<any>>}
     * @static
     */
    static sendEmail (content) {
        const { accessToken, organizationId } = store.getState().user
        const url = `${MESH_CONFIG.SERVER_URL}/push-notification-service/organizations/${organizationId}/email/60dd85338090d369c9417838`

        const config = {
            headers: {
                ...CONFIG.DEFAULT_HEADERS,
                CONTENT_TYPE: 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        return axios.post(url, content, config)
    }
}

axios.interceptors.response.use(
    response => response,
    error => {
        const errorCode = error.response.status
        if (errorCode && (errorCode === 401 || errorCode === 403)) {
            localStorage.clear()
            return Promise.reject(error)
        }
        return Promise.reject(error)
    })
