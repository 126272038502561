import get from 'lodash/get'
import { trackPromise } from 'react-promise-tracker'
import { types } from '../types'
import { formatData, mergeSchedulesLists, removeScheduleFromList, unFormatData } from '../helpers/scheduleDataFormatter'
import { appendMyEventsGroups, formatMyCourseData } from '../helpers/trainingDataFormatter'
import MeshEventCategory from '../../../backend/mesh/calendar_service/MeshEventCategory'
import MeshEventsGroup from '../../../backend/mesh/calendar_service/MeshEventsGroup'
import MeshEventSchedule from '../../../backend/mesh/calendar_service/MeshEventSchedule'
import { cmsLanguage } from '../../../cms/commons/cms_language/cmsLanguage'
import moment from 'moment'
import MeshContent from '../../../backend/mesh/content_service/MeshContent'
import { templateTypes } from '../../../cms/cms_templates_bars/components/cms_modal_multiple_elements/constants'
import MeshContentTemplate from '../../../backend/mesh/content_service/MeshContentTemplate'

/**
 * @module TrainingActions
 * @category Redux
 * @subcategory Pages / trainingSchedule
 */

const overlappingError = cmsLanguage.error.overlappingClasses

/**
 * @function
 * @inner
 * @static
 * @param {function} dispatch - A React Redux dispatch function
 * @param {Object} error
 * @returns {function} - A React Redux dispatch function
 */
const errorHandling = (dispatch, error) => {
    const responseCode = get(error.response?.data, 'code', 'unknownErrorCode')
    const responseMessage = get(error.response?.data, 'message', 'UnknownErrorMessage')
    const responseTitle = get(error.response?.data, 'title', responseCode)
    dispatch({
        type: types.ERROR,
        payload: {
            title: `Error: ${responseTitle}`,
            message: `${responseMessage}`,
            code: responseCode
        }
    })
}

/**
 * Gets all categories from the `calendar-service` and store it in the redux store.
 * @function
 * @inner
 * @static
 * @returns {function} - A React Redux dispatch function
 */
export const getCategories = () => async (dispatch, getState) => {
    const { loggedIn } = getState().user

    try {
        const res = await trackPromise(
            MeshEventCategory.getCategories()
        )

        dispatch({
            type: types.FETCH_FETCH_CATEGORIES,
            payload: appendMyEventsGroups(res.data, loggedIn)
        })
    } catch (e) {
        errorHandling(dispatch, e)
    }
}

/**
 * Gets all event groups for the logged-in user and store it in the redux store.
 * @function
 * @inner
 * @static
 * @returns {function} - A React Redux dispatch function
 */
export const getMyCourses = () => async (dispatch, getState) => {
    const { categories } = getState().training

    try {
        const res = await trackPromise(
            MeshEventsGroup.getMyCourses()
        )
        dispatch({
            type: types.FETCH_MY_COURSES,
            payload: formatMyCourseData(res.data, categories)
        })
    } catch (e) {
        errorHandling(dispatch, e)
    }
}

const getDateWithTime = (dateString, type) => {
    const date = new Date(dateString)
    if (type === 'start') {
        return new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            0,
            0,
            1
        )
    } else {
        return new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            23,
            59,
            59
        )
    }
}

/**
 * Gets the public schedule from the `calendar-service` with the received filters and store it in the redux store.
 * @function
 * @inner
 * @static
 * @param {string} startDate
 * @param {string} endDate
 * @param {string} courseId
 * @returns {function} - A React Redux dispatch function
 */
export const getPublicSchedule = (startDate, endDate, courseId) => async (
    dispatch,
    getState
) => {
    const startDateWithoutTime = getDateWithTime(startDate, 'start').toISOString()
    const endDateWithoutTime = getDateWithTime(endDate, 'end').toISOString()

    try {
        const searchDto = {
            sample: { metadata: { eventsGroupId: courseId } },
            matchers: [
                {
                    field: 'fields',
                    matcherType: 'NOT_EMPTY'
                },
                {
                    field: 'elements',
                    matcherType: 'NOT_EMPTY'
                }
            ]
        }
        const { data: templates } = await trackPromise(MeshContentTemplate.searchTemplate(searchDto))
        const { data: contents } = await trackPromise(MeshContent.searchContent({ templates: [{ id: templates[0].id }] }))
        const allSchedules = await trackPromise(Promise.all(
            contents
                .flatMap(it => it.templates)
                .filter(it => it.type === templateTypes.COURSE)
                .map(it => it.metadata.eventsGroupId)
                .map(it => MeshEventSchedule.getPublicSchedule(startDateWithoutTime, endDateWithoutTime, it))))
        const result = allSchedules.map(it => it.data).flat()
        dispatch({
            type: types.SET_PUBLIC_SCHEDULE_TIMES,
            payload: formatData(result)
        })
    } catch (e) {
        errorHandling(dispatch, e)
    }
}

/**
 * @function
 * @inner
 * @static
 * @returns {{type: (ActionsTrainingTypes)}}
 */
export const clearSchedule = () => {
    return {
        type: types.CLEAR_SCHEDULE
    }
}

/**
 * Saves schedule using `calendar-service` if it is not overlapping.
 * @function
 * @inner
 * @static
 * @param {Object} data
 * @returns {function} - A React Redux dispatch function
 */
export const postSchedule = (data) => async (dispatch, getState) => {
    try {
        const schedules = getState().training.schedules
        checkScheduleOverlapping(data, schedules)

        const res = await trackPromise(
            MeshEventSchedule.postSchedule(data)
        )
        dispatch(mergeSchedule(res.data.savedSchedules, true, schedules))
        return res.data
    } catch (e) {
        if (e.message === overlappingError) {
            throw new Error(
                overlappingError
            )
        } else {
            errorHandling(dispatch, e)
        }
    }
}

/**
 * Saves schedule list using `calendar-service` if it is not overlapping.
 * @function
 * @inner
 * @static
 * @param {Object} data
 * @returns @returns {function} - A React Redux dispatch function
 */
export const postScheduleList = (data) => async (dispatch, getState) => {
    try {
        const schedules = getState().training.schedules
        checkScheduleOverlapping(data, schedules)

        const res = await trackPromise(
            MeshEventSchedule.postScheduleList(data)
        )
        dispatch(mergeSchedule(res.data.savedSchedules, true, schedules))
        return res.data
    } catch (e) {
        if (e.message === overlappingError) {
            throw new Error(
                overlappingError
            )
        } else {
            errorHandling(dispatch, e)
        }
    }
}

/**
 * Delete a schedule by its ID.
 * @function
 * @inner
 * @static
 * @param {string} scheduleId
 * @returns {function} - A React Redux dispatch function
 */
export const deleteSchedule = (scheduleId) => async (dispatch, getState) => {
    try {
        const schedules = getState().training.schedules

        const res = await trackPromise(
            MeshEventSchedule.deleteSchedule(scheduleId)
        )

        dispatch(removeScheduleFromScheduleList(schedules, scheduleId))
        return res
    } catch (error) {
        errorHandling(dispatch, error)
    }
}

/**
 * Updates schedule using `calendar-service` if it is not overlapping.
 * @function
 * @inner
 * @static
 * @param {Object} data
 * @returns {function} - A React Redux dispatch function
 */
export const putSchedule = (data) => async (dispatch, getState) => {
    try {
        const schedules = getState().training.schedules

        checkScheduleOverlapping(data, schedules)

        const res = await trackPromise(
            MeshEventSchedule.putSchedule(data)
        )

        dispatch(mergeSchedule([res.data], false, schedules))
        return res
    } catch (e) {
        if (e.message === overlappingError) {
            throw new Error(
                overlappingError
            )
        } else {
            errorHandling(dispatch, e)
        }
    }
}

/**
 * @function
 * @inner
 * @static
 * @param schedule
 * @returns {{payload: Array<*>, type: (ActionsTrainingTypes)}}
 */
export const storeSchedule = schedule => {
    return {
        type: types.SET_PUBLIC_SCHEDULE_TIMES,
        payload: formatData(schedule)
    }
}

/**
 * @function
 * @inner
 * @static
 * @param schedule
 * @param isNewClass
 * @param oldSchedule
 * @returns {{payload: Array<*>, type: (ActionsTrainingTypes)}}
 */
export const mergeSchedule = (schedule, isNewClass, oldSchedule) => {
    return {
        type: types.SET_PUBLIC_SCHEDULE_TIMES,
        payload: formatData(mergeSchedulesLists(unFormatData(oldSchedule), schedule, isNewClass))
    }
}

/**
 * @function
 * @inner
 * @static
 * @param selectedCourse
 * @returns {{payload, type: (ActionsTrainingTypes)}}
 */
export const setSelectedCourse = (selectedCourse) => {
    return {
        type: types.SET_SELECTED_COURSE,
        payload: selectedCourse
    }
}

/**
 * Checks if the received class is not overlapping any pre-existing ones.
 * If it is overlapping, throw an error.
 * @function
 * @inner
 * @static
 * @param {Object} newClass
 * @param {Array<Object>} schedules
 */
const checkScheduleOverlapping = (newClass, schedules) => {
    schedules = unFormatData(schedules)

    schedules.forEach(oldEvent => {
        if ((moment(newClass.startDateTime).isBetween(oldEvent.startDateTime, oldEvent.endDateTime) ||
            moment(newClass.endDateTime).isBetween(oldEvent.startDateTime, oldEvent.endDateTime) ||
            moment(newClass.startDateTime) === (oldEvent.startDateTime)) &&
            (oldEvent.id !== newClass.id)
        ) { throw new Error(overlappingError) }
    })
}

/**
 * @function
 * @inner
 * @static
 * @param schedules
 * @param deletedScheduleId
 * @returns {{payload: Array<*>, type: (types|ActionsTrainingTypes)}}
 */
export const removeScheduleFromScheduleList = (schedules, deletedScheduleId) => {
    return {
        type: types.DELETE_PUBLIC_SCHEDULE,
        payload: formatData(removeScheduleFromList(unFormatData(schedules), deletedScheduleId))
    }
}
