import React from 'react'
import InputText from '../input_text'
import InputEmail from '../input_email'
import InputTextarea from '../input_textarea'
import InputLink from '../input_link'
import InputPhone from '../input_phone'
import InputDate from '../input_date'
import InputIban from '../input_iban'
import InputGender from '../input_gender'
import InputCheckbox from '../input_checkbox'
import InputPrice from '../input_price'

export const INPUT_TYPES = {
    TEXT: 'TEXT',
    DATE: 'DATE',
    PHONE: 'PHONE',
    LINK: 'LINK',
    TEXT_AREA: 'TEXT_AREA',
    EMAIL: 'EMAIL',
    GENDER: 'GENDER',
    CHECK_BOX: 'CHECK_BOX',
    IBAN: 'IBAN',
    PRICE: 'PRICE'
}

const InputFieldComponent = {
    [INPUT_TYPES.TEXT]: InputText,
    [INPUT_TYPES.DATE]: InputDate,
    [INPUT_TYPES.PHONE]: InputPhone,
    [INPUT_TYPES.LINK]: InputLink,
    [INPUT_TYPES.TEXT_AREA]: InputTextarea,
    [INPUT_TYPES.GENDER]: InputGender,
    [INPUT_TYPES.EMAIL]: InputEmail,
    [INPUT_TYPES.CHECK_BOX]: InputCheckbox,
    [INPUT_TYPES.IBAN]: InputIban,
    [INPUT_TYPES.PRICE]: InputPrice
}

const InputComponent = (props) => {
    if (!props) {
        return <></>
    }
    const { type, ...otherProps } = props
    try {
        const Component = InputFieldComponent[type]
        return Component ? <Component {...otherProps} /> : <></>
    } catch (e) {
        console.warn('Input type failed to render', e)
        return <></>
    }
}

export default InputComponent
