import { getLanguage } from '../../../utils/languageUtils'

/**
 * @module language
 * @category Theme
 * @subcategory commons
 */

/**
 * English language code
 * @const
 * @type {string}
 * @example
 * const en = 'en'
 */
const en = 'en'

/**
 * German language code
 * @const
 * @type {string}
 * @example
 * const de = 'de'
 */
const de = 'de'

/**
 * Current language
 * @type {string}
 * @example
 * const selectedLanguage = getLanguage()
 */
const selectedLanguage = getLanguage()

/**
 * Object with the keys and values for the english language
 * @type {Object}
 * @example
 * {
 *  templates: {
 *      images: 'Images',
 *      viewClassSchedule: 'View class schedule'
 *  },
 *  buttons: {
 *      signOut: 'Sign out',
 *      continue: 'Continue',
 *      ...
 * }
 */
export const languageEng = {
    templates: {
        images: 'Images',
        viewClassSchedule: 'View class schedule'
    },
    buttons: {
        signOut: 'Sign out',
        continue: 'Continue',
        done: 'Done',
        accept: 'Accept'
    },
    modals: {
        login: 'Log in',
        joinCourse: 'Join Course',
        registeredUser: 'Are you a registered user?',
        loggedIn: 'You are logged in as ',
        continue: 'Continue',
        pay: 'Pay',
        done: 'Done',
        haveAccount: 'Don\'t have an account?',
        notYou: 'Not you?',
        signUp: 'Sign up',
        cancel: 'Cancel',
        paymentStep: {
            summary: 'Summary',
            courseName: 'Course name',
            paymentMethods: 'Payment methods',
            paymentSuccess: 'Awesome, payment was successful'
        }
    },
    membership: {
        standardForm: 'Online declaration of entry',
        disabilitiesForm: 'Online declaration of entry with regulation',
        ourPrices: 'Our membership fees',
        contributions: 'Monthly basic contributions from October 1st, 2021',
        reductions: '*Reductions are only granted on presentation of proof. You can view additional contributions in the individual departments.',
        admissionFeeDetail: 'The admission fee corresponds to a basic monthly fee.',
        admissionFee: 'Admission fee',
        terminationDates: 'Termination dates',
        resignation: 'Resignation can only be declared as of June 30 or December 31 of a year, subject to a notice period of three months. The date of receipt of the letter of termination in the office applies.',
        admission: 'Entry declaration',
        date: 'Entry date',
        pleaseCheck: '(Please select relevant option)',
        personal: 'Personal Information',
        address: 'Address',
        contact: 'Contact',
        paymentInfo: 'Payment information',
        forMe: 'For me',
        formMySon: 'For my son',
        formMyDaughter: 'For my daughter',
        coupleContribution: 'Couple contribution',
        familyContribution: 'Family contribution',
        student: 'Student',
        trainee: 'Trainee',
        military: 'Military or civil service',
        pensioner: 'Pensioner',
        unemployed: 'Unemployed',
        familyName: 'Family name',
        firstName: 'First name',
        birthDay: 'Date of birth',
        gender: 'Gender',
        male: 'Male',
        female: 'Female',
        streetAddress: 'Street and house number',
        postCode: 'Post code',
        city: 'City',
        phone: 'Phone number',
        email: 'Email',
        iban: 'IBAN',
        bic: 'BIC',
        bicTooltip: 'The BIC (Bank Identifier Code) is an international code. Also called the SWIFT Code, is used as a second identifier in addition to the IBAN. The BIC comprises of eight or eleven alphanumeric characters.',
        bank: 'Bank',
        accountHolder: 'Name of account holder',
        membersInfo: 'Member information',
        vfl: 'VfL Wolfsburg e. V.',
        intro: 'Excerpt from the association\'s statutes from 21.08.2020',
        success: 'Awesome, we have received your request.',
        attention: 'Please pay attention to your email to follow the next step.',
        exclusionClauseLabel: '§ 6 - Clause 5', // fixme: verify text
        exclusionClause: 'A member can be excluded if, after two written reminders, they fail to pay membership fees and charges that have already become due - including additional charges, user fees and departmental charges. In the second reminder is au! to indicate the forthcoming exclusion.', // fixme: verify text
        bankClauseLabel: '§ 7-Clause 4', // fixme: verify text
        bankClause: 'In principle, the contributions are to be paid quarterly by direct debit and are due at the end of the first month of the quarter. They are to be credited to the departments in which the member is enrolled; if necessary, a division is to be made (e.g. family members in different departments). The member is obliged to give the association a corresponding authorization together with the application for membership. In exceptional cases, au! Application for a bank transfer or cash deposit au! a Kanta of the association will be approved. Da!Or is a surcharge of 10% of a monthly fee per quarter to cover the additional administrative expenses, rounded up. full euros to pay. Data protection information according to Art. 13 GDPR for association membership can be found on the homepage www.v1l-wob. de under Club/Privacy Policy for admission.' // fixme: verify text
    }
}

/**
 * Object with the keys and values for the german language
 * @type {Object}
 * @example
 * {
 *  templates: {
 *      images: 'Bilder',
 *      viewClassSchedule: 'Stundenplan ansehen'
 *  },
 *  buttons: {
 *      signOut: 'Austragen',
 *      continue: 'Weitermachen',
 *      ...
 * }
 */
export const languageDeu = {
    templates: {
        images: 'Bilder',
        viewClassSchedule: 'Stundenplan ansehen'
    },
    buttons: {
        signOut: 'Austragen',
        continue: 'Weitermachen',
        done: 'Erledigt',
        accept: 'Akzeptieren'
    },
    modals: {
        login: 'Anmeldung',
        joinCourse: 'Kurs beitreten',
        registeredUser: 'Sind Sie ein registrierter Benutzer?',
        loggedIn: 'Du bist eingeloggt als ',
        continue: 'Weitermachen',
        pay: 'Zahlen',
        done: 'Erledigt',
        haveAccount: 'Haben Sie kein Konto?',
        notYou: 'Nicht du?',
        signUp: 'Anmelden',
        cancel: 'Stornieren',
        paymentStep: {
            summary: 'Zusammenfassung',
            courseName: 'Kursname',
            paymentMethods: 'Zahlungsarten',
            paymentSuccess: 'Super, Zahlung war erfolgreich'
        }
    },
    membership: {
        standardForm: 'Online Eintrittserklärung',
        disabilitiesForm: 'Online Eintrittserklärung mit Verordnung',
        ourPrices: 'Unsere Mitgliedsbeiträge\'',
        contributions: 'Monatliche Grundbeiträge ab 1.10.2021',
        reductions: '*Ermäßigungen werden nur gegen Nachweis gewährt. Weitere Beiträge können Sie in den einzelnen Abteilungen einsehen.',
        admissionFee: 'Aufnahmegebühr',
        admissionFeeDetail: 'Die Aufnahmegebühr entspricht einer monatlichen Grundgebühr.',
        terminationDates: 'Kündigungstermine',
        resignation: 'Die Kündigung kann nur zum 30. Juni oder 31. Dezember eines Jahres unter Einhaltung einer Frist von drei Monaten erklärt werden. Es gilt das Datum des Eingangs des Kündigungsschreibens in der Geschäftsstelle.',
        admission: 'Eintrittserklärung',
        date: 'Eintrittsdatum',
        pleaseCheck: '(Zutreffendes bitte ankreuzen)',
        personal: 'Persönliche angaben',
        address: 'Anschrift',
        contact: 'Kontakt',
        paymentInfo: 'Zahlen information',
        forMe: 'Für mich',
        formMySon: 'Füe meinen Sohn',
        formMyDaughter: 'Für meine Tochter',
        coupleContribution: 'Ehepaar beitrag',
        familyContribution: 'Familien beitrag',
        student: 'Student',
        trainee: 'Auszubildender',
        military: 'Militär- oder Zivildiens',
        pensioner: 'Rentner',
        unemployed: 'Arbeitslos',
        familyName: 'Familienname',
        firstName: 'Vorname',
        birthDay: 'Geburtsdatum',
        gender: 'Geschlecht',
        male: 'Mann',
        female: 'Frau',
        streetAddress: 'Straße und Hausnummer',
        postCode: 'Postleitzahl',
        city: 'Wohnort',
        phone: 'Telefon',
        email: 'E-Mail',
        iban: 'IBAN',
        bic: 'BIC',
        bicTooltip: 'Der BIC (Bank Identifier Code) ist ein internationaler Code. Er wird auch SWIFT-Code genannt und wird als zweite Kennung neben der IBAN verwendet. Der BIC besteht aus acht oder elf alphanumerischen Zeichen.',
        bank: 'Bank',
        accountHolder: 'Name des Kontoinhabers',
        membersInfo: 'Mitgliedsinformation',
        vfl: 'VfL Wolfsburg e. V.',
        intro: 'Auszug aus der Vereinssatzung vom 21.08.2020',
        success: 'Super, wir haben Ihre Anfrage erhalten.',
        attention: 'Bitte achten Sie auf Ihre E-Mail für weitere Details.',
        exclusionClauseLabel: '§ 6 - Ziffer 5',
        exclusionClause: 'Ein Mitglied kann, wenn es nach zweimaliger schriftlicher Mahnung die Zahlung bereits fallig gewordener Mitgliedsbeitrage und Umlagen - auch Zusatzbeitrage, Nutzungsentgelte und Abteilungsumlagen - unterlasst, ausgeschlossen werden. Im zweiten Mahnschreiben ist au! den bevorstehenden Ausschluss hinzuweisen.',
        bankClauseLabel: '§ 7-Ziffer 4',
        bankClause: 'Die Beitrage sind grundsatzlich bargeldlos im Einzugsverfahren vierteljahrlich zu entrichten und jeweils am Ende des ersten Monats im Quartal fallig. Sie sind den Abteilungen gutzuschreiben, in der das Mitglied eingeschrieben ist; gegebenenfalls ist eine Aufteilung vorzunehmen (z.B. Familienmitglieder in unterschied·lichen Abteilungen). Das Mitglied ist verpflichtet, dem Verein gegenOber eine entsprechende Ermachtigung zusammen mit dem Aufnahmeantrag abzugeben. In Ausnahmefallen kann au! Antrag einer Oberweisung oder Bareinzahlung au! ein Kanta des Vereins zugestimmt warden. Da!Or ist zur Deckung des zusatzlichen Verwaltungsaufwands ein Zuschlag van 1 O % eines Monatsbeitrags je Quartal, gerundet au! vollen Euro, zu zahlen. Datenschutzhinweise nach Art. 13 DSGVO zur Vereinsmitgliedschaft konnen aul der Homepage www.v1l-wob. de unter Verein/Datenschutzhinweise zum Eintritt ein·gesehen werden.'
    }
}

/**
 * Exports the language object based on the selected language
 * @type {Object}
 */
export const language = (function () {
    switch (selectedLanguage) {
    case en:
        return languageEng
    case de:
        return languageDeu
    default:
        return languageEng
    }
}())
