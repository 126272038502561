import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { pricelistItems } from './pricelist-items'
import PriceListItem from './pricelist_item'
import { language } from '../../../../theme/commons/language/language'
import './membershipFirstStep.scss'

/**
 * The first step of the {@link MembersForm}.
 * @class MembershipFirstStep
 * @category Components
 * @subcategory Pages / become-a-member
 * @returns {React.ReactNode}
 * @example
 * <MembershipFirstStep />
 */
const MembershipFirstStep = () => {
    return (
        <Container className={'membership-prices'}>
            <Row className={'membership-prices__title'}>
                <Col>
                    <h3>{language.membership.ourPrices}</h3>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>{language.membership.contributions}</p>
                </Col>
            </Row>
            <Row xs={1} sm={2} className={'members-form-content'}>
                <Col className={'pricelist-container'}>
                    <div className={'members-form-content-pricelist'}>
                        {pricelistItems.map((item, index) =>
                            <PriceListItem key={index} item={item}/>)}
                    </div>
                </Col>
                <Col className={'description-container'}>
                    <h5>{language.membership.reductions}</h5>
                    <h5>{language.membership.admissionFee}:</h5>
                    <p>{language.membership.admissionFeeDetail}</p>
                    <h5>{language.membership.terminationDates}:</h5>
                    <p>{language.membership.resignation}</p>
                </Col>
            </Row>
        </Container>
    )
}

export default MembershipFirstStep
