import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { language } from '../../../../theme/commons/language/language'
import './membershipSecondStep.scss'
// components
import FormTypes from './components/form_types'
import FormDetails from './components/form_details'
import MemberSince from './components/member_since'
import Family from './components/family'
import FormInput from './components/form_input'
// utils
import { formPropsById, IDS } from './utils/inputFieldsList'
import {
    handleFormUpdate,
    handleMembershipTypeChange
} from './utils/handleFormDetails'
import {
    addFamilyMember,
    handleFamilyMembersChange,
    removeFamilyMember,
    formToFamilyMembers
} from './utils/familyUtils'
import { getPhoneValueFromUserInformation } from '../../../../utils/phoneUtils'

const isFieldValid = ({ value, hasError, id, index = undefined }) => value?.length > 0 && !hasError
const validateForm = (form) => {
    const ids = [
        IDS.POSTCODE,
        IDS.COUNTRY_CODE,
        IDS.PHONE,
        IDS.EMAIL,
        IDS.IBAN,
        IDS.BANK,
        IDS.BIC,
        IDS.ACC_HOLDER,
        IDS.CITY,
        IDS.POSTCODE,
        IDS.STREET_ADDRESS,
        IDS.GENDER,
        IDS.BIRTHDAY,
        IDS.FIRSTNAME,
        IDS.FAMILY_NAME,
        IDS.DATE
    ]
    const hasSelectedMembershipType = form[IDS.TYPES]?.value?.id !== undefined
    const areOtherFieldsValid = ids
        .map((id) => ({ ...form[id], id }))
        .map((field) => isFieldValid(field))
        .reduce((a, b) => a && b, true)

    const areOtherMembersDataValid = form[IDS.FAMILY_MEMBERS].value
        .map(
            (member, index) =>
                isFieldValid({ ...member[IDS.BIRTHDAY], id: IDS.BIRTHDAY, index }) &&
        isFieldValid({
            ...member[IDS.FIRSTNAME],
            id: IDS.FIRSTNAME,
            index
        })
        )
        .reduce((a, b) => a && b, true)
    return !!(hasSelectedMembershipType && areOtherMembersDataValid && areOtherFieldsValid)
}

/**
 * @typedef MembershipSecondStepProps
 * @memberOf MembershipSecondStep
 * @property {function} onFormValidationChange
 * @property {function} onValidForm
 * @property {Object} userInformation
 */

/**
 * The second step of the {@link MembersForm}.
 * @class MembershipSecondStep
 * @category Components
 * @subcategory Pages / become-a-member
 * @param {MembershipSecondStepProps} props
 * @returns {React.ReactNode}
 * @example
 * <MembershipSecondStep
 *     onFormValidationChange={handleFormValidationChange}
 *     onValidForm={handleValidFormUpdate}
 *     userInformation={form}
 * />
 */
const MembershipSecondStep = (props) => {
    const { onFormValidationChange, onValidForm, userInformation } = props

    const [form, setForm] = useState({
        [IDS.TYPES]: { value: {} },
        [IDS.DATE]: { value: userInformation ? userInformation[IDS.DATE] : '' },
        [IDS.FAMILY_NAME]: {
            value: userInformation ? userInformation[IDS.FAMILY_NAME] : ''
        },
        [IDS.FIRSTNAME]: {
            value: userInformation ? userInformation[IDS.FIRSTNAME] : ''
        },
        [IDS.FAMILY_MEMBERS]: { value: [] },
        [IDS.BIRTHDAY]: {
            value: userInformation ? userInformation[IDS.BIRTHDAY] : ''
        },
        [IDS.GENDER]: { value: userInformation ? userInformation[IDS.GENDER] : '' },
        [IDS.STREET_ADDRESS]: {
            value: userInformation ? userInformation[IDS.STREET_ADDRESS] : ''
        },
        [IDS.POSTCODE]: {
            value: userInformation ? userInformation[IDS.POSTCODE] : ''
        },
        [IDS.CITY]: { value: userInformation ? userInformation[IDS.CITY] : '' },
        [IDS.COUNTRY_CODE]: { value: userInformation ? userInformation[IDS.COUNTRY_CODE] : '' },
        [IDS.PHONE]: { value: getPhoneValueFromUserInformation(userInformation, IDS.PHONE, IDS.COUNTRY_CODE) },
        [IDS.EMAIL]: { value: userInformation ? userInformation[IDS.EMAIL] : '' },
        [IDS.IBAN]: { value: userInformation ? userInformation[IDS.IBAN] : '' },
        [IDS.BIC]: { value: userInformation ? userInformation[IDS.BIC] : '' },
        [IDS.BANK]: { value: userInformation ? userInformation[IDS.BANK] : '' },
        [IDS.ACC_HOLDER]: {
            value: userInformation ? userInformation[IDS.ACC_HOLDER] : ''
        }
    })
    const isValid = React.useMemo(() => validateForm(form), [form])

    React.useEffect(() => {
        onFormValidationChange(isValid)
    }, [isValid])
    React.useEffect(() => {
        if (isValid) {
            const validatedForm = {}
            Object.keys(form).forEach((key) => {
                validatedForm[key] = form[key].value
            })
            validatedForm[IDS.TYPES] = validatedForm[IDS.TYPES].id
            validatedForm[IDS.FAMILY_MEMBERS].forEach((member, index) => {
                Object.keys(member).forEach((key) => {
                    validatedForm[IDS.FAMILY_MEMBERS][index][key] =
                        validatedForm[IDS.FAMILY_MEMBERS][index][key].value
                })
            })
            onValidForm(validatedForm)
        }
    }, [isValid, form])

    /**
     * Gets the new object updated and saves it on the form state.
     * @function
     * @memberOf MembershipSecondStep
     * @param {string} id
     * @param {Object|string} value
     * @param {boolean} hasError
     */
    const handleChanges = (id, value, hasError) => {
        setForm((lastForm) => updateLastForm(lastForm, id, value, hasError))
    }

    /**
     * Update the form state with the new information.
     * @function
     * @memberOf MembershipSecondStep
     * @param {Object} lastForm
     * @param {string} id
     * @param {Object|string} value
     * @param {boolean} hasError
     * @returns {Object} - Updated object to be applied to the form state.
     */
    const updateLastForm = (lastForm, id, value, hasError) => ({ ...lastForm, [id]: { value, hasError } })

    /**
     * @function
     * @memberOf MembershipSecondStep
     * @param changes
     */
    const applyChanges = (changes) => {
        changes.forEach(({ id, value, hasError }) =>
            handleChanges(id, value, hasError)
        )
    }

    /**
     * @function
     * @memberOf MembershipSecondStep
     * @param {string} id
     * @param {Object|string} newValue
     * @param {boolean} hasError
     */
    const handleFormChanges = (id, newValue, hasError) => {
        applyChanges(handleFormUpdate(id, newValue, hasError))
    }
    /**
     * @function
     * @memberOf MembershipSecondStep
     * @param {Object|string} newValue
     * @param {boolean} hasError
     */
    const handleTypesChanges = (newValue, hasError) => {
        applyChanges(
            handleMembershipTypeChange(form[IDS.FAMILY_MEMBERS], newValue, hasError)
        )
    }
    /**
     * @function
     * @memberOf MembershipSecondStep
     * @param {Object|string} newValue
     * @param {boolean} hasError
     * @param {string} fieldId
     * @param {number} index
     */
    const handleFamilyChanges = (newValue, hasError, fieldId, index) => {
        applyChanges(
            handleFamilyMembersChange(
                form[IDS.FAMILY_MEMBERS],
                newValue,
                hasError,
                fieldId,
                index
            )
        )
    }

    /**
     * @function
     * @memberOf MembershipSecondStep
     */
    const handleAddMember = () =>
        applyChanges(addFamilyMember(form[IDS.FAMILY_MEMBERS]))

    /**
     * @function
     * @memberOf MembershipSecondStep
     * @param index
     */
    const handleRemoveMember = (index) =>
        applyChanges(removeFamilyMember(form[IDS.FAMILY_MEMBERS]))

    /**
     * @const
     * @memberOf MembershipSecondStep
     * @type {Array<string>}
     * @example
     * const detailsIds = [
     *     IDS.STREET_ADDRESS,
     *     IDS.POSTCODE,
     *     IDS.CITY,
     *     IDS.PHONE,
     *     IDS.EMAIL,
     *     IDS.IBAN,
     *     IDS.BIC,
     *     IDS.BANK,
     *     IDS.ACC_HOLDER
     * ]
     */
    const detailsIds = [
        IDS.STREET_ADDRESS,
        IDS.POSTCODE,
        IDS.CITY,
        IDS.PHONE,
        IDS.EMAIL,
        IDS.IBAN,
        IDS.BIC,
        IDS.BANK,
        IDS.ACC_HOLDER
    ]

    /**
     * @function
     * @memberOf MembershipSecondStep
     * @param form
     * @param ids
     * @returns {Map<any, any>}
     */
    const elementsWithId = (form, ids) => {
        const elementsDictionary = new Map()
        ids.forEach(id => {
            elementsDictionary[id] = <FormInput
                key={id}
                type={formPropsById(id).type}
                label={formPropsById(id).label}
                onChange={handleFormChanges.bind(null, id)}
                value={form[id].value}
                readOnly={id === IDS.PHONE || id === IDS.EMAIL}
                validationTypes={formPropsById(id).validationTypes}
            />
        })
        return elementsDictionary
    }

    const familyMembers = formToFamilyMembers(
        form[IDS.BIRTHDAY].value,
        form[IDS.FIRSTNAME].value,
        form[IDS.FAMILY_MEMBERS].value
    )

    const { min, max, id: typeId } = form[IDS.TYPES].value
    const { value: gender } = form[IDS.GENDER]
    const { value: familyName } = form[IDS.FAMILY_NAME]
    const { value: memberSince } = form[IDS.DATE]

    return (
        <Container className={'membership-admission-form'}>
            <Row className={'membership-admission-form-title'}>
                <Col>
                    <h3>{language.membership.admission}</h3>
                </Col>
            </Row>
            <Row xs={1} sm={2} className={'membership-admission-form-content'}>
                <FormTypes
                    selectedType={typeId}
                    onSelectType={handleTypesChanges}
                    memberSinceElement={
                        <MemberSince
                            date={memberSince}
                            onChangeDate={handleChanges.bind(null, IDS.DATE)}
                        />
                    }
                />
                {form[IDS.TYPES]?.value?.id && (
                    <FormDetails
                        detailsElements={elementsWithId(form, detailsIds)}
                        familyElement={
                            <Family
                                minFamily={min}
                                maxFamily={max}
                                gender={gender}
                                familyName={familyName}
                                onChangeMembers={handleFamilyChanges}
                                onChangeField={handleChanges}
                                onClickAdd={handleAddMember}
                                onClickRemove={handleRemoveMember}
                                familyMembers={familyMembers}
                                readOnly={false}
                            />
                        }
                    />
                )}
            </Row>
        </Container>
    )
}

export default MembershipSecondStep
