import React, { useState } from 'react'
import './EditTemplateBar.scss'
import AdminButton from '../../../commons/admin_button/AdminButton'
import TemplateEditorModal from '../cms_edit_template/TemplateEditorModalContainer'
import { cmsLanguage } from '../../../commons/cms_language/cmsLanguage'

const EditTemplateBar = (props) => {
    const {
        template,
        templatePosition,
        onSave,
        onDelete,
        availableTemplates
    } = props

    const [showEditModal, setShowEditModal] = useState(false)

    const toggleEditModal = () => {
        ((template.elements && template.elements.length > 0) || (!template.type)) &&
        setShowEditModal(!showEditModal)
    }

    const onSaveHandler = localTemplate => {
        onSave(localTemplate.id, localTemplate)
        toggleEditModal()
    }

    const onDeleteHandler = template => {
        onDelete(template.id)
        toggleEditModal()
    }

    const isNotATemplate = template.type === 'FOOTER' || template.type === 'HEADER' || template.type === 'SOCIAL_MEDIA'
    const isDeleteButtonAvailable = (templatePosition !== 0 && !isNotATemplate) ? onDeleteHandler : false

    const editButtonClassName = () => {
        if (isNotATemplate) {
            return 'edit-template-bar no-template-button'
        } else {
            return `edit-template-bar ${
                (templatePosition === 0) ? 'top-template' : 'content-template'
            } horizontal-website-padding`
        }
    }

    return (
        <>
            {showEditModal &&
            <TemplateEditorModal
                template={template}
                onClose={toggleEditModal}
                onSave={onSaveHandler}
                onDelete={isDeleteButtonAvailable}
                availableTemplates={availableTemplates}
            />}

            <div className={editButtonClassName()}>
                <AdminButton
                    text={!isNotATemplate && cmsLanguage.cms.edit}
                    onClick={toggleEditModal}
                    type={isNotATemplate ? 'border-solid' : 'solid'}
                    color='green'
                    icon={'edit'}
                    data-testid='edit-template-button'
                />
            </div>
        </>
    )
}

export default EditTemplateBar
