import templateRoot from '@src/cms/cms_templates_bars/components/empty_templates_inputs/TemplateRoot'
import { templateTypes } from '../cms_templates_bars/components/cms_modal_multiple_elements/constants'

/**
 * @module importTemplateInputs
 * @category Utils
 * @subcategory Cms
 */

/**
 * Returns the template inputs based on the received type.
 * @function
 * @inner
 * @static
 * @param {string} templateType
 * @returns {Object}
 */
const generateTemplate = (templateType) => {
    let tempInputs

    switch (templateType) {
    case templateTypes.CARROUSEL:
        tempInputs = templateRoot.carousel()
        break
    case templateTypes.GREY_BANNER:
        tempInputs = templateRoot.greyBanner()
        break
    case templateTypes.TEXT_AREA:
        tempInputs = templateRoot.textArea()
        break
    case templateTypes.ACTION_BUTTON_BANNER:
        tempInputs = templateRoot.actionButtonBanner()
        break
    case templateTypes.VIDEO:
        tempInputs = templateRoot.video()
        break
    case templateTypes.SMALL_CARROUSEL:
        tempInputs = templateRoot.smallCarousel()
        break
    case templateTypes.PARALLAX:
        tempInputs = templateRoot.parallax()
        break
    case templateTypes.MARQUESINA:
        tempInputs = templateRoot.marquesina()
        break
    case templateTypes.COMMON_BANNER:
        tempInputs = templateRoot.commonBanner()
        break
    case templateTypes.ACCORDION:
        tempInputs = templateRoot.accordion()
        break
    case templateTypes.PROFILES:
        tempInputs = templateRoot.profile()
        break
    case templateTypes.IMAGE_LIBRARY:
        tempInputs = templateRoot.imageLibrary()
        break
    case templateTypes.GRID:
        tempInputs = templateRoot.homeNews()
        break
    case templateTypes.COURSE:
        tempInputs = templateRoot.courseInfo()
        break
    case templateTypes.MEMBERS_FORM:
        tempInputs = templateRoot.membersForm()
        break
    case templateTypes.INSTAGRAM:
        tempInputs = templateRoot.instagram()
        break
    default:
        tempInputs = templateRoot.textArea()
    }

    return tempInputs
}

export default generateTemplate
