// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".profile-image-field{width:11.25em;height:11.25em;border-radius:50%;background-color:#fff;position:absolute;top:-5.625em;left:50%;transform:translateX(-50%)}.profile-image-field__frame{display:flex;align-items:center;justify-content:center;height:100%;width:100%;border-radius:50%;background-color:rgba(100,180,45,0.5);cursor:pointer;background-size:cover;background-position:center}.profile-image-field__frame:hover{opacity:0.9}.profile-image-field__icon{color:white;height:5.625em;width:auto !important}.profile-image-field input{display:none}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
