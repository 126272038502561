import { cmsLanguage } from '../cms/commons/cms_language/cmsLanguage'

export function getInitialTACValues () {
    const initialValuesTAC = {}
    cmsLanguage.termsAndConditions.content.dataProtectionAndTools.content.list.forEach(
        (tacItem) => {
            initialValuesTAC[tacItem.id] = false
        })
    return initialValuesTAC
}
