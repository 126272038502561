import { Prompt } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import BlockNavigationAlertModal from '../../../theme/commons/BlockNavigationAlertModal/BlockNavigationAlertModal'
import React, { useCallback, useEffect, useState } from 'react'

/**
 * Reset the listeners when this component is mounted.
 * @function
 * @memberOf PromptBlockNavigation
 * @param {Object} event
 * @returns {*}
 */
export const unloadVerification = (event) => {
    event.preventDefault()
    event.returnValue = true
    return event
}

/**
 * @typedef PromptBlockNavigationProps
 * @memberOf PromptBlockNavigation
 * @property {boolean} shouldBlockNavigation
 */

/**
 * Container to the modal that will block the navigation when a warning happens.
 * @class PromptBlockNavigation
 * @category Components
 * @subcategory cms / commons
 * @param {PromptBlockNavigationProps} props
 * @returns {React.ReactNode}
 */
const PromptBlockNavigation = ({ shouldBlockNavigation }) => {
    const [showWarning, setShowWarning] = useState(false)
    const [nextLocation, setNextLocation] = useState()
    const beforeUnloadListener = useCallback(event => {
        unloadVerification(event)
    }, [])

    useEffect(() => {
        if (shouldBlockNavigation) {
            window.addEventListener('beforeunload', beforeUnloadListener)
        } else {
            window.removeEventListener('beforeunload', beforeUnloadListener)
        }
        return () => {
            window.removeEventListener('beforeunload', beforeUnloadListener)
        }
    }, [shouldBlockNavigation])

    /**
     * If the parameter `shouldBlockNavigation` is true, shows a modal warning.
     * @function
     * @memberOf PromptBlockNavigation
     * @param {Object} location
     * @returns {boolean}
     */
    const handleShowBlockNavigationModal = (location) => {
        setNextLocation(location.pathname)
        if (shouldBlockNavigation) {
            setShowWarning(true)
            return false
        }
        return true
    }

    /**
     * When clicks on the modal continue button, closes the modal and goes to the next location.
     * @function
     * @memberOf PromptBlockNavigation
     */
    const handleOnContinueNavigationModal = () => {
        if (nextLocation) {
            window.location.pathname = nextLocation
            setShowWarning(false)
        }
    }

    return (
        <>
            <Prompt message={handleShowBlockNavigationModal} />
            {showWarning && (
                <Modal
                    className="modal-common-config"
                    size="md"
                    data-testid="modal-alert"
                    show={showWarning}
                >
                    <BlockNavigationAlertModal
                        onClose={() => setShowWarning(false)}
                        onContinue={handleOnContinueNavigationModal}
                    />
                </Modal>
            )}
        </>
    )
}
export default PromptBlockNavigation
