import { cmsLanguage } from '../../../../cms/commons/cms_language/cmsLanguage'

export const EMPTY_COURSES = () => [
    { label: cmsLanguage.content.memberAreaCourseDate, values: [] },
    { label: cmsLanguage.content.memberAreaCourse, values: [] },
    { label: cmsLanguage.content.memberAreaAmount, values: [] },
    { label: cmsLanguage.content.memberAreaPaymentDate, values: [] }
]

export const WITH_COURSES = [
    { label: cmsLanguage.content.memberAreaCourseDate, values: [{ name: '01-01-2022', id: '1' }] },
    { label: cmsLanguage.content.memberAreaCourse, values: [{ name: 'Yoga', id: '1' }] },
    { label: cmsLanguage.content.memberAreaAmount, values: [{ name: '50$', id: '1' }] },
    { label: cmsLanguage.content.memberAreaPaymentDate, values: [{ name: '01-01-2022', id: '1' }] }
]
