import React, { useEffect } from 'react'
import Mesh from '@backend/mesh/Mesh'
import { get } from '@utils/storage'
import STORAGE_KEYS from '@utils/storageKeys'

/**
 *
 * @function
 * @category Hooks
 * @subcategory Templates / CourseInfo
 * @returns {Array<boolean|string>} - [!!user, name]
 */
const useUserName = () => {
    const user = get(STORAGE_KEYS.USER)
    const [name, setName] = React.useState('')

    useEffect(() => {
        let isCancelled = false

        // declare fetching function
        const fetchUserData = () => {
            Mesh.getUserData()
                .then((response) => {
                    !isCancelled && setName(response.fullName)
                })
                .catch((err) => console.error(err))
        }

        // consume it when it is not unmounting and has possible identification
        !isCancelled && user && fetchUserData()
        return () => {
            isCancelled = true
        }
    }, [])

    return [!!user, name]
}

export default useUserName
