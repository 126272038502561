import React from 'react'
import './ProfileWithMenu.scss'
import ProfileMenuContent from './ProfileMenuContent'
import ProfileAvatar from './ProfileAvatar'

/**
 * Shows the {@link ProfileAvatar} and {@link ProfileMenuContent}
 * @class ProfileWithMenu
 * @category Components
 * @subcategory Header
 * @returns {React.ReactNode}
 * @example
 * <ProfileWithMenu isOpen={profileOpened} onClose={onProfileMenuClose} isMobile={!fullDesktopMenu}/>
 */

const ProfileWithMenu = () => {
    const [isOpen, setIsOpen] = React.useState(false)
    const handleAvatarOnClick = () => {
        setIsOpen(!isOpen)
    }
    const handleOnClose = () => {
        setIsOpen(false)
    }
    return (
        <div className={'profile-dropdown'}>
            <div className="profile-dropdown__wrapper--avatar avatar">
                <ProfileAvatar onClick={handleAvatarOnClick} />
                <ProfileMenuContent isOpen={isOpen} onClose={handleOnClose} />
            </div>
        </div>
    )
}

export default ProfileWithMenu
