import React from 'react'
import { cmsLanguage } from '../cms/commons/cms_language/cmsLanguage'
import { resetTermsAndConditions } from '../footer/Footer'

const TermsAndConditionsWarning = () => {
    return <h5 className='video-player-info-tac'>
        {cmsLanguage.termsAndConditions.warning}
        <br/>
        <button style={ { alignSelf: 'center' } } onClick={resetTermsAndConditions}>
            {cmsLanguage.termsAndConditions.resetAcceptance}
        </button>
    </h5>
}

export default TermsAndConditionsWarning
