import React from 'react'
import './InputText.scss'
import { cmsLanguage } from '../../../cms_language/cmsLanguage'
import { NAME_PATTERN } from '../input_component/constants'

const InputText = (props) => {
    const { value, onChange, onFocus, onBlur, maxLength, disabled, isCardName, onError, readOnly } = props

    const isValidCardName = (name) => name ? NAME_PATTERN.test(name) : true

    const handleOnChange = (event) => {
        const {
            value: newValue
        } = event.target

        let hasError = false
        if (isCardName) {
            hasError = !isValidCardName(newValue)
            showError(hasError, newValue)
            onChange(newValue, hasError)
        } else {
            onChange(newValue)
        }
    }

    const showError = (hasError, value) => {
        if (hasError && value.length) {
            onError(
                cmsLanguage.error.invalidField + '.'
            )
        } else {
            onError()
        }
    }

    return (
        <div className="cms-input-box cms-input-box-text cms-input-text">
            <input
                data-testid="cms-input-text"
                type="text"
                value={value}
                name={isCardName && 'cardName'}
                onChange={handleOnChange}
                disabled={disabled}
                onFocus={onFocus}
                onBlur={onBlur}
                maxLength={maxLength}
                placeholder={isCardName ? cmsLanguage.inputFields.cardNamePlaceholder : !value && disabled ? '' : cmsLanguage.cms.textPlaceholder + '...'}
                readOnly={readOnly}
                className={readOnly ? 'read-only' : ''}
            />
            {maxLength && (
                <div>
                    <span data-testid="cms-input-text-counter">
                        {value.length} / {maxLength}
                    </span>
                </div>
            )}
        </div>
    )
}

export default InputText
