/**
 * @module languageUtils
 * @category Utils
 * @subcategory General
 */

/**
 * Variable name to be used to store the selected language locally
 * @type {string}
 * @const
 */
const LANG_KEY = 'LANG'

/**
 * It takes a URL parameter called `lang` and sets the app's language using that parameter.
 * If not found, set `DE` as default language (`EN` is default language for tests)
 * @function
 * @returns {string} - language selected or default language
 */
export const getLanguage = () => {
    if (process.env.NODE_ENV === 'test') return 'en'
    const searchParams = new URLSearchParams(window.location.search)
    let selectedLanguage = searchParams.get('lang')
    if (!selectedLanguage || selectedLanguage === '') selectedLanguage = localStorage.getItem(LANG_KEY)
    else localStorage.setItem(LANG_KEY, selectedLanguage)
    return selectedLanguage || 'de'
}
