import React from 'react'
import { cmsLanguage } from '../../../cms_language/cmsLanguage'
import '../input_label/InputLabel.scss'
import { MASTER_CARD_PATTERN, VISA_CARD_PATTERN } from '../input_component/constants'

const InputCardNumber = (props) => {
    const { value, onChange, onFocus, onBlur, maxLength, onError } = props

    const isValidCardNumber = (cardNumber) => cardNumber ? getPatterByType(cardNumber[0]).test(cardNumber) : true

    const getPatterByType = (firstNumber) => {
        if (firstNumber === '4') {
            return VISA_CARD_PATTERN
        } else {
            return MASTER_CARD_PATTERN
        }
    }

    const handleOnChange = (event) => {
        const {
            value: newValue
        } = event.target

        const hasError = !isValidCardNumber(newValue)
        showError(hasError, newValue)
        onChange(newValue, hasError)
    }

    const showError = (hasError, value) => {
        if (hasError && value.length) {
            onError(
                cmsLanguage.error.notValidCard + '.'
            )
        } else {
            onError()
        }
    }

    return (
        <div className="cms-input-box cms-input-box-icon cms-input-card-number">
            <input
                data-testid="cms-input-card-number"
                id={'card-number-input'}
                name={'cardNumber'}
                type="tel"
                value={value}
                onChange={handleOnChange}
                onFocus={onFocus}
                onBlur={onBlur}
                maxLength={maxLength}
                placeholder={cmsLanguage.inputFields.cardNumberPlaceholder}
            />
        </div>
    )
}

export default InputCardNumber
