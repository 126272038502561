import store from '../../../redux/store'
import { CONFIG, MESH_CONFIG } from '../Constants'
import axios from 'axios'

/**
 * Returns the calendar-service base url to eventsGroup
 * @function
 * @memberOf MeshEventsGroup
 * @alias BASE_URL
 * @param {string|number} orgId
 * @returns {string}
 */
function BASE_URL (orgId) {
    return `${MESH_CONFIG.SERVER_URL}/users-service/organizations/${orgId}/users`
}

/**
 * Returns an object with the default header configuration (Content-type, x-api-key and authorization bearer token)
 * @function
 * @memberOf MeshEventsGroup
 * @param {string} accessToken
 * @returns {Object}
 * @example
 * {
 *   'x-api-key': '5c13a.....'
 *   CONTENT_TYPE: 'application/json',
 *   Authorization: `Bearer ${accessToken}`
 * }
 */
function DEFAULT_CONFIG (accessToken) {
    return ({
        headers: {
            ...CONFIG.DEFAULT_HEADERS,
            CONTENT_TYPE: 'application/json',
            Authorization: `Bearer ${accessToken}`
        }
    })
}

/**
 * @class MeshUsers
 * @category Backend
 * @subcategory users_service
 */
export default class MeshUsers {
    /**
     * @function
     * @memberOf MeshUsers
     * @param {Array<*>} userData
     * @return {Promise<User>}
     * @static
     */
    static async patchUserData (userData) {
        const { accessToken, organizationId, id } = store.getState().user
        const url = `${MESH_CONFIG.SERVER_URL}/users-service/organizations/${organizationId}/users/${id}`

        const config = {
            headers: {
                ...CONFIG.DEFAULT_HEADERS,
                CONTENT_TYPE: 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        userData.orgId = organizationId

        const updatedUserData = await axios.patch(url, userData, config)

        return updatedUserData.data
    }

    static getUsersById (userIds) {
        const { accessToken, organizationId } = store.getState().user
        const url = `${BASE_URL(organizationId)}?id%5B%5D=${userIds.join()}`
        const config = DEFAULT_CONFIG(accessToken)

        return axios.get(url, config)
    }
}
