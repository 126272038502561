// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".contact-hours .contact-hours-title-row{display:flex;align-items:center;margin-bottom:.9375em}.contact-hours .contact-hours-title-row .contact-icon-container{width:2.8125em}.contact-hours .contact-hours-title-row .contact-icon-container .contact-icon{color:#64b42d;font-size:1.625em}.contact-hours .contact-hours-title-row h4{font-family:'Open Sans', sans-serif;font-weight:700;font-style:normal;line-height:1.625em;font-size:1.375em;color:#191919;display:inline-block;margin:0}.contact-hours p{font-family:'Open Sans', sans-serif;font-weight:normal;font-style:normal;font-size:1em;color:#191919;line-height:2em;line-height:1.375em;margin:0}.contact-hours .col-left-contact-text p{font-weight:600}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
