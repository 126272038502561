import { DEFAULT_INSCRIPTIONS_NUMBER } from '../calendar_service/MeshEventsGroup'
import { PRODUCT_AVAILABLE } from '../product_service/MeshProducts'

/**
 * Product
 * @class Product
 * @category Backend
 * @subcategory mesh_domain
 */

/**
 * @function
 * @memberOf Product
 * @param {string} imageUrl
 * @param {string} name
 * @param {string} description
 * @param {number} price
 * @param {number} stock
 * @param {Array<*>} tags
 * @param {string} status
 * @returns {Product}
 */
export const Product = ({
    images,
    name,
    description = '',
    pricingDetails,
    stock,
    tags = { category: [], eventsGroupId: [] },
    status
}) => ({
    name,
    images,
    description,
    tags,
    pricingDetails,
    stock,
    status
})

/**
 * @function
 * @memberOf Product
 * @param {string} imageUrl
 * @param {string} name
 * @param {string} description
 * @param {number} price
 * @param {number} stock
 * @param {Array<*>} tags
 * @param {string} status
 * @returns {Product}
 */
export const EventRelatedProduct = ({
    images,
    name,
    description,
    pricingDetails,
    stock = DEFAULT_INSCRIPTIONS_NUMBER,
    tags,
    status = PRODUCT_AVAILABLE
}) => {
    return Product({ images, name, description, pricingDetails, stock, tags, status })
}
