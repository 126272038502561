import React from 'react'
import './SportPictureCard.scss'
import { NAVIGATION_TYPE } from '../../../../../theme/constants/navigationType'
import NavigationWrapper from '../../../../../theme/commons/navigation_wrapper/NavigationWrapperContainer'

/**
 * @typedef SportPictureCardProps
 * @memberOf SportPictureCard
 * @property {string} sport
 * @property {string} picture
 * @property {string} link
 * @property {string} testId
 */

/**
 * Card to the image of the carousel with sports information.
 * @class SportPictureCard
 * @category Components
 * @subcategory Home
 * @param {SportPictureCardProps} props
 * @returns {React.ReactNode}
 * @example
 * <SportPictureCard
 *     sport={item.H1}
 *     picture={item.PHOTO}
 *     link={item.LINK}
 *     testId={item.element.id}
 * />
 */
const SportPictureCard = (props) => {
    const { sport, picture, link, testId } = props

    return (
        <>
            <NavigationWrapper
                type={NAVIGATION_TYPE.link}
                href={link}
                className="sport-picture-container"
                data-testid="container-sport-picture-card"
            >
                <div
                    data-testid={'picture-sport-picture-card-' + testId}
                    className="picture"
                    style={{ backgroundImage: `url(${picture})` }}
                >
                    <div className="shadow">
                        <div className="sport">
                            <span data-testid="sport-sport-picture-card">{sport}</span>
                        </div>
                    </div>
                </div>
            </NavigationWrapper>
        </>

    )
}

export default SportPictureCard
