import React from 'react'
import './ProfilesTemplate.scss'
import ProfileItem from './components/profile_item/ProfileItem'
import { Col, Container, Row } from 'react-bootstrap'

/**
 * @typedef ProfilesTemplateProps
 * @memberOf ProfilesTemplate
 * @property {Array<Object>} profilesContent
 */

/**
 * ProfilesTemplate component.
 * Render the {@link ProfileItem} component.
 * @class ProfilesTemplate
 * @category Components
 * @subcategory Templates / ProfilesTemplate
 * @param {ProfilesTemplateProps} props
 * @returns {React.ReactNode}
 * @example
 * <ProfilesTemplate profilesContent={profilesContent} />
 */
const ProfilesTemplate = (props) => {
    const { profilesContent } = props
    return (
        <Container
            fluid
            className="profiles-container vertical-website-padding horizontal-website-padding"
        >
            <Row>
                {profilesContent.map((item, index) => {
                    return (
                        <Col xl={3} sm={6} className="profiles-col-item" key={index}>
                            <ProfileItem
                                picture={item.PHOTO && item.PHOTO}
                                name={item.H1}
                                role={item.H2}
                                address={item.TEXT && item.TEXT}
                                phone={item.PHONE && item.PHONE}
                                email={item.EMAIL && item.EMAIL}
                            />
                        </Col>
                    )
                })}
            </Row>
        </Container>
    )
}

export default ProfilesTemplate
