import { CONFIG, MESH_CONFIG } from '../Constants'
import store from '../../../redux/store'
import axios from 'axios'

/**
 * @class OrderRequest
 * @memberOf MeshOrders
 */
class OrderRequest {
    constructor (products, paymentMethod, concept, paymentDetails) {
        this.orderItems = products
        this.paymentMethod = paymentMethod
        this.concept = concept
        this.paymentRequest = paymentDetails
    }
}

/**
 * @class MeshOrders
 * @category Backend
 * @subcategory orders_service
 */
export default class MeshOrders {
    /**
     * @function
     * @memberOf MeshOrders
     * @returns {{config: {headers: {Authorization: string, 'x-api-key': string, CONTENT_TYPE: string}}, url: string}}
     * @static
     */
    static baseData () {
        const { accessToken, organizationId, id } = store.getState().user
        return {
            url: `${MESH_CONFIG.SERVER_URL}/orders-service/organizations/${organizationId}/users/${id}/orders`,
            config: {
                headers: {
                    ...CONFIG.DEFAULT_HEADERS,
                    CONTENT_TYPE: 'application/json',
                    Authorization: `Bearer ${accessToken}`
                }
            }
        }
    }

    /**
     * @function
     * @memberOf MeshOrders
     * @param {Array} products - It should include an {object} with productId and quantity fields
     * @param {string} paymentMethod - Method used to pay
     * @param {string} concept - Little description or related data
     * @param {object | undefined} paymentDetails - Details for VrPayment
     * @returns {Promise<Array<Order>>}
     * @static
     */
    static async sendOrder ({ products, paymentMethod = '', concept = '', paymentDetails }) {
        const url = MeshOrders.baseData().url
        const data = new OrderRequest(products, paymentMethod, concept, paymentDetails)
        const config = MeshOrders.baseData().config
        const response = await axios.post(url, data, config)
        return response.data
    }
}
