import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { language } from '../../../../../../theme/commons/language/language'
import FormInput from '../form_input'
import { INPUT_TYPES } from '../../../../../../cms/commons/cms_input/components/input_component/InputComponent'

const MemberSince = ({ date, onChangeDate }) => {
    return (
        <Row className="member_since">
            <Col lg={7}>
                <FormInput
                    type={INPUT_TYPES.DATE}
                    value={date || ''}
                    onChange={onChangeDate}
                    label={language.membership.date}
                />
            </Col>
        </Row>
    )
}

export default MemberSince
