import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import * as actions from '../actions/CMSActions'
import { setCustomNavigation } from '../../redux/actions/actions'
import AddCmsContentModalTwo from './AddCmsContentModalTwo'

const mapStateToProps = (state) => {
    const { cms } = state

    return {
        newContentMenuConfiguration: cms.newContentMenuConfiguration
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ ...actions, setCustomNavigation }, dispatch)

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    AddCmsContentModalTwo
)
