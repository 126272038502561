import React from 'react'
import { Card } from 'react-bootstrap'
import './CloudPhotos.scss'
import StandardModal from '../../../../../theme/commons/standard_modal/StandardModal'
import { cmsLanguage } from '../../../cms_language/cmsLanguage'

const CloudPhotos = ({ cloudImages, onClickCard, isOpen, closeModal, onSearchByLabel }) => {
    const [inputLabel, setInputLabel] = React.useState('')
    const handleOnClick = (href) => {
        onClickCard(href)
    }

    const handleInputTyping = (event) => {
        if (event.charCode === 13) {
            onSearchByLabel(event.target.value)
        } else {
            setInputLabel(event.target.value)
        }
    }

    const withImages = () => (
        <div className={'cloud-photos-items-wrapper'}>
            {cloudImages.map((img) => (
                <Card
                    data-testid={'photo-' + img.id}
                    className={'cloud-photos-item'}
                    style={{ width: '10rem' }}
                    key={img.id}
                    onClick={() => handleOnClick(img.href) }
                >
                    <Card.Img variant="top" src={img.href} />
                </Card>
            ))}
        </div>
    )

    const noImages = () => (<h5 className='cloud-photos_empty'>{cmsLanguage.cms.searchImageByLabel}</h5>)

    return (

        <StandardModal className={'cloud-photos'} title={'Mesh Images'} show={isOpen} onClose={closeModal} centered>
            <div className={'cloud-photos-dialog'}>
                <div className={'cloud-photos-search-wrapper'}>
                    <input
                        data-testid='search-bar'
                        className={'cloud-photos-search-input'}
                        type={'text'}
                        placeholder={'E.g: test'}
                        onChange={handleInputTyping}
                        onKeyPress={handleInputTyping}
                    />
                    <button
                        data-testid = 'search-button'
                        disabled={!inputLabel}
                        className={'cloud-photos-search-button'}
                        onClick={() => onSearchByLabel(inputLabel)}
                    >
                        Search
                    </button>
                </div>
                {cloudImages?.length > 0 && withImages()}
                {cloudImages?.length === 0 && noImages()}
            </div>
        </StandardModal>
    )
}

export default CloudPhotos
