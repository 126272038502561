// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".footer-menu-item{margin-bottom:.3125em}.footer-menu-item-container{list-style:none;padding:0;margin:0;max-width:14vw}.footer-menu-item a{color:inherit;text-decoration:inherit}.footer-menu-item a:hover{color:inherit;text-decoration:inherit}.footer-menu-item a:hover{color:#64b42d}.footer-menu-item a,.footer-menu-item div{font-family:rucksack, sans-serif;font-weight:400;font-style:normal;font-size:1em;color:#fff;font-weight:lighter}.footer-menu-item p{margin-bottom:0}@media screen and (min-width: 992px) and (max-width: 1200px){.footer-menu-item-container{max-width:28vw}}@media screen and (min-width: 768px) and (max-width: 992px){.footer-menu-item-container{max-width:65vw}}@media screen and (max-width: 767px){.footer-menu-item-container{max-width:inherit}}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
