export const sortContent = content => {
    // sort objects by date
    content.sort((a, b) => {
        const keyA = new Date(a.DATE)
        const keyB = new Date(b.DATE)
        if (keyA < keyB) return 1
        if (keyA > keyB) return -1
        return 0
    })

    // sort objects by year and convert to object
    let orderedContent = {}
    content.forEach(item => {
        const year = item.DATE.substring(0, 4)

        if (orderedContent[year]) {
            orderedContent = {
                ...orderedContent,
                [year]: [
                    ...orderedContent[year],
                    { ...item }
                ]
            }
        } else {
            orderedContent = {
                ...orderedContent,
                [year]: [
                    { ...item }
                ]

            }
        }
    })

    // convert object to array
    const convertToArray = obj => Object.keys(obj).map((key) => ({ [Number(key)]: obj[key] }))
    const orderedContentArr = convertToArray(orderedContent)

    // sort array by year
    orderedContentArr.sort((a, b) => {
        return Object.keys(b)[0] - Object.keys(a)[0]
    })

    return orderedContentArr
}
