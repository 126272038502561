import React from 'react'
import './contactHours.scss'
import { weekday } from '../../../../utils/DaysEnum'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter'

/**
 * @typedef ContactItemProps
 * @memberOf ContactItem
 * @property {Object} hours
 */

/**
 * Shows a text with hour information and a contact icon.
 * @class ContactItem
 * @category Components
 * @subcategory Templates / Contact
 * @param {ContactItemProps} props
 * @returns {React.ReactNode}
 * @example
 * <ContactHours hours={item}/>
 */
const ContactHours = ({ hours }) => {
    const hoursArr = hours.TEXT_1 && hours.TEXT_1.replace(/'/g, '"')

    return (
        <div className="contact-hours">
            <div className="contact-hours-title-row">
                <div className="contact-icon-container">
                    <FontAwesomeIcon
                        className="contact-icon"
                        icon={hours.SHORT_TEXT_2}
                    />
                </div>
                <h4 data-testid="contact-hours">{capitalizeFirstLetter(hours.H1)}</h4>
            </div>

            {JSON.parse(hoursArr).map((item, index) => {
                const allDays = item.days.map(d =>
                    weekday[d].substring(0, 3)
                )

                return (
                    <Row sm="row d-flex justify-content-between" key={index}>
                        <Col xs="left-contact-text" sm="auto">
                            <p data-testid={`contact-day-${item.days}`}>{item.days && allDays.join(', ')}</p>
                        </Col>
                        <Col xs="align-items-left" sm="auto">
                            <p data-testid={`contact-hour-${item.hours}`}>{item.hours && item.hours.join(' and ')}</p>
                        </Col>
                    </Row>
                )
            })}
        </div>
    )
}

export default ContactHours
