import { types } from '../types'

/**
 * Redux CMS Reducers.
 * @module CMSReducers
 * @category Redux
 * @subcategory Cms
 */

/**
 * @typedef Action
 * @property {CMSActionsTypes} type
 * @property {*} [payload]
 * @example
 *  {
 *    type: 'DISABLE_EDIT_TRAINING_PAGE',
 *    payload: { ... }
 *  }
 */

/**
 *
 * @const
 * @inner
 * @type {{isHomePage: boolean, isTrainingPage: boolean}}
 */
const initialState = {
    isTrainingPage: false,
    isHomePage: false
}

/**
 * CMS reducers.
 * @function
 * @inner
 * @param {Object} state - The current state
 * @param {Action} action - Any necessary data (including the action type)
 * @returns {Object} The new state
 */
function cmsReducer (state = initialState, action) {
    switch (action.type) {
    case types.DISABLE_EDIT_TRAINING_PAGE:
        return { ...state, isAddNewTemplateModeOn: false }
    case types.DISABLE_EDIT_MODE:
        return { ...state, isEditModeOn: false, isAddNewTemplateModeOn: false }
    case types.TOGGLE_ADD_NEW_TEMPLATE_MODE:
        return { ...state, isAddNewTemplateModeOn: !state.isAddNewTemplateModeOn }
    case types.TOGGLE_EDIT_MODE:
        return { ...state, isEditModeOn: !state.isEditModeOn, isAddNewTemplateModeOn: (!state.isEditModeOn) ? false : state.isAddNewTemplateModeOn }
    case types.TOGGLE_TRAINING_PAGE:
        return { ...state, isTrainingPage: action.payload }
    case types.IS_HOME_PAGE:
        return { ...state, isHomePage: action.payload }
    case types.SET_NEW_CONTENT_MENU_CONFIGURATION:
        return { ...state, newContentMenuConfiguration: action.payload }
    default:
        return state
    }
}

export default cmsReducer
