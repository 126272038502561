import React from 'react'
import './CommonBanner.scss'

/**
 * @typedef CommonBannerProps
 * @memberOf CommonBanner
 * @property {string} [backgroundImage]
 * @property {string} [pageTitle]
 * @property {string} [category]
 * @property {string} [text]
 */

/**
 * A full width banner that has an images as background, title, category name and a little text.
 * @class CommonBanner
 * @category Components
 * @subcategory theme / commons
 * @param {CommonBannerProps} props
 * @returns {React.ReactNode}
 * @example
 * <CommonBanner
 *    backgroundImage="public/background/image.jpg"
 *    pageTitle="Banner Title"
 *    category="Some category"
 *    text="A little long text"
 * />
 */
const CommonBanner = ({ backgroundImage, pageTitle, category, text }) => {
    return (
        <div className="common-banner" data-testid="common-banner">
            <div className="common-banner-image-container d-flex justify-content-center align-items-center">
                <div className="common-gradient-container d-flex justify-content-center align-items-center">
                    {backgroundImage && <span className="gradient-overlay" />}
                </div>
                <img
                    data-testid="common-banner-image"
                    className="common-banner-image"
                    src={backgroundImage}
                    alt={pageTitle}
                />
            </div>
            <div className="common-banner-text-container">
                <h1>
                    <span data-testid="common-banner-title" className="title">
                        {pageTitle}
                        <span className="dot">.</span>
                    </span>
                </h1>
                {category && <h2>{category}</h2>}
                {text && <h3 data-testid="common-banner-text">{text}</h3>}
            </div>
        </div>
    )
}

export default CommonBanner
