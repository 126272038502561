import carousel from './CarouselInputs'
import greyBanner from './GreyBannerInputs'
import textArea from './TextAreaInputs'
import actionButtonBanner from './ActionButtonBannerInputs'
import video from './VideoInputs'
import parallax from './ParallaxInputs'
import smallCarousel from './SmallCarrouselInputs'
import marquesina from './marquesinaInputs'
import commonBanner from './CommonBannerInputs'
import accordion from './AccordionInputs'
import profile from './ProfileInputs'
import imageLibrary from './ImageLibraryInputs'
import homeNews from './HomeNewsInputs'
import courseInfo from './CourseInputs'
import membersForm from './MembersFormInputs'
import instagram from './Instagram'

const templateRoot = {
    carousel,
    greyBanner,
    textArea,
    actionButtonBanner,
    video,
    parallax,
    smallCarousel,
    marquesina,
    commonBanner,
    accordion,
    profile,
    imageLibrary,
    homeNews,
    courseInfo,
    membersForm,
    instagram
}

export default templateRoot
