import { v4 as uuidv4 } from 'uuid'
import { cmsLanguage } from '../../../commons/cms_language/cmsLanguage'
import { templateTypes } from '../cms_modal_multiple_elements/constants'

const smallCarousel = () => {
    const fields = [
        {
            name: 'picture',
            type: 'PHOTO',
            id: uuidv4(),
            isMandatory: true
        },
        {
            name: 'title',
            type: 'H1',
            id: uuidv4(),
            isMandatory: true
        },
        {
            name: 'link',
            type: 'LINK',
            id: uuidv4(),
            isMandatory: false
        }
    ]
    const values = fields.map(it => {
        return {
            field: it,
            fieldId: it.id,
            id: uuidv4(),
            value: ''
        }
    })
    return {
        id: uuidv4(),
        name: cmsLanguage.templatesNames.smallCarousel,
        type: templateTypes.SMALL_CARROUSEL,
        elements: [
            {
                id: uuidv4(),
                values: values,
                type: 'default'
            }
        ],
        fields: fields
    }
}

export const SMALL_CARROUSEL_TEMPLATE_ORDER = { PHOTO: 0, H1: 1, LINK: 2 }

export default smallCarousel
