// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".administration-bar{height:3.75em;width:100vw;background-color:#000;display:flex;align-items:center;justify-content:space-between}.administration-bar-link>a{font-family:rucksack, sans-serif;text-transform:uppercase;font-size:1em;color:#fff}.administration-bar-link>a:before{font-family:'Font Awesome 5 Free';font-weight:900;content:\"\";margin-right:.625em}.administration-bar-link>a:hover{color:rgba(255,255,255,0.8)}.administration-bar-buttons{display:flex}.administration-bar-buttons button{margin-left:.9375em}.administration-bar-buttons-delete-content{justify-content:start}.administration-bar-buttons-content-editable,.administration-bar-buttons-save-cancel{display:flex;align-items:center}.administration-bar-buttons-add{margin-right:.9375em}.administration-bar-delete-content{margin-left:10px;margin-right:auto}@media screen and (max-width: 576px){.administration-bar-buttons{display:none}}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
