import React from 'react'
import './InfoModal.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../button/Button'
import { cmsLanguage } from '../../../cms/commons/cms_language/cmsLanguage'

/**
 * @typedef InfoModalProps
 * @memberOf InfoModal
 * @property {string} type - success, fail
 * @property {string} [optionalMessage]
 * @property {function} onModalClick
 * @property {function} [onModalTimesClick]
 * @property {string} [buttonText]
 */

/**
 * Modal that show a title, text and a button with some received action
 * @class InfoModal
 * @category Components
 * @subcategory theme / commons
 * @param {InfoModalProps} props
 * @returns {React.ReactNode}
 * @example
 * <InfoModal type="success" onModalClick={handleOnModalClick} onModalTimesClick={handleOnModalTimesClick} />
 */
const InfoModal = ({ type, optionalMessage, onModalClick, onModalTimesClick, buttonText }) => {
    let icon, intro, message

    switch (type) {
    case 'success':
        icon = ['fas', 'check-circle']
        intro = cmsLanguage.infoModal.successIntro
        message = optionalMessage || cmsLanguage.infoModal.successMessage
        buttonText = buttonText || cmsLanguage.infoModal.successButtonText
        break
    case 'fail':
        icon = ['fas', 'times-circle']
        intro = cmsLanguage.infoModal.failIntro
        message = optionalMessage || cmsLanguage.infoModal.failMessage
        buttonText = buttonText || cmsLanguage.infoModal.failButtonText
        break
    default:
        icon = ['fas', 'info-circle']
        intro = cmsLanguage.infoModal.defaultIntro
        message = optionalMessage || cmsLanguage.infoModal.defaultMessage
        buttonText = buttonText || cmsLanguage.infoModal.defaultButtonText
    }

    return (
        <div className="info-modal" data-testid="info-modal">
            <span className="info-modal-shim"/>
            <div className={`info-modal-container info-modal-container--${type}`}>
                {onModalTimesClick &&
                    <div className='info-modal-times'>
                        <FontAwesomeIcon
                            icon={'times'}
                            onClick={onModalTimesClick}
                        />
                    </div>
                }
                <div className="info-modal-content">
                    <FontAwesomeIcon
                        className="modal-info-icon"
                        data-testid="modal-info-icon"
                        icon={icon}
                    />
                    <h3 data-testid="intro-text">
                        {intro},
                    </h3>
                    <p className="info-message" data-testid="info-message">
                        {message}
                    </p>
                    <Button
                        text={buttonText}
                        onClick={onModalClick}
                        type={type || 'other'}
                    />
                </div>
            </div>
        </div>
    )
}

export default InfoModal
