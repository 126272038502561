import React, { useState } from 'react'
import './membersForm.scss'
import Button from '../../theme/commons/button/Button'
import MembershipFirstStep from './components/first_step/MembershipFirstStep'
import MembershipSecondStep from './components/second-step'
import ProgressBreadcrumb from '../../theme/commons/progress_breadcrumb'
import MembershipThirdStep from './components/third_step'
import MembershipFourthStep from './components/fourth-step'
import ButtonLink from '../../theme/commons/button_link/ButtonLink'
import { language } from '../../theme/commons/language/language'
import ButtonTypes from '../../theme/commons/button/buttonTypes'
import { IDS } from './components/second-step/utils/inputFieldsList'
import mergeFormWithUserData, { adaptFormToUserData } from './mergeFormWithUserData'
import adaptUserDataToForm from './components/second-step/utils/adaptUserDataToForm'
import useUserData from '../../hooks/useUserData'
import { initialUserData } from '../../hooks/useUserData/useUserData'
import useUserWallet from '../../hooks/useUserWallet'
import adaptWalletDataToForm from './components/second-step/utils/adaptWalletDataToForm'
import useDataAdaptor from './components/second-step/utils/useDataAdaptor'
import MeshUsers from '../../backend/mesh/users_service/MeshUsers'
import adaptToPatch from '../../utils/patchUtils'
import { differenceWith, toPairs, isEqual } from 'lodash'
import { cmsLanguage } from '../../cms/commons/cms_language/cmsLanguage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InfoModal from '../../theme/commons/infoModal'
import Loader from 'react-loader-spinner'
import { Col, Container, Row } from 'react-bootstrap'
import NavigationWrapper from '../../theme/commons/navigation_wrapper/NavigationWrapperContainer'
import { NAVIGATION_TYPE } from '../../theme/constants/navigationType'

/**
 * @const
 * @memberOf MembersForm
 * @type {number}
 * @example
 * const TOTAL_STEPS = 4
 */
const TOTAL_STEPS = 4

/**
 * @const
 * @memberOf MembersForm
 * @type {Array<{id: number, value: Array<string>}>}
 * @example
 * const ICONS_ARRAY = [
 *     { value: ['fas', 'info'], id: 0 },
 *     { value: ['fas', 'list'], id: 1 },
 *     { value: ['fas', 'info'], id: 2 },
 *     { value: ['fas', 'check'], id: 3 }
 * ]
 */
const ICONS_ARRAY = [
    { value: ['fas', 'info'], id: 0 },
    { value: ['fas', 'list'], id: 1 },
    { value: ['fas', 'info'], id: 2 },
    { value: ['fas', 'check'], id: 3 }
]

/**
 * @const
 * @memberOf MembersForm
 * @type {Array<string>}
 * @example
 * const MEMBER_ROLES = ['MEMBER_USER']
 */
const MEMBER_ROLES = ['MEMBER_USER']

/**
 * @typedef MembersFormProps
 * @memberOf MembersForm
 * @property {User} user
 */

/**
 * Become a member component page.
 * It shows the steps with the forms to become a member.
 * @class MembersForm
 * @category Components
 * @subcategory Pages / become-a-member
 * @param {MembersFormProps} props
 * @returns {React.ReactNode}
 * @example
 * <MembersForm />
 */
const MembersForm = (props) => {
    const { user } = props
    const [step, setStep] = useState(0)
    const [formValid, setFormValid] = useState(false)
    const userWalletArr = useUserWallet()
    const wallet = userWalletArr[0]
    const walletData = wallet?.defaultDepositMethod
    const isLoadingWallet = userWalletArr[2]
    const userDataObj = useUserData(initialUserData)
    const userData = userDataObj[0]
    const isLoadingUserData = userDataObj[2]
    const [submitting, setSubmitting] = useState(false)
    const [form, setForm] = useState({
        [IDS.TYPES]: '',
        [IDS.DATE]: '',
        [IDS.FAMILY_NAME]: '',
        [IDS.FIRSTNAME]: '',
        [IDS.FAMILY_MEMBERS]: '',
        [IDS.BIRTHDAY]: '',
        [IDS.GENDER]: '',
        [IDS.STREET_ADDRESS]: '',
        [IDS.POSTCODE]: '',
        [IDS.CITY]: '',
        [IDS.COUNTRY_CODE]: '',
        [IDS.PHONE]: '',
        [IDS.EMAIL]: '',
        [IDS.IBAN]: '',
        [IDS.BIC]: '',
        [IDS.BANK]: '',
        [IDS.ACC_HOLDER]: ''
    })
    const [showModal, setShowModal] = useState(false)
    const [modalData, setModalData] = useState({ optionalMessage: [] })
    const enableExternalForm = props.enableExternalForm !== undefined ? props.enableExternalForm : true

    useDataAdaptor(isLoadingUserData, setForm, userData, adaptUserDataToForm)
    useDataAdaptor(isLoadingWallet, setForm, walletData, adaptWalletDataToForm)

    /**
     * If step number is 2, it will submit the form, else it will increment or return to 0 (cyclic).
     * @function
     * @memberOf MembersForm
     */
    const onContinue = () => {
        if (step === 2) {
            submitFormIfValid()
        } else {
            setStep((step + 1) % TOTAL_STEPS)
        }
    }

    /**
     * It will increment the step number by one or return to 0 (cyclic).
     * @function
     * @memberOf MembersForm
     */
    const goNext = () => setStep((step + 1) % TOTAL_STEPS)

    /**
     * It will return the step number 0.
     * @function
     * @memberOf MembersForm
     */
    const onCancel = () => setStep(0)

    /**
     * Handle button text to be 'Continue', 'Accept' or 'Done', based on the step number.
     * @function
     * @memberOf MembersForm
     * @returns {JSX.Element|string}
     */
    const handleButtonText = () => {
        let label = language.buttons.continue
        if (step === 2) {
            label = <>
                {language.buttons.accept}
                {submitting && <div className="button-loader"><Loader width='15' height='15' type='Oval' /></div>}
            </>
        } else if (step === 3) {
            label = language.buttons.done
        }
        return label
    }

    /**
     * Gets the button type based on some rules:
     *  - The form is valid.
     *  - If step 2, the user must have the 'MEMBER_USER' role.
     *  - It is submitting.
     * @function
     * @memberOf MembersForm
     * @param {number} step
     * @param {boolean} formValid
     * @returns {string}
     */
    const handleButtonType = (step, formValid) => {
        const isMember = step === 2 && MEMBER_ROLES.includes(user.role?.name)
        const invalidForm = step === 1 && !formValid
        const isDisabled = isMember || invalidForm || submitting
        return isDisabled ? ButtonTypes.DISABLED : ButtonTypes.SUCCESS
    }

    /**
     * Updates the form state
     * @function
     * @memberOf MembersForm
     * @param {Object} form
     */
    const handleValidFormUpdate = (form) => setForm(form)

    /**
     * Updates the formValid state.
     * @function
     * @memberOf MembersForm
     * @param {boolean} value
     */
    const handleFormValidationChange = (value) => setFormValid(value)

    /**
     * Closes the modal and reset the modal data.
     * @function
     * @memberOf MembersForm
     */
    const closeModal = () => {
        setShowModal(false)
        setModalData({ optionalMessage: [] })
    }

    /**
     * Shows an error message if something is wrong.
     * @function
     * @memberOf MembersForm
     * @param {Object} error
     */
    const showOnError = (error) => {
        console.warn('Could not send member data', error)
        let message = cmsLanguage.error.unexpectedError
        if (error.response && error.response.data?.errorCode !== -1 && error.response.data?.errorMessage) {
            message = error.response.data?.errorMessage
        }
        setModalData(lastData => ({
            type: 'fail',
            optionalMessage: lastData.optionalMessage.concat(
                <>
                    {message}
                    <FontAwesomeIcon className='members-area__modal__times' icon='times'/>
                </>
            ),
            buttonText: cmsLanguage.inputFields.retryButton,
            onModalClick: () => {
                closeModal()
                submitFormIfValid()
            },
            onModalTimesClick: closeModal
        }))
        setShowModal(true)
    }

    /**
     * Sends the form data if it is valid.
     * @function
     * @memberOf MembersForm
     */
    const submitFormIfValid = () => {
        if (formValid && Object.keys(form).length > 0) {
            const formUserData = adaptFormToUserData(form)
            const mergedUserData = mergeFormWithUserData(formUserData, userData)
            console.debug('Submitting form', {
                mergedUserData,
                formUserData,
                userData
            })

            const amendedFieldsPairs =
                differenceWith(toPairs(mergedUserData), toPairs(userData), isEqual)

            const readOnlyFields = ['contactNumber', 'countryCode', 'email']
            const writableFields = amendedFieldsPairs.filter(([id]) => !readOnlyFields.includes(id))

            setSubmitting(true)
            MeshUsers.patchUserData(adaptToPatch(writableFields, 'replace'))
                .then((res) => {
                    console.debug('Member data received', res)
                    goNext()
                })
                .catch((err) => showOnError(err))
                .finally(() => setSubmitting(false))
        }
    }

    return (<>
        {showModal &&
            <InfoModal
                type={modalData.type}
                optionalMessage={modalData.optionalMessage.map((message, index) => <span key={index} className='members-area__modal'>{message}</span>)}
                onModalClick={modalData.onModalClick}
                onModalTimesClick={modalData.onModalTimesClick}
                buttonText={modalData.buttonText}
            />
        }
        <div className={'members-form horizontal-website-padding'}>
            <div className={'members-form-steps'}>
                <ProgressBreadcrumb inProgressIndex={step} iconsArray={ICONS_ARRAY} />
            </div>
            {step === 0 && <MembershipFirstStep />}
            {step === 1 && <MembershipSecondStep
                onFormValidationChange={handleFormValidationChange}
                onValidForm={handleValidFormUpdate}
                userInformation={form}
            />}
            {step === 2 && <MembershipThirdStep />}
            {step === 3 && <MembershipFourthStep />}
            <div className={'buttons-container'}>
                {
                    step === 3 && (
                        <div className='buttons-container__button-wrapper'>
                            <Button
                                text={language.templates.viewClassSchedule}
                                link={'/training'}
                                type={ButtonTypes.REVERTED}
                            />
                        </div>
                    )
                }
                <Container>
                    <Row xs={1} sm={2}>
                        {!enableExternalForm && (
                            <Col>
                                <Button
                                    text={handleButtonText()}
                                    onClick={onContinue}
                                    type={handleButtonType(step, formValid)}
                                />
                            </Col>
                        )}
                        {enableExternalForm && (
                            <>
                                <Col xs={12} sm={6} className="ml-auto">
                                    <NavigationWrapper
                                        type={NAVIGATION_TYPE.button}
                                        text={language.membership.standardForm}
                                        href="https://form.campai.com/CSFlya63ywSX"
                                        autoWidth={false}
                                    />
                                </Col>
                            </>
                        )}
                    </Row>
                </Container>
                {step > 0 && step !== 3 && <ButtonLink text={language.modals.cancel} onClick={onCancel} />}
            </div>
        </div></>
    )
}

export default MembersForm
