import React, { useState } from 'react'
import './SearchBar.scss'
import { cmsLanguage } from '../../../cms/commons/cms_language/cmsLanguage'

/**
 * @typedef SearchBarProps
 * @memberOf SearchBar
 * @property {Array<Object>} listDefault
 * @property {function} setListFiltered
 * @property {string} filterKey
 * @property {string} secondLevelKey
 */

/**
 * Component with an input text that filters the received `listDefault`
 * @class SearchBar
 * @category Components
 * @subcategory theme / commons
 * @param {SearchBarProps} props
 * @returns {React.ReactNode}
 * @example
 * <SearchBar
 *     listDefault={menu.children}
 *     setListFiltered={setListFiltered}
 *     filterKey={'name'}
 *     secondLevelKey={'children'}
 * />
 */
const SearchBar = (props) => {
    const { listDefault, setListFiltered, filterKey, secondLevelKey } = props

    const [input, setInput] = useState('')
    const [searching, setSearching] = useState(false)

    /**
     * Called on input change. Filter the listDefault based on the input's changes
     * @function
     * @memberOf SearchBar
     * @param {string} input
     * @returns {Promise<void>}
    */
    const updateInput = async (input) => {
        const filtered = secondLevelKey
            ? listDefault.reduce((list, item) => {
                const filteredChildren = item[secondLevelKey].filter((subItem) => {
                    return subItem[filterKey]
                        .toLowerCase()
                        .includes(input.toLowerCase())
                })
                if (item[filterKey].toLowerCase().includes(input.toLowerCase())) {
                    list.push({
                        ...item
                    })
                } else if (filteredChildren.length) {
                    list.push({
                        ...item,
                        [secondLevelKey]: [...filteredChildren]
                    })
                }
                return list
            }, [])
            : listDefault.filter((item) => {
                return item[filterKey].toLowerCase().includes(input.toLowerCase())
            })

        setInput(input)
        setListFiltered(filtered)
        setSearching(input.length)
    }

    /**
     * Reset the original state
    */
    const handleClearInput = () => {
        setInput('')
        setListFiltered(listDefault)
        setSearching(false)
    }

    return (
        <div className={`search-bar ${searching && 'active'}`}>
            <input
                key="searchBar"
                value={input}
                placeholder={cmsLanguage.cms.searchPlaceholder}
                onChange={(e) => updateInput(e.target.value)}
            />
            <div
                className={`icon ${searching && 'delete'}`}
                onClick={handleClearInput}
            />
        </div>
    )
}

export default SearchBar
