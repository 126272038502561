/**
 * @typedef {string} AddEditTypes
 **/

/**
 * Enum for actions types of AddEditClass Component
 * @readonly
 * @category Enums
 * @alias AddEditActionTypes
 * @enum {AddEditTypes}
 * @example
 * UPDATE_SELECTED_COURSE_ID: 'UPDATE_SELECTED_COURSE_ID'
 * UPDATE_DATE: 'UPDATE_DATE'
 * UPDATE_DURATION: 'UPDATE_DURATION'
 * UPDATE_REPEAT_SCHEDULE: 'UPDATE_REPEAT_SCHEDULE'
 * UPDATE_DESCRIPTION: 'UPDATE_DESCRIPTION'
 * UPDATE_TRAINER: 'UPDATE_TRAINER'
 * UPDATE_START_TIME: 'UPDATE_START_TIME'
 * UPDATE_LOCATION: 'UPDATE_LOCATION'
 */
export const types = {
    UPDATE_SELECTED_COURSE_ID: 'UPDATE_SELECTED_COURSE_ID',
    UPDATE_DATE: 'UPDATE_DATE',
    UPDATE_DURATION: 'UPDATE_DURATION',
    UPDATE_REPEAT_SCHEDULE: 'UPDATE_REPEAT_SCHEDULE',
    UPDATE_DESCRIPTION: 'UPDATE_DESCRIPTION',
    UPDATE_TRAINER: 'UPDATE_TRAINER',
    UPDATE_START_TIME: 'UPDATE_START_TIME',
    UPDATE_LOCATION: 'UPDATE_LOCATION'
}
