import store from '../../../redux/store'
import { CONFIG, MESH_CONFIG } from '../Constants'
// eslint-disable-next-line no-unused-vars
import axios, { AxiosResponse } from 'axios'

/**
 * @class MeshWallets
 * @category Backend
 * @subcategory wallets_service
 */
export default class MeshWallets {
    static getBaseUrl () {
        const { organizationId } = store.getState().user
        return `${MESH_CONFIG.SERVER_URL}/wallets-service/organizations/${organizationId}`
    }

    static getUserWalletBaseUrl () {
        const { id } = store.getState().user
        return `${MeshWallets.getBaseUrl()}/users/${id}/wallets`
    }

    static getBaseConfig () {
        const { accessToken } = store.getState().user
        return {
            headers: {
                ...CONFIG.DEFAULT_HEADERS,
                CONTENT_TYPE: 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
    }

    static getWallets () {
        const url = `${MeshWallets.getBaseUrl()}/wallets`
        const config = MeshWallets.getBaseConfig()
        return axios.get(url, config)
    }

    /**
     * @function
     * @memberOf MeshWallets
     * @returns {Promise<{}|AxiosResponse<Wallet>>}
     * @static
     */
    static async fetchUserWallet () {
        try {
            return await axios.get(MeshWallets.getUserWalletBaseUrl(), MeshWallets.getBaseConfig())
        } catch (err) {
            console.error(err)
            return {}
        }
    }

    static async checkout (paymentDetails) {
        const url = `${MeshWallets.getUserWalletBaseUrl()}/checkout`
        const response = await axios.post(url, paymentDetails, MeshWallets.getBaseConfig())
        return response.data
    }

    static async getCheckoutStatus (checkoutId, entityId) {
        const url = `${MeshWallets.getUserWalletBaseUrl()}/checkout/${checkoutId}/payment?entityId=${entityId}`
        const response = await axios.get(url, MeshWallets.getBaseConfig())
        return response.data
    }
}
