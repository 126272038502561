import React from 'react'
import withBlur from '../../../../../../cms/commons/cms_input/components/with_blur'
import withErrorMessage from './withErrorMessage'
import withValidation from '../../../../../../cms/commons/cms_input/components/with_validation'
import withLabel from '../../../../../../cms/commons/cms_input/components/with_label'
import Input from '../../../../../../cms/commons/cms_input/components/input_component'
const EnhancedInput = withBlur(withErrorMessage(withValidation(withLabel((Input)))))

const FormInput = (props) => {
    const { value, ...otherProps } = props
    const isNullOrUndefined = value === undefined || value === null
    return <EnhancedInput {...otherProps} value={isNullOrUndefined ? '' : value} />
}

export default FormInput
