import React from 'react'
import './courseSummary.scss'

/**
 * @const
 * @memberOf CourseSummary
 * @type {string}
 * @example
 * const CURRENCY = '€'
 */
const CURRENCY = '€'

/**
 * @typedef CourseSummaryProps
 * @memberOf CourseSummary
 * @property {string} price
 * @property {string} courseName
 */

/**
 * Course Summary Component
 * @class CourseSummary
 * @category Components
 * @subcategory Templates / CourseInfo
 * @param {CourseSummaryProps} props
 * @returns {React.ReactNode}
 * @example
 * <CourseSummary courseName={courseName} price={price} />
 */
const CourseSummary = ({ price, courseName }) => {
    return (
        <div className='course-summary'>
            <span>{courseName}</span>
            <span className={'divider'} />
            <span>
                {price}
                {CURRENCY}
            </span>
        </div>
    )
}

export default CourseSummary
