import React, { useState } from 'react'
import './HomeBanner.scss'
import { Carousel } from 'react-bootstrap'
import NextButton from './components/NextButton'
import NavigationWrapper from '../../theme/commons/navigation_wrapper/NavigationWrapperContainer'
import { NAVIGATION_TYPE } from '../../theme/constants/navigationType'

/**
 * @typedef HomeBannerProps
 * @memberOf HomeBanner
 * @property {Array<Element>} homeBannerData
 * @property {function} reducedElementsPro
 * @property {Array<Field>} fields
 */

/**
 * Carousel Home Banner
 * @class HomeBanner
 * @category Components
 * @subcategory Home
 * @param {HomeBannerProps} props
 * @returns {React.ReactNode}
 * @example
 * <HomeBanner
 *     homeBannerData={template.elements}
 *     reducedElementsPro={reducedElementsPro}
 *     fields={template.fields}
 * />
 */
const HomeBanner = ({ homeBannerData, reducedElementsPro, fields }) => {
    const [index, setIndex] = useState(0)

    /**
     * @function
     * @memberOf HomeBanner
     * @param selectedIndex
     * @param event - HTML event
     */
    const handleSelect = (selectedIndex, event) => {
        setIndex(selectedIndex)
    }

    /**
     * If the next index is greater than the length, it returns to 0 (cyclic)
     * @function
     * @memberOf HomeBanner
     * @param {number} prevIndex
     * @returns {number}
     */
    const getNextIndex = (prevIndex) => {
        let nextIndex = prevIndex + 1

        if (nextIndex >= homeBannerData.length) {
            nextIndex = 0
            return nextIndex
        }
        return nextIndex
    }

    /**
     * Adds punctuation to the title
     * @function
     * @memberOf HomeBanner
     * @param {string} chars
     * @returns {JSX.Element|string}
     */
    const checkLastChar = (chars) => {
        if (typeof chars === 'undefined' || chars === null) return 'Error loading HomeBanner'
        const lastChar = chars.substr(chars.length - 1)
        const regex = /[!?@#$%^&*)(+=';._-]/
        const handleFirstChars = () => regex.test(lastChar) ? chars.slice(0, -1) : chars
        const handleLastChar = () => regex.test(lastChar) ? lastChar : '.'
        return (
            <>
                {handleFirstChars()}
                <span className="dot">{handleLastChar()}</span>
            </>
        )
    }

    return (
        <Carousel
            fade
            data-testid="carousel"
            className={`home-banner-carousel ${homeBannerData.length > 1 ? '' : 'home-banner-solo'}`}
            activeIndex={index}
            onSelect={handleSelect}
            controls={false}
        >
            {homeBannerData.map((info) => {
                const reducedInfo = reducedElementsPro(info, fields)
                const imageSplited = reducedInfo.PHOTO.split('/')
                const imageName = imageSplited[imageSplited.length - 1]

                return (
                    <Carousel.Item
                        data-testid="slide"
                        className="home-banner"
                        key={info.id}
                        interval={5000}
                    >
                        <div className="image-container d-flex justify-content-center align-items-center">
                            <div className="gradient-container d-flex justify-content-center align-items-center">
                                <span className="gradient-overlay" />
                            </div>
                            <img
                                data-testid={'banner-image-' + info.id}
                                className="banner-image"
                                src={reducedInfo.PHOTO}
                                alt={imageName}
                            />
                        </div>
                        <Carousel.Caption
                            data-testid={'caption-' + info.id}
                            className="d-flex flex-column align-items-center"
                        >
                            <h1>
                                <span className="title">
                                    {checkLastChar(reducedInfo.H1)}
                                </span>
                            </h1>
                            <span className="line"/>
                            <h3>{reducedInfo.H2}</h3>
                        </Carousel.Caption>
                        <span className="caption-positioning-container">
                            <p data-testid={'hash-' + info.id} className="first-hash">
                                <span>
                                    {reducedInfo.SHORT_TEXT_2 && reducedInfo.SHORT_TEXT_2}
                                </span>
                                <span>
                                    {reducedInfo.SHORT_TEXT_3 && reducedInfo.SHORT_TEXT_3}
                                </span>
                            </p>
                            {reducedInfo.LINK && reducedInfo.SHORT_TEXT_1 &&
                                (reducedInfo.LINK !== '' || reducedInfo.SHORT_TEXT_1 !== '') && (
                                <NavigationWrapper
                                    type={NAVIGATION_TYPE.link}
                                    href={reducedInfo.LINK}
                                    data-testid={'link-' + info.id}
                                >
                                    {reducedInfo.SHORT_TEXT_1}
                                </NavigationWrapper>
                            )}
                            {homeBannerData.length > 1 && <NextButton
                                handleSelect={() => handleSelect(getNextIndex(index))}
                            /> }
                        </span>
                    </Carousel.Item>
                )
            })}
        </Carousel>
    )
}

export default HomeBanner
