import React from 'react'
import './addCmsContentModalTwo.scss'
import { v4 as uuidv4 } from 'uuid'
import generateTemplate from '../util/importTemplateInputs'
import TemplateEditorModal from '../cms_templates_bars/components/cms_edit_template/TemplateEditorModalContainer'
import { headerTemplates } from '../cms_templates_bars/components/cms_edit_template/templatesList'
import { templateTypes } from '../cms_templates_bars/components/cms_modal_multiple_elements/constants'

const AddCmsContentModalTwo = (props) => {
    const { onClose, newContentMenuConfiguration, createContent } = props

    const onSaveHandler = async template => {
        let contentTitle
        template.elements.forEach(e => e.values.forEach(v => {
            if (v.field.name === 'title') {
                contentTitle = v.value
            }
        }))
        const newContent = {
            id: uuidv4(),
            name: contentTitle,
            templateIds: [template.id],
            templates: [template],
            menuId: null,
            editable: true
        }
        await createContent(newContentMenuConfiguration, newContent, contentTitle)
        onClose()
        props.toggleEditMode()
        props.toggleAddNewTemplateMode()
    }

    return (
        <TemplateEditorModal
            template={generateTemplate(templateTypes.COMMON_BANNER)}
            onClose={onClose}
            onSave={onSaveHandler}
            isNewPage
            availableTemplates={headerTemplates.list}
        />

    )
}

export default AddCmsContentModalTwo
