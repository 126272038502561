/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { generateHours, generateMinutes } from '@utils/dateTimePickerUtils'
import DropDown from '../dropdown/Dropdown'
import './timePicker.scss'
import wrapErrorComponent from '../errorHOC/ErrorHoc'

/**
 * @typedef TimePickerProps
 * @memberOf TimePicker
 * @property {function} onTimeChange
 * @property {string} value
 * @property {string} [data-testid]
 * @property {string} [testId]
 * @property {('medium'|'large'|'small'|'auto')} [size]
 */

/**
 * Shows two dropdowns with hour and minutes to be chosen
 * @class TimePicker
 * @category Components
 * @subcategory theme / commons
 * @param {TimePickerProps} props
 * @returns {React.ReactNode}
 * @example
 * <TimePicker
 *     onTimeChange={(hour, minute) => ({})}
 *     value="00:00"
 *     data-testid="timePicker-schedule"
 * />
 */
function TimePicker ({ onTimeChange, value, 'data-testid': testId = 'timePicker-testId', size = 'small' }) {
    const [hours, setHours] = useState()
    const [minutes, setMinutes] = useState()

    useEffect(() => {
        if (value && typeof value === 'string' && value.includes(':')) {
            setHours(value.split(':')[0])
            setMinutes(value.split(':')[1])
        }
    }, [])

    useEffect(() => {
        if (hours && minutes) {
            onTimeChange(hours, minutes)
        }
    }, [hours, minutes])

    useEffect(() => {
        if (value && typeof value !== 'string') {
            setHours(value.hour)
            setMinutes(value.minute)
        }
    }, [value])

    return (
        <div className="time-picker">
            <DropDown
                data-testid={`${testId}-hour`}
                title="HH"
                width={size}
                printHeader={true}
                data={generateHours()}
                headerOnly={true}
                onSelect={(value) => setHours(value)}
                providedValue={hours}
            />
            <DropDown
                data-testid={`${testId}-minute`}
                title="MM"
                width={size}
                data={generateMinutes()}
                headerOnly={true}
                onSelect={(value) => setMinutes(value)}
                providedValue={minutes}
            />
        </div>
    )
}

export default wrapErrorComponent(TimePicker)
