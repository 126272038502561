import { useState, useEffect } from 'react'

/**
 * Filters the data based on the received query
 * @function
 * @category Hooks
 * @subcategory theme / commons / Dropdown
 * @param {string} query
 * @param {Menu} data
 * @param {boolean} headerOnly
 * @example
 * const searchResult = useSearch("Volleyball", JSON.parse(JSON.stringify(data)), false)
 */
function useSearch (query, data, headerOnly) {
    const [searchData, setSearchData] = useState([])
    useEffect(() => {
        if (!query) {
            setSearchData([])
            return []
        }
        const timeOutId = setTimeout(() => fetchData(), 300)
        return () => clearTimeout(timeOutId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    function fetchData () {
        setSearchData([])
        let result = []
        if (headerOnly) {
            result = data.filter((i) => {
                if (i.line || i.fixed) return true
                return i.name?.toLowerCase().includes(query?.toLowerCase())
            })
        } else {
            data.forEach((i) => {
                const subData = i.courses.filter((j) => {
                    if (j.line) return true
                    return j.name?.toLowerCase().includes(query?.toLowerCase())
                })
                if (subData.length !== 0) {
                    i.courses = subData
                    result.push(i)
                }
            })
        }
        setSearchData(result)
    }
    return searchData
}

export default useSearch
