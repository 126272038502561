// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".text-template{padding:3.75em 15em 5.625em;background-color:#f2f2f2;white-space:pre-line}.text-template a{color:#64b42d}.text-template p{font-family:'Open Sans', sans-serif;font-weight:normal;font-style:normal;font-size:1.125em;color:#191919}.text-template h2{font-family:rucksack, sans-serif;font-weight:900;font-style:normal;font-size:2.8125em;color:#64b42d;text-transform:uppercase;margin-bottom:.9375em}@media screen and (max-width: 768px){.text-template{padding:3.75em 9.375em 5.625em}}@media screen and (max-width: 576px){.text-template{padding:3.75em 5.625em 5.625em}}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
