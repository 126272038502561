// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".cms-info-error span{font-family:'Open Sans', sans-serif;font-size:.75em;color:#f03}.cms-info-error svg{font-size:1em;color:#f03;margin-left:.3125em}.cms-info-error-tooltip{padding:.625em .9375em;text-align:left}.cms-info-error-tooltip h1{font-size:1em;text-transform:uppercase}.cms-info-error-tooltip span{font-size:.875em}.cms-info-error-tooltip ul{list-style:none;padding:0;margin:0;padding:.625em .9375em;font-size:.875em}.cms-info-error-tooltip ul li{margin-bottom:.3125em}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
