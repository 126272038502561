// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".event-description hr{margin-top:1.875em;margin-bottom:1.875em}.event-description-container{display:flex;justify-content:space-between;flex-wrap:wrap}@media screen and (max-width: 576px){.event-description-container{flex-direction:column}.event-description-container .view-schedule-button{width:fit-content;margin-left:auto;margin-right:auto;margin-top:1em}}.event-description-label{font-family:'Open Sans', sans-serif;font-weight:600;font-style:normal;font-size:1.125em;color:#191919;padding-right:.9375em}.event-description-label-text{font-family:'Open Sans', sans-serif;font-weight:normal;font-style:normal;font-size:1.125em;color:#191919}.event-description__trainers{margin-top:3.75em}.event-description__trainers-list{display:flex;gap:3.75em}@media screen and (max-width: 576px){.event-description__trainers-list{flex-direction:column;gap:1.875em}}.event-description .view-schedule-button{width:fit-content}.event-description__button{display:flex;justify-content:center;width:fit-content;margin-top:3.75em;margin-left:auto;margin-right:auto}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
