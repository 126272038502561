// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".membership-admission-form-title h3{font-family:rucksack, sans-serif;font-weight:500;font-style:normal;font-size:1.625em;color:#191919;text-transform:uppercase;margin-bottom:.9375em}.cms-input-box{background-color:#fff}.cms-input-box input{color:#595959}.cms-input-box input+div{display:none}.cms-input-box .read-only{opacity:.5}.input-label{font-family:'Open Sans', sans-serif;font-weight:normal;font-style:normal;font-size:.75em;color:#595959;margin-bottom:.3125em}@media screen and (min-width: 576px){.membership-admission-form .types-container{padding-right:1.875em}.membership-admission-form .details-container{padding-left:1.875em}}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
