import React from 'react'
import './dateField.scss'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { getLanguage } from '../../../../utils/languageUtils'
import { cmsLanguageDeu, cmsLanguageEng } from '../../../../cms/commons/cms_language/cmsLanguage'

const daysEn = Object.values(cmsLanguageEng.weekday).map(day => day.substring(0, 2))
const monthsEn = Object.values(cmsLanguageEng.months)
const daysDe = Object.values(cmsLanguageDeu.weekday).map(day => day.substring(0, 2))
const monthsDe = Object.values(cmsLanguageDeu.months)

const locale = {
    en: {
        localize: { day: n => daysEn[n], month: n => monthsEn[n] },
        formatLong: { date: () => 'dd-MM-yyyy' }
    },
    de: {
        localize: { day: n => daysDe[n], month: n => monthsDe[n] },
        formatLong: { date: () => 'mm/dd/yyyy' }
    }
}

/**
 * @typedef DateFieldProps
 * @memberOf DateField
 * @property {string} value
 * @property {function} onChange
 * @property {string|Object} defaultValue
 */

/**
 * Date field.
 * @class DateField
 * @category Components
 * @subcategory Templates / MembersArea
 * @param {DateFieldProps} props
 * @returns {React.ReactNode}
 * @example
 * <DateField {...dateFieldProps} />
 */
const DateField = ({
    value,
    onChange,
    defaultValue = new Date()
}) => {
    return (
        <div data-testid="date-field" className="date-field cms-input-box">
            <div className="customDatePickerWidth">
                <DatePicker
                    selected={value ? new Date(value) : defaultValue}
                    onChange={(date) => onChange(date)}
                    dateFormat="dd-MM-yyyy"
                    maxDate={new Date()}
                    autoComplete="off"
                    locale={locale[getLanguage()]}
                />
            </div>
        </div>
    )
}

export default DateField
