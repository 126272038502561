import React, { Fragment } from 'react'
import { cmsLanguage } from '@src/cms/commons/cms_language/cmsLanguage'
import Button from '@theme/commons/button/Button'
import TrainerComponent from '@pages/trainingSchedule/components/TrainerComponent/TrainerComponent'
import './EventDescription.scss'
import ButtonTypes from '@theme/commons/button/buttonTypes'
import HTMLReactParser from 'html-react-parser'
import moment from 'moment'
import NavigationWrapper from '../../../../theme/commons/navigation_wrapper/NavigationWrapperContainer'
import { NAVIGATION_TYPE } from '../../../../theme/constants/navigationType'

/**
 * @typedef EventDescriptionProps
 * @memberOf EventDescription
 * @property {string} date
 * @property {string} duration
 * @property {boolean} [disabled]
 * @property {string} price
 * @property {string} description
 * @property {string} currency
 * @property {function} onClickJoin
 * @property {Object} scheduleData
 * @property {string} joinLabel
 * @property {Object} involvedPeopleData
 */

/**
 * Component to show the description of the event inside {@link CourseInfo}.
 * @class EventDescription
 * @category Components
 * @subcategory Templates / CourseInfo
 * @param {EventDescriptionProps} props
 * @returns {React.ReactNode}
 * @example
 * <EventDescription
 *     date={date}
 *     disabled={false}
 *     description={description}
 *     duration={duration}
 *     price={price}
 *     involvedPeopleData={{
 *         list: trainers,
 *         label: cmsLanguage.inputFields.trainers
 *     }}
 *     joinLabel={language.modals.joinCourse}
 *     trainers={trainers}
 *     currency={CURRENCY}
 *     scheduleData={{ link: '/training', label: language.templates.viewClassSchedule }}
 *     onClickJoin={handleOnNext}
 * />
 */
const EventDescription = ({
    date,
    duration,
    durationUnit,
    endingDate,
    location,
    repeatPeriod,
    repeatType,
    disabled,
    price,
    description,
    shortDescription,
    currency,
    onClickJoin,
    scheduleData,
    joinLabel,
    involvedPeopleData,
    categoryId,
    eventsGroupId
}) => (
    <React.Fragment>
        <div className="event-description-container">
            <div>
                {date && (
                    <p>
                        <span className="event-description-label">
                            {cmsLanguage.inputFields.date}{' '}
                        </span>
                        <span className="event-description-label-text">{moment(date).format('DD/MM/YYYY')}</span>
                    </p>
                )}
                <p>
                    <span className="event-description-label">
                        {cmsLanguage.inputFields.duration}{' '}
                    </span>
                    <span className="event-description-label-text">{`${duration} ${durationUnit ? cmsLanguage.inputFields[durationUnit.toLowerCase()] : durationUnit}`}</span>
                </p>
                <p>
                    <span className="event-description-label">
                        {cmsLanguage.inputFields.every}{' '}
                    </span>
                    <span className="event-description-label-text">{`${repeatPeriod > 1 ? repeatPeriod : ''} ${repeatType ? cmsLanguage.inputFields[repeatType.toLowerCase()] : repeatType}`}</span>
                </p>
                {endingDate && (
                    <p>
                        <span className="event-description-label">
                            {cmsLanguage.inputFields.endDate}{' '}
                        </span>
                        <span className="event-description-label-text">{moment(endingDate).format('DD/MM/YYYY')}</span>
                    </p>
                )}
                {price > 0 && (
                    <p>
                        <span className="event-description-label">
                            {cmsLanguage.inputFields.price}{' '}
                        </span>
                        <span className="event-description-label-text">{price === undefined ? '' : `${price}${currency}`}</span>
                    </p>
                )}
                <p>
                    <span className="event-description-label">
                        {cmsLanguage.inputFields.location}{' '}
                    </span>
                    <span className="event-description-label-text">{location}</span>
                </p>
            </div>
            <div className="view-schedule-button">
                <NavigationWrapper
                    type={NAVIGATION_TYPE.button}
                    text={scheduleData.label}
                    href={scheduleData.link}
                    state={{ categoryId, eventsGroupId }}
                />
            </div>
        </div>
        <hr />
        <p>
            <span className="event-description-label">
                {cmsLanguage.inputFields.description}
            </span>
        </p>
        <div className="event-description-label-text">{typeof description === 'string' ? HTMLReactParser(description) : description}</div>
        <p className="event-description__trainers">
            <span className="event-description-label">
                {involvedPeopleData.label}
            </span>
        </p>
        <div className={'event-description__trainers-list'}>
            {involvedPeopleData.list.map((person) => (
                <Fragment key={person.id}>
                    <TrainerComponent
                        trainer={person.trainer}
                        image={person.image}
                        size={'large'}
                        noTitle
                    />
                </Fragment>

            ))}
        </div>
        {price > 0 && (
            <div className="event-description__button">
                <Button
                    text={joinLabel}
                    onClick={onClickJoin}
                    type={disabled ? ButtonTypes.DISABLED : ButtonTypes.SUCCESS}
                />
            </div>
        )}
    </React.Fragment>
)

export default EventDescription
