// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".radio-btn label{display:flex;cursor:pointer;font-weight:500;position:relative;overflow:hidden;margin-bottom:0.375em}.radio-btn label input{position:absolute;left:-9999px}.radio-btn label input:checked+span:before{box-shadow:inset 0 0 0 0.4375em #64b42d}.radio-btn label span{display:flex;align-items:center;padding:0.375em 0.75em 0.375em 0.375em;border-radius:99em;transition:0.25s ease}.radio-btn label span:before{display:flex;flex-shrink:0;content:'';background-color:#fff;width:1.5em;height:1.5em;border-radius:50%;margin-right:0.375em;transition:0.25s ease;box-shadow:inset 0 0 0 0.125em #64b42d}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
