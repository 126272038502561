import { COURSE_TEMPLATE_ORDER } from '../empty_templates_inputs/CourseInputs'
import { CARROUSEL_TEMPLATE_ORDER } from '../empty_templates_inputs/CarouselInputs'
import { SMALL_CARROUSEL_TEMPLATE_ORDER } from '../empty_templates_inputs/SmallCarrouselInputs'
import { IMAGE_LIBRARY_TEMPLATE_ORDER } from '../empty_templates_inputs/ImageLibraryInputs'
import { ACCORDION_TEMPLATE_ORDER } from '../empty_templates_inputs/AccordionInputs'
import { PROFILES_TEMPLATE_ORDER } from '../empty_templates_inputs/ProfileInputs'
import { ACTION_BUTTON_BANNER_TEMPLATE_ORDER } from '../empty_templates_inputs/ActionButtonBannerInputs'
import { MARQUESINA_TEMPLATE_ORDER } from '../empty_templates_inputs/marquesinaInputs'
import { COMMON_BANNER_TEMPLATE_ORDER } from '../empty_templates_inputs/CommonBannerInputs'
import { GREY_BANNER_TEMPLATE_ORDER } from '../empty_templates_inputs/GreyBannerInputs'
import { PARALLAX_TEMPLATE_ORDER } from '../empty_templates_inputs/ParallaxInputs'
import { TEXT_AREA_TEMPLATE_ORDER } from '../empty_templates_inputs/TextAreaInputs'
import { VIDEO_TEMPLATE_ORDER } from '../empty_templates_inputs/VideoInputs'
import { MEMBERS_FORM_TEMPLATE_ORDER } from '../empty_templates_inputs/MembersFormInputs'
import { INSTAGRAM_TEMPLATE_ORDER } from '../empty_templates_inputs/Instagram'

export const BASE_FIELDS_TYPES = {
    PHOTO: 'PHOTO',
    H1: 'H1',
    H2: 'H2',
    LINK: 'LINK',
    SHORT_TEXT: 'SHORT_TEXT',
    TEXT: 'TEXT',
    DATE: 'DATE',
    PHONE: 'PHONE',
    EMAIL: 'EMAIL',
    BUTTON_LINK: 'BUTTON_LINK',
    LONG_TEXT: 'LONG_TEXT',
    TEXT_EDITOR: 'TEXT_EDITOR',
    VIDEO: 'VIDEO',
    PRICE: 'PRICE',
    TIME: 'TIME',
    NUMBER: 'NUMBER',
    DROPDOWN: 'DROPDOWN'
}

export const templateTypes = {
    CARROUSEL: 'CARROUSEL',
    GREY_BANNER: 'GREY_BANNER',
    TEXT_AREA: 'TEXT_AREA',
    ACTION_BUTTON_BANNER: 'ACTION_BUTTON_BANNER',
    SMALL_CARROUSEL: 'SMALL_CARROUSEL',
    PARALLAX: 'PARALLAX',
    VIDEO: 'VIDEO',
    ACCORDION: 'ACCORDION',
    PROFILES: 'PROFILES',
    MARQUESINA: 'MARQUESINA',
    IMAGE_LIBRARY: 'IMAGE_LIBRARY', // Photo Gallery
    COMMON_BANNER: 'COMMON_BANNER',
    CONTACT: 'CONTACT', // use in contact page and advertising page
    PERSONAL_INFO: 'PERSONAL_INFO', // use in become member page and personal info page
    BANNER_IMAGE_LIBRARY: 'BANNER_IMAGE_LIBRARY', // Photo Banner
    GRID: 'GRID', // use in news home page
    FULL_GRID: 'FULL_GRID', // use in news item page
    VIDEO_LIBRARY: 'VIDEO_LIBRARY',
    TRAINING_SCHEDULE: 'TRAINING_SCHEDULE',
    FOOTER: 'FOOTER',
    SOCIAL_MEDIA: 'SOCIAL_MEDIA',
    COURSE: 'COURSE',
    MEMBERS_FORM: 'MEMBERS_FORM',
    INSTAGRAM: 'INSTAGRAM'
}

const TEMPLATE_FIELD_POSITIONS = {
    [templateTypes.CARROUSEL]: CARROUSEL_TEMPLATE_ORDER,
    [templateTypes.SMALL_CARROUSEL]: SMALL_CARROUSEL_TEMPLATE_ORDER,
    [templateTypes.IMAGE_LIBRARY]: IMAGE_LIBRARY_TEMPLATE_ORDER,
    [templateTypes.BANNER_IMAGE_LIBRARY]: { TEXT: 0, PHOTO: 1, DATE: 2 },
    [templateTypes.ACCORDION]: ACCORDION_TEMPLATE_ORDER,
    [templateTypes.PROFILES]: PROFILES_TEMPLATE_ORDER,
    [templateTypes.ACTION_BUTTON_BANNER]: ACTION_BUTTON_BANNER_TEMPLATE_ORDER,
    [templateTypes.MARQUESINA]: MARQUESINA_TEMPLATE_ORDER,
    [templateTypes.COMMON_BANNER]: COMMON_BANNER_TEMPLATE_ORDER,
    [templateTypes.GREY_BANNER]: GREY_BANNER_TEMPLATE_ORDER,
    [templateTypes.PARALLAX]: PARALLAX_TEMPLATE_ORDER,
    [templateTypes.TEXT_AREA]: TEXT_AREA_TEMPLATE_ORDER,
    [templateTypes.VIDEO]: VIDEO_TEMPLATE_ORDER,
    [templateTypes.VIDEO_LIBRARY]: { VIDEO: 0, TEXT: 1, DATE: 2 },
    [templateTypes.GRID]: { H1: 0, BUTTON_TEXT: 1, BUTTON_LINK: 2 },
    [templateTypes.FOOTER]: { TEXT_EDITOR: 0, PHONE_1: 1, PHONE_2: 2, EMAIL: 3 },
    [templateTypes.SOCIAL_MEDIA]: { LINK: 0 },
    [templateTypes.COURSE]: COURSE_TEMPLATE_ORDER,
    [templateTypes.MEMBERS_FORM]: MEMBERS_FORM_TEMPLATE_ORDER,
    [templateTypes.INSTAGRAM]: INSTAGRAM_TEMPLATE_ORDER
}
export default TEMPLATE_FIELD_POSITIONS

export const MULTIELEMENT_TEMPLATES = [
    templateTypes.CARROUSEL,
    templateTypes.SMALL_CARROUSEL,
    templateTypes.IMAGE_LIBRARY,
    templateTypes.BANNER_IMAGE_LIBRARY,
    templateTypes.ACCORDION,
    templateTypes.PROFILES,
    templateTypes.MARQUESINA,
    templateTypes.SOCIAL_MEDIA
]
export const isMultiElementTemplate = type => MULTIELEMENT_TEMPLATES.includes(type)
