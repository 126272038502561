import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import * as actions from '../../../actions/ContentActions'
import TemplateEditorModal from './TemplateEditorModal'

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch)

const mapStateToProps = (state) => {
    const { content } = state

    return {
        contentDB: content
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    TemplateEditorModal
)
