/**
 * @const DEFAULT_COUNTRY_CODE
 * @type {string}
 * @memberOf UserDataRequest
 * @example
 * const DEFAULT_COUNTRY_CODE = '+49'
 */
const DEFAULT_COUNTRY_CODE = '+49'

/**
 * @const DEFAULT_DEPARTMENT
 * @type {string}
 * @memberOf UserDataRequest
 * @example
 * const DEFAULT_DEPARTMENT = ''
 */
const DEFAULT_DEPARTMENT = ''

/**
 * @typedef UserDataRequestArgs
 * @memberOf UserDataRequest
 * @param {string} args.orgId
 * @param {string} args.firstName
 * @param {string} args.lastName
 * @param {string?} args.fullName
 * @param {string} args.email
 * @param {string} args.countryCode
 * @param {string} args.contactNumber
 * @param {string?} args.department
 * @param {string?} args.description
 * @param {string?} args.displayName
 * @param {number} args.roleId
 * @param {number[]?} args.eventsGroups
 * @param {Date} args.memberSince
 * @param {Object} args.address
 * @param {string} args.address.streetNumber
 * @param {string} args.address.postCode
 * @param {string} args.address.city
 * @param {Object} args.paymentData
 * @param {string} args.paymentData.id
 * @param {string} args.paymentData.iban
 * @param {string} args.paymentData.bic
 * @param {string} args.paymentData.holderName
 * @param {string} args.paymentData.bank
 * @param {string?} args.birthDate
 * @param {"Female"| "Male"| "Other"} args.gender
 * @param {string?} args.roleName
 */

/**
 * Data required to update or create a user
 * @class UserDataRequest
 * @category Backend
 * @subcategory mesh_domain
 */
export default class UserDataRequest {
    /**
     * @function
     * @alias constructor
     * @param {UserDataRequestArgs} args
     * @memberOf UserDataRequest
   */
    constructor (args) {
        const {
            orgId,
            firstName,
            lastName,
            fullName,
            email,
            countryCode = DEFAULT_COUNTRY_CODE,
            contactNumber,
            department = DEFAULT_DEPARTMENT,
            description,
            displayName,
            roleId,
            eventsGroups,
            memberSince,
            address,
            paymentData,
            birthDate,
            gender,
            roleName
        } = args
        this.orgId = orgId
        this.firstName = firstName
        this.lastName = lastName
        this.fullName = fullName
        this.email = email
        this.countryCode = countryCode
        this.contactNumber = contactNumber
        this.department = department
        this.description = description
        this.displayName = displayName
        this.roleId = roleId
        this.eventsGroups = eventsGroups
        this.memberSince = memberSince
        this.address = address
        this.paymentData = paymentData
        this.birthDate = birthDate
        this.gender = gender
        this.roleName = roleName
    }
}
