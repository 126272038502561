import store from '../../../redux/store'
import { CONFIG, MESH_CONFIG } from '../Constants'
import axios from 'axios'

/**
 * @typedef Category
 * @memberOf MeshEventCategory
 * @property {string} [id]
 * @property {string} name
 * @property {Array<Object>} [eventsGroups]
 */

/**
 * MeshEventCategory
 * @class MeshEventCategory
 * @category Backend
 * @subcategory calendar_service
 */
export default class MeshEventCategory {
    /**
     * createCategory method. Submit the category to the calendar-service to be created.
     * The new category will be created related to the user's organization ID.
     * @memberof MeshEventCategory
     * @param {Category} category
     * @returns {Promise}
     */
    static createCategory (category) {
        const { accessToken, organizationId } = store.getState().user
        const url = `${MESH_CONFIG.SERVER_URL}/calendar-service/organizations/${organizationId}/category`
        const config = {
            headers: {
                ...CONFIG.DEFAULT_HEADERS,
                CONTENT_TYPE: 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        return axios.post(url, category, config)
    }

    /**
     * getCategories method.
     * Get all categories related to the user's organization ID.
     * @memberof MeshEventCategory
     * @returns {Promise} - All categories
     */
    static getCategories () {
        const { accessToken, organizationId } = store.getState().user
        return axios.get(`/calendar-service/organizations/${organizationId}/category`, {
            headers: {
                ...CONFIG.DEFAULT_HEADERS,
                CONTENT_TYPE: 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        })
    }

    /**
     * searchCategory method. Get all categories related with the user's organization ID and based on the category received by parameter.
     * @memberof MeshEventCategory
     * @param {Category} category - Category object to be searched
     * @returns {Promise} - The category found based on the category received by parameter
     */
    static searchCategory (category) {
        const { accessToken, organizationId } = store.getState().user
        const url = `${MESH_CONFIG.SERVER_URL}/calendar-service/organizations/${organizationId}/category/search`
        const config = {
            headers: {
                ...CONFIG.DEFAULT_HEADERS,
                CONTENT_TYPE: 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        return axios.post(url, category, config)
    }

    /**
     * deleteCategory method.
     * Delete a category related to the user's organization ID and based on the category id received by parameter.
     * @memberof MeshEventCategory
     * @param {string} categoryId
     * @returns {Promise<void>}
     */
    static deleteCategory (categoryId) {
        const { accessToken, organizationId } = store.getState().user
        return axios.delete(`/calendar-service/organizations/${organizationId}/category/${categoryId}`, {
            headers: {
                ...CONFIG.DEFAULT_HEADERS,
                CONTENT_TYPE: 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        })
    }
}
