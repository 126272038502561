/**
 * @module Util
 * @category Utils
 * @subcategory Cms
 */

/**
 * Gets a type name with number and returns it without the number
 * @function
 * @inner
 * @static
 * @param {string} type
 * @returns {string}
 * @example
 * getInputType('DROPDOWN_1') // DROPDOWN
 */
export const getInputType = (type) => {
    return type.replace(/_\d+/, '')
}

/**
 * Returns true if
 * - value === '' || value === undefined || value === null
 * - To object, checks if some value is value === '' || value === undefined || value === null
 * @function
 * @inner
 * @static
 * @param {*} value
 * @returns {boolean}
 */
export const isEmpty = (value) => {
    return value === '' || value === undefined || value === null ||
        (typeof value === 'object' && Object.values(value).every(x => x === null || x === '' || x === undefined))
}

/**
 * Receives a template and an object values.
 * It goes through the array of element values inside the template, searching for a value that exists in `listOfValues`.
 * If found, replace the value inside the template.
 * @function
 * @inner
 * @static
 * @param {Template} template
 * @param {Object} listOfValues
 */
export const fillTemplateData = (template, listOfValues) => {
    template.elements.forEach((element) => {
        element.values.forEach((value) => {
            Object.keys(listOfValues).forEach(function (key) {
                if (key === value.id && listOfValues[key]) {
                    value.value = listOfValues[key].text
                }
            })
        })
    })
}

/**
 * Receives an array of templates and for each one, apply the function {@link fillTemplateData}
 * @function
 * @inner
 * @static
 * @param {TemplateSection} content
 * @param {Template} template
 * @param {Object} listOfValues
 */
export const updateTemplatesOfContent = (content, template, listOfValues) => {
    // TODO: Do not modify state directly use pure functions - content.templates.element
    content.templates.forEach((template) => {
        fillTemplateData(template, listOfValues)
    })
}

/**
 * Receives an array of templates.
 * It goes through the array of element values inside the templates.
 * For each one, binds the field inside the values with the fields inside the template object parent
 * @function
 * @inner
 * @static
 * @param {TemplateSection} content
 * @returns {TemplateSection}
 */
export const bindValueFieldIdsWithField = content => {
    content.templates.forEach(template => {
        template.elements && template.elements.forEach(element => {
            return element.values.forEach(value => {
                value.field = template.fields.find(field => field.id === value.fieldId)
            })
        })
    })
    return content
}

/**
 * Receives an array of objects and a key. Returns a new array grouping the elements by the received key.
 * @function
 * @inner
 * @static
 * @param {Array<Object>} array
 * @param {string} key
 * @returns {Array<Object>}
 */
export const groupBy = (array, key) => array.reduce(
    (accumulator, currentValue) => {
        const elementType = currentValue[key]
        if (accumulator[elementType]) {
            accumulator[elementType] = [...accumulator[elementType], currentValue]
        } else {
            accumulator[elementType] = [currentValue]
        }

        return accumulator
    },
    {}
)

/**
 * Receives an object and apply the received function (second parameter) for each value
 * @function
 * @inner
 * @static
 * @param {Object} obj
 * @param {function} fn - parser function that will be applied to each value of the object
 * @returns {Object} - transformed object
 */
export const objectMap = (obj, fn) =>
    Object.fromEntries(
        Object.entries(obj).map(
            ([key, value]) => [key, fn(key, value)]
        )
    )
