import { types } from './types'

/**
 * @type {ClassData}
 * @alias initialState
 * @memberOf AddEditClassRedux
 */
export const initialState = {
    eventsGroupId: '',
    date: '',
    duration: '',
    repeat: {},
    description: '',
    trainer: '',
    time: { minute: '', hour: '' },
    location: ''
}

/**
 * @class AddEditClassRedux
 * @category Components
 * @subcategory theme / commons / AddEditClass
 */
export const reducer = (state, action) => {
    switch (action.type) {
    case types.UPDATE_SELECTED_COURSE_ID:
        return { ...state, eventsGroupId: action.value }
    case types.UPDATE_DATE:
        return { ...state, date: action.value }
    case types.UPDATE_DURATION:
        return { ...state, duration: action.value }
    case types.UPDATE_REPEAT_SCHEDULE:
        return { ...state, repeat: action.value }
    case types.UPDATE_DESCRIPTION:
        return { ...state, description: action.value }
    case types.UPDATE_TRAINER:
        return { ...state, trainer: action.value }
    case types.UPDATE_START_TIME:
        return { ...state, time: action.value }
    case types.UPDATE_LOCATION:
        return { ...state, location: action.value }
    default:
        return state
    }
}
