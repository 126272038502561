import React from 'react'
import logo from '../../assets/vfl-wolfsburg-logo.png'
import './Logo.scss'

/**
 * @typedef LogoProps
 * @memberOf Logo
 * @property {boolean} vertical
 * @property {string} navigation
 */

/**
 * Logo component
 * @class Logo
 * @category Components
 * @subcategory theme / commons
 * @param {LogoProps} props
 * @returns {React.ReactNode}
 * @example
 * <Logo vertical={false} navigation="./" />
 */
const Logo = (props) => {
    const { vertical, navigation } = props

    /**
     * Default content if navigation is not set
      * If vertical is true, adds a class called 'vertical'
      * @type {JSX.Element}
    */
    const content = (
        <div className={`logo-container ${vertical ? 'vertical' : 'horizontal'}`}>
            <img className="logo" src={logo} alt="VfL Wolfsburg e.V. - logo" />
            <span className="title">
        VfL <span>WOLFSBURG</span> e.V.
            </span>
        </div>
    )
    return navigation ? <a className="logo-navigation" href={navigation}>{content}</a> : content
}

export default Logo
