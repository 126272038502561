import React, { useRef } from 'react'
import { REDIRECT_URL } from '../../../backend/mesh/Constants'
import './ProfileMenuContent.scss'
import NavigationWrapper from '../../../theme/commons/navigation_wrapper/NavigationWrapperContainer'
import { cmsLanguage } from '../../../cms/commons/cms_language/cmsLanguage'
import Button from '../../../theme/commons/button/Button'
import { language } from '../../../theme/commons/language/language'
import useOutsideClick from '../../../theme/commons/dropdown/useOutsideClick'
import { AVATAR_ID } from './ProfileAvatar'
import STORAGE_KEYS from '@utils/storageKeys'

/**
 * @typedef ProfileMenuContentProps
 * @memberOf ProfileMenuContent
 * @property {boolean} isOpen
 * @property {function} onClose
 * @property {boolean} isMobile
 */

/**
 * When is open, it will show the profile menu with links to members area.
 * @class ProfileMenuContent
 * @category Components
 * @subcategory Header
 * @param {ProfileMenuContentProps} props
 * @returns {React.ReactNode}
 * @example
 * <ProfileMenuContent isOpen={profileOpened} onClose={onProfileMenuClose} isMobile={!fullDesktopMenu}/>
 */
const ProfileMenuContent = ({ isOpen, onClose, isMobile }) => {
    const wrapperRef = useRef(null)
    const handleRemoveUser = () => localStorage.removeItem(STORAGE_KEYS.USER)
    const outFocusWrapup = (event) => { onClose(event) }
    useOutsideClick(wrapperRef, outFocusWrapup, AVATAR_ID)
    return (
        <div id='profile-menu-content' ref={wrapperRef} className={`menu ${isOpen ? 'open' : 'closed'} ${isMobile ? 'mobile' : 'desktop'}`}>
            <div className="profile-items">
                <div data-testid='profile-link' onClick={onClose}><NavigationWrapper type={'LINK'} href={'/members'}>{cmsLanguage.profile.profile}</NavigationWrapper ></div>
                <div onClick={onClose}><NavigationWrapper type={'LINK'} href={'/members#associatedProfiles'}>{cmsLanguage.profile.associatedProfile}</NavigationWrapper ></div>
                <div onClick={onClose}><NavigationWrapper type={'LINK'} href={'/members#payments'}>{cmsLanguage.profile.paymentMethod}</NavigationWrapper ></div>
            </div>
            <div data-testid='divider' className={'divider'}> </div>
            <div className="button-container">
                <Button
                    text={language.buttons.signOut}
                    onClick={handleRemoveUser}
                    link={REDIRECT_URL}
                    type={'menu'}
                    lightButton
                />
            </div>

        </div>

    )
}

export default ProfileMenuContent
