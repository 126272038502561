// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".top-bar{display:flex}.top-bar .login-logout-button{border:1px solid #fff;padding:.3125em .625em}.top-bar .login-logout-button:hover,.top-bar .login-logout-button:focus{border:1px solid #68c329}.top-bar-big{height:2.8125em;background-color:rgba(0,0,0,0.75);border-bottom:1px #595959 solid;align-items:center;justify-content:flex-end;padding:0 3.75em}.top-bar-big .top-bar-item{margin-left:2.8125em}.top-bar-big.dark-background{transition:all 1000ms ease;background-color:#000}.top-bar-collapsed{flex-direction:column;border-bottom:solid 1px #fff;margin-top:1.875em;margin-bottom:2.8125em}.top-bar-collapsed .top-bar-item{margin-bottom:.9375em}.top-bar-collapsed .top-bar-item:last-child{margin-bottom:2.8125em}.top-bar-collapsed .login-logout-button{margin-right:auto}.top-bar-item{font-family:rucksack, sans-serif;font-weight:400;font-style:normal;font-size:1em;color:#fff;text-transform:uppercase}.top-bar-item:hover,.top-bar-item:focus{color:#68c329}.top-bar-item:focus{outline-offset:inherit;outline:none}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
