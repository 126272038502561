import React from 'react'
import moment from 'moment'
import './scheduleCard.scss'
import { htmlContainsText } from '../../../../utils/stringUtils'

/**
 * @typedef ScheduleCardProps
 * @memberOf ScheduleCard
 * @property {string} from
 * @property {string} to
 * @property {string} title
 * @property {string} path
 * @property {Object} data
 * @property {function} clickHandler
 */

/**
 * Application Week Calendar component.
 * @class ScheduleCard
 * @category Components
 * @subcategory Pages / trainingSchedule
 * @param {ScheduleCardProps} props
 * @returns {React.ReactNode}
 * @example
 * <ScheduleCard from="10:00" to="10:00" title="Yoga" />
 */
export default function ScheduleCard ({ from, to, title, path, data, clickHandler }) {
    const setCurrentModalValue = () => {
        const start = moment(data.startDateTime)
        const end = moment(data.endDateTime)
        const value = {
            date: moment(data.startDateTime).format('DD MMM YYYY'),
            time: moment(data.startDateTime).format('HH:mm'),
            duration: moment.duration(end.diff(start)).asMinutes(),
            image: data.image, // Need to update name once backend is implemented
            trainer: data.coach,
            name: title,
            description: data.description,
            shortDescription: data.shortDescription,
            location: data.place,
            path: path,
            id: data.id,
            eventsGroupId: data.eventsGroupId
        }
        clickHandler(value)
    }
    return (
        <div data-testid="schedule-card" className="schedule-card-container" onClick={setCurrentModalValue}>
            <span className="schedule-card-time">
                {from} - {to}
            </span>
            <span dangerouslySetInnerHTML={{ __html: data.shortDescription && htmlContainsText(data.shortDescription) ? data.shortDescription : title }}/>
            {/* <span>{title}</span> */}
        </div>
    )
}
