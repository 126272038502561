import React from 'react'
import './PhotoModal.scss'

/**
 * @typedef PhotoModalProps
 * @memberOf PhotoModal
 * @property {string} picture
 * @property {string} [text]
 * @property {string} [date]
 */

/**
 * The modal photo with more details about the photo, with the image and additional information
 * @class PhotoModal
 * @category Components
 * @subcategory theme / commons
 * @param {PhotoModalProps} props
 * @returns {React.ReactNode}
 * @example
 * <PhotoModal picture="http://image.com/image.jpg" text="Photo headline" date="2023-05-18" />
 */
const PhotoModal = (props) => {
    const { picture, text, date } = props

    return (
        <div className="photo-modal-container">
            <div className="photo-modal-picture-container">
                <img
                    className="photo-modal-picture"
                    data-testid="photo-modal-picture"
                    src={picture}
                    alt={text}
                />
            </div>
            <div className="photo-modal-info">
                {text && <p data-testid="photo-modal-text">{text}</p>}
                {date && <span data-testid="photo-modal-date">{date}</span>}
            </div>
        </div>
    )
}

export default PhotoModal
