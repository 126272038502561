/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { getInputType } from '../../util/Util'
import CmsInput from '../cms_input/CmsInput'
import { sortElementValues } from '../../util/sortElementValues'
import { translateValue } from '../../../utils/elementsUtils'

const AddCmsTemplateValues = (props) => {
    const { templateType, element, onChange } = props

    // Errors can have 3 states. This is to check if the input have ever been changed
    // has error will be false when all of the mandatory checks are false
    const [elementErrors, setElementErrors] = useState({})

    useEffect(() => {
        element.values
            .filter(v => v.field.isMandatory)
            .forEach(v => {
                elementErrors[v.id] = v.value ? false : undefined
            })
        setElementErrors(elementErrors)
    }, [])

    const handleOnChange = (valueId, newValue, hasError) => {
        elementErrors[valueId] = hasError
        setElementErrors(elementErrors)
        const mandatoryErrorsState = element.values
            .filter(v => v.field.isMandatory)
            .map(v => elementErrors[v.id])

        const hasAnyMandatoryUndefined = mandatoryErrorsState.filter(v => v === undefined).length > 0
        const hasAnyMandatoryError = mandatoryErrorsState.filter(v => v).length > 0

        const hasAnyError = element.values.map(v => elementErrors[v.id]).filter(v => !!v).length > 0

        const elementHasError = hasAnyError ? true : hasAnyMandatoryUndefined ? undefined : hasAnyMandatoryError
        onChange(valueId, newValue, elementHasError)
    }

    return (
        <div className="add-cms-modal-inputs" data-testid="add-cms-template-values">
            <div key={element.id} className={`edit-cms-modal-element element-type-${element.type.toLowerCase()}`}>
                {element && sortElementValues(templateType, element.values).map(value => {
                    return (
                        <CmsInput
                            key={value.id}
                            name={translateValue(value.field.name)}
                            type={getInputType(value.field.type)}
                            value={translateValue(value.value)}
                            valueId={value.id}
                            mandatory={value.field.isMandatory}
                            onChange={(valueId, newValue, hasError) => handleOnChange(valueId, { ...value, value: newValue }, hasError)}
                            defaultValue={value.defaultValue && value.defaultValue}
                            placeholder={value.placeholder && value.placeholder}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default AddCmsTemplateValues
