import { v4 as uuidv4 } from 'uuid'
import { cmsLanguage } from '../../../commons/cms_language/cmsLanguage'

const membersForm = () => {
    const fields = [
        {
            name: 'text',
            type: 'LONG_TEXT',
            id: uuidv4(),
            isMandatory: true
        }
    ]

    const values = fields.map(f => ({
        fieldId: f.id,
        id: uuidv4(),
        value: '',
        field: f
    }))
    return {
        id: uuidv4(),
        name: cmsLanguage.templatesNames.membersForm,
        type: 'MEMBERS_FORM',
        fields: fields,
        elements: [
            {
                id: uuidv4(),
                values: values,
                type: 'default'
            }
        ]
    }
}

export const MEMBERS_FORM_TEMPLATE_ORDER = { LONG_TEXT: 0 }

export default membersForm
