import React from 'react'
import './LoadingIndicator.scss'
import { usePromiseTracker } from 'react-promise-tracker'
import Loader from 'react-loader-spinner'

/**
 * Renders the LoadingIndicator component.
 * This component takes up the entire width and height of its parent component.
 * This component is typically used when loading a page of data.
 * @class
 * @category Components
 * @subcategory theme / commons
 * @returns {React.ReactNode}
 * @example
 * <LoadingIndicator />
 */
const LoadingIndicator = () => {
    const { promiseInProgress } = usePromiseTracker()

    return (
        promiseInProgress && (
            <div className="loader-indicator">
                <Loader className="loader-indicator-animation" type="Oval" />
            </div>
        )
    )
}

export default LoadingIndicator
