import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import SocialMedia from './SocialMedia'
import * as cmsActions from '@src/cms/actions/CMSActions'
import * as contentActions from '@src/cms/actions/ContentActions'

const mapStateToProps = (state) => {
    const { cms, socialMediaMenu, socialMediaDraft } = state

    return {
        socialMediaContent: socialMediaDraft,
        isEditModeOn: cms.isEditModeOn,
        socialMediaMenu: socialMediaMenu
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ ...cmsActions, ...contentActions }, dispatch)

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    SocialMedia
)
