// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".instagram-template .title_container{margin-bottom:2.8125em;display:flex;justify-content:space-between;align-items:center;gap:8px}.instagram-template .title_container .title{font-family:rucksack, sans-serif;font-weight:900;font-style:normal;font-size:2.8125em;color:#191919;text-transform:uppercase}@media screen and (max-width: 640px){.instagram-template .title_container{flex-direction:column;justify-content:start;align-items:start}}.instagram-template .row div div{display:flex}.instagram-template .row div div div{flex:1;width:25%}.instagram-template .row div div .instagram-count-item{display:none}.instagram-template .ig-instagram-link{display:block;position:relative;width:100%;overflow:hidden}.instagram-template .ig-instagram-link img{width:100%;height:100%;object-fit:cover}.instagram-template .ig-instagram-link video{position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
