import React, { useEffect, useState } from 'react'
import './paymentStep.scss'
import { language } from '@theme/commons/language/language'
import { PaymentMethodInput, PaymentMethodFulfillment } from './components'
import Button from '@theme/commons/button/Button'
import ButtonTypes from '@theme/commons/button/buttonTypes'
import CourseSummary from '../components/course-summary'
import paymentMethods from './payment-methods'
import { PAYMENT_DATA_PROPERTIES, PAYMENT_METHODS } from './constants/constants'
import { adaptPaymentData, SUCCESS_PAYMENT_CODE } from './payment-utils'

/**
 * @typedef PaymentStepProps
 * @memberOf PaymentStep
 * @property {number} price
 * @property {string} courseName
 * @property {function} onNext
 * @property {PaymentStepContainer~handlePayment} onPay
 * @property {string} errorMessage
 * @property {string} eventsGroupId
 */

/**
 * This component renders the payment process
 * @class PaymentStep
 * @category Components
 * @subcategory Templates / CourseInfo
 * @param {PaymentStepProps} props
 * @return {React.ReactNode}
 */
const PaymentStep = ({
    price,
    courseName,
    onNext,
    onPay,
    errorMessage,
    eventsGroupId,
    checkoutId
}) => {
    const [hasPaid, setHasPaid] = React.useState(false)
    const [selectedType, setSelectedType] = React.useState(checkoutId ? PAYMENT_METHODS.CHECKOUT : undefined)
    const [paymentData, setPaymentData] = useState()

    useEffect(() => {
        if (selectedType) {
            setPaymentData(PAYMENT_DATA_PROPERTIES[selectedType])
        }
    }, [selectedType])

    const hasSelected = !!selectedType || selectedType >= 0
    const isSelected = (type) => selectedType === type
    const itemClass = (type) => (isSelected(type) ? 'selectedType' : 'idle')
    const isDisabled = (key) => {
        return hasPaid || paymentMethods[key].disabled
    }
    const buttonType = hasSelected && selectedType !== PAYMENT_METHODS.CHECKOUT ? ButtonTypes.SUCCESS : ButtonTypes.DISABLED
    const buttonText = hasPaid ? language.modals.continue : language.modals.pay
    /**
     * It returns a function that will process the order
     * @return {PaymentMethod_GenerateOrder | PaymentMethod_MockFailedOrder | PaymentMethod_MockSuccessOrder}
     */
    const selectAction = () => {
        const pmSelected = Object.values(paymentMethods).find(
            (pm) => pm.type === selectedType
        )
        return pmSelected.action
    }
    const handleCardDataOnChange = (paymentChange) => {
        if (paymentChange.paymentMethod === PAYMENT_METHODS.PAYPAL) {
            makeOrder(paymentChange.id)
        } else if (paymentChange && paymentChange.result && paymentChange.result.code === SUCCESS_PAYMENT_CODE) {
            makeOrder(paymentChange.id)
        } else {
            setPaymentData(paymentChange)
        }
    }

    const makeOrder = (checkoutId) => {
        const withDetails = selectedType !== 'WALLET'

        onPay(selectedType, selectAction(), withDetails ? adaptPaymentData(paymentData, price, eventsGroupId, selectedType, checkoutId) : undefined)
            .then((hasPaid) => {
                setHasPaid(hasPaid)
                if (hasPaid) onNext()
            })
            .catch()
    }

    const handlePayment = () => {
        makeOrder()
    }

    const buttonAction = hasPaid
        ? onNext
        : handlePayment

    return (
        <div className="payment-step">
            <div className="payment-step__summary-container">
                <h3>{`${language.modals.paymentStep.summary}:`}</h3>
                <CourseSummary courseName={courseName} price={price} />
            </div>
            <div className="payment-step__methods">
                <h3>{language.modals.paymentStep.paymentMethods}:</h3>
                <ul className="payment-step__methods-list">
                    {Object.entries(paymentMethods)
                        .filter(([key, { visible }]) => visible)
                        .map(([key, { id, name, logos, type }]) => {
                            const disabled = isDisabled(key)
                            return (
                                <li
                                    className={`item--${itemClass(type)} ${
                                        disabled ? 'item--disabled' : ''
                                    }`}
                                    key={id}
                                >
                                    <PaymentMethodInput
                                        label={name}
                                        onClick={(type) => setSelectedType(type)}
                                        disabled={disabled}
                                        id={id}
                                        type={type}
                                        value={id}
                                        logos={logos}
                                    />
                                    {isSelected(type) && type !== 'WALLET' && (
                                        <React.Fragment>
                                            <hr className="label-underline"/>
                                            <div className="payment-container">
                                                <PaymentMethodFulfillment
                                                    onChange={handleCardDataOnChange}
                                                    price={price}
                                                    concept={eventsGroupId}
                                                    paymentData={paymentData}
                                                    type={type}/>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </li>
                            )
                        })}
                </ul>
            </div>
            <div className="payment-step__continue-container">
                <Button text={buttonText} onClick={buttonAction} type={buttonType} />
                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            </div>
        </div>
    )
}

export default PaymentStep
