import React from 'react'
import AdminButton from '../../../../../commons/admin_button/AdminButton'
import { cmsLanguage } from '../../../../../commons/cms_language/cmsLanguage'
import './deleteElementButton.scss'

const DeleteElementButton = ({ element, onDelete, template }) => {
    const isElementDeletable = template?.elements.filter(el => {
        return el.type === element.type
    }).length > 1

    const inputDelete = cmsLanguage.tooltip.inputDelete

    const handleDeleteElement = async () => {
        onDelete(element.id)
    }

    return (
        onDelete
            ? (
                <div className="delete-element-button">
                    {isElementDeletable &&
                        <AdminButton
                            data-testid="delete-element-button-id"
                            icon={'trash-alt'}
                            onClick={handleDeleteElement}
                            type="solid"
                            color="red"
                            tooltip={inputDelete}
                        />
                    }
                </div>
            )
            : null
    )
}

export default DeleteElementButton
