// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".sport-picture-container{height:100%}.sport-picture-container .picture{height:100%;background-size:cover;background-position:center}.sport-picture-container .shadow{height:100%;background-color:rgba(0,0,0,0.15)}.sport-picture-container .sport{height:100%;display:flex;flex-direction:column;align-items:center}.sport-picture-container .sport span{position:absolute;bottom:.9375em;font-family:rucksack, sans-serif;font-weight:900;font-style:normal;font-size:2.8125em;color:#fff;text-transform:uppercase;text-align:center}.sport-picture-container .shadow,.sport-picture-container .sport span{transition:all 1000ms ease}.sport-picture-container:hover .shadow{background-color:rgba(0,0,0,0.4)}.sport-picture-container:hover .sport span{bottom:50%}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
