import { save } from '@utils/storage'
import STORAGE_KEYS from '../../../../../../utils/storageKeys'

export const saveCurrentLocation = (url) => {
    save(STORAGE_KEYS.LAST_URL, url)
}

export const saveStep = (step) => {
    save(STORAGE_KEYS.JOIN_COURSE, { step })
}
