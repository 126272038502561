import { v4 as uuidv4 } from 'uuid'

export const filterElementByType = (type, template) => template.elements.filter(element => element.type === type)

export const generateNewElement = (template, elementType) => {
    const values = []
    const selectedElement = filterElementByType(elementType, template)

    selectedElement[selectedElement.length - 1].values.forEach(value => {
        const fields = template.fields.filter((field) => field.id === value.fieldId)
        fields.forEach(field =>
            values.push({
                fieldId: field.id,
                field: field,
                id: uuidv4(),
                value: ''
            })
        )
    })

    return {
        id: uuidv4(),
        values: values,
        type: elementType
    }
}
