import { useEffect } from 'react'

/**
 * Closes the Dropdown when clicks outside it
 * @function
 * @memberOf Dropdown
 * @category Hooks
 * @subcategory theme / commons / Dropdown
 * @param {Object} ref - useRef() react hook
 * @param {function} callback
 * @param {string} elementId
 * @example
 * useOutsideClick(wrapperRef, callbackFunction, "AVATAR_ID")
 */
function useOutsideClick (ref, callback, elementId) {
    useEffect(() => {
        function handleClickOutside (event) {
            const inputContainer = document.getElementById(elementId)
            if (
                ref.current &&
        !ref.current?.contains(event.target) &&
        !inputContainer?.contains(event.target)
            ) {
                callback()
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref, callback])
}
export default useOutsideClick
