import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './calendarHeaderButton.scss'
import { cmsLanguage } from '../../../../cms/commons/cms_language/cmsLanguage'

/**
 * @typedef CalendarHeaderButtonProps
 * @memberOf CalendarHeaderButton
 * @property {string} month
 * @property {number} week
 * @property {function} changeWeek
 * @property {string} theme
 */

/**
 * Application Calendar Header Button component.
 * (see: {@link WeekCalendar})
 * @class CalendarHeaderButton
 * @category Components
 * @subcategory Pages / trainingSchedule
 * @param {CalendarHeaderButtonProps} props
 * @returns {React.ReactNode}
 * @example
 * <CalendarHeaderButton month={month} week={week} changeWeek={changeWeek}/>
 */
export default function CalendarHeaderButton ({
    month,
    week,
    changeWeek,
    theme = 'dark'
}) {
    return (
        <div className={`calendar-header-button calendar-header-button-${theme}`}>
            <FontAwesomeIcon
                id="calendar-previous-icon"
                data-testid="calendar-previous-icon"
                onClick={() => changeWeek(week, month, '-')}
                icon={['fa', 'angle-left']}
            />
            <span>
                {month.toUpperCase()} - {cmsLanguage.cms.week} {week}
            </span>
            <FontAwesomeIcon
                id="calendar-next-icon"
                data-testid="calendar-next-icon"
                onClick={() => changeWeek(week, month, '+')}
                icon={['fa', 'angle-right']}
            />
        </div>
    )
}
