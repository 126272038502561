import store from '../../../redux/store'
import { CONFIG, MESH_CONFIG } from '../Constants'
// eslint-disable-next-line no-unused-vars
import axios, { AxiosResponse } from 'axios'

/**
 * @class MeshMultimedia
 * @category Backend
 * @subcategory multimedia_service
 */
export default class MeshMultimedia {
    /**
     * Fetches the user's multimedia, sending an optional array of labels to be used as filter
     * @function
     * @memberOf MeshMultimedia
     * @param {Array<string>} labels
     * @param {string} userId
     * @returns {Promise<AxiosResponse<Multimedia>>}
     * @static
     */
    static async getImage (labels, userId) {
        const { accessToken, organizationId } = store.getState().user
        const url = `${MESH_CONFIG.SERVER_URL}/multimedia-service/organizations/${organizationId}/multimedia`

        const config = {
            headers: {
                ...CONFIG.DEFAULT_HEADERS,
                CONTENT_TYPE: 'application/json',
                Authorization: `Bearer ${accessToken}`
            },
            params: {
                labels,
                userId
            }
        }
        return axios.get(url, config)
    }

    /**
     * Saves the user's profile image
     * @function
     * @memberOf MeshMultimedia
     * @param {Object} image - multipart/form-data
     * @param {string} userId
     * @returns {Promise<AxiosResponse<Multimedia>>}
     * @static
     */
    static async uploadProfileImage (image, userId) {
        const { accessToken, organizationId } = store.getState().user
        const url = `${MESH_CONFIG.SERVER_URL}/multimedia-service/organizations/${organizationId}/users/${userId}`

        const config = {
            headers: {
                ...CONFIG.DEFAULT_HEADERS,
                CONTENT_TYPE: 'multipart/form-data',
                Authorization: `Bearer ${accessToken}`
            }
        }
        const formData = new FormData()
        formData.append('image', image)

        return axios.post(url, formData, config)
    }

    /**
     * Saves a file related to user ID
     * @function
     * @memberOf MeshMultimedia
     * @param {Object} image - multipart/form-data
     * @param {Array<string>} labels
     * @param {string} userId
     * @returns {Promise<AxiosResponse<Multimedia>>}
     * @static
     */
    static async uploadImage (image, labels, userId) {
        const { accessToken, organizationId } = store.getState().user
        const url = `${MESH_CONFIG.SERVER_URL}/multimedia-service/organizations/${organizationId}/multimedia/generic`

        const config = {
            headers: {
                ...CONFIG.DEFAULT_HEADERS,
                CONTENT_TYPE: 'multipart/form-data',
                Authorization: `Bearer ${accessToken}`
            },
            params: {
                labels,
                userId
            }
        }
        const formData = new FormData()
        formData.append('image', image)

        return axios.post(url, formData, config)
    }
}
