import Checkbox from './checkbox'
import React, { useState } from 'react'
import './InputCheckbox.scss'

/**
 *
 * @param {string?} label
 * @param {boolean?} checked
 * @param {function?} onClick
 * @param {string?} value
 * @param {string} id
 * @param {string?} name
 * @param {boolean?} disabled
 * @param {boolean?} multiple
 * @return {JSX.Element}
 * @constructor
 */
const InputCheckbox = ({
    label,
    checked = false,
    onClick,
    value,
    id,
    name,
    disabled = false,
    multiple = false,
    onChange
}) => {
    const isSelectedClass = (selected) => (selected ? 'active' : 'not-active')
    const isDisabledClass = disabled => disabled ? 'disabled' : 'not-disabled'
    const type = (isMultiple) => isMultiple ? 'checkbox' : 'radio'

    const [_checked, setChecked] = useState(checked)

    const handleOnClick = event => {
        const newValue = !_checked
        setChecked(newValue)
        onClick && onClick(event)
        onChange && onChange(newValue, false)
    }

    return (
        <div
            className={`input_checkbox ${isSelectedClass(_checked)} ${isDisabledClass(disabled)} `}
            onClick={disabled ? () => {} : handleOnClick}
        >
            <input
                className={'input_checkbox__circle'}
                type={type(multiple)}
                id={id}
                disabled={disabled}
                name={name}
                value={value}
            />
            <Checkbox checked={_checked} />
            <label aria-labelledby={id} htmlFor={id}>{label}</label>
        </div>
    )
}

export default InputCheckbox
