import React from 'react'
import './ProfileRoundPicture.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/**
 * @typedef ProfileRoundPictureProps
 * @memberOf ProfileRoundPicture
 * @property {string} [picture]
 * @property {boolean} [editing]
 * @property {function} [handleProfileImage] - function for the link if `editing` === true
 */

/**
 * Picture for the profile, with a link or not (depends on the `editing` and `handleProfileImage` properties)
 * @class ProfileRoundPicture
 * @category Components
 * @subcategory theme / commons
 * @param {ProfileRoundPictureProps} props
 * @returns {React.ReactNode}
 * @example
 * <ProfileRoundPicture
 *     picture="http://image.com/image.jpg"
 *     editing={true}
 *     handleProfileImage={handleProfileImage}
 * />
 */
const ProfileRoundPicture = (props) => {
    const { picture, editing, handleProfileImage } = props

    return (
        <div
            className="profile-round-picture"
            data-testid="profile-round-picture"
            style={{ backgroundImage: `url(${picture || ''})` }}
        >
            {editing && (
                <div className="edit-overlay" onClick={() => handleProfileImage()}>
                    <FontAwesomeIcon className="edit-icon" icon={['fas', 'user-edit']} />
                </div>
            )}
            {!picture && (
                <div className="no-picture-icon">
                    <FontAwesomeIcon icon={['far', 'user']} />
                </div>
            )}
        </div>
    )
}

export default ProfileRoundPicture
