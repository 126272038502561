// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".photo-gallery{padding-bottom:3.75em}.photo-gallery:first-child{padding-top:3.75em}.photo-gallery:last-child{padding-bottom:5.625em}.photo-gallery-date-title{width:100%;padding-top:.9375em;margin-bottom:1.875em}.photo-gallery-date-title-line{border-top:solid .3125em #64b42d}.photo-gallery-date-title h1{font-family:rucksack, sans-serif;font-weight:900;font-style:normal;font-size:2.8125em;color:#191919;text-transform:uppercase}.photo-gallery-container>.row{margin-left:-30px;margin-right:-30px}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
