// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dropdown-item-list,.dropdown-item-list-header{display:flex;align-items:center;padding-left:10px;height:30px}.dropdown-item-list:hover,.dropdown-item-list-header:hover{background-color:#f5f5f5}.dropdown-item-list{font-family:rucksack, sans-serif;font-weight:400;font-style:normal;font-size:1em;color:#191919;font-weight:lighter}.dropdown-item-list-disabled{color:rgba(0,0,0,0.25);cursor:not-allowed}.dropdown-item-list-selected{background-color:#f2f2f2}.dropdown-item-list-selected:hover{background-color:#f2f2f2}.dropdown-item-list-fixed{margin:5px auto}.dropdown-item-list-with-icon{display:grid;grid-template-columns:auto 1fr;grid-column-gap:.3125em;align-items:center;margin:.625em 0 .625em 0}.dropdown-item-list-with-iconPosition{display:initial}.child-list-item{display:flex}.dropdown-item-list-header{cursor:default;font-family:rucksack, sans-serif;font-weight:700;font-style:normal;font-size:1.125em;color:#191919;text-transform:uppercase;margin:8px 0;text-transform:uppercase}.dropdown-item-list-header:hover{background-color:unset !important;color:unset !important}.dropdown-line{width:95%;margin:0 auto;border-top:0.5px solid #191919}.dropdown-delete-content{grid-column-gap:50%}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
