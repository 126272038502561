import masterCard from '@theme/assets/pictures/MasterCard_Logo.svg'
import visa from '@theme/assets/pictures/Former_Visa_(company)_logo.svg'
import paypal from '@theme/assets/pictures/Paypal_2014_logo.png'
import klarna from '@theme/assets/pictures/Klarna_Payment_Badge.svg'
import sepa from '@theme/assets/pictures/sepa.jpeg'
import giroPay from '@theme/assets/pictures/giropay.png'
import MeshOrders from '../../../../../backend/mesh/orders_service/MeshOrders'

const payPalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID

/**
 * @callback PaymentMethod_MockSuccessOrder
 * @return {Promise<*>}
 */

/**
 * @callback PaymentMethod_MockFailedOrder
 * @return {Promise<*>}
 */
const paymentActionError = () => new Promise((resolve, reject) => reject(Error('Not Accepted')))

/**
 * @callback PaymentMethod_CreateOrder
 * @param products {array} - It should include an {object} with productId and quantity fields
 * @param paymentMethod {string} - Method used to pay
 * @param concept {string} - Little description or related data
 * @param paymentDetails {object | undefined} - Details for VrPayment
 * @return {Promise<*>}
 */
const sendOrder = (products, paymentMethod, concept, paymentDetails) => MeshOrders.sendOrder({ products, paymentMethod, concept, paymentDetails })

/**
 * @typedef PaymentMethods
 * @property id {string}
 * @property name {string}
 * @property type {string}
 * @property action {PaymentMethod_CreateOrder}
 * @property disabled {boolean}
 * @property [visible] {boolean}
 * @property logos {Array<Object>}
 */

/**
 *
 * @type {{wallet: PaymentMethods, klarna: PaymentMethods, sepa: PaymentMethods, credit: PaymentMethods, paypal: PaymentMethods, giro: PaymentMethods}}
 */
const paymentMethods = {
    wallet: { id: 'wallet', type: 'WALLET', name: 'Wallet', logos: [], action: sendOrder, disabled: true, visible: true },
    credit: { id: 'credit', type: 'CHECKOUT', name: 'Credit card / debit card', logos: [masterCard, visa], action: sendOrder, disabled: false, visible: true },
    paypal: { id: 'paypal', type: 'PAYPAL', name: 'Paypal', logos: [paypal], action: sendOrder, disabled: false, visible: Boolean(payPalClientId) },
    klarna: { id: 'klarna', type: 'KLARNA', name: 'Klarna', logos: [klarna], action: paymentActionError, disabled: true, visible: true },
    sepa: { id: 'sepa', type: 'SEPA', name: 'SEPA direct debit', logos: [sepa], action: paymentActionError, disabled: true, visible: true },
    giro: { id: 'giro', type: 'GIROPAY', name: 'Giropay', logos: [giroPay], action: paymentActionError, disabled: true, visible: true }
}

export default paymentMethods
