import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import Navigation from './Navigation'
import { setCustomNavigation, setCurrentRoutes } from '../redux/actions/actions'

/**
 * {@link Navigation} container that binds the Header with the {@link module:reduxSetup}
 * @class NavigationContainer
 * @category Containers
 * @subcategory Navigation
 *
 * @mermaid
 *   sequenceDiagram
 *   App->>NavigationContainer: Request the Header via NavigationContainer.
 *   Note right of NavigationContainer: NavigationContainer gets information from redux store.
 *   NavigationContainer->>Navigation: NavigationContainer sends redux store information to Navigation as props (Menus).
 *   Navigation-->>App: Navigation is rendered in the parent component.
 */

/**
 * Gets the `content.menu` from redux state and makes available to the {@link Navigation} as a prop called `contentMenu`.
 * @function
 * @memberOf NavigationContainer
 * @alias mapStateToProps
 * @param {Object} state - redux state
 * @returns {{pageMenu: Menu, currentRoutes: Array<*>, currentContentMenu: Menu}}
 */
const mapStateToProps = (state) => {
    const { content } = state
    let currentContentMenu
    try {
        currentContentMenu = JSON.parse(localStorage.getItem('menu'))
    } catch (err) {
        currentContentMenu = undefined
    }

    return {
        pageMenu: content.menu,
        currentRoutes: state.getCurrentRoutes,
        currentContentMenu: currentContentMenu
    }
}

/**
 * Passes certain Redux actions
 * ([setCustomNavigation]{@link module:actions.setCustomNavigation} and [setCurrentRoutes]{@link module:actions.setCurrentRoutes})
 * to the {@link Navigation} component as props.
 * This function is used only by the react-redux connect function.
 * @function
 * @memberOf NavigationContainer
 * @alias mapDispatchToProps
 * @param {function} dispatch - The react-redux dispatch function
 * @returns {Object} - Redux actions used in the {@link Navigation} component
 */
const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ setCustomNavigation, setCurrentRoutes }, dispatch)

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    Navigation
)
