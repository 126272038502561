import { compose } from 'redux'
import { connect } from 'react-redux'
import AddClassTemplateBar from './AddClassTemplateBar'

const mapStateToProps = (state) => {
    const { training } = state
    return {
        selectedCourse: training.selectedCourse
    }
}

export default compose(connect(mapStateToProps, null))(
    AddClassTemplateBar
)
