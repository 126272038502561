import React, { useState } from 'react'
import DropdownWithTitle from '../../dropdown_with_title/DropdownWithTitle'
import { cmsLanguage } from '../../../../commons/cms_language/cmsLanguage'
import { menus } from '../../../menusList'
import { initializerMenuData } from '../../../../../initializerData'
import { translateValue } from '../../../../../utils/elementsUtils'

/**
 * @typedef MenuSelectionProps
 * @memberOf MenuSelection
 * @property {Array<Object>} menuOptions
 * @property {function} onMenuSelection
 */

/**
 * @class MenuSelection
 * @category Components
 * @subcategory Templates / CmsContent
 * @param {MenuSelectionProps} props
 * @returns {React.ReactNode}
 * @example
 * <MenuSelection
 *     menuOptions={[topMenu, headerMenu, footerMenu]}
 *     onMenuSelection={handleMenuSelection}
 * />
 */
const MenuSelection = ({ menuOptions, onMenuSelection }) => {
    const internalMenuOptions = menuOptions.map(menuOption => ({
        ...menuOption,
        originalName: menuOption.name,
        name: `\${menuNames.${menuOption.name}}`
    }))

    const [selectedMenuName, setSelectedMenuName] = useState()
    const [menuPicture, setMenuPicture] = useState('')

    /**
     * @function
     * @memberOf MenuSelection
     * @param {string} name
     */
    const assignMenuPreviewPicture = name => {
        const tempMenuPicture = menus[name] ? menus[name].picture : ''
        tempMenuPicture
            ? setMenuPicture(tempMenuPicture)
            : setMenuPicture('')
    }

    /**
     * @function
     * @memberOf MenuSelection
     * @param {string} name
     * @param {string} id
     */
    const handleMenuSelection = (name, id) => {
        setSelectedMenuName(name)
        const selectedMenu = internalMenuOptions.find(it => it.id === id)
        if (selectedMenu?.path) {
            if (selectedMenu.path === initializerMenuData.footerMenu.path) {
                const informationMenuItem = selectedMenu.children.find(m => translateValue(m.name) === translateValue(cmsLanguage.initializerData.informationMenuName))
                onMenuSelection({
                    id: informationMenuItem.id,
                    name: informationMenuItem.name,
                    path: informationMenuItem.path
                })
            } else {
                onMenuSelection({ name: menuOptions.find(it => it.id === id).name, id, path: selectedMenu.path })
            }
            assignMenuPreviewPicture(name)
        }
    }

    return (
        <div className="cms-input" data-testid="menu-section">
            <DropdownWithTitle
                headline={cmsLanguage.inputFields.assignedMenu}
                title={cmsLanguage.cms.chooseAMenu}
                data={internalMenuOptions}
                onSelect={(name, id) => handleMenuSelection(name, id)}
                value={selectedMenuName}
                menuPicture={menuPicture}
                data-testid="navigation-dropdown"
            />
        </div>
    )
}

export default MenuSelection
