import { cmsLanguage } from '../commons/cms_language/cmsLanguage'
import topMenu from '../assets/pictures/menus_preview/TopMenu.png'
import headerMenu from '../assets/pictures/menus_preview/HeaderMenu.png'
import subPageMenu from '../assets/pictures/menus_preview/SubPageMenu.png'
import footerMenu from '../assets/pictures/menus_preview/FooterMenu.png'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

export const menus = {
    [cmsLanguage.menuNames.topMenu]: {
        name: cmsLanguage.menuNames.topMenu,
        picture: topMenu,
        path: 'top_menu'
    },
    [cmsLanguage.menuNames.headerMenu]: {
        name: cmsLanguage.menuNames.headerMenu,
        picture: headerMenu,
        path: 'header_menu'
    },
    [cmsLanguage.menuNames.footerMenu]: {
        name: cmsLanguage.menuNames.footerMenu,
        picture: footerMenu,
        path: 'footer_menu'
    }
}

export const submenu = {
    name: cmsLanguage.menuNames.subPageMenu,
    picture: subPageMenu
}

export const addNewGroupMenuItem = {
    id: 'ADD_SECTION',
    name: cmsLanguage.sectionNames.addNewOne,
    icon: faPlus
}
export const subSectionsAdd = [addNewGroupMenuItem]

export const hasGroups = menuOptions => !!menuOptions.find(m => (m.children && (m.contentId === undefined || m.contentId === null)))
export const isHeadingGroup = menuItem => menuItem.contentId === undefined || menuItem.contentId === null
