/**
 * @typedef {string} ButtonTypes
 **/

/**
 * Button Types
 * @category Enums
 * @readonly
 * @enum {ButtonTypes}
 * @example
 * FAIL: 'fail'
 * SUCCESS: 'success'
 * MENU: 'menu'
 * REVERTED: 'reverted'
 * DISABLED: 'disabled'
 */

const ButtonTypes = {
    FAIL: 'fail',
    SUCCESS: 'success',
    MENU: 'menu',
    REVERTED: 'reverted',
    DISABLED: 'disabled'
}

export default ButtonTypes
