import { Col, Row } from 'react-bootstrap'
import FooterMenuItem from './FooterMenuItem'
import React from 'react'
import './FooterColumn.scss'
import { translateValue } from '../../utils/elementsUtils'

/**
 * @typedef FooterColumnProps
 * @memberOf FooterColumn
 * @property {Array<Object>} menuItems
 * @property {string} id
 * @property {string} name
 * @property {JSX.Element|function}
 */

/**
 * Footer column component for the {@link FooterMenu}
 * @class FooterColumn
 * @category Components
 * @subcategory Footer
 * @param {FooterColumnProps} props
 * @returns {React.ReactNode}
 * @example
 * <FooterColumn
 *     id={column.id}
 *     name={column.name}
 *     menuItems={[{...}]}
 *     wrapper={({children}) => children}
 * />
 */
const FooterColumn = ({ menuItems, id, name, wrapper: Wrapper = ({ children }) => children }) => (
    <Col className='footer-column col-12 col-md-auto'
        key={id}
    >
        {menuItems.length &&
            <Row className='row-cols-1 row-cols-md-2'>
                <Col className="footer-column-title-container col-md-auto">
                    <p
                        className='footer-column-title'
                        data-testid={'menu-title-' + id}
                    >
                        {translateValue(name)}
                    </p>
                </Col>
                <Col className="footer-column-item-container col-md-auto">
                    <Wrapper>
                        <FooterMenuItem menuItems={menuItems}/>
                    </Wrapper>
                </Col>
            </Row>
        }
    </Col>
)

export default FooterColumn
