import './membersForm.scss'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import MembersForm from './MembersForm'

/**
 * {@link MembersForm} container that binds the MembersForm with the {@link module:reduxSetup}
 * @class MembersFormContainer
 * @category Containers
 * @subcategory MembersForm
 *
 * @mermaid
 *   sequenceDiagram
 *   Parent->>MembersFormContainer: Request the MembersForm via MembersFormContainer.
 *   Note right of MembersFormContainer: MembersFormContainer gets information from redux store.
 *   MembersFormContainer->>MembersForm: MembersFormContainer sends redux store information to MembersForm as props.
 *   MembersForm-->>Parent: MembersForm is rendered in the parent component.
 */

/**
 * Gets the `state.user` from redux state and makes available to the {@link MembersForm} as a prop called `user`.
 * @function
 * @memberOf MembersFormContainer
 * @alias mapStateToProps
 * @param {Object} state - redux state
 * @returns {Object}
 */
const mapStateToProps = (state) => {
    return { user: state.user }
}

/**
 * Passes certain Redux actions to the {@link MembersForm} component as props.
 * This function is used only by the react-redux connect function.
 * @function
 * @memberOf MembersFormContainer
 * @alias mapDispatchToProps
 * @param {function} dispatch - The react-redux dispatch function
 * @returns {Object} - Redux actions used in the {@link MembersForm} component
 */
const mapDispatchToProps = (dispatch) => bindActionCreators({ }, dispatch)

export default compose(connect(mapStateToProps, mapDispatchToProps))(MembersForm)
