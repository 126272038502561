import { cmsLanguage } from '../cms/commons/cms_language/cmsLanguage'

/**
 * @module CommonReformatter
 * @category Utils
 * @subcategory General
 */

/**
 * Takes a string with a valid date format and returns the date in a new format,
 * with the first 3 letters of the month spelled out
 * @function
 * @param {string} date - a valid date format
 * @returns {string} - the formatted date
 * @example
 * const _date = '2023-05-23'
 * const converted = convertDate(_date) // 23 May 2023
 */
export const convertDate = (date) => {
    const newDate = new Date(date)
    const convertedMontDate = shortMonthString(monthToString(newDate.getMonth()))
    return `${newDate.getDate()} ${convertedMontDate} ${newDate.getFullYear()}`
}

/**
 * Takes a string with the month number and returns the month spelled out
 * @function
 * @param {number} month - month number
 * @returns {string} - the month spelled out (i18n)
 * @example
 * const _month = '05'
 * const converted = monthToString(_month) // May
 */
export const monthToString = (month) => {
    const monthNames = [
        cmsLanguage.months.january,
        cmsLanguage.months.february,
        cmsLanguage.months.march,
        cmsLanguage.months.april,
        cmsLanguage.months.may,
        cmsLanguage.months.june,
        cmsLanguage.months.july,
        cmsLanguage.months.august,
        cmsLanguage.months.september,
        cmsLanguage.months.october,
        cmsLanguage.months.november,
        cmsLanguage.months.december
    ]

    return monthNames[month]
}

/**
 * Generates a new random number between 1 and 1001
 * @function
 * @returns {number} - random number
 * @example
 * randomNumGenerator() // 854
 */
export const randomNumGenerator = () => Math.floor(Math.random() * 1000 + 1)

/**
 * Takes a month spelled out and returns the 3 first letters
 * @function
 * @param {number} monthString - month number
 * @returns {string} - month 3 first letters
 * @example
 * const _month = 'September'
 * const converted = shortMonthString(_month) // Sep
 */
const shortMonthString = (monthString) => monthString.slice(0, 3)

/**
 * Converts the string to a specific pattern
 * - `/` will be replaced by `_and_`
 * - ` ` (space) will be replaced by `_`
 * - `-` will be removed
 * - All letters will be lowered case
 * - All special characters will be removed
 * @function
 * @param {string} name - original string
 * @returns {string} - formatted string
 * @example
 * const name = 'string_to_and_beconverted#$%ˆ&'
 * randomNumGenerator() // 'string_to_and_beconverted'
 */
export const convertNameToRoute = (name) => {
    return name.normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/ /g, '_')
        .replace(/\//g, '_and_')
        .replace(/[^a-zA-Z0-9_]/gim, '').toLowerCase()
}

/**
 * Converts the given path, removing the first section (the path will be split by `/`)
 * @function
 * @param {string} path - original path
 * @returns {string} - converted path
 * @example
 * const path = 'path/to/be/maniputated/'
 * popPathElement(path) // 'to/be/maniputated/'
 */
export const popPathElement = path => path.split('/').slice(1).join('/')
