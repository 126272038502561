import React, { useState } from 'react'
import './InputPhoto.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { trackPromise } from 'react-promise-tracker'
import { cmsLanguage } from '../../../cms_language/cmsLanguage'
import MeshMultimedia from '@backend/mesh/multimedia_service/MeshMultimedia'
import CloudPhotos from './CloudPhotos'
import { MAX_PICTURE_SIZE, MAX_PICTURE_SIZE_NUMBER, PICTURE_ERROR } from './constants'

const InputPhoto = (props) => {
    const {
        onFocus,
        onBlur,
        onError,
        value,
        onChange
    } = props

    const [cloudImages, setCloudImages] = useState(null)
    const [isOpen, setIsOpen] = useState(false)

    let file
    const handleChangePicture = (e) => {
        [file] = e.target.files
        if (file && file.size <= MAX_PICTURE_SIZE_NUMBER) {
            const pictureUrl = URL.createObjectURL(file)
            onChange(pictureUrl)
            uploadToMediaService(file)
        } else {
            onError(PICTURE_ERROR)
        }
    }

    const uploadToMediaService = (image) => {
        trackPromise(
            MeshMultimedia.uploadImage(image).then(response => {
                onError()
                onChange(response.data.href)
            })
        ).catch((error) => {
            if (error.response?.status === 413) {
                onError(PICTURE_ERROR)
            } else {
                console.error(error)
            }
        })
    }

    const handlePhotoSearch = (label = '') => {
        trackPromise(
            MeshMultimedia.getImage(label).then(response => {
                setCloudImages(response.data)
                setIsOpen(true)
            })
        ).catch((error) => {
            console.error(error)
        })
    }

    const handleClickCard = (href) => {
        setIsOpen(false)
        onChange(href)
    }

    const closeModal = () => setIsOpen(false)

    const splitImage = value.split('/')
    const imageName = splitImage[splitImage.length - 1]

    return (
        <>
            <div className="cms-input-box cms-input-box-icon cms-input-photo">
                <div className="cms-input-photo-container">
                    {value
                        ? <img
                            className="cms-input-photo-preview"
                            data-testid="cms-input-photo-preview"
                            src={value}
                            alt={imageName}
                        />
                        : <div className="cms-input-photo-icon cms-input-photo-preview">
                            <FontAwesomeIcon icon={['far', 'image']} />
                        </div>}
                    <div className="search-images-container">
                        <div className="search-images-buttons">
                            <button
                                className={'cms-input-photo-browse--cloud'}
                                onClick={() => handlePhotoSearch()}
                                data-testid="mesh-button"
                            >
                                {cmsLanguage.cms.searchPhotoFromMesh}
                            </button>
                            <p>or</p>
                            <div className="cms-input-photo-browse">
                                <input
                                    id="cms-input-photo"
                                    data-testid="cms-input-photo"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleChangePicture}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    placeholder={cmsLanguage.cms.photoPlaceholder + '...'}
                                />
                            </div>
                        </div>
                        <span>
                            {cmsLanguage.cms.maxPictureSize} {MAX_PICTURE_SIZE}
                        </span>
                    </div>
                </div>
                <div>
                    <FontAwesomeIcon
                        icon={['fa', 'upload']}
                        data-testid="cms-input-photo-icon"
                    />
                </div>
            </div>
            <CloudPhotos
                cloudImages={cloudImages}
                onClickCard={handleClickCard}
                isOpen={isOpen}
                closeModal={closeModal}
                onSearchByLabel={(label) => {
                    handlePhotoSearch(label)
                }}
            />

        </>
    )
}

export default InputPhoto
