// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".schedule-card-container{background-color:#595959;opacity:0.7;border-bottom:8px solid #64b42d;font-family:'Open Sans', sans-serif;font-weight:normal;font-style:normal;font-size:1em;color:#fff;line-height:2em;flex-direction:column;padding:10px;min-width:3.75em;min-height:8.4375em;cursor:pointer}.schedule-card-time{font-family:'Open Sans', sans-serif;font-weight:normal;font-style:normal;font-size:.875em;color:#fff}.schedule-center,.schedule-card-container,.schedule-card-time{display:flex;justify-content:center;align-items:center}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
