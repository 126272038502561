// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".multi-schedule-card{width:100%}.calendar-container{width:100%;height:fit-content;min-height:6.5625em;background-color:#fff}.calendar-day-container,.calendar-row{display:grid;grid-template-columns:repeat(7, 1fr);row-gap:.625em;column-gap:.625em;margin:0 .3125em;overflow:auto;margin-top:.3125em;padding:0 .3125em}.calendar-day-header{display:flex;flex:1}.calendar-day-empty{display:flex;justify-content:center;align-items:center;min-height:6.5625em}.calendar-card-empty{height:100%;width:100%;min-width:3.75em}.calendar-row{min-height:2.8125em}.calendar-col,.calendar-container{display:flex;flex-direction:column}.calendar-center,.calendar-day-header{justify-content:center;align-items:center}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
