import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './SocialMediaItem.scss'

/**
 * @typedef SocialMediaItemProps
 * @memberOf SocialMediaItem
 * @property {Array<string>} icon
 * @property {string} link
 * @property {string} id
 * @property {string} color
 */

/**
 * Social menu item.
 * Shows the icon with a link
 * @class SocialMediaItem
 * @category Components
 * @subcategory theme / commons
 * @param {SocialMediaItemProps} props
 * @returns {React.ReactNode}
 * @example
 * <SocialMediaItem
 *     icon={['fab']}
 *     link="/"
 *     id="212as1..."
 *     color="#FFFFFF"
 * />
 */
const SocialMediaItem = ({ icon, link, id, color }) => {
    return (
        <a
            href={link}
            data-testid={`social-media-${id}`}
            className="social-media-item"
        >
            <FontAwesomeIcon className="social-icon" icon={icon} color={color} />
        </a>
    )
}

export default SocialMediaItem
