import { PAYMENT_METHODS } from './constants/constants'

export const VISA_MASTER_ENTITY_ID = process.env.REACT_APP_CREDIT_CARD_ENTITY_ID
export const SUCCESS_PAYMENT_CODE = '000.100.110'

const getVisaMasterData = (paymentData, price, concept, selectedType) => {
    let values
    paymentData.forEach(data => {
        values = {
            ...values,
            [data.id]: data.value
        }
    })
    const cardType = getCardType(values.cardNumber[0])
    return {
        amount: price,
        concept: concept,
        depositMethods: {
            currency: 'EUR',
            type: selectedType,
            entityId: VISA_MASTER_ENTITY_ID,
            paymentType: cardType.paymentType,
            paymentBrand: cardType.paymentBrand,
            card: {
                number: values.cardNumber,
                holder: values.cardName,
                expiryMonth: values.expiryDate.split(' / ')[0],
                expiryYear: values.expiryDate.split(' / ')[1],
                cvv: values.securityCode
            }
        }
    }
}
const getCheckoutData = (price, concept, paramCheckoutId) => {
    return {
        amount: price,
        concept: concept,
        depositMethods: {
            currency: 'EUR',
            type: PAYMENT_METHODS.CHECKOUT,
            entityId: VISA_MASTER_ENTITY_ID,
            paymentType: 'DB',
            checkoutId: paramCheckoutId
        }
    }
}

const getPaypalCheckoutData = (price, concept, checkoutId) => {
    return {
        amount: price,
        concept: concept,
        depositMethods: {
            currency: 'EUR',
            type: PAYMENT_METHODS.PAYPAL,
            checkoutId: checkoutId
        }
    }
}

export const adaptPaymentData = (paymentData, price, concept, selectedType, paramCheckoutId) => {
    switch (selectedType) {
    case PAYMENT_METHODS.CHECKOUT: return getCheckoutData(price, concept, paramCheckoutId)
    case PAYMENT_METHODS.PAYPAL: return getPaypalCheckoutData(price, concept, paramCheckoutId)
    default: return getVisaMasterData(paymentData, price, concept, selectedType)
    }
}

const getCardType = (firstNumber) => {
    if (firstNumber === '4') {
        return { paymentBrand: 'VISA', paymentType: 'DB' }
    } else {
        return { paymentBrand: 'MASTER', paymentType: 'PA' }
    }
}
