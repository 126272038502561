import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const InfoInput = (props) => {
    const { tooltip } = props
    const testId = props['data-testid']
    return (
        <>
            {tooltip && (
                <div className="cms-info-input" data-testid={`${testId}-info-input`}>
                    <OverlayTrigger
                        placement={'right'}
                        overlay={
                            <Tooltip id={`${testId}-tooltip`} data-testid={`${testId}-tooltip`}>
                                {tooltip}
                            </Tooltip>
                        }
                    >
                        <FontAwesomeIcon
                            data-testid={`${testId}-icon`}
                            icon={['fa', 'exclamation-circle']}
                        />
                    </OverlayTrigger>
                </div>
            )}
        </>
    )
}

export default InfoInput
