import { language } from '../../../../../theme/commons/language/language'
import { INPUT_TYPES } from '../../../../../cms/commons/cms_input/components/input_component/InputComponent'
import { VALIDATION_TYPES } from '../../../../../cms/commons/cms_input/components/with_validation'
import React from 'react'
import InfoInput from '../../../../../cms/commons/cms_input/components/info_input/InfoInput'

export const IDS = {
    DATE: 'date',
    TYPES: 'types',
    FAMILY_NAME: 'familyName',
    FAMILY_MEMBERS: 'familyMembers',
    GENDER: 'gender',
    FIRSTNAME: 'firstName',
    BIRTHDAY: 'birthDay',
    STREET_ADDRESS: 'streetAddress',
    POSTCODE: 'postCode',
    CITY: 'city',
    COUNTRY_CODE: 'countryCode',
    PHONE: 'phone',
    EMAIL: 'email',
    IBAN: 'iban',
    BIC: 'bic',
    BANK: 'bank',
    ACC_HOLDER: 'accountHolder'
}

const formValue = (form, id) => form ? form[id]?.value : undefined

export const formFieldById = (id, form) => inputFieldsList(form).filter(it => it.id === id)[0]
export const formPropsById = (id, form) => {
    const { type, label, validationTypes } = formFieldById(id, form)
    return { type, label, validationTypes }
}

export const inputFieldsList = form => [
    {
        id: IDS.DATE,
        type: INPUT_TYPES.DATE,
        value: formValue(form, IDS.DATE),
        label: language.membership.date,
        hasError: false
    },
    {
        id: IDS.TYPES,
        type: INPUT_TYPES.CHECK_BOX,
        value: formValue(form, IDS.TYPES),
        label: language.membership.familyName,
        hasError: false
    },
    {
        id: IDS.FAMILY_MEMBERS,
        value: formValue(form, IDS.FAMILY_MEMBERS)
    },
    {
        id: IDS.FAMILY_NAME,
        type: INPUT_TYPES.TEXT,
        value: formValue(form, IDS.FAMILY_NAME),
        label: language.membership.familyName,
        validationTypes: [VALIDATION_TYPES.TEXT_CHARS_2_30]
    },
    {
        id: IDS.GENDER,
        type: INPUT_TYPES.GENDER,
        value: formValue(form),
        label: language.membership.gender
    },
    {
        id: IDS.FIRSTNAME,
        type: INPUT_TYPES.TEXT,
        value: formValue(form, IDS.FIRSTNAME),
        label: language.membership.firstName,
        validationTypes: [VALIDATION_TYPES.TEXT_CHARS_2_30]
    },
    {
        id: IDS.BIRTHDAY,
        type: INPUT_TYPES.DATE,
        value: formValue(form, IDS.BIRTHDAY),
        label: language.membership.birthDay
    },
    {
        id: IDS.STREET_ADDRESS,
        type: INPUT_TYPES.TEXT,
        value: formValue(form, IDS.STREET_ADDRESS),
        label: language.membership.streetAddress,
        validationTypes: [VALIDATION_TYPES.NOT_EMPTY]
    },
    {
        id: IDS.POSTCODE,
        type: INPUT_TYPES.TEXT,
        value: formValue(form, IDS.POSTCODE),
        label: language.membership.postCode,
        validationTypes: [VALIDATION_TYPES.POSTCODE]

    },
    {
        id: IDS.CITY,
        type: INPUT_TYPES.TEXT,
        value: formValue(form, IDS.CITY),
        label: language.membership.city,
        validationTypes: [VALIDATION_TYPES.TEXT_CHARS_2_30]
    },
    {
        id: IDS.PHONE,
        type: INPUT_TYPES.PHONE,
        value: formValue(form, IDS.PHONE),
        label: language.membership.phone,
        validationTypes: [VALIDATION_TYPES.NOT_EMPTY, VALIDATION_TYPES.PHONE]
    },
    {
        id: IDS.EMAIL,
        type: INPUT_TYPES.EMAIL,
        value: formValue(form, IDS.EMAIL),
        label: language.membership.email,
        validationTypes: [VALIDATION_TYPES.NOT_EMPTY, VALIDATION_TYPES.EMAIL]
    },
    {
        id: IDS.IBAN,
        type: INPUT_TYPES.IBAN,
        value: formValue(form, IDS.IBAN),
        label: language.membership.iban
    },
    {
        id: IDS.BIC,
        type: INPUT_TYPES.TEXT,
        value: formValue(form, IDS.BIC),
        label:
            <div className="d-flex">
                {language.membership.bic}
                <span className="pl-2">
                    <InfoInput tooltip={language.membership.bicTooltip} />
                </span>
            </div>,
        validationTypes: [VALIDATION_TYPES.NOT_EMPTY, VALIDATION_TYPES.BIC]
    },
    {
        id: IDS.BANK,
        type: INPUT_TYPES.TEXT,
        value: formValue(IDS.BANK),
        label: language.membership.bank,
        validationTypes: [VALIDATION_TYPES.TEXT_CHARS_2_30]
    },
    {
        id: IDS.ACC_HOLDER,
        type: INPUT_TYPES.TEXT,
        value: formValue(form, IDS.ACC_HOLDER),
        label: language.membership.accountHolder,
        validationTypes: [VALIDATION_TYPES.TEXT_CHARS_2_30]
    }

]

export const firstNameField = (form) => formFieldById(IDS.FIRSTNAME, form)

export const birthdayField = (form) => formFieldById(IDS.BIRTHDAY, form)
