import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './addRemoveFamilyMemberIcon.scss'
export const DELETE_ICON = 'minus'
export const ADD_ICON = 'plus'

const AddRemoveFamilyMemberIcon = ({ isDelete = false, onClick }) => {
    const icon = isDelete ? DELETE_ICON : ADD_ICON
    return (
        <div className={'add-family-member'} onClick={onClick}>
            <div className={'add-family-member-icon'}>
                <FontAwesomeIcon
                    className={'add-family-member-icon-fontawesome'}
                    icon={icon}/>
            </div>
        </div>
    )
}

export const AddMemberIcon = ({ onClick }) => <AddRemoveFamilyMemberIcon isDelete={false} onClick={onClick} />
export const RemoveMemberIcon = ({ onClick }) => <AddRemoveFamilyMemberIcon isDelete={true} onClick={onClick} />

export default AddRemoveFamilyMemberIcon
