import { IDS } from './inputFieldsList'

const undefinedIfNull = (field) => {
    if (field === null) {
        return undefined
    }
    return field
}

export default function adaptUserDataToForm (userData) {
    const form = {
        [IDS.DATE]: undefinedIfNull(userData?.memberSince ? userData?.memberSince?.split(' ')[0] : userData?.memberSince),
        [IDS.FAMILY_NAME]: undefinedIfNull(userData?.lastName),
        [IDS.FIRSTNAME]: undefinedIfNull(userData?.firstName),
        [IDS.BIRTHDAY]: undefinedIfNull(userData?.birthDate ? userData?.birthDate?.split(' ')[0] : userData?.birthDate),
        [IDS.GENDER]: undefinedIfNull(userData?.gender),
        [IDS.STREET_ADDRESS]: undefinedIfNull(userData?.address?.streetNumber),
        [IDS.POSTCODE]: undefinedIfNull(userData?.address?.postCode),
        [IDS.CITY]: undefinedIfNull(userData?.address?.city),
        [IDS.COUNTRY_CODE]: undefinedIfNull(userData?.countryCode),
        [IDS.PHONE]: undefinedIfNull(userData?.contactNumber),
        [IDS.EMAIL]: undefinedIfNull(userData?.email)
    }
    return form
}
