export const PAYMENT_METHODS = {
    WALLET: 'WALLET',
    VISA_MASTER: 'VISA_MASTER',
    CHECKOUT: 'CHECKOUT',
    PAYPAL: 'PAYPAL',
    KLARNA: 'KLARNA',
    GIROPAY: 'GIROPAY',
    SEPA: 'SEPA'
}

export const PAYMENT_FIELD_POSITIONS = {
    [PAYMENT_METHODS.VISA_MASTER]: { CARD_NUMBER: 0, CARD_NAME: 1, EXPIRY_DATE: 2, SECURITY_CODE: 3 }
}

export const PAYMENT_DATA_PROPERTIES =
    {
        [PAYMENT_METHODS.VISA_MASTER]:
        [
            {
                field: {
                    name: 'cardNumberPlaceholder',
                    type: 'CARD_NUMBER',
                    id: 'card-number-field-id',
                    isMandatory: true
                },
                fieldId: 'card-number-field-id',
                id: 'cardNumber',
                value: ''
            },
            {
                field: {
                    name: 'cardNamePlaceholder',
                    type: 'CARD_NAME',
                    id: 'card-name-field-id',
                    isMandatory: true
                },
                fieldId: 'card-name-field-id',
                id: 'cardName',
                value: ''
            },
            {
                field: {
                    name: 'expiryDatePlaceholder',
                    type: 'EXPIRY_DATE',
                    id: 'expiry-date-field-id',
                    isMandatory: true
                },
                fieldId: 'expiry-date-field-id',
                id: 'expiryDate',
                value: ''
            },
            {
                field: {
                    name: 'securityCodePlaceholder',
                    type: 'SECURITY_CODE',
                    id: 'security-code-field-id',
                    isMandatory: true
                },
                fieldId: 'security-code-field-id',
                id: 'securityCode',
                value: ''
            }

        ]
    }
