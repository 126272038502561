// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".common-banner{position:relative;width:100%;height:50.625em}.common-banner .common-banner-image-container{position:relative;height:100%}.common-banner .common-banner-image-container .common-gradient-container{position:absolute;top:0;left:0;right:0;bottom:0;background-size:cover;overflow:hidden}.common-banner .common-banner-image-container .common-gradient-container .gradient-overlay{position:absolute;top:0;height:100vh;width:200vw;background:linear-gradient(rgba(0,0,0,0.005), 65%, rgba(0,0,0,0.75))}.common-banner .common-banner-image-container .common-banner-image{height:100%;width:100%;object-fit:cover}.common-banner .common-banner-text-container{position:absolute;left:0;bottom:0;margin:0 3.75em 5.625em;max-width:43.125em}.common-banner .common-banner-text-container h1{font-family:rucksack, sans-serif;font-weight:900;font-style:normal;font-size:4.125em;color:#fff;text-transform:uppercase}.common-banner .common-banner-text-container h1 .title{display:inline-block;padding:0}.common-banner .common-banner-text-container h1 .dot{color:#68c329}.common-banner .common-banner-text-container h2{font-family:'Open Sans', sans-serif;font-weight:700;font-style:normal;text-transform:uppercase;font-size:1em;color:#fff;font-size:1.625em;padding-top:.9375em}.common-banner .common-banner-text-container h3{font-family:'Open Sans', sans-serif;font-weight:700;font-style:normal;line-height:1.625em;font-size:1.375em;color:#fff;display:inline-block}@media (min-width: 576px) and (max-width: 768px){.common-banner .common-banner-text-container h1{font-size:4.71429em}}@media (min-width: 768px){.common-banner .common-banner-text-container h1{font-size:4.71429em}}@media (min-width: 1200px){.common-banner .common-banner-text-container{width:45em}}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
