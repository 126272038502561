import React, { useEffect, useState } from 'react'
import './AdministrationBar.scss'
import AdminButton from '../commons/admin_button/AdminButton'
import Modal from 'react-bootstrap/Modal'
import AddCmsContentModal from '../cms_content/CmsContentContainer'
import AddCmsContentModalTwo from '../cms_content/CmsContentTwoContainer'
import DeleteAlertModal from '../../theme/commons/DeleteAlertModal/DeleteAlertModal'
import Dropdown from '../../theme/commons/dropdown/Dropdown'
import { cmsLanguage } from '../commons/cms_language/cmsLanguage'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { CONTENT_FOOTER, CONTENT_SOCIAL_MEDIA } from '../types'
import { ADMIN_URL } from '../../backend/mesh/Constants'
import onEventContentCreateOrUpdate from './onEventContentCreateOrUpdate'

/**
 * @typedef AdministrationBarProps
 * @memberOf AdministrationBar
 * @property {User} user,
 * @property {boolean} isEditModeOn,
 * @property {boolean} isAddNewTemplateModeOn,
 * @property {function} toggleEditMode,
 * @property {function} toggleAddNewTemplateMode,
 * @property {function} persistCurrentContent,
 * @property {function} restoreDefaultValues,
 * @property {function} deleteContent,
 * @property {boolean} isTrainingPage,
 * @property {boolean} isEditTrainingPageModeOn,
 * @property {boolean} isHomePage,
 * @property {object} content,
 * @property {object} contentDraft,
 * @property {Array<Object>} products
 * @property {function} addProduct
 * @property {function} updateProduct
 * @property {function} postScheduleList
 * @property {function} setContent
 * @property {function} toggleEditTrainingPageMode
 */

/**
 * AdministrationBar component.
 * Shows the administration top bar.
 * @class AdministrationBar
 * @category Components
 * @subcategory CMS / AdministrationBar
 * @param {AdministrationBarProps} props
 * @returns {React.ReactNode} The administration bar.
 * @example
 * <AdministrationBar />
 */
const AdministrationBar = (props) => {
    const {
        user,
        isEditModeOn,
        isAddNewTemplateModeOn,
        toggleEditMode,
        toggleAddNewTemplateMode,
        persistCurrentContent,
        restoreDefaultValues,
        deleteContent,
        isTrainingPage,
        isEditTrainingPageModeOn,
        isHomePage,
        content,
        contentDraft
    } = props

    const [showModal, setShowModal] = useState(false)
    const [showWarning, setShowWarning] = useState(false)
    const [showModalTwo, setShowModalTwo] = useState(false)
    const [saveClicked, setSaveClicked] = useState(false)
    const meshDirection = ADMIN_URL

    useEffect(() => {
        const persistFn = async () => {
            isEditModeOn && toggleEditMode()
            isEditTrainingPageModeOn && props.toggleEditTrainingPageMode()
            isAddNewTemplateModeOn && toggleAddNewTemplateMode()

            await Promise.all([
                persistCurrentContent(),
                persistCurrentContent(CONTENT_FOOTER),
                persistCurrentContent(CONTENT_SOCIAL_MEDIA)
            ])

            restoreDefaultValues()
            restoreDefaultValues(CONTENT_FOOTER)
            restoreDefaultValues(CONTENT_SOCIAL_MEDIA)
        }
        if (saveClicked && contentDraft.waitForRedux) {
            persistFn()
            setSaveClicked(false)
            contentDraft.waitForRedux = false
            props.setContent(contentDraft, true)
        }
    }, [saveClicked, contentDraft])

    /**
     * @function
     * @memberOf AdministrationBar
     * @returns {Promise<void>}
     */
    const handleSaveButton = async () => {
        const eventsGroupUpdatedTemplates = await onEventContentCreateOrUpdate(content, contentDraft)
        const updatedTemplates = contentDraft.templates.map(currentTemplateDraft => {
            const eventsGroupUpdatedTemplate = eventsGroupUpdatedTemplates.find(it => it.id === currentTemplateDraft.id)
            return eventsGroupUpdatedTemplate || currentTemplateDraft
        })
        contentDraft.templates = updatedTemplates
        contentDraft.waitForRedux = true
        props.setContent(contentDraft, true)
        setSaveClicked(true)
    }

    /**
     * @function
     * @memberOf AdministrationBar
     */
    const handleCancelButton = () => {
        isEditModeOn && toggleEditMode()
        isEditTrainingPageModeOn && props.toggleEditTrainingPageMode()
        isAddNewTemplateModeOn && toggleAddNewTemplateMode()
        restoreDefaultValues()
        restoreDefaultValues(CONTENT_FOOTER)
        restoreDefaultValues(CONTENT_SOCIAL_MEDIA)
    }

    /**
     * @function
     * @memberOf AdministrationBar
     */
    const handleCreateButton = () => {
        setShowModal(true)
    }

    /**
     * @function
     * @memberOf AdministrationBar
     */
    const handleOpenModalTwo = () => {
        setShowModal(false)
        setShowModalTwo(true)
    }

    /**
     * @function
     * @memberOf AdministrationBar
     */
    const handleShowDeleteContentModal = () => {
        setShowWarning(true)
    }

    /**
     * @function
     * @memberOf AdministrationBar
     * @returns {Promise<void>}
     */
    const handleDeleteContent = async () => {
        await deleteContent()
        setShowWarning(false)
    }

    /**
     * @function
     * @memberOf AdministrationBar
     */
    const handleCloseModalTwo = () => {
        setShowModalTwo(false)
    }

    /**
     * @const
     * @memberOf AdministrationBar
     * @type {boolean}
     */
    const isDeleteDisabled = isHomePage || isTrainingPage

    /**
     * @const
     * @memberOf AdministrationBar
     * @type {Object}
     */
    const contentConfigData = {
        // TODO: to be uncommented when implemented
        list: [
            // {
            //   id: "editNavigation",
            //   name: cmsLanguage.cms.editNavigation,
            // },
            // {
            //   id: "editOwnNavigationBar",
            //   name: cmsLanguage.cms.editOwnNavigationBar,
            // },
            // {
            //   line:true
            // },
            {
                id: 'deleteContent',
                name: cmsLanguage.cms.deleteContent,
                icon: faTrashAlt,
                iconPosition: 'right',
                disabled: isDeleteDisabled
            }
        ]
    }

    /**
     * @function
     * @memberOf AdministrationBar
     * @param {string} name
     * @param {string} id
     * @returns {Promise<void>}
     */
    const handleDropdownContentConfig = async (name, id) => {
        switch (id) {
        case 'editNavigation':
            // TODO
            break
        case 'editOwnNavigationBar':
            // TODO
            break
        case 'deleteContent':
            setShowWarning(true)
            break
        default :
            break
        }
    }

    return (
        <>
            {showModal &&
            <AddCmsContentModal
                onClose={() => setShowModal(false)}
                onSave={handleOpenModalTwo}
            />
            }

            {showModalTwo &&
            <AddCmsContentModalTwo
                onClose={handleCloseModalTwo}
            />
            }

            {showWarning &&
            <Modal
                className="modal-common-config"
                size="md"
                show={showWarning}
                onHide={handleShowDeleteContentModal}>
                <DeleteAlertModal
                    onDelete={handleDeleteContent}
                    onClose={() => setShowWarning(false)}
                />
            </Modal>
            }

            {user.role && user.role.permissions && user.role.permissions.includes('WRITE_CONTENT') && (
                <div className="administration-bar horizontal-website-padding">
                    <div className="administration-bar-link">
                        <a href={meshDirection} target="_blank" rel="noreferrer" data-testid="administration-link">{cmsLanguage.cms.administratorArea}</a>
                    </div>

                    <div className="administration-bar-delete-content" hidden={!isEditModeOn}
                        data-testid="administration-bar-delete-content">
                        <Dropdown
                            title={cmsLanguage.cms.contentConfiguration}
                            isRounded={true}
                            width={'auto'}
                            data={contentConfigData.list}
                            headerOnly="true"
                            onSelect={(name, id) => handleDropdownContentConfig(name, id)}
                            printHeader={true}
                            arrowColor={'white'}
                        />
                    </div>

                    <div className="administration-bar-buttons">
                        {!isEditModeOn
                            ? (
                                <>
                                    <div className="administration-bar-buttons-default">
                                        <AdminButton
                                            text={cmsLanguage.cms.createNewContent}
                                            icon={['fa', 'plus']}
                                            onClick={handleCreateButton}
                                            type="default"
                                            data-testid="create-content-button"
                                        />
                                    </div>
                                    <div className="administration-bar-buttons-default">
                                        <AdminButton
                                            text={cmsLanguage.cms.editContent}
                                            icon={['fa', 'edit']}
                                            onClick={toggleEditMode}
                                            type="default"
                                            data-testid="edit-content-button"
                                        />
                                    </div>
                                </>
                            )
                            : (
                                <div className="administration-bar-buttons-content-editable"
                                    data-testid="administration-bar-buttons-content-editable">
                                    <div className="administration-bar-buttons-add">
                                        <AdminButton
                                            data-testid="show-add-templates"
                                            text={`${
                                                isAddNewTemplateModeOn
                                                    ? cmsLanguage.cms.hideAddTemplates
                                                    : cmsLanguage.cms.showAddTemplates
                                            }`}
                                            icon={isAddNewTemplateModeOn ? ['fa', 'eye'] : ['fa', 'eye-slash']}
                                            onClick={toggleAddNewTemplateMode}
                                            type={isTrainingPage ? 'disable-dark' : 'border'}
                                            color="blue"
                                        />
                                    </div>
                                    <div className="administration-bar-buttons-save-cancel">
                                        <AdminButton
                                            data-testid="save-content-changes"
                                            text={cmsLanguage.cms.saveChanges}
                                            icon={['fa', 'check']}
                                            onClick={handleSaveButton}
                                            type="border"
                                            color="green"
                                        />
                                        <AdminButton
                                            data-testid="cancel-content-changes"
                                            text={cmsLanguage.cms.removeChanges}
                                            icon={['fa', 'times']}
                                            onClick={handleCancelButton}
                                            type="border"
                                            color="red"
                                        />
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            )}
        </>
    )
}

export default AdministrationBar
