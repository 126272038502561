// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "#sport-pictures-banner .carousel-control-prev,#sport-pictures-banner .carousel-control-next,#sport-pictures-banner .carousel-indicators{display:none}.sport-pictures-container{display:flex}.sport-picture-item-desktop{height:20vw;width:20vw}.sport-picture-item-tablet{height:33.33vw;width:33.33vw}.sport-picture-item-mobile{height:100vw;width:100vw}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
