// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".home-news-container .title{margin-bottom:2.8125em}.home-news-container .title span{font-family:rucksack, sans-serif;font-weight:900;font-style:normal;font-size:2.8125em;color:#191919;text-transform:uppercase}.home-news-container .home-news-actions,.home-news-container .news-card-grid{margin-left:-30px;margin-right:-30px}.home-news-container .home-news-actions{margin-bottom:3.75em}.home-news-container .home-news-button{display:flex;justify-content:flex-end;margin-top:20px}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
