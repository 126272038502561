// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".sponsors-container{background:#64b42d;display:flex;align-items:center;justify-content:space-between;padding:2.8125em 0}.sponsors-container span{padding:0 .9375em;display:inline}.sponsors-container .title{font-family:rucksack, sans-serif;font-weight:900;font-style:normal;font-size:2.8125em;color:#fff;text-transform:uppercase;line-height:1}.sponsors-container .sliding-logos{display:flex;overflow:hidden;width:100%}.sponsors-container .sliding-logos .logos-container,.sponsors-container .sliding-logos .logos-container-two{display:flex;animation:slideshow 24s linear infinite}.sponsors-container .sliding-logos .logos-container img,.sponsors-container .sliding-logos .logos-container-two img{height:7.5em;margin:0 2.8125em}.sponsors-container .sliding-logos .logos-container a:hover,.sponsors-container .sliding-logos .logos-container-two a:hover{background:rgba(255,255,255,0.3)}.sponsors-container .sliding-logos .logos-container-two{transform:translateX(100%)}@keyframes slideshow{0%{transform:translateX(0%)}100%{transform:translateX(-100%)}}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
