import React from 'react'
import { cmsLanguage } from '../../../cms_language/cmsLanguage'
import '../input_label/InputLabel.scss'

const InputDate = (props) => {
    const { value, onChange, onFocus, onBlur, readOnly } = props

    let newValue

    const handleOnChange = (e) => {
        newValue = e.target.value
        onChange(newValue)
    }

    return (
        <div className="cms-input-box cms-input-box-icon cms-input-date">
            <input
                data-testid="cms-input-date"
                id={'date-input'}
                type="date"
                value={value}
                onChange={handleOnChange}
                onFocus={onFocus}
                onBlur={onBlur}
                placeholder={cmsLanguage.cms.datePlaceholder + '...'}
                readOnly={readOnly}
                className={readOnly ? 'read-only' : ''}
            />
        </div>
    )
}

export default InputDate
