import React from 'react'
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'
import MeshWallets from '../../../../../../../backend/mesh/wallets_service/MeshWallets'
import { adaptPaymentData } from '../../payment-utils'
import { PAYMENT_METHODS } from '../../constants/constants'

const clientId = process.env.REACT_APP_PAYPAL_CLIENT_ID
// Personal test account
// sb-be43ew26504278@personal.example.com
// jS5*HJGA
const initialOptions = {
    clientId: clientId,
    currency: 'EUR',
    intent: 'capture'
}
const PayPal = ({ price, concept, onCompleted }) => {
    const createOrder = async () => {
        const checkoutResponse = await MeshWallets.checkout(adaptPaymentData(null, price, concept, PAYMENT_METHODS.PAYPAL))
        return checkoutResponse.originalResponse.id
    }
    const onApprove = async (data) => {
        onCompleted({
            id: data.orderID,
            paymentMethod: PAYMENT_METHODS.PAYPAL
        })
    }

    if (!clientId || clientId === '') return <></>

    return (
        <PayPalScriptProvider options={initialOptions}>
            <PayPalButtons
                createOrder={createOrder}
                onApprove={onApprove}
                style={{ layout: 'horizontal' }}
            />
        </PayPalScriptProvider>
    )
}

export default PayPal
