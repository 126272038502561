import React from 'react'
import './Counter.scss'

/**
 * @typedef CounterProps
 * @memberOf Counter
 * @property {number} current
 * @property {number} total
 */

/**
 * Text with the current and total counter information
 * @class Counter
 * @category Components
 * @subcategory theme / commons
 * @param {CounterProps} props
 * @returns {React.ReactNode}
 * @example
 * <Counter current={3} total={5} />
 */
const Counter = ({ current, total }) => {
    return (
        <span className={'max-count-indicator'}>{`${
            current
        } - ${total}`}</span>
    )
}

export default Counter
