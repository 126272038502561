import React, { useContext } from 'react'
import './AccordionItem.scss'
import { Accordion, AccordionContext, Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HTMLReactParser from 'html-react-parser'

/**
 * @typedef AccordionItemProps
 * @memberOf AccordionItem
 * @property {number} id
 * @property {string} title
 * @property {string} text
 */

/**
 * Accordion Item template, to be rendered on the {@link AccordionTemplate}.
 * @class AccordionItem
 * @category Components
 * @subcategory Templates / Accordion
 * @param {AccordionItemProps} props
 * @returns {React.ReactNode}
 * @example
 * <AccordionItem
 *     id={index + 1}
 *     title={item.TEXT}
 *     text={item.TEXT_EDITOR}
 * />
 */
const AccordionItem = (props) => {
    const { id, title, text } = props

    const currentEventKey = useContext(AccordionContext)

    return (
        <div className="accordion-item-container">
            <Card className="accordion-item-card">
                <Card.Header className="accordion-item-header">
                    <Accordion.Toggle className="accordion-item-button" eventKey={id}>
                        <div className="accordion-item-left-icon">
                            <FontAwesomeIcon icon={['far', 'question-circle']} />
                        </div>
                        <div className="accordion-item-title">
                            <span data-testid="accordion-item-title">{title}</span>
                        </div>
                        <div
                            className={`accordion-item-right-icon ${
                                currentEventKey === id ? 'active' : ''
                            }`}
                        />
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse className="accordion-item-collapse" eventKey={id}>
                    <Card.Body className="accordion-item-body">
                        <div data-testid="accordion-item-text">{typeof text === 'string' ? HTMLReactParser(text) : text}</div>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </div>
    )
}

export default AccordionItem
