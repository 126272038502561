import React, { useRef, Fragment } from 'react'
import { cmsLanguage } from '../../../cms_language/cmsLanguage'
import './InputExpiryDate.scss'

const InputExpiryDate = (props) => {
    const { value, onChange, onFocus, onError } = props

    const inputRefs = useRef([])
    let expiryMonth, expiryYear, expiryDate

    const isDateValid = (date) => new Date(date) > new Date()

    const handleOnChange = () => {
        expiryMonth = inputRefs.current[0].value
        expiryYear = inputRefs.current[1].value

        expiryDate = `${expiryYear}/${expiryMonth}`
        const hasError = !isDateValid(expiryDate)

        showError(hasError, expiryDate)
        onChange(`${expiryMonth} / ${expiryYear}`, hasError)
    }

    const showError = (hasError, value) => {
        if (hasError && value.length) {
            onError(
                cmsLanguage.error.notValidExpiryDate + '.'
            )
        } else {
            onError()
        }
    }

    const inputArray = [
        {
            type: 'month',
            placeholder: cmsLanguage.inputFields.expiryMonthPlaceholder,
            expiryName: 'expiryMonth',
            minlength: 2,
            maxLength: 2
        },
        {
            type: 'year',
            placeholder: cmsLanguage.inputFields.expiryYearPlaceholder,
            expiryName: 'expiryYear',
            minlength: 4,
            maxLength: 4
        }]

    return (
        <div className="cms-input-box cms-input-box-text cms-input-date">
            {inputArray.map((input, index) => {
                let reformattedValue = value
                if (input.type === 'month' && value !== '') {
                    reformattedValue = value.split(' / ')[0]
                } else if (input.type === 'year' && value !== '') {
                    reformattedValue = value.split(' / ')[1]
                }
                return (
                    <Fragment key={index}>
                        <input
                            data-testid={`cms-input-${input.type}`}
                            className={`cms-input-${input.type}`}
                            id={`expiry-${input.type}-input`}
                            name={`expiry${input.type[0].toUpperCase() + input.type.slice(1)}`}
                            type="tel"
                            ref={(el) => (inputRefs.current[index] = el)}
                            value={reformattedValue}
                            minLength={input.minlength}
                            maxLength={input.maxLength}
                            onChange={handleOnChange}
                            onFocus={onFocus}
                            placeholder={input.placeholder}
                        />
                        {input.type === 'month' &&
                            <span className='expiry-date-slash' data-testid='expiry-date-slash'>/</span>}
                    </Fragment>
                )
            })}
        </div>
    )
}

export default InputExpiryDate
