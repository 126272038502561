// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".modal-title{height:3.75em;margin-bottom:2.8125em;display:flex;justify-content:space-between;align-items:center;border-bottom:solid .3125em #64b42d}.modal-title>button{width:inherit;padding:0;border:none;border-radius:inherit;text-decoration:none;text-align:inherit;background-color:transparent;cursor:pointer;color:inherit;font:inherit;display:flex;align-items:center;font-size:1.875em;color:#191919}.modal-title>button:focus{outline:none}.modal-title>button:hover{color:rgba(25,25,25,0.5)}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
