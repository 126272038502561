import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'
import { CATEGORIES } from '../../backend/mesh/Constants'
import { intersectionBy } from 'lodash'

/**
 * @module utils
 * @category Utils
 * @subcategory Templates / MembersArea
 */

/**
 * Receives an object user data and returns it formatted.
 * @function
 * @inner
 * @static
 * @param {Object} response
 * @returns {Object}
 */
export const formattedUserData = (response) => {
    const {
        firstName,
        lastName,
        email,
        displayName,
        gender,
        address,
        birthDate,
        memberSince,
        countryCode,
        contactNumber,
        id,
        departmentId,
        department,
        description,
        roleId,
        eventsGroups,
        paymentData
    } = response

    return {
        firstName: capitalizeFirstLetter(firstName),
        lastName: capitalizeFirstLetter(lastName),
        displayName,
        birthDate: birthDate,
        gender: gender || undefined,
        email,
        contactNumber: getFullPhoneNumber(countryCode, contactNumber),
        streetNumber: address?.streetNumber,
        postCode: address?.postCode,
        city: capitalizeFirstLetter(address?.city),
        sports: 'Football, Basketball',
        id,
        profileImage: '',
        countryCode,
        departmentId,
        department,
        description,
        roleId,
        eventsGroups,
        memberSince: memberSince,
        address,
        paymentData
    }
}

export const initialFullUserData = {
    firstName: '',
    lastName: '',
    displayName: '',
    birthDate: '',
    gender: '',
    email: '',
    contactNumber: '',
    streetNumber: '',
    postCode: '',
    city: '',
    sports: '',
    id: '',
    profileImage: '',
    countryCode: '',
    departmentId: '',
    department: '',
    description: '',
    roleId: '',
    eventsGroups: [],
    memberSince: '',
    address: {},
    paymentData: {}
}

/**
 * Receives a date and returns it formatted dd-MM-yyyy
 * @function
 * @inner
 * @static
 * @param {string} date
 * @returns {string} - `${dd}-${MM}-${yyyy}`
 * @example
 * const formattedData = ddMMyyyy(userDataDB.memberSince)
 */
export const ddMMyyyy = (date) => {
    try {
        if (date) {
            const parsedDate = new Date(date)
            const dd = parsedDate.getDate().toString().padStart(2, '0')
            const MM = (parsedDate.getMonth() + 1).toString().padStart(2, '0')
            const yyyy = parsedDate.getFullYear()
            return `${dd}-${MM}-${yyyy}`
        }
    } catch {
        return ''
    }

    return ''
}

/**
 * @function
 * @inner
 * @static
 * @param {Array<Object>} courses
 * @returns {Array<Object>}
 */
export const findMySports = (courses) => {
    const myCourses = findByCategories(CATEGORIES.myCourses, courses)
    const sports = findByCategories(CATEGORIES.sports, courses)
    return intersectionBy(myCourses, sports, 'id')
}

/**
 * @function
 * @inner
 * @static
 * @param {string} category
 * @param {Array<Object>} courses
 * @returns {*}
 */
const findByCategories = (category, courses) => {
    return courses?.find(course =>
        course.name === category)?.eventsGroups
}

/**
 * Receives the `countryCode` and the `phoneNumber`, and returns a new string with this two values concatenated.
 * @function
 * @inner
 * @static
 * @param {string} countryCode
 * @param {string} phoneNumber
 * @returns {*}
 */
const getFullPhoneNumber = (countryCode, phoneNumber) => {
    return (phoneNumber && phoneNumber[0] === '+') ? phoneNumber : countryCode + phoneNumber
}
