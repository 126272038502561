import React, { useState } from 'react'
import { cmsLanguage } from '../../../../commons/cms_language/cmsLanguage'
import DropdownWithTitle from '../../dropdown_with_title/DropdownWithTitle'
import { addNewGroupMenuItem, submenu } from '../../../menusList'
import InputWithCount from '../../../../../theme/commons/InputWithCount/InputWithCount'

/**
 * @typedef PageMenuSelectionProps
 * @memberOf PageMenuSelection
 * @property {Array<Object>} pageMenuOptions
 * @property {function} onPageMenuChange
 */

/**
 * @class PageMenuSelection
 * @category Components
 * @subcategory Templates / CmsContent
 * @param {PageMenuSelectionProps} props
 * @returns {React.ReactNode}
 * @example
 * <PageMenuSelection onClose={() => setShowModal(false)} onSave={handleOpenModalTwo} />
 */
const PageMenuSelection = ({ pageMenuOptions, onPageMenuChange }) => {
    const [pageMenuSelection, setPageMenuSelection] = useState()

    /**
     * @function
     * @memberOf PageMenuSelection
     * @param {string} name
     * @param {string} id
     * @param {string} contentId
     */
    const handleOnPageMenuChange = (name, id, contentId) => {
        const selectedPageMenu = pageMenuOptions.find(it => it.id === id)
        const selectedPage = { name, id, contentId, path: selectedPageMenu.path }
        setPageMenuSelection(selectedPage)
        onPageMenuChange(selectedPage)
    }

    return (
        <div className="cms-input" data-testid="page-menu-section">
            <h4>{cmsLanguage.menuNames.pageMenu}</h4>
            <div className="cms-section">

                {/* --- Selecting Page Heading --- */}
                <DropdownWithTitle
                    headline={cmsLanguage.inputFields.assignedPageSection}
                    title={cmsLanguage.cms.chooseASubTitle}
                    data={pageMenuOptions}
                    onSelect={handleOnPageMenuChange}
                    value={pageMenuSelection === undefined ? '' : pageMenuSelection.id === addNewGroupMenuItem.id ? addNewGroupMenuItem.name : pageMenuSelection.name}
                    menuPicture={submenu.picture}
                    data-testid="page-heading-dropdown"
                />

                {pageMenuSelection && pageMenuSelection.id === addNewGroupMenuItem.id &&
                <InputWithCount
                    text={pageMenuSelection.name === addNewGroupMenuItem.name ? '' : pageMenuSelection.name}
                    placeholder={cmsLanguage.cms.introduceName}
                    setText={value => handleOnPageMenuChange(value, addNewGroupMenuItem.id)}
                    size='medium'
                    maxAllowedCount={20}
                    data-testid="page-heading-input"
                />}

            </div>
        </div>
    )
}

export default PageMenuSelection
