import React from 'react'
import InputLabel from '../input_label/InputLabel'
import { setDisplayName } from '../../../../../utils/getDisplayName'

const withLabel = (BaseComponent) => {
    const WithLabel = (props) => {
        const { label, ...otherProps } = props
        return <>
            <InputLabel label={label} />
            <BaseComponent {...otherProps} />
        </>
    }
    setDisplayName(BaseComponent, WithLabel, 'withLabel')
    return WithLabel
}

export default withLabel
