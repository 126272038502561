// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".custom-model{font-family:'Open Sans', sans-serif;font-weight:normal;font-style:normal}.custom-model-title{font-family:'Open Sans', sans-serif;font-weight:700;font-style:normal;line-height:1.625em;font-size:1.375em;color:#191919;margin-bottom:.9375em}.custom-model-content>*{margin-bottom:1.875em}.custom-model-field{display:flex;flex-direction:row}.custom-model-field>*{margin-right:.9375em}.custom-model-label{margin-bottom:.625em;font-family:'Open Sans', sans-serif;font-weight:normal;font-style:normal;font-size:1.125em;color:#191919}.custom-model-btnContainer{display:flex;justify-content:center;align-items:center;justify-content:space-evenly}.custom-model-btnContainer>a{min-width:150px}@media screen and (max-width: 768px){.custom-model-field>.dropdown-container-wrapper{min-width:100px}.endsOn-row>.date-picker{width:100%}}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
