import { cmsLanguage } from '../../cms_language/cmsLanguage'

export const DROPDOWN_CONFIG_DATA = {
    currency: [
        {
            id: 'euros',
            name: cmsLanguage.inputFields.euros,
            disabled: false
        }
    ],
    repeat: [
        {
            id: 'monthly',
            name: cmsLanguage.inputFields.monthly,
            disabled: false
        },
        {
            id: 'weekly',
            name: cmsLanguage.inputFields.weekly,
            disabled: false
        },
        {
            id: 'daily',
            name: cmsLanguage.inputFields.daily,
            disabled: false
        }
    ],
    unit: [
        {
            id: 'minutes',
            name: cmsLanguage.inputFields.minutes,
            disabled: false
        }
    ]
}
