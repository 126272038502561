import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'

import * as actions from '@src/redux/actions/actions'
import MembershipSecondStep from './MembershipSecondStep'

/**
 * {@link MembershipSecondStep} container that binds the MembershipSecondStep with the {@link module:reduxSetup}
 * @class MembershipSecondStepContainer
 * @category Containers
 * @subcategory MembersForm
 *
 * @mermaid
 *   sequenceDiagram
 *   MemberForm->>MembershipSecondStepContainer: Request the MembershipSecondStep via MembershipSecondStepContainer.
 *   Note right of MembershipSecondStepContainer: MembershipSecondStepContainer gets information from redux store.
 *   MembershipSecondStepContainer->>MembershipSecondStep: MembershipSecondStepContainer sends redux store information to MembershipSecondStep as props.
 *   MembershipSecondStep-->>MemberForm: MembershipSecondStep is rendered in the parent component.
 */

/**
 * Gets the `state.user` from redux state and makes available to the {@link MembershipSecondStep} as a prop called `user`.
 * @function
 * @memberOf MembershipSecondStepContainer
 * @alias mapStateToProps
 * @param {Object} state - redux state
 * @returns {Object}
 */
const mapStateToProps = (state) => {
    const { user } = state
    return { user: user }
}

/**
 * Passes certain Redux actions ({@link module:actions})
 * to the {@link MembershipSecondStep} component as props.
 * This function is used only by the react-redux connect function.
 * @function
 * @memberOf MembershipSecondStepContainer
 * @alias mapDispatchToProps
 * @param {function} dispatch - The react-redux dispatch function
 * @returns {Object} - Redux actions used in the {@link MembershipSecondStep} component
 */
const mapDispatchToProps = (dispatch) => bindActionCreators({ ...actions }, dispatch)

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    MembershipSecondStep
)
