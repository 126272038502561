// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".modal-backdrop{position:fixed;top:0;left:0;width:100%;height:100%;background-color:rgba(0,0,0,0.5);display:flex;justify-content:center;align-items:center}.modal-content{background-color:white;padding:20px;border-radius:8px;box-shadow:0 5px 15px rgba(0,0,0,0.3);max-width:650px;min-width:450px;width:100%}.terms-block{padding:5px}.button-block{padding:10px}.terms-container{display:flex;align-items:center;justify-content:space-between;margin-bottom:20px}.terms-container p{margin-right:20px}button{padding:10px 20px;background-color:#68c329;color:white;border:none;border-radius:5px;cursor:pointer;margin-top:10px}button:disabled{background-color:grey;cursor:not-allowed}button:hover:not(:disabled){background-color:#68c329}.switch{position:relative;display:inline-block;width:60px;height:34px}.switch input{opacity:0;width:0;height:0}.slider{position:absolute;cursor:pointer;top:0;left:0;right:0;bottom:0;background-color:#ccc;transition:0.4s;border-radius:34px}.slider:before{position:absolute;content:\"\";height:26px;width:26px;left:4px;bottom:4px;background-color:white;transition:0.4s;border-radius:50%}input:checked+.slider{background-color:#68c329}input:checked+.slider:before{transform:translateX(26px)}.slider.round{border-radius:34px}.slider.round:before{border-radius:50%}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
