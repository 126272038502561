import React, { useEffect, useState } from 'react'
import './HomeNews.scss'
import NewsCard from '../../../theme/commons/news_card/NewsCard'
import { Col, Container, Row } from 'react-bootstrap'
import Mesh from '../../../backend/mesh/Mesh'
import { trackPromise } from 'react-promise-tracker'
import Button from '../../../theme/commons/button/Button'
import { cmsLanguage } from '../../../cms/commons/cms_language/cmsLanguage'

/**
 * @typedef HomeNewsProps
 * @memberOf HomeNews
 * @property {Object} newsHomeButton
 */

/**
 * News content to be showed in the Home page.
 * @class HomeNews
 * @category Components
 * @subcategory Home
 * @param {HomeNewsProps} props
 * @returns {React.ReactNode}
 * @example
 * <HomeNews newsHomeButton={newsHomeButton} />
 */
const HomeNews = ({ newsHomeButton }) => {
    const [newsContent, setNewsContent] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [hasMore, setHasMore] = useState(true)

    const defaultPageSize = 4
    const getNewsContent = async (page, size) => {
        try {
            const response = await trackPromise(Mesh.getNewsContent(page, size))
            const news = response.news
            setNewsContent([...newsContent, ...news])
            setHasMore(news.length === size)
        } catch (e) {
            console.debug(`Error loading news: ${e}`)
        }
    }

    useEffect(() => {
        getNewsContent(currentPage, defaultPageSize)
    }, [currentPage])

    return (
        <div className="home-news-container vertical-website-padding horizontal-website-padding">
            <div className="title">
                <span>{newsHomeButton.H1}.</span>
            </div>
            <Container fluid>
                <Row className="news-card-grid">
                    {newsContent?.map((item) => {
                        return (
                            <Col
                                key={item.newsId}
                                xl={3}
                                sm={6}
                                data-testid={`news-card-${item.newsId}`}
                            >
                                <NewsCard item={item}/>
                            </Col>
                        )
                    })}
                </Row>
                <Row>
                    <Col className="home-news-button">
                        {hasMore && (
                            <Button
                                autoWidth={true}
                                onClick={() => setCurrentPage(currentPage + 1)}
                                text={cmsLanguage.news.more}
                            />
                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default HomeNews
