import { v4 as uuidv4 } from 'uuid'
import { cmsLanguage } from '../../../commons/cms_language/cmsLanguage'

const accordion = () => {
    const fields = [
        {
            name: 'title',
            type: 'TEXT',
            id: uuidv4(),
            isMandatory: true
        },
        {
            name: 'text',
            type: 'TEXT_EDITOR',
            id: uuidv4(),
            isMandatory: true
        }
    ]
    const values = fields.map(it => {
        return {
            field: it,
            fieldId: it.id,
            id: uuidv4(),
            value: ''
        }
    })
    return {
        id: uuidv4(),
        name: cmsLanguage.templatesNames.accordion,
        type: 'ACCORDION',
        elements: [
            {
                id: uuidv4(),
                values: values,
                type: 'default'
            }
        ],
        fields: fields
    }
}

export const ACCORDION_TEMPLATE_ORDER = { TEXT: 0, TEXT_EDITOR: 1 }

export default accordion
