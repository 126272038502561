import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DatePicker from '../../../../../DatePicker/DatePicker'
import get from 'lodash/get'
import { types } from '../../../../useReducer/types'
import TimePicker from '../../../../../timePicker/TimePicker'
import DropDown from '../../../../../dropdown/Dropdown'
import { generateIncrementalExp, padZero } from '../../../../../../../utils/dateTimePickerUtils'
import { repeatDropdownData } from '../../../../dataUtils/staticData'
import InputWithCount from '../../../../../InputWithCount/InputWithCount'
import InfoError from '../../../../../../../cms/commons/cms_input/components/info_error/InfoError'
import { cmsLanguage } from '../../../../../../../cms/commons/cms_language/cmsLanguage'

/**
 * @typedef AddEditClassContentProps
 * @memberOf AddEditClassContent
 * @property {ClassData} classData
 * @property {function} onChange
 * @property {Object} error
 * @property {string} globalError
 * @property {boolean} disableRepeat
 */

/**
 * @class AddEditClassContent
 * @category Components
 * @subcategory theme / commons / AddEditClass
 * @param {AddEditClassContentProps} props
 * @returns {React.ReactNode}
 */
export default function AddEditClassContent ({ classData, onChange, error, globalError, disableRepeat }) {
    /**
     * @function
     * @memberOf AddEditClassContent
     * @param {string} name
     * @param {boolean} isMandatory
     * @returns {JSX.Element}
     */
    const labelName = (name, isMandatory) => <label className="add-class-input-name">
        {isMandatory &&
        <div className="add-class-input-icon">
            <FontAwesomeIcon icon={['fa', 'asterisk']}/>
        </div>}
        <span data-testid="add-class-input-name">{name}</span>
    </label>

    return (
        <div className="add-class-modal-content">
            <div>
                {labelName(cmsLanguage.inputFields.date, true)}
                <DatePicker
                    error={get(error, 'date', '')}
                    value={classData.date}
                    onChangeDate={(val) => onChange(types.UPDATE_DATE, val)}
                    data-testid="datePicker-schedule"
                />
            </div>

            <div>
                {labelName(cmsLanguage.inputFields.time, true)}
                <TimePicker
                    error={`${get(error, 'time.hour', '')} ${
                        get(error, 'time.minute', '') && ' && '
                    } ${get(error, 'time.minute', '')}`}
                    onTimeChange={(hour, minute) =>
                        onChange(types.UPDATE_START_TIME, { hour, minute })
                    }
                    value={classData.time}
                    data-testid="timePicker-schedule"
                />
            </div>

            <div>
                {labelName(cmsLanguage.inputFields.durationMin, true)}
                <DropDown
                    error={get(error, 'duration', '')}
                    title={cmsLanguage.inputFields.duration}
                    width="small"
                    data={generateIncrementalExp(15, 120, 15, 'duration')}
                    headerOnly={true}
                    onSelect={(val) => onChange(types.UPDATE_DURATION, val)}
                    providedValue={classData.duration !== '' ? padZero(classData.duration) : '--'}
                    data-testid="duration-schedule"
                />
            </div>
            {!disableRepeat && (
                <div className="display-none">
                    {labelName(cmsLanguage.inputFields.repeat, true)}
                    <DropDown
                        error={get(error, 'repeat', '')}
                        title={cmsLanguage.inputFields.repeat}
                        data-testid="recurrence-schedule"
                        data={repeatDropdownData}
                        headerOnly={true}
                        onSelect={(val, id) =>
                            onChange(types.UPDATE_REPEAT_SCHEDULE, val, id)
                        }
                        providedValue=""
                    />
                </div>
            )}

            <div>
                {labelName(cmsLanguage.inputFields.trainer, true)}
                <InputWithCount
                    data-testid="trainer-schedule-text-area"
                    error={get(error, 'trainer', '')}
                    text={classData.trainer}
                    setText={(val) => onChange(types.UPDATE_TRAINER, val)}
                    maxAllowedCount={40}
                />
            </div>

            <div>
                {labelName(cmsLanguage.inputFields.description, false)}
                <InputWithCount
                    data-testid="description-schedule-text-area"
                    error={get(error, 'description', '')}
                    text={classData.description}
                    setText={(val) => onChange(types.UPDATE_DESCRIPTION, val)}
                    canResize={true}
                    maxAllowedCount={400}
                />
            </div>

            <div>
                {labelName(cmsLanguage.inputFields.location, true)}
                <InputWithCount
                    data-testid="location-schedule-text-area"
                    error={get(error, 'location', '')}
                    text={classData.location}
                    setText={(val) => onChange(types.UPDATE_LOCATION, val)}
                    maxAllowedCount={120}
                />
            </div>

            {globalError && (
                <InfoError infoError={globalError} tooltip={globalError}/>
            )}
        </div>
    )
}
