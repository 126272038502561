import { cmsLanguage } from '../cms/commons/cms_language/cmsLanguage'

/**
 * @module elementsUtils
 * @category Utils
 * @subcategory General
 */

/**
 * Recursive search on an object by the value of a key
 * @function
 * @param {Object} bundle - object to be used as a source for the search
 * @param {string} tag - searched key to get the value
 * @returns {object|string|undefined}
 * @example
 * deepValue({content:{name:'Text'},deepContent:{level:{name:'Other text'}}},'content.name') // 'Text'
 * deepValue({content:{name:'Text'},deepContent:{level:{name:'Other text'}}},'deepContent.level.name') // 'Other text'
 */
const deepValue = (bundle, tag) => tag.split('.').reduce((a, v) => a[v], bundle)

/**
 * Function that given an element and fields it provides a record `type, value` for each element that
 * matches a field according the id, such as `EMAIL: vfl@email.com`
 * @function
 * @param {Object} element - includes the value related to a field id
 * @param {array} fields - fields include an id, either if is mandatory, and the type
 * @param {boolean} withElement to mark if an element is required at the result too
 * @param {string} reducingProperty
 * @param {boolean} normalizeFieldNames
 * @returns {*}
 */
export const reducedElementsPro = (element, fields, withElement = true, reducingProperty = 'type', normalizeFieldNames = false) => {
    const reducedElement = element.values.reduce((acc, value) => {
        const field = fields.find((field) => field.id === value.fieldId)
        let fieldProp = field[reducingProperty]
        if (normalizeFieldNames) {
            fieldProp = fieldProp.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
                return index === 0 ? word.toLowerCase() : word.toUpperCase()
            }).replace(/\s+/g, '')
        }
        acc[fieldProp] = translateValue(value.value)
        return acc
    }, {})
    if (withElement) {
        reducedElement.element = element
    }
    return reducedElement
}

/**
 * Parses a translation key and search this tag within the language pack.
 * If it finds the value, it returns it. If not found, returns the searched tag itself
 * @function
 * @param {string} translationTag - translation tag
 * @returns {string}
 * @example
 * translateValue('{cms.editContent}') // 'Edit content'
 * translateValue('{error.mandatory}') // 'This field is mandatory'
 * translateValue('{any.other.tag}') // '{any.other.tag}'
 */
export const translateValue = (translationTag) => {
    if (typeof translationTag === 'string') {
        const match = translationTag.match(/\$\{(.*)\}/)
        if (match && match.length > 1) {
            const translatedValue = deepValue(cmsLanguage, match[1]) || translationTag
            console.debug(`${translationTag} = ${translatedValue}`)
            return translatedValue
        }
    }
    return translationTag
}
