import React, { useState } from 'react'
import { cmsLanguage } from '../../../../commons/cms_language/cmsLanguage'
import DropdownWithTitle from '../../dropdown_with_title/DropdownWithTitle'
import InputWithCount from '../../../../../theme/commons/InputWithCount/InputWithCount'
import { addNewGroupMenuItem, hasGroups } from '../../../menusList'
import { hideDefaultHeadingGroups } from '../../../../../initializerData'
import { translateValue } from '../../../../../utils/elementsUtils'

/**
 * @typedef MainMenuItemSelectionProps
 * @memberOf MainMenuItemSelection
 * @property {Array<Object>} headingOptions
 * @property {function} onMenuItemSelection
 */

/**
 * @class MainMenuItemSelection
 * @category Components
 * @subcategory Templates / CmsContent
 * @param {MainMenuItemSelectionProps} props
 * @returns {React.ReactNode}
 * @example
 * <MainMenuItemSelection
 *     headingOptions={headerMenu.children.filter(
 *         (menu) => menu.contentId == null
 *     )}
 *     onMenuItemSelection={handleMenuItemSelection}
 * />
 */
const MainMenuItemSelection = ({
    headingOptions,
    onMenuItemSelection
}) => {
    const [menuItemSelection, setMenuItemSelection] = useState()
    const [groupOptions, setGroupOptions] = useState()
    const [pageOptions, setPageOptions] = useState()

    /**
     * @function
     * @memberOf MainMenuItemSelection
     * @param {string} name
     * @param {string} id
     */
    const onHeadingOptionChange = (name, id) => {
        const selectedHeading = headingOptions.find(menu => menu.id === id)
        if (selectedHeading?.children) {
            const hasHeadingGroups = hasGroups(selectedHeading.children)
            const newMenuItemSelection = {
                heading: { name, id, hasHeadingGroups, path: selectedHeading.path }
            }
            setMenuItemSelection(newMenuItemSelection)
            onMenuItemSelection(newMenuItemSelection)

            if (hasHeadingGroups) {
                setGroupOptions([...hideDefaultHeadingGroups(selectedHeading.children), addNewGroupMenuItem])
                setPageOptions()
            } else {
                setGroupOptions()
                setPageOptions([...selectedHeading.children])
            }
        }
    }

    /**
     * @function
     * @memberOf MainMenuItemSelection
     * @param {string} name
     * @param {string} id
     * @param {string} contentId
     */
    const onGroupOptionChange = (name, id, contentId) => {
        const selectedGroupOption = groupOptions.find(it => it.id === id)

        if (selectedGroupOption) {
            const newMenuItemSelection = {
                heading: menuItemSelection.heading,
                group: { name, id, contentId, path: selectedGroupOption.path }
            }
            setMenuItemSelection(newMenuItemSelection)
            onMenuItemSelection(newMenuItemSelection)
        }

        if (groupOptions) {
            const newSubtitleOptions = groupOptions.find(m => m.id === id)
            setPageOptions(newSubtitleOptions ? newSubtitleOptions.children : [])
        }
    }

    /**
     * @function
     * @memberOf MainMenuItemSelection
     * @param {string} name
     * @param {string} menuItemId
     * @param {string} contentId
     */
    const onPageOptionChange = (name, menuItemId, contentId) => {
        const newMenuItemSelection = {
            heading: menuItemSelection.heading,
            group: menuItemSelection.group,
            page: { name, id: menuItemId, contentId, path: pageOptions.find(page => page.id === menuItemId).path }
        }
        setMenuItemSelection(newMenuItemSelection)
        onMenuItemSelection(newMenuItemSelection)
    }

    return (
        <>
            <h4>{cmsLanguage.menuNames.mainMenu}</h4>
            <div className="cms-section" data-testid="main-menu-section">

                {/* --- Selecting Heading --- */}
                <DropdownWithTitle
                    headline={cmsLanguage.inputFields.assignedSection}
                    title={cmsLanguage.cms.chooseASection}
                    data={headingOptions}
                    onSelect={onHeadingOptionChange}
                    value={(menuItemSelection && menuItemSelection.heading) ? translateValue(menuItemSelection.heading.name) : ''}
                    data-testid="heading-dropdown"
                />

                {/* --- Selecting Title --- */}
                {groupOptions &&
                <>
                    <DropdownWithTitle
                        headline={cmsLanguage.inputFields.assignedSubSection }
                        title={cmsLanguage.cms.chooseASubSection}
                        data={groupOptions}
                        onSelect={onGroupOptionChange}
                        value={menuItemSelection.group === undefined ? '' : menuItemSelection.group.id === addNewGroupMenuItem.id ? translateValue(addNewGroupMenuItem.name) : menuItemSelection.group.name}
                        data-testid="title-dropdown"
                    />

                    {menuItemSelection && menuItemSelection.group && menuItemSelection.group.id === addNewGroupMenuItem.id &&
                    <InputWithCount
                        text={menuItemSelection && menuItemSelection.group.name === addNewGroupMenuItem.name ? '' : translateValue(menuItemSelection.group.name)}
                        placeholder={cmsLanguage.cms.introduceName}
                        setText={value => onGroupOptionChange(value, addNewGroupMenuItem.id, null)}
                        size='medium'
                        maxAllowedCount={20}
                        data-testid="title-input"
                    />}
                </>}

                {/* --- Page name selection --- */}
                {pageOptions && pageOptions.length > 0 &&
                <DropdownWithTitle
                    headline={cmsLanguage.inputFields.assignedPageMenu}
                    title={cmsLanguage.cms.chooseATitle}
                    data={pageOptions}
                    onSelect={onPageOptionChange}
                    value={menuItemSelection.page ? translateValue(menuItemSelection.page.name) : ''}
                    data-testid="main-page-dropdown"
                />}
            </div>
        </>
    )
}

export default MainMenuItemSelection
