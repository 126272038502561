import { cmsLanguage } from '../../../cms/commons/cms_language/cmsLanguage'

/**
 * Utils functions
 * @module trainingDataFormatter
 * @category Components
 * @subcategory Pages / trainingSchedule
 */

/**
 * @function
 * @inner
 * @static
 * @param {Array<Object>} data
 * @param {Array<Object>} categories
 * @returns {*}
 */
export const formatMyCourseData = (data, categories) => {
    if (categories[0].id === 'myCourses') {
        data.forEach((course) => {
            if (!categories[0].eventsGroups) categories[0].eventsGroups = []
            categories[0].eventsGroups.push(course)
        })
    }
    return categories
}

/**
 * @function
 * @inner
 * @static
 * @param {Array<Object>} data
 * @param {boolean} loggedIn
 * @returns {Array<*>}
 */
export const appendMyEventsGroups = (data, loggedIn) => {
    const categories = [
        {
            id: 'myCourses',
            name: cmsLanguage.cms.myCourses,
            eventsGroups: [],
            disabled: true,
            fixed: 'true'
        },
        { line: true }
    ]
    if (loggedIn) {
        categories[0].disabled = false
    }
    return [...categories, ...data]
}
