import React from 'react'
import './messageField.scss'

/**
 * @typedef MessageFieldProps
 * @memberOf MessageField
 * @property {function} setInputValue
 * @property {string} title
 * @property {string} alert
 * @property {string} defaultInput
 * @property {string} inputKey
 */

/**
 * A textarea to be showed on the {@link ContactTemplate}.
 * @class MessageField
 * @category Components
 * @subcategory Templates / Contact
 * @param {MessageFieldProps} props
 * @returns {React.ReactNode}
 * @example
 * <MessageField
 *     title={cmsLanguage.inputFields.message}
 *     defaultInput={userData.message ? userData.message : ''}
 *     inputKey="message"
 *     alert={alert.message}
 *     setInputValue={setInputValue}
 * />
 */
const MessageField = ({
    setInputValue,
    title,
    alert,
    defaultInput,
    inputKey
}) => {
    const targetValue = (event) => {
        setInputValue(inputKey, event.target.value)
    }

    return (
        <div className="message-input" data-testid="input-field">
            <h4>{title}</h4>
            <div className={alert ? 'members-input-field alert' : 'members-input-field'}>
                <textarea
                    id="message-input-field"
                    name="contact-message"
                    value={defaultInput}
                    onChange={targetValue}
                />
            </div>
            <p>{alert}</p>
        </div>
    )
}

export default MessageField
