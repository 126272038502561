import { v4 as uuidv4 } from 'uuid'
import { cmsLanguage } from '../../../commons/cms_language/cmsLanguage'

const parallax = () => {
    const fields = [
        {
            name: 'picture',
            type: 'PHOTO',
            id: uuidv4(),
            isMandatory: true
        },
        {
            name: 'title',
            type: 'H1',
            id: uuidv4(),
            isMandatory: true
        },
        {
            name: 'subtitle',
            type: 'H2',
            id: uuidv4(),
            isMandatory: false
        },
        {
            name: 'text',
            type: 'LONG_TEXT',
            id: uuidv4(),
            isMandatory: true
        },
        {
            name: 'linkText',
            type: 'SHORT_TEXT',
            id: uuidv4(),
            isMandatory: false
        },
        {
            name: 'link',
            type: 'LINK',
            id: uuidv4(),
            isMandatory: false
        }
    ]
    const values = fields.map(it => {
        return {
            field: it,
            fieldId: it.id,
            id: uuidv4(),
            value: ''
        }
    })
    return {
        id: uuidv4(),
        name: cmsLanguage.templatesNames.parallax,
        type: 'PARALLAX',
        elements: [
            {
                id: uuidv4(),
                values: values,
                type: 'default'
            }
        ],
        fields: fields
    }
}

export const PARALLAX_TEMPLATE_ORDER = { PHOTO: 0, H1: 1, H2: 2, LONG_TEXT: 3, SHORT_TEXT: 4, LINK: 5 }

export default parallax
