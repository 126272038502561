// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".training-container{background-color:#f2f2f2;padding:3.75em}.training-timetable-container{background-color:#fff;margin-top:2.8125em}.training-header{justify-content:space-between;width:100%}.training-dropbox-container{display:flex}.training-dropbox-container>*{margin-right:1.875em}.training-header-row{display:flex;flex-direction:row}@media (max-width: 576px){.training-dropbox-container,.training-header{flex-direction:column}.dropdown-container-wrapper{margin-bottom:.9375em;width:100%}.training-container{background-color:#fff}}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
