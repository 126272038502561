export const CONFIG = {
    // TODO: Replace this with MESH_CONFIG when all the data will be on mesh
    DEFAULT_HEADERS: {
        'x-api-key': '5c13a519-abfa-42d5-986a-c846fc232632'
    // CONTENT_TYPE: 'application/json',
    // Authorization: `Bearer ${accessToken}`,
    }
}

export const MESH_CONFIG = {
    ORGANIZATION: {
        EMAIL: 'frontend.mesh@meshplatform.io',
        PASSWORD: 'Frontend@123'
    },
    // SERVER_URL: `http://localhost`,'
    // SERVER_URL: `https://kongapi.hexad.de`,
    // SERVER_URL: `https://k8s.hexad.de`,
    // SERVER_URL: `https://vfl-verein.k8s.hexad.de`,
    SERVER_URL: window.location.origin
}

const orgName = window.location.hostname.split('.')[0]

export const REDIRECT_URL = window.location.origin

const getAdminUrl = () => {
    if (window.location.origin === 'https://vfl-wob.de') {
        return 'https://vfl-wob-admin.vfl-wob.de'
    } else {
        return window.location.origin.split('.').map((it, i) => i === 0 ? `${it}-admin` : it).join('.')
    }
}

export const ADMIN_URL = getAdminUrl()
export const LOGGING_URL = `${ADMIN_URL}?clientId=${orgName}&redirectUri=${REDIRECT_URL}`

export const CATEGORIES = {
    sports: 'Sports',
    myCourses: 'My Courses',
    courses: 'Courses'
}
