// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".cms-input-textarea-editor{display:flex;margin-bottom:.9375em;flex-direction:column;width:100%;border:solid 1px #b8b8b8}.cms-input-textarea-editor input,.cms-input-textarea-editor textarea{border:none;background-image:none;background-color:transparent;-webkit-box-shadow:none;-moz-box-shadow:none;box-shadow:none;width:100%;font-family:'Open Sans', sans-serif;font-size:.875em;color:#191919}.cms-input-textarea-editor input:focus:hover,.cms-input-textarea-editor input:focus,.cms-input-textarea-editor input:hover,.cms-input-textarea-editor textarea:focus:hover,.cms-input-textarea-editor textarea:focus,.cms-input-textarea-editor textarea:hover{outline:none;border-radius:0}.cms-input-textarea-editor-focus .cms-input-box{border-color:#64b42d}.cms-input-textarea-editor-error .cms-input-box{border-color:#f03}.cms-input-textarea-editor-info-error{height:1.875em;display:flex;align-items:center}.cms-input-textarea-editor-no-display-info-error{height:.625em}.cms-input-textarea-editor-counter{justify-self:flex-end;text-align:right;margin-left:.9375em;font-family:'Open Sans', sans-serif;font-size:.75em;color:#595959;padding:0 .625em}.input-label{font-family:'Open Sans', sans-serif;font-weight:normal;font-style:normal;font-size:.75em;color:#595959;margin-bottom:.3125em}.ql-editor{min-height:80px}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
