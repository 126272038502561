// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".message-input{position:relative}.message-input h4{font-family:'Open Sans', sans-serif;font-weight:700;font-style:normal;line-height:1.625em;font-size:1.375em;color:#191919}.message-input p{position:absolute;bottom:-.625em;left:0;color:#f03}.message-input .members-input-field{display:flex;font-family:'Open Sans', sans-serif;font-weight:normal;font-style:normal;font-size:1em;color:#595959;line-height:2em;border:none;border-bottom:#595959 solid 1px;width:100%;padding:.3125em .9375em;margin-bottom:1.875em}.message-input .members-input-field #message-input-field{width:100%}.message-input .bankCode{padding-right:0}.message-input div.alert{border-bottom:1px solid #f03}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
