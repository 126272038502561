import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './dropdownArrowButton.scss'

const DropdownArrowButton = ({ onClick, isOpen }) => {
    return (
        <div onClick={onClick} className="arrow-icon" data-testid="arrow-icon">
            <FontAwesomeIcon
                className="arrow"
                icon={isOpen ? ['fas', 'chevron-up'] : ['fas', 'chevron-down']}
            />
        </div>
    )
}

export default DropdownArrowButton
