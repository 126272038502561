import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { cmsLanguage } from '../../../../cms/commons/cms_language/cmsLanguage'
import {
    MAX_PICTURE_SIZE_NUMBER,
    PICTURE_ERROR
} from '../../../../cms/commons/cms_input/components/input_photo/constants'
import './ProfileImageField.scss'

/**
 * @typedef ProfileImageFieldProps
 * @memberOf ProfileImageField
 * @property {string} value
 * @property {function} onChange
 * @property {function} [onError]
 * @property {function} [onFocus]
 * @property {function} [onBlur]
 */

/**
 * Input Field component.
 * @class ProfileImageField
 * @category Components
 * @subcategory Templates / MembersArea
 * @param {ProfileImageFieldProps} props
 * @returns {React.ReactNode}
 * @example
 * <ProfileImageField
 *     value={inputValue('profileImage')}
 *     onError={() =>({})}
 *     onChange={() =>({})}
 * />
 */
const ProfileImageField = ({ value, onChange, onError, onFocus, onBlur = () => {} }) => {
    let file
    const handleChangePicture = (e) => {
        [file] = e.target.files
        if (file && file.size <= MAX_PICTURE_SIZE_NUMBER) {
            const pictureUrl = URL.createObjectURL(file)
            onChange(pictureUrl, file)
        } else {
            onError(PICTURE_ERROR)
        }
    }

    return (
        <div className="profile-image-field">
            <label
                className="profile-image-field__frame"
                style={{
                    backgroundImage: value ? `url('${value}')` : undefined
                }}
            >
                {!value && (
                    <FontAwesomeIcon
                        className="profile-image-field__icon"
                        icon={['far', 'user']}
                    />
                )}
                <input
                    data-testid="cms-input-photo"
                    type="file"
                    accept="image/*"
                    onChange={handleChangePicture}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    placeholder={cmsLanguage.cms.photoPlaceholder + '...'}
                />
            </label>
        </div>
    )
}

export default ProfileImageField
