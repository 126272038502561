import React from 'react'
import './PaymentMethodInput.scss'

/**
 * @typedef PaymentMethodInputProps
 * @memberOf PaymentMethodInput
 * @property {string} value
 * @property {string} id
 * @property {function} onClick
 * @property {string} label
 * @property {Array<string>} [logos] = []
 * @property {string} type
 * @property {boolean} [disabled]
 */

/**
 * This component renders the payment method input.
 * @class PaymentMethodInput
 * @category Components
 * @subcategory Templates / CourseInfo
 * @param {PaymentMethodInputProps} props
 * @return {React.ReactNode}
 * @example
 * <PaymentMethodInput
 *     label={name}
 *     onClick={(type) => setSelectedType(type)}
 *     disabled={disabled}
 *     id={id}
 *     type={type}
 *     value={id}
 *     logos={logos}
 * />
 */
const PaymentMethodInput = ({ value, id, onClick, label, logos = [], type, disabled }) => (
    <label className="payment-method-input">
        <div className={'payment-method-input__container'}>
            <input
                type={'radio'}
                value={value}
                disabled={disabled}
                name={'payment-method'}
                id={'payment-method--' + id}
                data-testid={'payment-method--' + id}
                onClick={() => onClick(type)}
            />
            {label}
        </div>
        <div className="payment-method-input__logos">
            {logos.map((logo, index) => (
                <div className={'logo-container'} key={logo} data-testid={'logo-container'}>
                    <img className="logo" src={logo} alt={'logo_' + label + '_' + index} />
                </div>
            ))}
        </div>
    </label>
)

export default PaymentMethodInput
