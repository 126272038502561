// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".class-modal{margin-bottom:1.875em;padding:.9375em}.class-modal-title>h1{font-family:'Open Sans', sans-serif;font-weight:700;font-style:normal;line-height:1.625em;font-size:1.375em;color:#191919;margin-bottom:.9375em;text-transform:uppercase}.class-modal-name>span{font-family:'Open Sans', sans-serif;font-weight:normal;font-style:normal;font-size:1em;color:#595959;line-height:2em;font-weight:bold;text-transform:uppercase}.class-modal-value{font-family:'Open Sans', sans-serif;font-weight:normal;font-style:normal;font-size:.875em;color:#595959;font-weight:400}.class-modal-item{margin-bottom:.9375em}.class-modal-buttons{justify-content:space-evenly;margin-top:1.875em}.class-modal-buttons>a{min-width:200px}.class-modal-row,.class-modal-name,.class-modal-container,.class-modal-buttons{display:flex;flex-direction:row;min-width:100px}.class-modal-col,.class-modal-section{display:flex;flex-direction:column;min-width:100px}@media (max-width: 576px){.class-modal-container{flex-direction:column-reverse}.class-modal-buttons{flex-direction:column}.class-modal-buttons>:first-child{margin-bottom:.9375em}}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
