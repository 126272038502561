// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".search-bar{width:19.375em;display:flex;align-items:center;background-color:#fff;border:solid 1px #595959;padding:0 .9375em}.search-bar.active{border-color:#64b42d}.search-bar input{height:2.8125em;flex:9;border:none;font-family:rucksack, sans-serif;font-weight:400;font-style:normal;font-size:1em;color:#595959;font-weight:lighter;text-transform:capitalize}.search-bar input:focus{outline:none}.search-bar .icon{flex:1;cursor:pointer;color:#64b42d}.search-bar .icon:after{font-family:'Font Awesome 5 Free';font-weight:900;content:\"\";font-size:1.125em}.search-bar .icon.delete:after{font-family:'Font Awesome 5 Free';font-weight:900;content:\"\"}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
