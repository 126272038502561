import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'

import MembersArea from './MembersArea'
import { getMyCourses, getCategories } from '../../pages/trainingSchedule/actions/trainingActions'
import { findMySports } from './utils'

/**
 * {@link MembersArea} container that binds the MembersArea with the {@link module:reduxSetup}
 * @class MembersAreaContainer
 * @category Containers
 * @subcategory Templates / MembersArea
 *
 * @mermaid
 *   sequenceDiagram
 *   Parent->>MembersAreaContainer: Request the MembersArea via MembersAreaContainer.
 *   Note right of MembersAreaContainer: MembersAreaContainer gets information from redux store.
 *   MembersAreaContainer->>MembersArea: MembersAreaContainer sends redux store information to MembersArea as props.
 *   MembersArea-->>Parent: MembersArea is rendered in the parent component.
 */

/**
 * Gets the `training: { courses, categories }`
 * from redux state and makes available to the {@link MembersArea} as a prop called `categories, mySports`.
 * @function
 * @memberOf MembersAreaContainer
 * @alias mapStateToProps
 * @param {Object} state - redux state
 * @returns {Object}
 */
const mapStateToProps = (state) => {
    const { training: { courses, categories } } = state
    return {
        categories,
        mySports: findMySports(courses)
    }
}

/**
 * Passes certain Redux actions
 * ({@link module:TrainingActions.getMyCourses} and {@link module:TrainingActions.getCategories})
 * to the {@link MembersArea} component as props.
 * This function is used only by the react-redux connect function.
 * @function
 * @memberOf MembersAreaContainer
 * @alias mapDispatchToProps
 * @param {function} dispatch - The react-redux dispatch function
 * @returns {Object} - Redux actions used in the {@link MembersArea} component
 */
const mapDispatchToProps = (dispatch) => bindActionCreators({ getMyCourses, getCategories }, dispatch)

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    MembersArea
)
