// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".course-summary{border:1px solid #64b42d;padding:.9375em .9375em 1.875em .9375em;width:100%;display:grid;grid-row-gap:1.875em;margin-bottom:.9375em;padding:.9375em 1.875em;display:flex;justify-content:space-between;position:relative;align-items:center;column-gap:.9375em}.course-summary>span{font-family:'Open Sans', sans-serif;font-weight:normal;font-style:normal;font-size:1.125em;color:#191919}.course-summary .divider{flex-grow:1;height:1px;background-color:#b8b8b8}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
