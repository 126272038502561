import React, { useEffect, useState } from 'react'
import MeshWallets from '../../../../../../../backend/mesh/wallets_service/MeshWallets'
import { adaptPaymentData, SUCCESS_PAYMENT_CODE } from '../../payment-utils'
import { saveStep } from '../../../login-step/utils'
import JOIN_COURSE_STEP from '../../../../../joinCourseStep'

const ExternalCardPayment = ({ returnUrl, paymentData, price, concept, type, onChange }) => {
    const urlParams = new URLSearchParams(window.location.search)
    const paramCheckoutId = urlParams.get('id')
    const [checkoutId, setCheckoutId] = useState(paramCheckoutId)
    useEffect(() => {
        if (checkoutId) {
            onChange && onChange({ id: checkoutId, result: { code: SUCCESS_PAYMENT_CODE } })
        } else {
            const startCheckout = async () => {
                const response = await MeshWallets.checkout(adaptPaymentData(paymentData, price, concept, type))
                setCheckoutId(response.originalResponse.id)
                saveStep(JOIN_COURSE_STEP.PAYMENT)
            }
            startCheckout()
        }
    }, [])

    useEffect(() => {
        let script
        if (checkoutId) {
            script = document.createElement('script')

            script.src = `${process.env.REACT_APP_VR_URL}/v1/paymentWidgets.js?checkoutId=${checkoutId}`
            script.async = true

            document.body.appendChild(script)
        }
        return () => {
            script && document.body.removeChild(script)
        }
    }, [checkoutId])
    return (
        <div>
            <form action={returnUrl} className="paymentWidgets" data-brands="VISA MASTER AMEX"></form>
        </div>
    )
}

export default ExternalCardPayment
