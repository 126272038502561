import React, { useCallback, useEffect, useState } from 'react'
import './Header.scss'
import MainMenu from './components/main_menu/MainMenu'
import TopBar from './components/top_bar/TopBarContainer'
import { responsiveMenuBreakpoint } from './../theme/styles/_responsive_breakpoints.scss'
import AdministrationBar from '../cms/administration_bar/AdministrationBarContainer'
import SubpageMenu from './components/subpage_menu/SubpageMenu'
import ProfileMenuContent from './components/profile/ProfileMenuContent'

/**
 * @typedef HeaderProps
 * @memberOf Header
 * @property {Menu} menu
 * @property {Menu} topMenu
 * @property {Menu} headerMenu
 * @property {Menu} contentMenu
 * @property {boolean} headerOpened
 * @property {function} setHeaderOpened
 */

/**
 * Menu item component
 * @class Header
 * @category Components
 * @subcategory Header
 * @param {HeaderProps} props
 * @returns {React.ReactNode}
 * @example
 * <Header
 *     topMenu={{...}}
 *     headerMenu={{...}}
 *     headerMenu={{...}}
 *     headerOpened={false}
 *     setHeaderOpened={setHeaderOpened}
 * />
 */
const Header = (props) => {
    const {
        topMenu,
        headerMenu,
        headerOpened,
        setHeaderOpened,
        contentMenu
    } = props
    const [fullDesktopMenu, setFullDesktopMenu] = useState(true)
    const [profileOpened, setProfileOpened] = useState(false)

    const responsiveMenu = useCallback(() => {
        const isDesktop = document.body.offsetWidth >= responsiveMenuBreakpoint
        isDesktop ? setFullDesktopMenu(true) : setFullDesktopMenu(false)
        if (isDesktop) setProfileOpened(false)
    }, [])

    useEffect(() => {
        responsiveMenu()
    }, [responsiveMenu])

    const resizeMenu = () => {
        responsiveMenu()
        setHeaderOpened(false)
    }

    window.addEventListener('load', responsiveMenu)
    window.addEventListener('resize', resizeMenu)

    const onAvatarClick = () => {
        setProfileOpened(!profileOpened)
        setHeaderOpened(false)
    }
    const onProfileMenuClose = (e) => {
        setProfileOpened(false)
    }
    return (
        <div id='vfl-header'
            className='header-container'>
            <AdministrationBar/>
            {fullDesktopMenu &&
            <TopBar fullDesktopMenu={fullDesktopMenu}
                headerOpened={headerOpened}
                topMenu={topMenu}
            />
            }
            <MainMenu
                topMenu={topMenu}
                headerMenu={headerMenu}
                fullDesktopMenu={fullDesktopMenu}
                subpageMenu={props.menu}
                headerOpened={headerOpened}
                setHeaderOpened={setHeaderOpened}
                setProfileOpened={setProfileOpened}
                profileOpened={profileOpened}
                onAvatarClick={onAvatarClick}
            />
            <div className='green-line'/>
            <ProfileMenuContent isOpen={profileOpened} onClose={onProfileMenuClose} isMobile={!fullDesktopMenu}/>
            {contentMenu && contentMenu.children && contentMenu.children.length !== 0 &&
            <SubpageMenu menu={contentMenu}/>
            }
        </div>
    )
}

export default Header
