import { formatPhoneNumberIntl } from 'react-phone-number-input'

/**
 *
 * @module phoneUtils
 * @category Utils
 * @subcategory General
 */

/**
 *
 * @type {string}
 * @const
 */
export const DEFAULT_COUNTRY_CODE = '+49'

/**
 * Takes a phone number, apply a new format (from library) then gets and returns the country code
 * @function
 * @param {string} phone
 * @returns {string}
 * @example
 * const telephone = '+34666970450'
 * const result = getCountryCode(telephone) // '+34'
 */
export function getCountryCode (phone) {
    let countryCode = DEFAULT_COUNTRY_CODE
    if (phone) {
        const formatted = formatPhoneNumberIntl(phone)
        const spaced = formatted.split(' ')
        if (!!spaced && spaced[0].includes('+')) {
            countryCode = spaced[0]
        }
    }
    return countryCode
}

/**
 * Takes a phone number, apply a new format (from library), removes the country code then the phone number without the country code
 * @function
 * @param {string} phone
 * @returns {string}
 * @example
 * const telephone = '+34666970450'
 * const result = getPhone(telephone) // '666970450'
 */
export function getPhone (phone) {
    let finalPhone = phone
    if (phone) {
        const formatted = formatPhoneNumberIntl(phone)
        const spaced = formatted.split(' ')
        if (spaced) {
            finalPhone = spaced[0].includes('+') ? spaced.slice(1).join('') : phone
        }
    }
    return finalPhone
}

/**
 * Gets the phone number and the country code from the user information then return a new phone number with the country code
 * @function
 * @param {Object} userInformation
 * @param {string} phoneId
 * @param {string} countryCodeId
 * @returns {string}
 * @example
 * const user = { phone: '666970450', countryCode: '+55' }
 * const result = getPhoneValueFromUserInformation(user, 'phone', 'countryCode') // '+55666970450'
 */
export function getPhoneValueFromUserInformation (userInformation, phoneId, countryCodeId) {
    if (!userInformation) return ''
    if (userInformation[phoneId] && userInformation[phoneId].indexOf('+') >= 0) {
        return userInformation[phoneId]
    }
    return (userInformation[countryCodeId] || '') + userInformation[phoneId]
}
