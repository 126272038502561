// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".iban-input{display:flex;height:2.8125em}.iban-input input{border:none;background-image:none;background-color:transparent;-webkit-box-shadow:none;-moz-box-shadow:none;box-shadow:none;height:100%;font-family:'Open Sans', sans-serif;font-size:.875em;color:#595959;padding:.625em;border:solid #b8b8b8 1px}.iban-input input:focus:hover,.iban-input input:focus,.iban-input input:hover{outline:none;border-radius:0}.iban-input input:first-child{border-right:none}.iban-input input:last-child{flex-grow:1;background-color:#fff}.iban-input input:focus{border-color:#64b42d}.iban-input-error input:last-child{border-color:#f03}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
