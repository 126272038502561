import { EMPTY_COURSES } from './mockCoursesData'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'

/**
 * @function
 * @static
 * @memberOf CourseArea
 * @param {Array<Object>} data
 * @param {Array<Object>} walletStatementArray
 * @param {Array<Object>} listOfScheduleStartDate
 * @returns {Array<Object>}
 */
export default function adaptEventsGroupsResponseToTable ({ data }, walletStatementArray, listOfScheduleStartDate) {
    const courses = EMPTY_COURSES()
    const date = courses[0]
    const name = courses[1]
    const amount = courses[2]
    const paymentDate = courses[3]
    data?.forEach((course, index) => {
        const startDate = (listOfScheduleStartDate.find(schedule => schedule[course.name]) ?? {})[course.name]
        date.values.push({ name: moment(startDate).format('DD MMM YYYY'), id: uuidv4() })
        name.values.push({ name: course.name, id: uuidv4() })
        amount.values.push({ name: walletStatementArray[index].amount.toString().replace('-', ''), id: uuidv4() })
        paymentDate.values.push({ name: moment(walletStatementArray[index].createdDate).format('DD-MM-YYYY'), id: uuidv4() })
    })

    return courses
}
