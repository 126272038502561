import React, { useState } from 'react'
import './inputIban.scss'
import { cmsLanguage } from '../../../cms_language/cmsLanguage'
import iban from 'iban'

const InputIban = ({ value, onChange, onError }) => {
    const [errorMessage, setErrorMessage] = useState()
    const countryCode = 'DE'

    const handleOnChange = (e) => {
        const ibanValue = e.target.value
        const error = checkIBAN(ibanValue)
        const hasError = !!error
        onChange(countryCode + ibanValue, hasError)
    }

    const checkError = (errorMessage) => {
        setErrorMessage(errorMessage)
        onError(errorMessage)
        return errorMessage
    }

    const checkIBAN = (newValue) => {
        const errorMessageEmpty = '*' + cmsLanguage.error.mustNotBeEmpty
        const errorMessageNotValid = cmsLanguage.error.notValidIban
        const countryCode = 'DE'

        const ibanValue = countryCode.concat(newValue)
        const bban = newValue.slice(2)

        return ibanValue.slice(2) !== ''
            ? iban.isValid(ibanValue) && iban.isValidBBAN(countryCode, bban)
                ? checkError(null)
                : checkError(errorMessageNotValid)
            : checkError(errorMessageEmpty)
    }

    return (
        <div className={`iban-input ${errorMessage ? 'iban-input-error' : ''}`}>
            <input
                className="bankCode"
                type="text"
                value={countryCode}
                disabled={true}
                size={2}
            />
            <input
                id="members-input-field"
                data-testid="cms-input-iban"
                type="text"
                pattern="[0-9]{12}"
                value={value.replace(countryCode, '')}
                onChange={handleOnChange}
                placeholder={`${cmsLanguage.cms.ibanPlaceholder}...`}
            />
        </div>
    )
}

export default InputIban
