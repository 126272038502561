import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import FooterMenu from './FooterMenu'
import * as cmsActions from '@src/cms/actions/CMSActions'
import * as contentActions from '@src/cms/actions/ContentActions'

/**
 * {@link FooterMenu} container that binds the FooterMenu with the {@link module:reduxSetup}
 * @class FooterMenuContainer
 * @category Containers
 * @subcategory Footer
 */

/**
 * Gets the `cms.isEditModeOn` from redux state and makes available to the {@link FooterMenu} as a prop called `isEditModeOn`.
 * @function
 * @memberOf FooterMenuContainer
 * @alias mapStateToProps
 * @param {Object} state - redux state
 * @returns {{isEditModeOn}}
 */
const mapStateToProps = (state) => {
    const { cms } = state

    return {
        isEditModeOn: cms.isEditModeOn
    }
}

/**
 * Passes certain Redux actions ({@link module:ContentActions} and {@link module:CmsActions})
 * to the {@link FooterMenu} component as props.
 * This function is used only by the react-redux connect function.
 * @function
 * @memberOf FooterMenuContainer
 * @alias mapDispatchToProps
 * @param {function} dispatch - The react-redux dispatch function
 * @returns {Object} - Redux actions used in the {@link FooterMenu} component
 */
const mapDispatchToProps = (dispatch) => bindActionCreators({ ...cmsActions, ...contentActions }, dispatch)

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    FooterMenu
)
