/**
 *
 * @module timeUtils
 * @category Utils
 * @subcategory General
 */

/**
 * Returns the number of weeks based on a date split
 * @function
 * @param {number} year
 * @param {number} month
 * @param {number} day
 * @returns {number}
 * @example
 * const weeks = getWeekNumber(2023, 5, 12) // 19
 */
export const getWeekNumber = (year, month, day) => {
    function serial (days) {
        return 86400000 * days
    }
    function dateserial (year, month, day) {
        return new Date(year, month - 1, day).valueOf()
    }
    function weekday (date) {
        return new Date(date).getDay() + 1
    }
    function yearserial (date) {
        return new Date(date).getFullYear()
    }
    const date =
      year instanceof Date
          ? year.valueOf()
          : typeof year === 'string'
              ? new Date(year).valueOf()
              : dateserial(year, month, day)
    const date2 = dateserial(
        yearserial(date - serial(weekday(date - serial(1))) + serial(4)),
        1,
        3
    )
    return ~~((date - date2 + serial(weekday(date2) + 5)) / serial(7))
}

/**
 * Takes a date and returns an object with the start and end dates.
 * The start date and end date will be the start and end dates for the week.
 * @function
 * @param {string} day
 * @returns {{startDateTime: string, endDateTime: string}}
 * @example
 * const _date = '2023-05-12T10:49:06.329Z'
 * const result = getStartAndEndTimeByWeek(_date)
 * // { startDateTime: "2023-05-15T10:47:06.380Z", endDateTime: "2023-05-21T10:47:06.380Z" }
 */
export const getStartAndEndTimeByWeek = (day) => {
    day = new Date(day)
    const currentWeekDay = day.getDay()
    const lessDays = currentWeekDay === 0 ? 6 : currentWeekDay - 1
    const startTime = new Date(new Date(day).setDate(day.getDate() - lessDays))
    const endTime = new Date(new Date(startTime).setDate(startTime.getDate() + 6))
    return {
        startDateTime: startTime.toISOString(),
        endDateTime: endTime.toISOString()
    }
}

/**
 * Changes a date by adding or subtracting days
 * @function
 * @param {string} dateString
 * @param {number} noOdDays
 * @param {"+"|"-"}operation
 * @returns {Date}
 */
export const incrementDecrementDay = (dateString, noOdDays, operation) => {
    let newDate
    if (operation === '+') {
        const date = new Date(dateString)
        newDate = new Date(date.setDate(date.getDate() + 2))
    } else {
        const date = new Date(dateString)
        newDate = new Date(date.setDate(date.getDate() - 2))
    }
    return newDate
}
