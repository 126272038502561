// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".profile-round-picture,.profile-round-picture>div{display:flex;align-items:center;justify-content:center;border-radius:100px;z-index:10}.profile-round-picture{position:relative;height:11.25em;width:11.25em;background-color:#fff;background-size:cover;background-position:center}.profile-round-picture .no-picture-icon{height:100%;width:100%;background-color:rgba(100,180,45,0.5);font-size:3.75em;color:white}.profile-round-picture .edit-overlay{position:absolute;top:0;left:0;height:100%;width:100%}.profile-round-picture .edit-overlay .edit-icon{display:none;color:#fff;font-size:1.625em}.profile-round-picture .edit-overlay:hover{background:rgba(0,0,0,0.3)}.profile-round-picture .edit-overlay:hover .edit-icon{display:inline-block}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
