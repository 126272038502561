import { types } from '../types'

/**
 * Redux Content Reducers.
 * @module ContentReducers
 * @category Redux
 * @subcategory Cms
 */

/**
 * @function
 * @inner
 * @param {boolean} isDraft
 * @param {string} variant
 * @returns @returns {Object} The new state
 */
const createContentReducer = (isDraft, variant) => (content = {}, action) => {
    if (isDraft === action.applyOnDraft && variant === action.variant) {
        return updateContent(content, action)
    } else {
        return content
    }
}

const updateContent = (content = {}, action) => {
    switch (action.type) {
    case types.SET_CONTENT_MENU:
        return {
            ...content,
            menu: action.payload
        }
    case types.SET_CONTENT:
        return {
            ...addContentDefaultValues(action.payload.content)
        }
    case types.ADD_TEMPLATE:
    case types.UPDATE_TEMPLATE:
    case types.DELETE_TEMPLATE:
    case types.ADD_ELEMENT:
    case types.UPDATE_ELEMENT:
    case types.DELETE_ELEMENT:
    case types.ADD_VALUE:
    case types.UPDATE_VALUE:
    case types.DELETE_VALUE:
        return {
            ...content,
            templates: updateTemplates(content.templates, action)
        }
    default:
        return content
    }
}

const updateTemplates = (templates, action) => {
    switch (action.type) {
    case types.ADD_TEMPLATE: {
        const { templatePosition } = action.payload
        templates.splice(templatePosition, 0, {
            ...action.payload.template,
            isNew: true
        })
        return templates
    }
    case types.UPDATE_TEMPLATE:
    case types.ADD_ELEMENT:
    case types.UPDATE_ELEMENT:
    case types.DELETE_ELEMENT:
    case types.ADD_VALUE:
    case types.UPDATE_VALUE:
    case types.DELETE_VALUE: {
        const { templateId } = action.payload
        const indexToUpdate = templates.findIndex((it) => it.id === templateId)
        return [
            ...templates.slice(0, indexToUpdate),
            updateTemplate(templates[indexToUpdate], action),
            ...templates.slice(indexToUpdate + 1)
        ]
    }
    case types.DELETE_TEMPLATE: {
        const { templateId } = action.payload
        const indexToUpdate = templates.findIndex((it) => it.id === templateId)

        return [
            ...templates.slice(0, indexToUpdate),
            ...templates.slice(indexToUpdate + 1)
        ]
    }
    default:
        return templates
    }
}

export const updateTemplate = (template, action) => {
    switch (action.type) {
    case types.UPDATE_TEMPLATE:
        return {
            ...action.payload.template
        }
    case types.ADD_ELEMENT:
    case types.UPDATE_ELEMENT:
    case types.DELETE_ELEMENT:
    case types.ADD_VALUE:
    case types.UPDATE_VALUE:
    case types.DELETE_VALUE:
        return {
            ...template,
            elements: updateElements(template.elements, action)
        }
    default:
        return template
    }
}

export const updateElements = (elements, action) => {
    switch (action.type) {
    case types.ADD_ELEMENT:
        return [
            ...elements,
            action.payload.element
        ]
    case types.UPDATE_ELEMENT:
    case types.ADD_VALUE:
    case types.UPDATE_VALUE:
    case types.DELETE_VALUE: {
        const { elementId } = action.payload
        const indexToUpdate = elements.findIndex(it => it.id === elementId)
        return [
            ...elements.slice(0, indexToUpdate),
            updateElement(elements[indexToUpdate], action),
            ...elements.slice(indexToUpdate + 1)
        ]
    }
    case types.DELETE_ELEMENT: {
        const { elementId } = action.payload
        const indexToUpdate = elements.findIndex(it => it.id === elementId)
        return [
            ...elements.slice(0, indexToUpdate),
            ...elements.slice(indexToUpdate + 1)
        ]
    }
    default:
        return elements
    }
}

export const updateElement = (element, action) => {
    switch (action.type) {
    case types.UPDATE_ELEMENT:
        return {
            ...action.payload.element
        }
    case types.ADD_VALUE:
    case types.UPDATE_VALUE:
    case types.DELETE_VALUE:
        return {
            ...element,
            values: updateValues(element.values, action)
        }
    default:
        return element
    }
}

const updateValues = (values, action) => {
    switch (action.type) {
    case types.ADD_VALUE:
        return [
            ...values,
            action.payload.value
        ]
    case types.UPDATE_VALUE: {
        const { valueId } = action.payload
        const indexToUpdate = values.findIndex(it => it.id === valueId)
        return [
            ...values.slice(0, indexToUpdate),
            updateValue(values[indexToUpdate], action),
            ...values.slice(indexToUpdate + 1)
        ]
    }
    case types.DELETE_VALUE: {
        const { valueId } = action.payload
        const indexToUpdate = values.findIndex(it => it.id === valueId)
        return [
            ...values.slice(0, indexToUpdate),
            ...values.slice(indexToUpdate + 1)
        ]
    }
    default:
        return values
    }
}

const updateValue = (value, action) => {
    switch (action.type) {
    case types.UPDATE_VALUE:
        return {
            ...action.payload.value
        }
    case types.ADD_VALUE:
    case types.DELETE_VALUE:
    default:
        return value
    }
}

export const addContentDefaultValues = content => {
    if (content && content.templates) {
        content.templates = content.templates.map(t => addTemplateDefaultValues(t))
    }
    return content
}
const addTemplateDefaultValues = template => {
    template.elements = template.elements.map(e => addElementDefaultValue(e))
    return template
}
const addElementDefaultValue = element => {
    element.values = element.values.map(v => addValueDefaultValue(v))
    return element
}
const addValueDefaultValue = value => {
    delete value.defaultValue
    return { ...value, defaultValue: { ...value } }
}

export default createContentReducer
