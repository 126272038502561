import { v4 as uuidv4 } from 'uuid'
import { cmsLanguage } from '../../../commons/cms_language/cmsLanguage'

const video = () => {
    const fields = [
        {
            name: 'video',
            type: 'VIDEO',
            id: uuidv4(),
            isMandatory: true
        },
        {
            name: 'text',
            type: 'TEXT',
            id: uuidv4(),
            isMandatory: false
        },
        {
            name: 'date',
            type: 'DATE',
            id: uuidv4(),
            isMandatory: false
        }
    ]
    const values = fields.map(it => {
        return {
            field: it,
            fieldId: it.id,
            id: uuidv4(),
            value: ''
        }
    })
    return {
        id: uuidv4(),
        name: cmsLanguage.templatesNames.video,
        type: 'VIDEO',
        elements: [
            {
                id: uuidv4(),
                values: values,
                type: 'default'
            }
        ],
        fields: fields
    }
}

export const VIDEO_TEMPLATE_ORDER = { VIDEO: 0, TEXT: 1, DATE: 2 }

export default video
