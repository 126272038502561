
import React, { useEffect } from 'react'
import { get, remove } from '@utils/storage'
import STORAGE_KEYS from '@utils/storageKeys'
import JOIN_COURSE_STEP from '../joinCourseStep'

/**
 * Checks if already starts a join course.
 * If true, the step will be the stored one.
 * If false, the step will be the initial.
 * @function
 * @memberOf CourseInfo
 * @category Hooks
 * @returns {Array<number>} - [step: number]
 * @example
 * const [storedStep] = useStepRedirection()
 */
const useStepRedirection = () => {
    const [step, setStep] = React.useState(undefined)
    useEffect(() => {
        // Get data
        try {
            const joinCourse = get(STORAGE_KEYS.JOIN_COURSE)
            const user = get(STORAGE_KEYS.USER)

            // Evaluate whether it has to be processed
            if (joinCourse && user) {
                const { step } = joinCourse
                const numberStep = Number(step)
                // Save when component accomplishes the predicate and is not unmounting
                console.debug('Join a course process started lately', numberStep)
                setStep(numberStep)
            } else {
                console.debug('Join a course process not started lately')
                setStep(JOIN_COURSE_STEP.INITIAL)
            }
            // Cleanup once used
            if (joinCourse) {
                remove(STORAGE_KEYS.JOIN_COURSE)
            }
        } catch (e) {
            console.debug('Error while parsing stored data')
            setStep(JOIN_COURSE_STEP.INITIAL)
        }
    }, [])
    return [step]
}

export default useStepRedirection
