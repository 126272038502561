// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".video-player{width:100%}.video-player-frame{height:70vh;width:100%;border:none}.video-player-info{padding-top:.3125em;padding-bottom:5.625em;background-color:#fff}.video-player-info-text{font-family:'Open Sans', sans-serif;font-weight:normal;font-style:normal;font-size:1.25em;color:#191919}.video-player-info-date{font-family:'Open Sans', sans-serif;font-weight:normal;font-style:normal;font-size:.75em;color:#595959}.video-player-info-date:before{font-family:'Font Awesome 5 Free';font-weight:normal;content:\"\";margin-right:.625em}.video-player-info-tac{font-family:'Open Sans', sans-serif;font-weight:normal;font-style:normal;font-size:.75em;color:#595959;text-align:center}.video-player-info-tac:before{font-family:'Font Awesome 5 Free';font-weight:normal;content:\"\";margin-right:.625em}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
