// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".calendar-header-button{display:flex;justify-content:space-between;align-items:center;font-family:'Open Sans', sans-serif;font-weight:700;font-style:normal;line-height:1.625em;font-size:1.375em;color:#fff;min-height:.9375em;padding:.625em}.calendar-header-button>svg{cursor:pointer}.calendar-header-button-dark{background-color:#64b42d}.calendar-header-button-dark:hover:after{color:#fff}.calendar-header-button-dark span{color:#fff}.calendar-header-button-light{border:solid 2px #64b42d}.calendar-header-button-light:hover:after{color:#64b42d}.calendar-header-button-light span{color:#64b42d}@media (max-width: 576px){.calendar-header-button{height:1.875em}}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
