import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import PromptBlockNavigation from './PromptBlockNavigation'

/**
 * Function that checks if navigation should be blocked
 * @param {boolean} isEditModeOn - user is editing
 * @param {string?} path - new url to navigate
 * @param {object } content - persisted content
 * @param {object} contentDraft - content after changes
 * @returns {boolean}
 */
export const isNavigationBlocked = (isEditModeOn, path, content, contentDraft) => {
    const isNotAttemptingToNavigate = path == null
    if (isEditModeOn && isNotAttemptingToNavigate) {
        // content has changed
        return JSON.stringify(content) !== JSON.stringify(contentDraft)
    }
    return false
}

export const hasUnsavedChanges = (isEditModeOn, path, content, contentDraft, footer, footerDraft, socialMedia, socialMediaDraft) => {
    return isNavigationBlocked(isEditModeOn, path, content, contentDraft) || isNavigationBlocked(isEditModeOn, path, footer, footerDraft) || isNavigationBlocked(isEditModeOn, path, socialMedia, socialMediaDraft)
}

const mapStateToProps = (state) => {
    const { cms, content, contentDraft, getCustomNavigation: { path }, footer, footerDraft, socialMedia, socialMediaDraft } = state
    const {
        isEditModeOn
    } = cms

    const blockNavigation = hasUnsavedChanges(isEditModeOn, path, content, contentDraft, footer, footerDraft, socialMedia, socialMediaDraft)
    return ({ shouldBlockNavigation: blockNavigation })
}
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    PromptBlockNavigation
)
