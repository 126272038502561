import store from '../../../redux/store'
import { CONFIG, MESH_CONFIG } from '../Constants'
// eslint-disable-next-line no-unused-vars
import axios, { AxiosResponse } from 'axios'

/**
 * Returns the calendar-service base url to eventsGroup
 * @function
 * @memberOf MeshEventsGroup
 * @alias BASE_URL
 * @param {string|number} orgId
 * @returns {string}
 */
function BASE_URL (orgId) {
    return `${MESH_CONFIG.SERVER_URL}/calendar-service/organizations/${orgId}/eventsGroup`
}

/**
 * Returns an object with the default header configuration (Content-type, x-api-key and authorization bearer token)
 * @function
 * @memberOf MeshEventsGroup
 * @param {string} accessToken
 * @returns {Object}
 * @example
 * {
 *   'x-api-key': '5c13a.....'
 *   CONTENT_TYPE: 'application/json',
 *   Authorization: `Bearer ${accessToken}`
 * }
 */
function DEFAULT_CONFIG (accessToken) {
    return ({
        headers: {
            ...CONFIG.DEFAULT_HEADERS,
            CONTENT_TYPE: 'application/json',
            Authorization: `Bearer ${accessToken}`
        }
    })
}

/**
 * @memberOf MeshEventsGroup
 * @alias DEFAULT_PRICE_EURO
 * @type {number}
 * @example
 * const DEFAULT_PRICE_EURO = 30
 */
export const DEFAULT_PRICE_EURO = 30

/**
 * @memberOf MeshEventsGroup
 * @alias DEFAULT_INSCRIPTIONS_NUMBER
 * @type {number}
 * @example
 * const DEFAULT_INSCRIPTIONS_NUMBER = 15
 */
export const DEFAULT_INSCRIPTIONS_NUMBER = 15

/**
 * MeshEventsGroup
 * @class MeshEventsGroup
 * @category Backend
 * @subcategory calendar_service
 */
export default class MeshEventsGroup {
    /**
     * Create a new event group
     * @function
     * @memberOf MeshEventsGroup
     * @param {EventsGroup} eventsGroup
     * @returns {Promise<AxiosResponse<EventsGroup>>}
     */
    static createEventsGroup (eventsGroup) {
        const { accessToken, organizationId } = store.getState().user
        const url = BASE_URL(organizationId)
        const config = DEFAULT_CONFIG(accessToken)

        return axios.post(url, eventsGroup, config)
    }

    /**
     * Create a new event group
     * @function
     * @memberOf MeshEventsGroup
     * @param {EventsGroup} eventsGroup
     * @returns {Promise<AxiosResponse<EventsGroup>>}
     */
    static updateEventsGroup (eventsGroup) {
        const { accessToken, organizationId } = store.getState().user
        const url = BASE_URL(organizationId)
        const config = DEFAULT_CONFIG(accessToken)

        return axios.put(url, eventsGroup, config)
    }

    /**
     * Gets an event group by its name
     * @function
     * @memberOf MeshEventsGroup
     * @param {string} eventsGroupName
     * @returns {Promise<AxiosResponse<EventsGroup>>}
     */
    static findByName (eventsGroupName) {
        const { accessToken, organizationId } = store.getState().user
        const url = `${BASE_URL(organizationId)}/name/${eventsGroupName}`
        const config = DEFAULT_CONFIG(accessToken)

        return axios.get(url, config)
    }

    /**
     * Gets an event group by its ID
     * @function
     * @memberOf MeshEventsGroup
     * @param {string} eventsGroupId
     * @returns {Promise<AxiosResponse<EventsGroup>>}
     */
    static findByEventsGroupId (eventsGroupId) {
        const { accessToken, organizationId } = store.getState().user
        const url = `${BASE_URL(organizationId)}/${eventsGroupId}`
        const config = DEFAULT_CONFIG(accessToken)

        return axios.get(url, config)
    }

    /**
     * Gets all event groups for the logged-in user
     * @function
     * @memberOf MeshEventsGroup
     * @returns {Promise<AxiosResponse<Array<EventsGroup>>>}
     */
    static getMyCourses () {
        const { accessToken, organizationId } = store.getState().user
        const url = BASE_URL(organizationId)
        const config = DEFAULT_CONFIG(accessToken)

        return axios.get(url, config)
    }

    /**
     * Deletes an event group by its ID
     * @function
     * @memberOf MeshEventsGroup
     * @param {string} eventsGroupsId
     * @returns {Promise<AxiosResponse<void>>}
     */
    static deleteEventsGroups (eventsGroupsId) {
        const { accessToken, organizationId } = store.getState().user
        const url = `${BASE_URL(organizationId)}/${eventsGroupsId}`
        const config = DEFAULT_CONFIG(accessToken)

        return axios.delete(url, config)
    }

    /**
     * Searches an event
     * @function
     * @memberOf MeshEventsGroup
     * @param {string} example
     * @returns {Promise<AxiosResponse<void>>}
     */
    static searchEventsGroups (example) {
        const { accessToken, organizationId } = store.getState().user
        const url = `${BASE_URL(organizationId)}/search`
        const config = DEFAULT_CONFIG(accessToken)

        return axios.post(url, example, config)
    }
}
