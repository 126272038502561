// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "a:hover{cursor:pointer;text-decoration:none}.logo-container{width:max-content;display:flex;align-items:center}.logo-container .logo{width:3.75em}.logo-container .title{font-family:'Open Sans', sans-serif;font-weight:normal;font-style:normal;color:#fff}.logo-container .title span{font-family:'Open Sans', sans-serif;font-weight:700;font-style:normal}.logo-container.horizontal{padding:1.875em 3.75em}.logo-container.horizontal .logo{margin-right:1.25em}.logo-container.horizontal .title{font-size:1.25em}.logo-container.vertical{padding:2.5em 0;flex-direction:column}.logo-container.vertical .logo{margin-bottom:1.25em}.logo-container.vertical .title{font-size:.83333em}@media screen and (max-width: 320px){.logo-container .logo{width:2.8125em}.logo-container.horizontal{padding:1.40625em 2.8125em}.logo-container.horizontal .logo{margin-right:.9375em}.logo-container.horizontal .title{font-size:.9375em}.logo-container.vertical{padding:1.875em 0}.logo-container.vertical .logo{margin-bottom:.9375em}.logo-container.vertical .title{font-size:.625em}}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
