
import React from 'react'
import ProgressBreadcrumbItem, { PROGRESS_STATES } from './components/progress_breadcrumb_item'
import './ProgressBreadcrumb.scss'

/**
 * @typedef ProgressBreadcrumbProps
 * @memberOf ProgressBreadcrumb
 * @property {number} inProgressIndex
 * @property {IconsArray} iconsArray
 */

/**
 * Breadcrumb to show steps navigation
 * @class ProgressBreadcrumb
 * @category Components
 * @subcategory theme / commons
 * @param {ProgressBreadcrumbProps} props
 * @returns {React.ReactNode}
 * @example
 * <ProgressBreadcrumb inProgressIndex={step - 1} iconsArray={ICONS_ARRAY} />
 */
const ProgressBreadcrumb = ({ inProgressIndex, iconsArray }) => {
    /**
     * Returns the status based on `inProgressIndex`
     * @function
     * @memberOf ProgressBreadcrumb
     * @param {number} index
     * @param {number} inProgressIndex
     * @returns {PROGRESS_STATES}
     */
    const statusMapping = (index, inProgressIndex) => {
        if (index === inProgressIndex) return PROGRESS_STATES.IN_PROGRESS
        else if (index < inProgressIndex) return PROGRESS_STATES.COMPLETED
        else return PROGRESS_STATES.PENDING
    }
    return (<div className="progress-breadcrumb">
        {iconsArray.map((icon, index) => (
            <ProgressBreadcrumbItem icon={icon.value} key={icon.id} status={statusMapping(index, inProgressIndex)}/>
        ))}

    </div>)
}

export default ProgressBreadcrumb
