import React from 'react'
import './HomeSponsors.scss'
import { NAVIGATION_TYPE } from '../../../theme/constants/navigationType'
import NavigationWrapper from '../../../theme/commons/navigation_wrapper/NavigationWrapperContainer'

/**
 * @typedef HomeSponsorsProps
 * @memberOf HomeSponsors
 * @property {Array<Object>} homeSponsorsContent
 * @property {string} name
 */

/**
 * Slide with the sponsor logos.
 * @class HomeSponsors
 * @category Components
 * @subcategory Home
 * @param {HomeSponsorsProps} props
 * @returns {React.ReactNode}
 * @example
 * <HomeSponsors homeSponsorsContent={[{...}]} name="Sponsor name" />
 */
const HomeSponsors = ({ homeSponsorsContent, name }) => {
    const renderLogos = () => {
        return homeSponsorsContent.map((slide, index) => {
            return slide.LINK !== ''
                ? (
                    <NavigationWrapper
                        type={NAVIGATION_TYPE.link}
                        key={index}
                        data-testid={`link-${index}`}
                        href={slide.LINK}
                    >
                        <img
                            data-testid={`logo-${index}`}
                            src={`${slide.PHOTO}`}
                            alt="sponsor"
                        />
                    </NavigationWrapper>
                )
                : (
                    <img
                        key={index}
                        data-testid={`logo-${index}`}
                        src={`${slide.PHOTO}`}
                        alt="sponsor"
                    />
                )
        })
    }

    return (
        <div className="sponsors-container">
            <span data-testid="title" className="title">
                {name}.
            </span>
            <div className="sliding-logos">
                <div className="logos-container">{renderLogos()}</div>
                <div className="logos-container-two">{renderLogos()}</div>
            </div>
        </div>
    )
}

export default HomeSponsors
