import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Mesh from '../../backend/mesh/Mesh'
import CommonBanner from '../../theme/commons/common_banner/CommonBanner'
import { convertDate } from '../../utils/CommonReformatter'
import TextTemplate from '../../templates/text_template/TextTemplate'
import PhotoGalleryTemplate from '../../templates/photo_gallery_template/PhotoGalleryTemplate'
import { language } from '../../theme/commons/language/language'

/**
 * It is used to show the news details based on the URL id parameter.
 * @class FullNews
 * @category Components
 * @subcategory Pages / full-news
 * @returns {React.ReactNode}
 * @example
 * <FullNews />
 */
const FullNews = () => {
    const [newsDetails, setNewsDetails] = useState([])
    const [loading, setLoading] = useState(true)
    const { newsId } = useParams()

    useEffect(() => {
        let isCancelled = false

        const _getANewsDetails = async () => {
            if (!isCancelled) {
                const response = await Mesh.getANewsDetails(newsId)
                setNewsDetails(response)
                setLoading(false)
            }
        }
        _getANewsDetails()

        return () => (isCancelled = true)
    }, [newsId])

    const convertText = (newsContent) => {
        const content = JSON.parse(newsContent)
        let newsText = ''

        content.blocks.map(
            (item) => (newsText = newsText.concat(`<p>${item.text}</p>`))
        )

        return newsText
    }

    const photoGalleryContent =
    newsDetails.media
        ? newsDetails.media.map(m => {
            return {
                DATE: newsDetails.publishedAt,
                PHOTO: m,
                TEXT: null
            }
        })
        : null

    return (
        !loading && (
            <>
                <CommonBanner
                    backgroundImage={
                        newsDetails.media &&
                        newsDetails.media.length > 0 &&
                        newsDetails.media[0]
                    }
                    pageTitle={newsDetails.headline}
                    category={newsDetails.category.split(';')[1]}
                    text={convertDate(newsDetails.publishedAt).toUpperCase()}
                />
                {newsDetails.content && (
                    <TextTemplate textContent={convertText(newsDetails.content)} />
                )}
                {newsDetails.media.length > 0 && (
                    <PhotoGalleryTemplate
                        photoContent={photoGalleryContent}
                        name={language.templates.images}
                    />
                )}
            </>
        )
    )
}

export default FullNews
