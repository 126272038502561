import { format, utcToZonedTime } from 'date-fns-tz'

/**
 * Utils functions
 * @module scheduleDataFormatter
 * @category Components
 * @subcategory Pages / trainingSchedule
 */

/**
 * @function
 * @inner
 * @static
 * @param {Array<Object>} scheduleList
 * @returns {Array<*>}
 */
export function formatData (scheduleList) {
    // const response = {
    //     1: [],
    //     2: [],
    //     3: [],
    //     4: [],
    //     5: [],
    //     6: [],
    //     7: []
    // }

    const formatInTimeZone = (date, fmt, tz) =>
        format(utcToZonedTime(date, tz),
            fmt,
            { timeZone: tz })

    const schedulePositions = []
    const formattedSchedule = []

    scheduleList.forEach((scheduleElement) => {
        scheduleElement.startDateTime = new Date(scheduleElement.startDateTime)
        const formattedDate = formatInTimeZone(scheduleElement.startDateTime, 'HH:mm')

        if (schedulePositions.indexOf(formattedDate) === -1) {
            schedulePositions.push(formattedDate)
            formattedSchedule.push(new Array(7).fill(0))
        }
    })
    schedulePositions.sort((a, b) => (a.localeCompare(b)))

    scheduleList.forEach((schedule) => {
        schedule.startDateTime = new Date(schedule.startDateTime)
        schedule.endDateTime = new Date(schedule.endDateTime)
        const day = schedule.startDateTime.getDay() === 0 ? 7 : schedule.startDateTime.getDay()
        const formattedDate = formatInTimeZone(schedule.startDateTime, 'HH:mm')
        const position = schedulePositions.indexOf(formattedDate)

        const current = formattedSchedule[position][day - 1]
        formattedSchedule[position][day - 1] = current ? [...current, schedule] : [schedule]
    })

    console.log(formattedSchedule)

    return formattedSchedule || []
}

/**
 * @function
 * @inner
 * @static
 * @param formattedSchedule
 * @returns {*}
 */
export function unFormatData (formattedSchedule) {
    return formattedSchedule.flat().filter(element => element !== 0).flat()
}

/**
 * Merges two different schedule arrays.
 * @function
 * @inner
 * @static
 * @param {Array<Object>} oldSchedules
 * @param {Array<Object>} newSchedules
 * @param {boolean} isNewClass
 * @returns {*}
 */
export function mergeSchedulesLists (oldSchedules, newSchedules, isNewClass) {
    let mergedSchedule = []

    if (!isNewClass) {
        mergedSchedule = newSchedules
        oldSchedules.forEach((oldClass) => {
            if (!newSchedules.some((newClass) => newClass.id === oldClass.id)) {
                mergedSchedule.push(oldClass)
            }
        })
    } else {
        mergedSchedule = [...oldSchedules, ...newSchedules]
    }

    return mergedSchedule
}

/**
 * Removes a schedule from a list by its ID.
 * @function
 * @inner
 * @static
 * @param {Array<Object>} scheduleList
 * @param {string} removedScheduleId
 * @returns {Array<*>}
 */
export function removeScheduleFromList (scheduleList, removedScheduleId) {
    const updatedScheduleList = [...scheduleList]
    updatedScheduleList.forEach((schedule, index) => {
        if (schedule.id === removedScheduleId) {
            updatedScheduleList.splice(index, 1)
        }
    })

    return updatedScheduleList
}
