import React from 'react'
import originalLogo from '../../theme/assets/vfl-wolfsburg-logo_2.png'
import './ClassicLogo.scss'

/**
 * Shows the VfL original logo, from the assets folder
 * @class ClassicLogo
 * @category Components
 * @subcategory Footer
 * @returns {React.ReactNode}
 * @example
 * <ClassicLogo />
 */
const ClassicLogo = () => {
    return (
        <div className="classic-logo-container">
            <img
                src={originalLogo}
                className="classic-logo"
                alt="VfL Wolfsburg e.V."
            />
            <span data-testid="title" className="title">
                Wir sind der sport<span>.</span>
            </span>
        </div>
    )
}

export default ClassicLogo
