import React from 'react'
import './successStep.scss'
import { language } from '@theme/commons/language/language'
import Button from '@theme/commons/button/Button'
import CourseSummary from '../components/course-summary'

/**
 * @typedef SuccessStepProps
 * @memberOf SuccessStep
 * @property {function} onNext
 * @property {function} onLogin
 * @property {string} price
 * @property {string} courseName
 */

/**
 * Success Step Component (see {@link SuccessStepContainer}.
 * @class SuccessStep
 * @category Components
 * @subcategory Templates / CourseInfo
 * @param {SuccessStepProps} props
 * @returns {React.ReactNode}
 * @example
 * <SuccessStep courseName="Yoga" price="30" onNext={handleNext} />
 */
const SuccessStep = ({ onNext, courseName, price }) => {
    return (
        <div className={'success-step'}>
            <h3>{language.modals.paymentStep.paymentSuccess}</h3>
            <CourseSummary courseName={courseName} price={price} />
            <div className='button-container button-container--schedule'>
                <Button type='reverted' text={language.templates.viewClassSchedule} link={'/training'} />
            </div>
            <div className="button-container button-container--done">
                <Button text={language.modals.done} onClick={onNext} />
            </div>

        </div>
    )
}

export default SuccessStep
