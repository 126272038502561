import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InputPhoneToolTip from './InputPhoneToolTip'

import 'react-phone-number-input/style.css'
import { cmsLanguage } from '../../../cms_language/cmsLanguage'
import PhoneInput, { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'

const InputPhone = (props) => {
    const { value, onChange, onFocus, onBlur, onError, readOnly } = props
    const handleOnChange = e => {
        const newValue = e
        const hasError = ((newValue && newValue.length > 0 && !isValidPhoneNumber(newValue)))
        showError(hasError, value)
        onChange(newValue, hasError)
    }

    const handleOnBlur = () => {
        onBlur()
    }

    const showError = (hasError, value) => {
        if (hasError && value && value.length) {
            onError(
                cmsLanguage.error.noValidPhone + '.',
                <InputPhoneToolTip/>
            )
        } else {
            onError()
        }
    }

    return (
        <div className="cms-input-box cms-input-box-icon cms-input-phone">
            <PhoneInput
                data-testid="cms-input-phone"
                defaultCountry="DE"
                type="tel"
                placeholder={cmsLanguage.cms.phonePlaceholder + '...'}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                name="phone"
                value={value}
                autoComplete="nope"
                onFocus={onFocus}
                readOnly={readOnly}
                className={readOnly ? 'read-only' : ''}
                formatInputText={(value, country) => {
                    if (!value) {
                        return value
                    }
                    return formatPhoneNumberIntl(value, country)
                }}
            />
            <div>
                <FontAwesomeIcon icon={['fa', 'phone']} data-testid="cms-input-phone-icon" />
            </div>
        </div>
    )
}

export default InputPhone
