import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import './ProgressBreadcrumbItem.scss'

/**
 * Enum for progress states
 * @readonly
 * @category Enums
 * @enum {string}
 * @example
 * PENDING: 'pending'
 * IN_PROGRESS: 'in-progress'
 * COMPLETED: 'completed'
 */
export const PROGRESS_STATES = {
    PENDING: 'pending',
    IN_PROGRESS: 'in-progress',
    COMPLETED: 'completed'
}

/**
 * Progress breadcrumb item
 * @class ProgressBreadcrumbItem
 * @category Components
 * @subcategory theme / commons
 * @param {*} icon
 * @param {string} status
 * @returns {JSX.Element}
 * @constructor
 */
const ProgressBreadcrumbItem = ({ icon, status }) => (
    <div className={`progress-breadcrumb__item item item--${status}`}>
        <FontAwesomeIcon icon={icon} />
    </div>
)

export default ProgressBreadcrumbItem
