import React from 'react'
import { Provider } from 'react-redux'
import defaultStore from './redux/store'

/**
 * @typedef RootProps
 * @memberof Root
 * @property {Object} store A redux store.
 * @property {React.ReactNode} children Child react component
 */

/**
 *
 * A wrapper component. This will provide a redux context.
 * @class Root
 * @category Components
 * @subcategory General
 * @example
 *      <Provider store={store}>{children}</Provider>
 *
 * @param {RootProps} props
 * @returns {React.ReactNode} A redux provider.
 */
const Root = ({ store = defaultStore, children }) => {
    return <Provider store={store}>{children}</Provider>
}

export default Root
