import React from 'react'
import './ProfileWhiteCard.scss'
import ProfileRoundPicture from '../profile_round_picture/ProfileRoundPicture'

/**
 * @typedef ProfileWhiteCardProps
 * @memberOf ProfileWhiteCard
 * @property {string} [picture]
 * @property {JSX.Element} [internalComponent]
 * @property {boolean} [editing]
 * @property {function} [handleProfileImage] - function for the link if `editing` === true
 */

/**
 * Card that will be used to show the profile picture ({@link ProfileRoundPicture}) and the profile information (`internalComponent`)
 * @class ProfileWhiteCard
 * @category Components
 * @subcategory theme / commons
 * @param {ProfileWhiteCardProps} props
 * @returns {React.ReactNode}
 * @example
 * <ProfileWhiteCard
 *     picture="http://image.com/image.jpg"
 *     internalComponent={<div className="profile-item-info"> ... </div>}
 * />
 */
const ProfileWhiteCard = (props) => {
    const { picture, internalComponent, editing, handleProfileImage } = props

    return (
        <div className="profile-white-card-container">
            <div className="profile-white-card-picture-container">
                <ProfileRoundPicture
                    picture={picture && picture}
                    editing={editing}
                    handleProfileImage={handleProfileImage}
                />
            </div>

            <div
                className="profile-white-card-content"
                data-testid="profile-white-card-content"
            >
                {internalComponent}
            </div>
        </div>
    )
}

export default ProfileWhiteCard
