import { ACTIONS } from '../../redux/actions/actions'

/**
 * Redux Product Reducer.
 * @module ProductReducer
 * @category Redux
 * @subcategory Cms
 */

/**
 * @typedef Action
 * @property {Actions} type
 * @property {*} [payload]
 * @example
 *  {
 *    type: 'SET_PRODUCTS',
 *    payload: { ... }
 *  }
 */

/**
 * CMS reducers.
 * @function
 * @inner
 * @param {Object} products - The current state
 * @param {Action} action - Any necessary data (including the action type)
 * @returns {Object} The new state
 */
export const createProductReducer = (products = [], action) => {
    switch (action.type) {
    case ACTIONS.SET_PRODUCTS:
        return action.payload
    case ACTIONS.SET_PRODUCT:
        products.push(action.payload)
        return products
    case ACTIONS.UPDATE_PRODUCT:
        return products.map(product => product.id === action.payload.id ? action.payload : product)
    default:
        return products
    }
}
