
export const CONTENT_SOCIAL_MEDIA = 'SOCIAL_MEDIA'
export const CONTENT_FOOTER = 'FOOTER'
export const CONTENT_PAGE = 'PAGE'
export const DRAFT = 'Draft'
export const DEFAULT_CONTENT_VARIANT = CONTENT_PAGE

/**
 * Names of the actions that will be dispatched and checked on the CMS reducers.
 * @typedef {string} CMSActionsTypes
 * @readonly
 * @enum {CMSActionsTypes}
 * @example
 * DISABLE_EDIT_MODE: 'DISABLE_EDIT_MODE'
 * DISABLE_EDIT_TRAINING_PAGE: 'DISABLE_EDIT_TRAINING_PAGE'
 * TOGGLE_EDIT_MODE: 'TOGGLE_EDIT_MODE'
 * TOGGLE_EDIT_TRAINING_PAGE: 'TOGGLE_EDIT_TRAINING_PAGE'
 * SAVED_TEMPLATES: 'SAVED_TEMPLATES'
 * SAVE_EDITED_TEMPLATE: 'SAVE_EDITED_TEMPLATE'
 * DELETED_TEMPLATES: 'DELETED_TEMPLATES'
 * TOGGLE_ADD_NEW_TEMPLATE_MODE: 'TOGGLE_ADD_NEW_TEMPLATE_MODE'
 * UPDATE_EDITED_TEMPLATES: 'UPDATE_EDITED_TEMPLATES'
 * SAVED_VALUES: 'SAVED_VALUES'
 * EDITED_VALUES: 'EDITED_VALUES'
 * CHANGE_INPUT_VALUE: 'CHANGE_INPUT_VALUE'
 * UPDATE_EDITED_VALUES: 'UPDATE_EDITED_VALUES'
 * UPDATE_SAVED_VALUES: 'UPDATE_SAVED_VALUES'
 * EDIT_CMS_CONTENT: 'EDIT_CMS_CONTENT'
 * SET_NEW_CONTENT_MENU_CONFIGURATION: 'SET_NEW_CONTENT_MENU_CONFIGURATION'
 * SAVE_EDITED_CONTENT: 'SAVE_EDITED_CONTENT'
 * CLEAR_EDITED_ITEMS: 'CLEAR_EDITED_ITEMS'
 * RESET_OLD_EDITED_ITEMS: 'RESET_OLD_EDITED_ITEMS'
 * UPDATE_CONTENT_OF_PAGE: 'UPDATE_CONTENT_OF_PAGE'
 * HANDLE_INPUT_CHANGE: 'HANDLE_INPUT_CHANGE'
 * DELETED_ELEMENTS: 'DELETED_ELEMENTS'
 * COMPLETE_MENU_CREATION: 'COMPLETE_MENU_CREATION'
 * TOGGLE_TRAINING_PAGE: 'TOGGLE_TRAINING_PAGE'
 * IS_HOME_PAGE: 'IS_HOME_PAGE'
 * SET_CONTENT: 'SET_CONTENT'
 * ADD_TEMPLATE: 'ADD_TEMPLATE'
 * UPDATE_TEMPLATE: 'UPDATE_TEMPLATE'
 * DELETE_TEMPLATE: 'DELETE_TEMPLATE'
 * ADD_ELEMENT: 'ADD_ELEMENT'
 * UPDATE_ELEMENT: 'UPDATE_ELEMENT'
 * DELETE_ELEMENT: 'DELETE_ELEMENT'
 * ADD_VALUE: 'ADD_VALUE'
 * UPDATE_VALUE: 'UPDATE_VALUE'
 * DELETE_VALUE: 'DELETE_VALUE'
 * RESTORE_DEFAULT_VALUES: 'RESTORE_DEFAULT_VALUES'
 * SET_CONTENT_MENU: 'SET_CONTENT_MENU'
 * SET_PRODUCTS: 'SET_PRODUCTS'
 * SET_PRODUCT: 'SET_PRODUCT'
 * UPDATE_PRODUCT: 'UPDATE_PRODUCT'
 */
export const types = {
    DISABLE_EDIT_MODE: 'DISABLE_EDIT_MODE',
    DISABLE_EDIT_TRAINING_PAGE: 'DISABLE_EDIT_TRAINING_PAGE',
    TOGGLE_EDIT_MODE: 'TOGGLE_EDIT_MODE',
    TOGGLE_EDIT_TRAINING_PAGE: 'TOGGLE_EDIT_TRAINING_PAGE',
    SAVED_TEMPLATES: 'SAVED_TEMPLATES',
    SAVE_EDITED_TEMPLATE: 'SAVE_EDITED_TEMPLATE',
    DELETED_TEMPLATES: 'DELETED_TEMPLATES',
    TOGGLE_ADD_NEW_TEMPLATE_MODE: 'TOGGLE_ADD_NEW_TEMPLATE_MODE',
    UPDATE_EDITED_TEMPLATES: 'UPDATE_EDITED_TEMPLATES',
    SAVED_VALUES: 'SAVED_VALUES',
    EDITED_VALUES: 'EDITED_VALUES',
    CHANGE_INPUT_VALUE: 'CHANGE_INPUT_VALUE',
    UPDATE_EDITED_VALUES: 'UPDATE_EDITED_VALUES',
    UPDATE_SAVED_VALUES: 'UPDATE_SAVED_VALUES',
    EDIT_CMS_CONTENT: 'EDIT_CMS_CONTENT',
    SET_NEW_CONTENT_MENU_CONFIGURATION: 'SET_NEW_CONTENT_MENU_CONFIGURATION',
    SAVE_EDITED_CONTENT: 'SAVE_EDITED_CONTENT',
    CLEAR_EDITED_ITEMS: 'CLEAR_EDITED_ITEMS',
    RESET_OLD_EDITED_ITEMS: 'RESET_OLD_EDITED_ITEMS',
    UPDATE_CONTENT_OF_PAGE: 'UPDATE_CONTENT_OF_PAGE',
    HANDLE_INPUT_CHANGE: 'HANDLE_INPUT_CHANGE',
    DELETED_ELEMENTS: 'DELETED_ELEMENTS',
    COMPLETE_MENU_CREATION: 'COMPLETE_MENU_CREATION',
    TOGGLE_TRAINING_PAGE: 'TOGGLE_TRAINING_PAGE',
    IS_HOME_PAGE: 'IS_HOME_PAGE',
    SET_CONTENT: 'SET_CONTENT',
    ADD_TEMPLATE: 'ADD_TEMPLATE',
    UPDATE_TEMPLATE: 'UPDATE_TEMPLATE',
    DELETE_TEMPLATE: 'DELETE_TEMPLATE',
    ADD_ELEMENT: 'ADD_ELEMENT',
    UPDATE_ELEMENT: 'UPDATE_ELEMENT',
    DELETE_ELEMENT: 'DELETE_ELEMENT',
    ADD_VALUE: 'ADD_VALUE',
    UPDATE_VALUE: 'UPDATE_VALUE',
    DELETE_VALUE: 'DELETE_VALUE',
    RESTORE_DEFAULT_VALUES: 'RESTORE_DEFAULT_VALUES',
    SET_CONTENT_MENU: 'SET_CONTENT_MENU',
    SET_PRODUCTS: 'SET_PRODUCTS',
    SET_PRODUCT: 'SET_PRODUCT',
    UPDATE_PRODUCT: 'UPDATE_PRODUCT'
}
