// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".payment-step{display:flex;flex-direction:column;row-gap:.9375em}.payment-step h3{font-family:'Open Sans', sans-serif;font-weight:700;font-style:normal;text-transform:uppercase;font-size:1em;color:#191919}.payment-step a.web-button{margin:1.875em auto .9375em}.payment-step__methods-list{padding:0;list-style:none;display:flex;flex-direction:column;row-gap:.625em}.payment-step__methods-list li{border:1px solid #b8b8b8;padding:.9375em .9375em 1.875em .9375em;width:100%;display:grid;grid-row-gap:1.875em;margin-bottom:.9375em;padding:.9375em 1.875em;margin-bottom:0;grid-row-gap:0}.payment-step__methods-list li label{margin:0}.payment-step__methods-list li .cms-input{margin-bottom:0}.payment-step__methods-list .label-underline{border:solid 0.5px #64b42d;width:100%}.payment-step__methods-list .item--selectedType{border:1px solid #64b42d;padding:.9375em .9375em 1.875em .9375em;width:100%;display:grid;grid-row-gap:1.875em;padding:.9375em 1.875em;grid-row-gap:0}.payment-step__methods-list .item--disabled{opacity:0.5}.payment-step__continue-container{width:fit-content;margin-left:auto;margin-right:auto}.payment-step__continue-container a.button>span{font-family:rucksack, sans-serif;font-weight:700;font-style:normal}.payment-step .payment-container .cms-input:nth-last-child(2){width:46%;display:inline-block;margin-right:1.2em}.payment-step .payment-container .cms-input:nth-last-child(2) .cms-input-month{width:94%}.payment-step .payment-container .cms-input:nth-last-child(2) .cms-input-info-error{display:table}.payment-step .payment-container .cms-input:nth-last-child(2) .expiry-date-slash{width:50%;line-height:initial;color:#b8b8b8}.payment-step .payment-container .cms-input:nth-last-child(1){width:50%;display:inline-block}.payment-step .payment-container .cms-input:nth-last-child(1) .cms-input-info-error{display:table}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
