import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { cmsLanguage } from '../../../cms_language/cmsLanguage'
import { EMAIL_PATTERN } from './constants'

const InputEmail = (props) => {
    const { value, onChange, onFocus, onBlur, onError, readOnly } = props

    const pattern = EMAIL_PATTERN

    const isValidEmail = (email) => email ? pattern.test(email.toLowerCase()) : true

    const handleOnChange = e => {
        const newValue = e.target.value
        let hasError = false
        if (newValue) {
            hasError = !isValidEmail(newValue)
        }
        onChange(newValue, hasError)
    }

    const handleOnBlur = e => {
        const newValue = e.target.value
        const hasError = !isValidEmail(newValue)
        hasError
            ? onError(cmsLanguage.error.noValidEmail + '.')
            : onError()
        onChange(newValue, hasError)
        onBlur()
    }

    return (
        <>
            <div className="cms-input-box cms-input-box-icon cms-input-email">
                <input
                    data-testid="cms-input-email"
                    type="email"
                    value={value}
                    onChange={handleOnChange}
                    onFocus={onFocus}
                    onBlur={handleOnBlur}
                    placeholder={cmsLanguage.cms.emailPlaceholder + '...'}
                    readOnly={readOnly}
                    className={readOnly ? 'read-only' : ''}
                />
                <div>
                    <FontAwesomeIcon icon={faEnvelope} data-testid="cms-input-email-icon" />
                </div>
            </div>
        </>
    )
}

export default InputEmail
