// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".cms-modal{padding:2.8125em 3.75em 5.625em;border-bottom:solid .3125em #64b42d}.cms-modal-content{margin-bottom:3.75em}.cms-modal-title{height:3.75em;margin-bottom:2.8125em;display:flex;justify-content:space-between;align-items:center;border-bottom:solid .3125em #64b42d}.cms-modal-title-headline{display:flex;align-items:flex-end}.cms-modal-title-headline h4{padding-left:.3125em}.cms-modal-title-headline>h1{font-family:rucksack, sans-serif;font-size:1.625em;color:#191919;text-transform:uppercase}.cms-modal-title>button{width:inherit;padding:0;border:none;border-radius:inherit;text-decoration:none;text-align:inherit;background-color:transparent;cursor:pointer;color:inherit;font:inherit;display:flex;align-items:center;font-size:1.875em;color:#191919}.cms-modal-title>button:focus{outline:none}.cms-modal-title>button:hover{color:rgba(25,25,25,0.5)}.cms-modal-mandatory-warning p{padding-bottom:.9375em;font-family:'Open Sans', sans-serif;font-size:.875em;color:#f03}.cms-modal-buttons{height:5.625em;display:flex;flex-direction:column;justify-content:flex-end;font-weight:500}.cms-modal-buttons-container,.cms-modal-buttons-values{display:flex}.cms-modal-buttons-container{width:100%;justify-content:space-between}.cms-modal-buttons-values>button:last-child{margin-left:.9375em}@media only screen and (min-device-width: 768px) and (max-device-width: 992px){.cms-modal-mandatory-warning p{padding-bottom:inherit;margin-bottom:.625em}.cms-modal-buttons-container{flex-direction:column}.cms-modal-buttons-values{margin-bottom:.9375em}.cms-modal-buttons-values>button:last-child{margin-left:.625em}}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
