import { v4 as uuidv4 } from 'uuid'
import { cmsLanguage } from '../../../commons/cms_language/cmsLanguage'

const actionButtonBanner = () => {
    const fields = [
        {
            name: 'text',
            type: 'TEXT',
            id: uuidv4(),
            isMandatory: false
        },
        {
            name: 'textButton',
            type: 'BUTTON_TEXT',
            id: uuidv4(),
            isMandatory: true
        },
        {
            name: 'linkButton',
            type: 'BUTTON_LINK',
            id: uuidv4(),
            isMandatory: true
        }
    ]
    const values = fields.map(it => {
        return {
            field: it,
            fieldId: it.id,
            id: uuidv4(),
            value: ''
        }
    })
    return {
        id: uuidv4(),
        name: cmsLanguage.templatesNames.actionButtonBanner,
        type: 'ACTION_BUTTON_BANNER',
        elements: [
            {
                id: uuidv4(),
                values: values,
                type: 'default'
            }
        ],
        fields: fields
    }
}

export const ACTION_BUTTON_BANNER_TEMPLATE_ORDER = { TEXT: 0, BUTTON_TEXT: 1, BUTTON_LINK: 2 }

export default actionButtonBanner
