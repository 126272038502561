import React from 'react'
import './DeleteAlertModal.scss'
import AdminButton from '../../../cms/commons/admin_button/AdminButton'
import { cmsLanguage } from '../../../cms/commons/cms_language/cmsLanguage'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/**
 * @typedef DeleteAlertModalProps
 * @memberOf DeleteAlertModal
 * @property {function} onDelete - confirm function
 * @property {function} onClose - cancel function
 */

/**
 * Modal to get the confirmation before deleting a content
 * @class DeleteAlertModal
 * @category Components
 * @subcategory theme / commons
 * @param {DeleteAlertModalProps} props
 * @returns {React.ReactNode}
 * @example
 * <DeleteAlertModal onDelete={handleOnDelete} onClose={handleOnClose} />
 */
const DeleteAlertModal = (props) => {
    const { onDelete, onClose } = props

    return (
        <div className="cms-delete-modal" data-testid="info-delete-modal">
            <FontAwesomeIcon icon={['fa', 'exclamation-triangle']} className="cms-delete-modal-triangle"/>

            <div className={'cms-delete-modal-title'}>
                <div className="cms-delete-modal-title-headline">
                    <h3 className="cms-delete-modal-title-headline-text" data-testid="deleteContent">{cmsLanguage.cms.removeContent}</h3>
                </div>
            </div>

            <pre className={'cms-delete-modal-description'}>
                {cmsLanguage.cms.removeContentDescription}
            </pre>

            <div className="cms-delete-modal-buttons">
                <div className="cms-delete-modal-buttons-container">
                    <div className="cms-delete-modal-buttons-values">
                        <AdminButton
                            text={cmsLanguage.cms.delete}
                            type={'border-solid'}
                            color="red"
                            icon={faTrashAlt}
                            onClick={onDelete}/>
                        <AdminButton
                            text={cmsLanguage.cms.cancel}
                            type={'border-solid'}
                            color="black"
                            icon={['fa', 'times']}
                            onClick={onClose}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteAlertModal
