import store from '../../../redux/store'
import { CONFIG, MESH_CONFIG } from '../Constants'
// eslint-disable-next-line no-unused-vars
import axios, { AxiosResponse } from 'axios'

/**
 * MeshContentTemplate
 * @class MeshContentTemplate
 * @category Backend
 * @subcategory content_service
 */
export default class MeshContentTemplate {
    /**
     * Saves the template data
     * @function
     * @memberof MeshContentTemplate
     * @param {Template} templateBody
     * @returns {Promise<AxiosResponse<Template>>}
     * @static
     */
    static saveTemplate (templateBody) {
        const { accessToken, organizationId } = store.getState().user
        const url = `${MESH_CONFIG.SERVER_URL}/content-service/organizations/${organizationId}/templates`

        const config = {
            headers: {
                ...CONFIG.DEFAULT_HEADERS,
                CONTENT_TYPE: 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        return axios.post(url, templateBody, config)
    }

    /**
     * Deletes a template by its ID
     * @function
     * @memberof MeshContentTemplate
     * @param {string} templateId
     * @returns {Promise<AxiosResponse<void>>}
     * @static
     */
    static deleteTemplate (templateId) {
        const { accessToken, organizationId } = store.getState().user
        const url = `${MESH_CONFIG.SERVER_URL}/content-service/organizations/${organizationId}/templates/${templateId}`

        const config = {
            headers: {
                ...CONFIG.DEFAULT_HEADERS,
                CONTENT_TYPE: 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        return axios.delete(url, config)
    }

    /**
     * Updates a template
     * @function
     * @memberof MeshContentTemplate
     * @param {Template} templateBody
     * @returns {Promise<AxiosResponse<Template>>}
     * @static
     */
    static updateTemplate (templateBody) {
        const { accessToken, organizationId } = store.getState().user
        const url = `${MESH_CONFIG.SERVER_URL}/content-service/organizations/${organizationId}/templates`

        const config = {
            headers: {
                ...CONFIG.DEFAULT_HEADERS,
                CONTENT_TYPE: 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        return axios.put(url, templateBody, config)
    }

    static searchTemplate (templateExample) {
        const { accessToken, organizationId } = store.getState().user
        const url = `${MESH_CONFIG.SERVER_URL}/content-service/organizations/${organizationId}/templates/search`

        const config = {
            headers: {
                ...CONFIG.DEFAULT_HEADERS,
                CONTENT_TYPE: 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        return axios.post(url, templateExample, config)
    }
}
