import React, { useState } from 'react'
import InfoError from '../../../../../../../cms/commons/cms_input/components/info_error/InfoError'
import { setDisplayName } from '../../../../../../../utils/getDisplayName'
import './withErrorMessage.scss'

/**
 * Given a component which
 * @param BaseComponent
 * @return {function(*)}
 */
const withErrorMessage = (BaseComponent) => {
    const WithErrorMessage = (props) => {
        const [tooltip, setTooltip] = useState()
        const [errorMessage, setErrorMessage] = useState()

        const handleOnError = (errorMessage, tooltip) => {
            setErrorMessage(errorMessage)
            setTooltip(tooltip)
        }

        const inputWrapperClassName = `${
            errorMessage ? 'error-message-container--error' : ''
        } ${props.focused ? 'error-message-container--focus' : ''}`
        return (
            <div>
                <div className={inputWrapperClassName}>
                    <BaseComponent {...props} onError={handleOnError} />
                    <div className="cms-input-info-error">
                        <InfoError infoError={errorMessage} tooltip={tooltip} />
                    </div>
                </div>
            </div>
        )
    }
    setDisplayName(BaseComponent, WithErrorMessage, 'withErrorMessage')
    return WithErrorMessage
}

export default withErrorMessage
