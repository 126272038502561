import thunk from 'redux-thunk'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from './rootReducer'

/**
 * Sets up Redux
 * @module reduxSetup
 * @category Redux
 * @subcategory General
 */

/**
 * Gets the Redux developer tools extension if the app is running in dev mode.
 * Otherwise, gets the Redux compose variable
 */
const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 })

/**
 * Creates the Redux store.
 * Uses redux-thunk middleware.
 */
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))

export default store
