// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".cms-block-nav-modal{padding:2.8125em 3.75em 5.625em;border-bottom:solid .3125em #f03;font-family:'Open Sans', sans-serif;font-weight:200}.cms-block-nav-modal-triangle{color:#ff0033;font-size:4.5rem;margin-bottom:15px}.cms-block-nav-modal-description{line-height:2.5;font-size:16px;font-family:'Open Sans', sans-serif}.cms-block-nav-modal-title{height:3.75em;display:flex;justify-content:space-between;align-items:center;border-bottom:none}.cms-block-nav-modal-title-headline{display:flex;align-items:flex-end;color:#ff0033}.cms-block-nav-modal-title-headline h4{padding-left:.3125em}.cms-block-nav-modal-title-headline>h1{font-family:'Open Sans', sans-serif;font-size:1.625em;color:#191919;text-transform:uppercase}.cms-block-nav-modal-title-headline-text{font-weight:bold}.cms-block-nav-modal-buttons{display:flex;flex-direction:column}.cms-block-nav-modal-buttons-container,.cms-block-nav-modal-buttons-values{display:flex;gap:10px}.cms-block-nav-modal-buttons-container{width:100%;justify-content:space-between}.cms-block-nav-modal-buttons-values{margin-right:50px}.cms-block-nav-modal-buttons-values>button:last-child{margin-left:.9375em}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
