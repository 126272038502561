// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".edit-template-bar{position:relative;width:100%;margin-bottom:-1.875em;z-index:20;display:flex;justify-content:flex-end}.edit-template-bar.top-template{top:15.9375em}.edit-template-bar.content-template{top:.9375em}.edit-template-bar.no-template-button{position:absolute;top:-1.875em;right:-1.875em}.edit-template-bar button{margin-left:.9375em}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
