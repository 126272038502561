import store from '../../../redux/store'
import { CONFIG, MESH_CONFIG } from '../Constants'
// eslint-disable-next-line no-unused-vars
import axios, { AxiosResponse } from 'axios'
import { bindValueFieldIdsWithField } from '../../../cms/util/Util'
import MeshContentMenu from './MeshContentMenu'

/**
 * MeshContent
 * @class MeshContent
 * @category Backend
 * @subcategory content_service
 */
export default class MeshContent {
    /**
     * Gets a content by its ID.
     * @function
     * @memberof MeshContent
     * @param {string} contentId
     * @returns {Promise<TemplateSection>}
     * @static
     */
    static async fetchContent (contentId) {
        const { accessToken, organizationId } = store.getState().user
        const url = `${MESH_CONFIG.SERVER_URL}/content-service/organizations/${organizationId}/content/${contentId}`
        const config = {
            headers: {
                ...CONFIG.DEFAULT_HEADERS,
                CONTENT_TYPE: 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        const response = await axios.get(url, config)
            .catch(err => console.error(err))

        const menuId = response.data.menuId
        if (menuId) {
            response.data.menu = await MeshContentMenu.getMenu(menuId)
        }

        return bindValueFieldIdsWithField(response.data)
    }

    /**
     * Creates or updates the content (isNew = true/false).
     * @function
     * @memberof MeshContent
     * @param {TemplateSection} content
     * @param {boolean} isNew
     * @returns {Promise<TemplateSection>}
     * @static
     */
    static async createOrUpdateContent (content, isNew) {
        const { accessToken, organizationId } = store.getState().user
        const url = `${MESH_CONFIG.SERVER_URL}/content-service/organizations/${organizationId}/content`

        const config = {
            headers: {
                ...CONFIG.DEFAULT_HEADERS,
                CONTENT_TYPE: 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'accepts-version': '2'
            }
        }

        let response
        if (isNew) {
            response = await axios.post(url, content, config)
        } else {
            response = await axios.put(url, content, config)
        }

        return bindValueFieldIdsWithField(response.data)
    }

    /**
     * Deletes a content by its ID.
     * @function
     * @memberof MeshContent
     * @param {string} contentId
     * @returns {Promise<AxiosResponse<void>>}
     * @static
     */
    static deleteContent (contentId) {
        const { accessToken, organizationId } = store.getState().user
        const url = `${MESH_CONFIG.SERVER_URL}/content-service/organizations/${organizationId}/content/${contentId}`

        const config = {
            headers: {
                ...CONFIG.DEFAULT_HEADERS,
                CONTENT_TYPE: 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        return axios.delete(url, config)
    }

    static searchContent (contentExample) {
        const { accessToken, organizationId } = store.getState().user
        const url = `${MESH_CONFIG.SERVER_URL}/content-service/organizations/${organizationId}/content/search`

        const config = {
            headers: {
                ...CONFIG.DEFAULT_HEADERS,
                CONTENT_TYPE: 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        return axios.post(url, contentExample, config)
    }
}
