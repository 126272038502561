import React from 'react'
import './radio.scss'
import { cmsLanguage } from '../../../cms/commons/cms_language/cmsLanguage'

/**
 * @typedef RadioProps
 * @memberOf Radio
 * @property {string} name
 * @property {function} onClickHandler
 * @property {boolean} [checked]
 * @property {string} [selected]
 */

/**
 * Radio component.
 * Shows a radio button with its label
 * @class Radio
 * @category Components
 * @subcategory theme / commons
 * @param {RadioProps} props
 * @returns {React.ReactNode}
 * @example
 * <Radio  name="Never" disabled={false} onClickHandler={handleOnClick} selected={"Never} />
 */
export default function Radio ({ name, onClickHandler, checked, selected }) {
    const parseName = (name) => cmsLanguage.inputFields[name] || name
    return (
        <div className="radio-btn">
            <label>
                <input
                    onClick={(e) => onClickHandler(e.currentTarget.value)}
                    value={name}
                    type="radio"
                    name="radio"
                    defaultChecked={checked || selected === name}
                />
                <span>{parseName(name)}</span>
            </label>
        </div>
    )
}
