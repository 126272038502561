import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { cmsLanguage } from '../../../commons/cms_language/cmsLanguage'
import Dropdown from '../../../../theme/commons/dropdown/Dropdown'

/**
 * @typedef DropdownWithTitleProps
 * @memberOf DropdownWithTitle
 * @property {string} headline
 * @property {string} title
 * @property {Array<Menu>} data
 * @property {function} onSelect
 * @property {string} value
 * @property {string} menuPicture
 * @property {string} [data-testid]
 * @property {boolean} isMandatory
 */

/**
 * @class DropdownWithTitle
 * @category Components
 * @subcategory Templates / CmsContent
 * @param {DropdownWithTitleProps} props
 * @returns {React.ReactNode}
 * @example
 * <DropdownWithTitle
 *     headline={cmsLanguage.inputFields.assignedSection}
 *     title={cmsLanguage.cms.chooseASection}
 *     data={headingOptions}
 *     onSelect={onHeadingOptionChange}
 *     value={(menuItemSelection && menuItemSelection.heading) ? translateValue(menuItemSelection.heading.name) : ''}
 *     data-testid="heading-dropdown"
 * />
 */
const DropdownWithTitle = ({ headline, title, data, onSelect, value, menuPicture, 'data-testid': testId, isMandatory = true }) => {
    return (
        <div id="dropdown-with-title">
            <label className="cms-input-name">
                <div className="cms-input-icon"> {
                    isMandatory && (<FontAwesomeIcon
                        icon={['fa', 'asterisk']}
                        data-testid="cms-input-mandatory"
                    />)
                }

                </div>
                <span data-testid="cms-input-name">{headline}</span>
            </label>

            <div className="add-content-dropdown-div">
                <Dropdown
                    title={title}
                    data={data}
                    headerOnly="true"
                    onSelect={onSelect}
                    printHeader={true}
                    providedValue={value}
                    data-testid={testId}
                />
                {menuPicture && (
                    <img src={menuPicture} alt={cmsLanguage.cms.menuPreview} data-testid="menu-picture"/>
                )}
            </div>
        </div>
    )
}

export default DropdownWithTitle
